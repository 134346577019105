/* eslint-disable */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** The `BigInt` scalar type represents non-fractional signed whole numeric values. */
  BigInt: { input: bigint; output: bigint };
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: { input: GraphQL_Date; output: GraphQL_Date };
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: { input: GraphQL_DateTime; output: GraphQL_DateTime };
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  Json: { input: GraphQL_Json; output: GraphQL_Json };
  /** A scalar type for numbers with an associated currency code */
  Money: { input: GraphQL_Money; output: GraphQL_Money };
};

export type AccessGrantScopesInput = {
  grantedUserId: Scalars["Int"]["input"];
  marketId?: InputMaybe<Scalars["Int"]["input"]>;
  scope?: InputMaybe<ScopeInput>;
};

export type AccessGrantSupportedManagerIdsInput = {
  employeeIds: Array<Scalars["Int"]["input"]>;
  grantedUserId: Scalars["Int"]["input"];
};

export enum ActualVariableCashCompType {
  BONUS = "BONUS",
  COMMISSION = "COMMISSION",
}

export type ActualVariableCashCompensation = {
  __typename: "ActualVariableCashCompensation";
  employeeId: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  paymentDate: Scalars["Date"]["output"];
  subType: Maybe<Scalars["String"]["output"]>;
  type: ActualVariableCashCompType;
  value: Scalars["Money"]["output"];
};

export type AddGuidanceInput = {
  compCycleId: Scalars["Int"]["input"];
};

export type AdjustedCashBand = {
  __typename: "AdjustedCashBand";
  bandPoints: Array<AdjustedCashBandPoint>;
  currencyCode: CurrencyCode;
  /**
   * A CashBand is uniquely defined by its Position and
   *         CashBandName. Additionally, an adjusted band is unique by its target
   *         currency, market, and location adjustment (if any). Our id combines those four
   *         fields, separated by colons. For example, "123:SALARY:USD:Primary Market:Source"
   *         represents the Salary band for Position 123, in USD and without a
   *         location adjustment.
   */
  id: Scalars["String"]["output"];
  market: Market;
  name: Scalars["String"]["output"];
  position: Position;
};

export type AdjustedCashBandPoint = {
  __typename: "AdjustedCashBandPoint";
  annualCashEquivalent: Scalars["Money"]["output"];
  bandName: Scalars["String"]["output"];
  bandUnit: BandUnit;
  hourlyCashEquivalent: Scalars["Money"]["output"];
  /**
   * A CashBandPoint is uniquely defined by its band and point
   *         name. Additionally, an adjusted band is unique by its target currency
   *         and location adjustment (if any). Our id combines the band's id fields
   *         with these additional fields, separated by colons. For example,
   *         "123:SALARY:Min:USD:Source" represents the minimum of the Salary band
   *         for Position 123, in USD and without a location adjustment.
   */
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  value: CashBandValue;
};

export type AdjustedEquityBand = {
  __typename: "AdjustedEquityBand";
  bandPoints: Array<AdjustedEquityBandPoint>;
  currencyCode: CurrencyCode;
  /**
   * An EquityBand is uniquely defined by its Position and
   *         EquityBandName. Additionally, an adjusted band is unique by its target
   *         currency, market, and location adjustment (if any). Our id combines those four
   *         fields, separated by colons. For example,
   *         "123:INITIAL_EQUITY_GRANT:USD:Primary Market:Source" represents the Initial Equity
   *         Grant band for Position 123, in USD and without a location adjustment.
   */
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  valuation: Maybe<Valuation>;
};

export type AdjustedEquityBandPoint = {
  __typename: "AdjustedEquityBandPoint";
  annualCashEquivalent: Scalars["Money"]["output"];
  bandName: Scalars["String"]["output"];
  bandUnit: BandUnit;
  hourlyCashEquivalent: Scalars["Money"]["output"];
  /**
   * An EquityBandPoint is uniquely defined by its band and
   *         point name. Additionally, an adjusted band is unique by its target
   *         currency and location adjustment (if any). Our id combines the band's id
   *         fields with these additional fields, separated by colons. For example,
   *         "123:INITIAL_EQUITY_GRANT:Min:USD:Source" represents the minimum of the
   *         INITIAL_EQUITY_GRANT band for Position 123, in USD and without a
   *         location adjustment.
   */
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  /** The total gross value of the equity, independent of vesting period. */
  totalGrossValue: Scalars["Money"]["output"];
  /** The total percent ownership, independent of vesting period */
  totalPercentOwnership: Maybe<PercentValue>;
  totalUnits: Maybe<Scalars["Float"]["output"]>;
  value: EquityBandValue;
  vestingMonths: Scalars["Int"]["output"];
};

export type AnonymizedCompensation = {
  __typename: "AnonymizedCompensation";
  position: PositionStatistics;
  positionAndLocationGroup: PositionAndLocationGroupStatistics;
};

export enum AnonymizedCompensationStatisticsAccessType {
  COMP_BAND_ACCESS = "COMP_BAND_ACCESS",
  FULL_ACCESS = "FULL_ACCESS",
}

export type AnonymizedDataPoint = {
  __typename: "AnonymizedDataPoint";
  bonus: Maybe<Scalars["Money"]["output"]>;
  commission: Maybe<Scalars["Money"]["output"]>;
  salary: Maybe<Scalars["Money"]["output"]>;
};

export type ApplyGuidanceToAllInput = {
  compCycleId: Scalars["Int"]["input"];
};

export type AssignEmployeeBenefitsInput = {
  benefitsPackageId?: InputMaybe<Scalars["Int"]["input"]>;
  employeeId: Scalars["Int"]["input"];
};

export type AtsCandidate = {
  __typename: "AtsCandidate";
  email: Scalars["String"]["output"];
  firstName: Scalars["String"]["output"];
  jobTitle: Scalars["String"]["output"];
  lastName: Scalars["String"]["output"];
};

export type AuthSubject = {
  __typename: "AuthSubject";
  createdAt: Scalars["DateTime"]["output"];
  displayName: Maybe<Scalars["String"]["output"]>;
  email: Scalars["String"]["output"];
  emailVerified: Scalars["Boolean"]["output"];
  employeeId: Maybe<Scalars["Int"]["output"]>;
  id: Scalars["Int"]["output"];
  isDisabled: Scalars["Boolean"]["output"];
  nounScopes: Maybe<NounScopes>;
  organization: Organization;
  photoURL: Maybe<Scalars["String"]["output"]>;
  role: Maybe<PrimaryRoleName>;
  uid: Scalars["String"]["output"];
  user: User;
  userAccessGrant: Maybe<UserAccessGrant>;
  userView: Maybe<UserView>;
};

export enum AutomatedManagerAccessType {
  NONE = "NONE",
  REPORTS_CURRENT_AND_NEXT_POSITIONS = "REPORTS_CURRENT_AND_NEXT_POSITIONS",
  REPORTS_CURRENT_POSITIONS = "REPORTS_CURRENT_POSITIONS",
}

export enum BandPlacement {
  ABOVE = "ABOVE",
  BELOW = "BELOW",
  INSIDE = "INSIDE",
}

export type BandPointInput = {
  cuid?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  unit: BandUnit;
  value: Scalars["Float"]["input"];
};

export enum BandUnit {
  CASH = "CASH",
  PERCENTAGE = "PERCENTAGE",
  UNITS = "UNITS",
}

export type BaseNounScope = {
  __typename: "BaseNounScope";
  edit: Maybe<BaseScope>;
  view: Maybe<BaseScope>;
};

export type BaseScope = {
  __typename: "BaseScope";
  global: Scalars["Boolean"]["output"];
};

export type BatchPayload = {
  __typename: "BatchPayload";
  count: Scalars["Int"]["output"];
};

export type Benefit = {
  __typename: "Benefit";
  benefitsPackageId: Scalars["Int"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  description: Scalars["String"]["output"];
  id: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
  rank: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
  /** The UNADJUSTED monetary value in the user-input currency. */
  value: Maybe<Scalars["Money"]["output"]>;
};

export type BenefitInput = {
  description: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  rank: Scalars["String"]["input"];
  value?: InputMaybe<Scalars["Money"]["input"]>;
};

export type BenefitsPackage = {
  __typename: "BenefitsPackage";
  benefits: Array<Benefit>;
  createdAt: Scalars["DateTime"]["output"];
  currencyCode: CurrencyCode;
  id: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
  /** The total value of the benefits in the currency of the package. */
  totalValue: Scalars["Money"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type BenefitsPackageInput = {
  benefits: Array<BenefitInput>;
  currencyCode: CurrencyCode;
  name: Scalars["String"]["input"];
};

export type BoolFilter = {
  equals?: InputMaybe<Scalars["Boolean"]["input"]>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type BudgetGuide = {
  __typename: "BudgetGuide";
  budgetPerfRatingOption: BudgetPerfRatingOption;
  budgetPerfRatingOptionId: Scalars["Int"]["output"];
  budgetRange: BudgetRange;
  budgetRangeId: Scalars["Int"]["output"];
  compCycleId: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  organizationId: Scalars["Int"]["output"];
  percent: Scalars["Float"]["output"];
};

export type BudgetOverage = {
  __typename: "BudgetOverage";
  employee: Maybe<Employee>;
  overage: Scalars["Money"]["output"];
  variant: CompComponent;
};

export type BudgetPerfRatingOption = {
  __typename: "BudgetPerfRatingOption";
  compCycleId: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
  organizationId: Scalars["Int"]["output"];
  rank: Scalars["Int"]["output"];
};

export type BudgetRange = {
  __typename: "BudgetRange";
  compCycleId: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  organizationId: Scalars["Int"]["output"];
  rangeStart: Scalars["Float"]["output"];
};

export type BuildEmptyMatrixInput = {
  compCycleId: Scalars["Int"]["input"];
};

export enum BusinessStructure {
  B_CORP = "B_CORP",
  C_CORP = "C_CORP",
  LLC = "LLC",
  LLP = "LLP",
  LP = "LP",
  NONPROFIT = "NONPROFIT",
  SOLE_PROPRIETORSHIP = "SOLE_PROPRIETORSHIP",
}

export type Candidate = {
  __typename: "Candidate";
  candidateEmail: Scalars["String"]["output"];
  candidateName: Scalars["String"]["output"];
  id: Scalars["Int"]["output"];
  organization: Organization;
};

export type CandidateInput = {
  candidateEmail: Scalars["String"]["input"];
  candidateName: Scalars["String"]["input"];
};

export type CandidateWhereInput = {
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
};

export type CartaConnectionConfig = {
  __typename: "CartaConnectionConfig";
  author: User;
  id: Scalars["Int"]["output"];
  isActive: Scalars["Boolean"]["output"];
  lastSyncAt: Maybe<Scalars["DateTime"]["output"]>;
  refreshToken: Maybe<Scalars["String"]["output"]>;
};

export type CashBandInput = {
  bandPoints: Array<BandPointInput>;
  cuid?: InputMaybe<Scalars["String"]["input"]>;
  currencyCode: CurrencyCode;
  description?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
};

/** A CashBand Point value, which is always a CashValue */
export type CashBandValue = CashValue;

export enum CashCompType {
  COMMISSION = "COMMISSION",
  RECURRING_BONUS = "RECURRING_BONUS",
  RELOCATION_BONUS_OFFICE = "RELOCATION_BONUS_OFFICE",
  RELOCATION_BONUS_REMOTE = "RELOCATION_BONUS_REMOTE",
  SALARY = "SALARY",
  SIGNING_BONUS = "SIGNING_BONUS",
  SPOT_BONUS = "SPOT_BONUS",
}

export type CashCompensation = {
  __typename: "CashCompensation";
  activeAt: Scalars["Date"]["output"];
  adjustedCashBand: Maybe<AdjustedCashBand>;
  annualCashEquivalent: Scalars["Money"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  employeeId: Scalars["Int"]["output"];
  hourlyCashEquivalent: Scalars["Money"]["output"];
  jobTitle: Maybe<Scalars["String"]["output"]>;
  percentOfSalary: Scalars["Float"]["output"];
  positionId: Maybe<Scalars["Int"]["output"]>;
  provenance: Scalars["String"]["output"];
  type: CashCompType;
  unit: CompUnit;
};

export type CashCompensation2 = {
  __typename: "CashCompensation2";
  activeAt: Scalars["Date"]["output"];
  annualCashEquivalent: Scalars["Money"]["output"];
  employeeId: Scalars["Int"]["output"];
  hourlyCashEquivalent: Scalars["Money"]["output"];
  jobTitle: Maybe<Scalars["String"]["output"]>;
  percentOfSalary: Scalars["Float"]["output"];
  positionId: Maybe<Scalars["Int"]["output"]>;
  provenance: Scalars["String"]["output"];
  type: CashCompType;
  unit: CompUnit;
};

export enum CashComponentEnum {
  COMMISSION = "COMMISSION",
  RECURRING_BONUS = "RECURRING_BONUS",
  SALARY = "SALARY",
}

export type CashStatistics = {
  __typename: "CashStatistics";
  anonymizedDataPoints: Array<AnonymizedDataPoint>;
  salaryStatistics: SalaryStatistics;
};

/** A simple monetary value, used for either Cash or Equity comp */
export type CashValue = {
  __typename: "CashValue";
  annualRate: Maybe<Scalars["Money"]["output"]>;
  currencyCode: CurrencyCode;
  hourlyRate: Maybe<Scalars["Money"]["output"]>;
  /**
   * Returns the underlying raw cash value of the band point,
   *         regardless of whether it is an annual or hourly rate. This function
   *         should primarily be used internally, or when you already know whether
   *         you are dealing with annual or hourly values. Otherwise, use
   *         annualRate or hourlyRate.
   */
  rate: Maybe<Scalars["Money"]["output"]>;
};

export type CompBandAccessInput = {
  departmentIDs: Array<Scalars["Int"]["input"]>;
  global: Scalars["Boolean"]["input"];
  ladderIDs: Array<Scalars["Int"]["input"]>;
  positionIDs: Array<Scalars["Int"]["input"]>;
};

export enum CompComponent {
  ACTUAL_RECURRING_BONUS = "ACTUAL_RECURRING_BONUS",
  BONUS = "BONUS",
  EQUITY = "EQUITY",
  SALARY = "SALARY",
  SALARY_MARKET = "SALARY_MARKET",
  SALARY_MERIT = "SALARY_MERIT",
  SALARY_PROMOTION = "SALARY_PROMOTION",
  TARGET_COMMISSION = "TARGET_COMMISSION",
  TARGET_RECURRING_BONUS = "TARGET_RECURRING_BONUS",
}

export type CompComponentsInput = {
  allowActualRecurringBonus?: InputMaybe<Scalars["Boolean"]["input"]>;
  allowBonus?: InputMaybe<Scalars["Boolean"]["input"]>;
  allowEquity?: InputMaybe<Scalars["Boolean"]["input"]>;
  allowSalary?: InputMaybe<Scalars["Boolean"]["input"]>;
  allowSalaryMarket?: InputMaybe<Scalars["Boolean"]["input"]>;
  allowSalaryMerit?: InputMaybe<Scalars["Boolean"]["input"]>;
  allowSalaryPromotion?: InputMaybe<Scalars["Boolean"]["input"]>;
  allowTargetCommission?: InputMaybe<Scalars["Boolean"]["input"]>;
  allowTargetRecurringBonus?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CompCycle = {
  __typename: "CompCycle";
  allowActualRecurringBonus: Scalars["Boolean"]["output"];
  allowBonus: Scalars["Boolean"]["output"];
  allowEquity: Scalars["Boolean"]["output"];
  allowSalary: Scalars["Boolean"]["output"];
  allowSalaryMarket: Scalars["Boolean"]["output"];
  allowSalaryMerit: Scalars["Boolean"]["output"];
  allowSalaryPromotion: Scalars["Boolean"]["output"];
  allowTargetCommission: Scalars["Boolean"]["output"];
  allowTargetRecurringBonus: Scalars["Boolean"]["output"];
  awaitingReviewCount: Maybe<Scalars["Int"]["output"]>;
  budgetGuides: Array<BudgetGuide>;
  budgetOverages: Array<BudgetOverage>;
  createdAt: Scalars["DateTime"]["output"];
  currentPhase: Maybe<CompCyclePhase>;
  deletedAt: Maybe<Scalars["DateTime"]["output"]>;
  eligibilityRules: Maybe<Array<Array<Scalars["Json"]["output"]>>>;
  endedAt: Maybe<Scalars["DateTime"]["output"]>;
  estimatedSalaryBudget: Scalars["Money"]["output"];
  estimatedSalaryMerit: Scalars["Money"]["output"];
  finalApproversIds: Array<Scalars["Int"]["output"]>;
  id: Scalars["Int"]["output"];
  meritAdjustmentGuides: Array<MeritAdjustmentGuide>;
  name: Scalars["String"]["output"];
  participant: Maybe<Employee>;
  perfRatings: Array<Scalars["String"]["output"]>;
  phases: Array<CompCyclePhase>;
  potentialParticipants: PotentialParticipantsResult;
  recommendationsPreFill: Scalars["Boolean"]["output"];
  totalSpend: Scalars["Money"]["output"];
};

export type CompCycleparticipantArgs = {
  id: Scalars["Int"]["input"];
};

export type CompCyclepotentialParticipantsArgs = {
  displayName?: InputMaybe<Scalars["String"]["input"]>;
  eligibleOrIneligible?: InputMaybe<Scalars["String"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order?: InputMaybe<Scalars["String"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
};

export type CompCycle2 = {
  __typename: "CompCycle2";
  allowActualRecurringBonus: Scalars["Boolean"]["output"];
  allowBonus: Scalars["Boolean"]["output"];
  allowEquity: Scalars["Boolean"]["output"];
  allowSalary: Scalars["Boolean"]["output"];
  allowSalaryMarket: Scalars["Boolean"]["output"];
  allowSalaryMerit: Scalars["Boolean"]["output"];
  allowSalaryPromotion: Scalars["Boolean"]["output"];
  allowTargetCommission: Scalars["Boolean"]["output"];
  allowTargetRecurringBonus: Scalars["Boolean"]["output"];
  closeDate: Maybe<Scalars["Date"]["output"]>;
  currentPhase: Maybe<CompCyclePhase2>;
  finalApproversIds: Array<Scalars["Int"]["output"]>;
  id: Scalars["Int"]["output"];
  meritAdjustmentGuides: Array<MeritAdjustmentGuide>;
  name: Scalars["String"]["output"];
  organizationId: Scalars["Int"]["output"];
  participant: Maybe<CompCycleParticipant>;
  participants: ParticipantsResult2;
  phases: Array<CompCyclePhase2>;
  recommendationsPreFill: Scalars["Boolean"]["output"];
  /** Only use for non-sequential comp cycles. For sequential comp cycles, use 'compCycleAssignments' */
  reviewers: Array<Employee2>;
  startDate: Scalars["Date"]["output"];
};

export type CompCycle2participantArgs = {
  id: Scalars["Int"]["input"];
};

export type CompCycle2participantsArgs = {
  input?: InputMaybe<GetParticipantsInput>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  sortBy?: InputMaybe<Scalars["String"]["input"]>;
  sortDir?: InputMaybe<Scalars["String"]["input"]>;
};

export type CompCycleBudget = {
  __typename: "CompCycleBudget";
  actualRecurringBonus: Maybe<Scalars["Money"]["output"]>;
  bonus: Maybe<Scalars["Money"]["output"]>;
  compCycleId: Scalars["Int"]["output"];
  employee: Maybe<Employee>;
  employeeId: Maybe<Scalars["Int"]["output"]>;
  equity: Maybe<Scalars["Money"]["output"]>;
  reportsBudgets: Array<Maybe<CompCycleBudget>>;
  salary: Maybe<Scalars["Money"]["output"]>;
  salaryMarket: Maybe<Scalars["Money"]["output"]>;
  salaryMerit: Maybe<Scalars["Money"]["output"]>;
  salaryPromotion: Maybe<Scalars["Money"]["output"]>;
  targetCommission: Maybe<Scalars["Money"]["output"]>;
  targetRecurringBonus: Maybe<Scalars["Money"]["output"]>;
};

export type CompCycleBudgetDraft = {
  __typename: "CompCycleBudgetDraft";
  actualRecurringBonus: Maybe<Scalars["Money"]["output"]>;
  bonus: Maybe<Scalars["Money"]["output"]>;
  compCycleId: Scalars["Int"]["output"];
  employee: Maybe<Employee>;
  employeeId: Maybe<Scalars["Int"]["output"]>;
  equity: Maybe<Scalars["Money"]["output"]>;
  reportsDrafts: Array<CompCycleBudgetDraft>;
  salary: Maybe<Scalars["Money"]["output"]>;
  salaryMarket: Maybe<Scalars["Money"]["output"]>;
  salaryMerit: Maybe<Scalars["Money"]["output"]>;
  salaryPromotion: Maybe<Scalars["Money"]["output"]>;
  targetCommission: Maybe<Scalars["Money"]["output"]>;
  targetRecurringBonus: Maybe<Scalars["Money"]["output"]>;
};

export type CompCycleInput = {
  compComponents: CompComponentsInput;
  name: Scalars["String"]["input"];
  totalBudget: CompCycleTotalBudgetInput;
};

export type CompCycleParticipant = {
  __typename: "CompCycleParticipant";
  compCycleId: Scalars["Int"]["output"];
  compRecommendation: Maybe<CompRecommendation2>;
  lastActivityAt: Maybe<Scalars["Date"]["output"]>;
  managerCompCycleBudget: Maybe<CompCycleBudget>;
  perfRating: Maybe<Scalars["String"]["output"]>;
  phaseTimeline: Array<CompCyclePhaseAssignment2>;
  subject: Employee2;
  subjectId: Scalars["Int"]["output"];
};

export type CompCycleParticipantcompRecommendationArgs = {
  skipEligibility?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CompCyclePerfScoreInput = {
  compCycleId: Scalars["Int"]["input"];
  employeePerfScores: Array<PerfScoreRowInput>;
};

export type CompCyclePhase = {
  __typename: "CompCyclePhase";
  compCycle: Maybe<CompCycle>;
  compCycleId: Scalars["Int"]["output"];
  compCyclePhaseAssignments: Array<CompCyclePhaseAssignment>;
  id: Scalars["Int"]["output"];
  isMyPhase: Scalars["Boolean"]["output"];
  phaseOrder: Scalars["Int"]["output"];
  startDate: Scalars["Date"]["output"];
};

export type CompCyclePhase2 = {
  __typename: "CompCyclePhase2";
  compCycleId: Scalars["Int"]["output"];
  compCyclePhaseAssignments: Array<Maybe<CompCyclePhaseAssignment2>>;
  id: Scalars["Int"]["output"];
  isMyPhase: Scalars["Boolean"]["output"];
  organizationId: Scalars["Int"]["output"];
  phaseOrder: Scalars["Int"]["output"];
  startDate: Scalars["Date"]["output"];
};

export type CompCyclePhaseAssignment = {
  __typename: "CompCyclePhaseAssignment";
  assigneeId: Scalars["Int"]["output"];
  author: Maybe<User>;
  authorId: Maybe<Scalars["Int"]["output"]>;
  /** The employee who is assigned to this assignment. This field is only accessible if the user has view permissions to the assignee. Note that if the user does not have global access and an error occurs, the assignee is most likely not included in the user's scopes because it is someone outside of their reporting chain. */
  employee: Employee;
  id: Scalars["Int"]["output"];
  phase: Maybe<CompCyclePhase>;
  phaseId: Scalars["Int"]["output"];
  recommendees: Array<CompCyclePhaseAssignmentSubject>;
  reviewees: Array<CompCyclePhaseAssignmentSubject>;
  status: Maybe<RelativeCompRecStatus>;
  submittedItems: Maybe<Array<RecItem>>;
  submittedReviews: Maybe<Array<RecReview>>;
  userId: Maybe<Scalars["Int"]["output"]>;
};

export type CompCyclePhaseAssignment2 = {
  __typename: "CompCyclePhaseAssignment2";
  assigneeId: Scalars["Int"]["output"];
  compCycleId: Scalars["Int"]["output"];
  employee: Employee2;
  id: Scalars["Int"]["output"];
  organizationId: Scalars["Int"]["output"];
  phase: Maybe<CompCyclePhase2>;
  phaseId: Scalars["Int"]["output"];
  recommendees: Array<CompCyclePhaseAssignmentSubject2>;
  reviewees: Array<CompCyclePhaseAssignmentSubject2>;
  status: Maybe<PhaseTimelineStatus>;
};

export type CompCyclePhaseAssignmentSubject = {
  __typename: "CompCyclePhaseAssignmentSubject";
  author: Maybe<User>;
  authorId: Maybe<Scalars["Int"]["output"]>;
  id: Scalars["Int"]["output"];
  organizationId: Scalars["Int"]["output"];
  phaseAssignmentId: Scalars["Int"]["output"];
  subject: MinimalEmployee;
  subjectId: Scalars["Int"]["output"];
  type: CompCyclePhaseAssignmentSubjectType;
};

export type CompCyclePhaseAssignmentSubject2 = {
  __typename: "CompCyclePhaseAssignmentSubject2";
  id: Scalars["Int"]["output"];
  organizationId: Scalars["Int"]["output"];
  participant: CompCyclePhaseAssignmentSubjectParticipant;
  phaseAssignmentId: Scalars["Int"]["output"];
  subjectId: Scalars["Int"]["output"];
  type: CompCyclePhaseAssignmentSubjectType;
};

export type CompCyclePhaseAssignmentSubjectParticipant = {
  __typename: "CompCyclePhaseAssignmentSubjectParticipant";
  compCycleId: Scalars["Int"]["output"];
  managerId: Maybe<Scalars["Int"]["output"]>;
  photoURL: Maybe<Scalars["String"]["output"]>;
  subjectId: Scalars["Int"]["output"];
};

export enum CompCyclePhaseAssignmentSubjectType {
  RECOMMENDEE = "RECOMMENDEE",
  REVIEWEE = "REVIEWEE",
}

export type CompCycleSingleEmployeePerfScoreInput = {
  compCycleId: Scalars["Int"]["input"];
  employeeId: Scalars["Int"]["input"];
  note?: InputMaybe<Scalars["String"]["input"]>;
  perfRating: Scalars["String"]["input"];
};

export type CompCycleTotalBudgetInput = {
  actualRecurringBonus?: InputMaybe<Scalars["Money"]["input"]>;
  bonus?: InputMaybe<Scalars["Money"]["input"]>;
  equity?: InputMaybe<Scalars["Money"]["input"]>;
  salary?: InputMaybe<Scalars["Money"]["input"]>;
  targetCommission?: InputMaybe<Scalars["Money"]["input"]>;
  targetRecurringBonus?: InputMaybe<Scalars["Money"]["input"]>;
};

export type CompRecommendation = {
  __typename: "CompRecommendation";
  allSubmittedItems: Array<RecItem>;
  allSubmittedReviews: Array<RecReview>;
  compCycleId: Maybe<Scalars["Int"]["output"]>;
  latestSubmittedItems: Array<RecItem>;
  /** This calculates the comp change from the recommendation's latest submitted salary items (merit increases, promotions, market changes). */
  latestSubmittedPayIncrease: CompValue;
  latestSubmittedReviews: Array<RecReview>;
  reviewStatus: Maybe<ReviewRequestStatus>;
  subject: Employee;
  subjectId: Scalars["Int"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type CompRecommendationlatestSubmittedPayIncreaseArgs = {
  currencyCode?: InputMaybe<CurrencyCode>;
};

export type CompRecommendation2 = {
  __typename: "CompRecommendation2";
  allSubmittedItems: Array<RecItem2>;
  allSubmittedReviews: Array<RecReview2>;
  canICurrentlyEdit: Scalars["Boolean"]["output"];
  canICurrentlyReview: Scalars["Boolean"]["output"];
  compCycleId: Scalars["Int"]["output"];
  latestSubmittedItems: Array<RecItem2>;
  latestSubmittedPayIncrease: CompValue;
  latestSubmittedReviews: Array<RecReview2>;
  reviewStatus: CompRecommendationStatus;
  subjectId: Scalars["Int"]["output"];
};

export type CompRecommendation2canICurrentlyEditArgs = {
  actingManagerEmployeeId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type CompRecommendation2canICurrentlyReviewArgs = {
  actingManagerEmployeeId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type CompRecommendation2latestSubmittedPayIncreaseArgs = {
  currencyCode?: InputMaybe<CurrencyCode>;
};

export type CompRecommendationCsvExport = {
  __typename: "CompRecommendationCsvExport";
  data: Maybe<Scalars["String"]["output"]>;
};

export type CompRecommendationInput = {
  items: Array<RecItemInput>;
  subjectId: Scalars["Int"]["input"];
};

export enum CompRecommendationStatus {
  FUTURE_PHASE = "FUTURE_PHASE",
  LATE = "LATE",
  NEEDS_REVIEW = "NEEDS_REVIEW",
  REVIEWED = "REVIEWED",
}

export type CompStructure = {
  __typename: "CompStructure";
  allBandTypes: Array<Scalars["String"]["output"]>;
  allowHourlyEmployees: Scalars["Boolean"]["output"];
  availableCashBandTypes: Array<Scalars["String"]["output"]>;
  availableEquityBandTypes: Array<Scalars["String"]["output"]>;
  bandPointTypes: Array<Scalars["String"]["output"]>;
  businessStructure: Maybe<BusinessStructure>;
  cashBandTypes: Array<Scalars["String"]["output"]>;
  companyDescription: Maybe<Scalars["String"]["output"]>;
  employmentHoursPerWeek: Scalars["Int"]["output"];
  employmentHoursPerYear: Scalars["Int"]["output"];
  employmentWeeksPerYear: Scalars["Int"]["output"];
  equityBandTypes: Array<Scalars["String"]["output"]>;
  equityGrantMethod: EquityGrantMethod;
  equityGrantTypes: Array<EquityGrantTypes>;
  id: Scalars["Int"]["output"];
  levels: Array<Scalars["Int"]["output"]>;
  organization: Organization;
  refreshGrantValue: Maybe<Scalars["Float"]["output"]>;
  showPercentOwnership: Scalars["Boolean"]["output"];
  showValuation: Scalars["Boolean"]["output"];
  vestingCliff: Scalars["Int"]["output"];
  vestingMonths: Scalars["Int"]["output"];
};

export type CompStructureInput = {
  allowHourlyEmployees?: InputMaybe<Scalars["Boolean"]["input"]>;
  bandPointTypes?: InputMaybe<Array<Scalars["String"]["input"]>>;
  businessStructure?: InputMaybe<BusinessStructure>;
  cashBandTypes?: InputMaybe<Array<Scalars["String"]["input"]>>;
  companyDescription?: InputMaybe<Scalars["String"]["input"]>;
  employmentHoursPerWeek?: InputMaybe<Scalars["Int"]["input"]>;
  employmentWeeksPerYear?: InputMaybe<Scalars["Int"]["input"]>;
  equityBandTypes?: InputMaybe<Array<Scalars["String"]["input"]>>;
  equityGrantMethod?: InputMaybe<EquityGrantMethod>;
  equityGrantTypes?: InputMaybe<Array<EquityGrantTypes>>;
  refreshGrantValue?: InputMaybe<Scalars["Float"]["input"]>;
  showPercentOwnership?: InputMaybe<Scalars["Boolean"]["input"]>;
  showValuation?: InputMaybe<Scalars["Boolean"]["input"]>;
  vestingCliff?: InputMaybe<Scalars["Int"]["input"]>;
  vestingMonths?: InputMaybe<Scalars["Int"]["input"]>;
};

export enum CompSubComponent {
  SALARY_MARKET = "SALARY_MARKET",
  SALARY_MERIT = "SALARY_MERIT",
  SALARY_PROMOTION = "SALARY_PROMOTION",
}

export enum CompUnit {
  CASH = "CASH",
  HOURLY_CASH = "HOURLY_CASH",
  PERCENT_OF_SALARY = "PERCENT_OF_SALARY",
  UNITS = "UNITS",
}

/** Represents the cash compensation for an employee in both annual and hourly Money values, along with the original unitType. If the unitType is null, no change was found and the cash equivalents are zero. */
export type CompValue = {
  __typename: "CompValue";
  annualCashEquivalent: Scalars["Money"]["output"];
  hourlyCashEquivalent: Scalars["Money"]["output"];
  unitType: Maybe<CompUnit>;
};

export type CompaRatioStatistics = {
  __typename: "CompaRatioStatistics";
  average: Scalars["Float"]["output"];
  max: Scalars["Float"]["output"];
  median: Scalars["Float"]["output"];
  min: Scalars["Float"]["output"];
};

export type CompleteCompCycleResponse = {
  __typename: "CompleteCompCycleResponse";
  autoApprovedCompRecommendations: Array<CompRecommendation>;
  compCycle: CompCycle;
};

export type CostToMove = {
  __typename: "CostToMove";
  affectedEmployeeCount: Scalars["Int"]["output"];
  costToMove: Scalars["Money"]["output"];
  totalEmployeeCount: Scalars["Int"]["output"];
};

export type CostToMoveAnalysis = {
  __typename: "CostToMoveAnalysis";
  affectedEmployeeCount: Scalars["Int"]["output"];
  averageAffectedComparatio: Maybe<Scalars["Float"]["output"]>;
  averageTotalComparatio: Maybe<Scalars["Float"]["output"]>;
  byDepartment: Array<DepartmentCostToMove>;
  costToMove: Scalars["Money"]["output"];
  employeeExport: Scalars["String"]["output"];
  employees: Array<EmployeeCostToMove>;
  totalEmployeeCount: Scalars["Int"]["output"];
  totalSpend: Scalars["Money"]["output"];
};

export type CostToMoveAnalysisemployeesArgs = {
  input?: InputMaybe<GetEmployeesInput>;
};

export type CreatePhasesInput = {
  phaseOrder: Scalars["Int"]["input"];
  startDate: Scalars["Date"]["input"];
};

export type CreateUsersInput = {
  employeeIds: Array<Scalars["Int"]["input"]>;
  roles: Array<Scalars["String"]["input"]>;
};

export type CsvExport = {
  __typename: "CsvExport";
  data: Scalars["String"]["output"];
};

export type Currency = {
  __typename: "Currency";
  code: CurrencyCode;
  exchangeRate: Scalars["Float"]["output"];
  id: Scalars["Int"]["output"];
  isDefault: Scalars["Boolean"]["output"];
};

export enum CurrencyCode {
  AED = "AED",
  AMD = "AMD",
  ARS = "ARS",
  AUD = "AUD",
  BHD = "BHD",
  BRL = "BRL",
  CAD = "CAD",
  CHF = "CHF",
  CLP = "CLP",
  CNY = "CNY",
  COP = "COP",
  CZK = "CZK",
  DKK = "DKK",
  EUR = "EUR",
  GBP = "GBP",
  HKD = "HKD",
  HUF = "HUF",
  IDR = "IDR",
  ILS = "ILS",
  INR = "INR",
  JOD = "JOD",
  JPY = "JPY",
  KES = "KES",
  KRW = "KRW",
  KWD = "KWD",
  MXN = "MXN",
  NAD = "NAD",
  NOK = "NOK",
  NZD = "NZD",
  OMR = "OMR",
  PHP = "PHP",
  PKR = "PKR",
  PLN = "PLN",
  QAR = "QAR",
  RON = "RON",
  RUB = "RUB",
  SAR = "SAR",
  SEK = "SEK",
  SGD = "SGD",
  THB = "THB",
  TND = "TND",
  TRY = "TRY",
  TWD = "TWD",
  USD = "USD",
  UYU = "UYU",
  XPF = "XPF",
  ZAR = "ZAR",
}

export type CurrencyInput = {
  code: CurrencyCode;
  exchangeRate: Scalars["Float"]["input"];
};

/**
 * If 'variant' is TEXT, then 'options' will be empty and 'description'
 * might be empty/null. If 'variant' is DROPDOWN, then 'options' should have
 * at least one string in it and 'description' will be empty/null.
 */
export type CustomField = {
  __typename: "CustomField";
  description: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  options: Array<Scalars["String"]["output"]>;
  variant: CustomFieldVariant;
};

export type CustomFieldAnswer = {
  __typename: "CustomFieldAnswer";
  answer: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
};

export type CustomFieldAnswerInput = {
  answer: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
};

export type CustomFieldInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  options: Array<Scalars["String"]["input"]>;
  variant: CustomFieldVariant;
};

export enum CustomFieldVariant {
  DROPDOWN = "DROPDOWN",
  TEXT = "TEXT",
}

export type DateValueTuple = {
  __typename: "DateValueTuple";
  label: Scalars["Date"]["output"];
  value: Scalars["Int"]["output"];
};

export type DeleteGuidanceItemInput = {
  id: Scalars["Int"]["input"];
};

export type Department = {
  __typename: "Department";
  createdAt: Scalars["DateTime"]["output"];
  description: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
  ladders: Array<Ladder>;
  name: Scalars["String"]["output"];
  organization: Organization;
  organizationId: Scalars["Int"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type DepartmentCostToMove = {
  __typename: "DepartmentCostToMove";
  costToMoveAnalysis: CostToMoveAnalysis;
  department: Department;
};

export type DepartmentInput = {
  name: Scalars["String"]["input"];
};

export type DepartmentUpdateInput = {
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type DepartmentWhereInput = {
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
};

export type EmplaceCashBandInput = {
  bands: Array<CashBandInput>;
  marketId: Scalars["Int"]["input"];
  positionId: Scalars["Int"]["input"];
};

export type EmplaceCompRecommendationsResponse = {
  __typename: "EmplaceCompRecommendationsResponse";
  participants: Array<CompCycleParticipant>;
};

export type EmplaceEquityBandInput = {
  bands: Array<EquityBandInput>;
  marketId: Scalars["Int"]["input"];
  positionId: Scalars["Int"]["input"];
  valuationId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Employee = {
  __typename: "Employee";
  activeAt: Maybe<Scalars["DateTime"]["output"]>;
  activeCashCompensation: Maybe<Array<CashCompensation>>;
  activeEmployment: Maybe<Employment>;
  actualVariableCashCompensations: Maybe<Array<ActualVariableCashCompensation>>;
  adjustedCashBands: Maybe<Array<AdjustedCashBand>>;
  adjustedEquityBands: Maybe<Array<AdjustedEquityBand>>;
  allPerfRatings: Array<EmployeeCompCyclePerfRating>;
  benefitsPackage: Maybe<BenefitsPackage>;
  cashCompensation: Maybe<Array<CashCompensation>>;
  compCycleBudget: Maybe<CompCycleBudget>;
  compCycleBudgetDraft: Maybe<CompCycleBudgetDraft>;
  compRecommendation: Maybe<CompRecommendation>;
  compaRatio: Maybe<Scalars["Float"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  directReports: Array<Employee>;
  directReportsCount: Scalars["Int"]["output"];
  displayName: Scalars["String"]["output"];
  email: Scalars["String"]["output"];
  employeeNumber: Maybe<Scalars["String"]["output"]>;
  employmentStatus: EmploymentStatus;
  employments: Array<Employment>;
  equityHoldings: EquityHoldings;
  homeLocation: Maybe<Location>;
  id: Scalars["Int"]["output"];
  inactiveAt: Maybe<Scalars["DateTime"]["output"]>;
  indirectReports: Array<Employee>;
  indirectReportsCount: Scalars["Int"]["output"];
  isManager: Scalars["Boolean"]["output"];
  lastActivityAt: Maybe<Scalars["DateTime"]["output"]>;
  lastCompChangeAmount: Maybe<CompValue>;
  lastCompChangeDate: Maybe<Scalars["Date"]["output"]>;
  latestUserInvite: Maybe<UserInvitation>;
  location: Maybe<LocationGroup>;
  manager: Maybe<Employee>;
  managerId: Maybe<Scalars["Int"]["output"]>;
  minimalManager: Maybe<MinimalEmployee>;
  nextCashBands: Maybe<Array<EmployeeLevelingInfo>>;
  nextPositions: Array<Position>;
  perfRating: Maybe<EmployeeCompCyclePerfRating>;
  personalEmail: Maybe<Scalars["String"]["output"]>;
  phaseTimeline: Array<CompCyclePhaseAssignment>;
  reports: Maybe<Array<Employee>>;
  suggestedBudget: Maybe<Scalars["Money"]["output"]>;
  totalBenefitsValue: Maybe<Scalars["Money"]["output"]>;
  totalReportsCount: Scalars["Int"]["output"];
  totalRewardsStatementUrl: Maybe<Scalars["String"]["output"]>;
  user: Maybe<User>;
};

export type EmployeeactiveCashCompensationArgs = {
  currencyCode?: InputMaybe<CurrencyCode>;
};

export type EmployeeactiveEmploymentArgs = {
  currencyCode?: InputMaybe<CurrencyCode>;
};

export type EmployeeadjustedCashBandsArgs = {
  currencyCode?: InputMaybe<CurrencyCode>;
};

export type EmployeeadjustedEquityBandsArgs = {
  currencyCode?: InputMaybe<CurrencyCode>;
};

export type EmployeeallPerfRatingsArgs = {
  compCycleId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type EmployeecompCycleBudgetArgs = {
  compCycleId: Scalars["Int"]["input"];
};

export type EmployeecompCycleBudgetDraftArgs = {
  compCycleId: Scalars["Int"]["input"];
};

export type EmployeecompRecommendationArgs = {
  compCycleId: Scalars["Int"]["input"];
  currencyCode?: InputMaybe<CurrencyCode>;
  skipEligibility?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type EmployeeequityHoldingsArgs = {
  currencyCode?: InputMaybe<CurrencyCode>;
};

export type EmployeelastActivityAtArgs = {
  compCycleId: Scalars["Int"]["input"];
};

export type EmployeeperfRatingArgs = {
  compCycleId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type EmployeephaseTimelineArgs = {
  compCycleId: Scalars["Int"]["input"];
};

export type EmployeesuggestedBudgetArgs = {
  compCycleId: Scalars["Int"]["input"];
  currencyCode?: InputMaybe<CurrencyCode>;
};

export type Employee2 = {
  __typename: "Employee2";
  activeAt: Maybe<Scalars["Date"]["output"]>;
  activeCashCompensation: Maybe<Array<CashCompensation2>>;
  activeEmployment: Maybe<Employment2>;
  adjustedCashBands: Maybe<Array<AdjustedCashBand>>;
  adjustedEquityBands: Maybe<Array<AdjustedEquityBand>>;
  cashCompensation: Maybe<Array<CashCompensation2>>;
  directReportsCount: Scalars["Int"]["output"];
  displayName: Scalars["String"]["output"];
  email: Maybe<Scalars["String"]["output"]>;
  employeeNumber: Maybe<Scalars["String"]["output"]>;
  employmentStatus: EmploymentStatus;
  employments: Array<Employment2>;
  equityHoldings: EquityHoldings2;
  homeLocation: Maybe<Location2>;
  id: Scalars["Int"]["output"];
  inactiveAt: Maybe<Scalars["Date"]["output"]>;
  /** The count of indirect reports. Does not include "direct reports" */
  indirectReportsCount: Scalars["Int"]["output"];
  lastCompChangeAmount: Maybe<CompValue>;
  lastCompChangeDate: Maybe<Scalars["Date"]["output"]>;
  location: Maybe<LocationGroup2>;
  managerId: Maybe<Scalars["Int"]["output"]>;
  managerIds: Maybe<Array<Scalars["Int"]["output"]>>;
  minimalManager: Maybe<MinimalEmployee2>;
  organizationId: Scalars["Int"]["output"];
  personalEmail: Maybe<Scalars["String"]["output"]>;
  photoURL: Maybe<Scalars["String"]["output"]>;
  /** The count of indirect and direct reports */
  totalReportsCount: Scalars["Int"]["output"];
  totalRewardsStatementUrl: Maybe<Scalars["String"]["output"]>;
  user: Maybe<User2>;
  userId: Maybe<Scalars["Int"]["output"]>;
};

export type Employee2activeCashCompensationArgs = {
  currencyCode?: InputMaybe<CurrencyCode>;
};

export type Employee2adjustedCashBandsArgs = {
  currencyCode?: InputMaybe<CurrencyCode>;
};

export type Employee2adjustedEquityBandsArgs = {
  currencyCode?: InputMaybe<CurrencyCode>;
};

export type Employee2lastCompChangeAmountArgs = {
  currencyCode?: InputMaybe<CurrencyCode>;
};

export type Employee2s = {
  __typename: "Employee2s";
  employees: Array<Employee2>;
  meta: Employee2sMeta;
};

export type Employee2sCounts = {
  __typename: "Employee2sCounts";
  ACTIVE: Scalars["Int"]["output"];
  ALL: Scalars["Int"]["output"];
  INACTIVE: Scalars["Int"]["output"];
  LEVELING: Scalars["Int"]["output"];
  LEVELING_ISSUES: Scalars["Int"]["output"];
  filteredTotal: Scalars["Int"]["output"];
};

export type Employee2sFilter = {
  departmentIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  displayName?: InputMaybe<Scalars["String"]["input"]>;
  excludeCashComponents?: InputMaybe<Array<CashComponentEnum>>;
  ladderIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  levelIds?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  locationIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  managerIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  metaStatus?: InputMaybe<MetaStatusEnum>;
  payType?: InputMaybe<PayPeriodType>;
  reports?: InputMaybe<ReportsEnum>;
};

export type Employee2sMeta = {
  __typename: "Employee2sMeta";
  counts: Employee2sCounts;
  departments: Array<Department>;
  ladders: Array<Ladder>;
  levels: Array<Scalars["Int"]["output"]>;
  locations: Array<LocationGroup2>;
  managers: Array<MinimalEmployee2>;
};

export type Employee2sSort = {
  sortBy?: InputMaybe<Employee2sSortByEnum>;
  sortDir?: InputMaybe<Employee2sSortDirEnum>;
};

export enum Employee2sSortByEnum {
  compaRatio = "compaRatio",
  department = "department",
  displayName = "displayName",
  equityCount = "equityCount",
  jobTitle = "jobTitle",
  ladder = "ladder",
  level = "level",
  location = "location",
  manager = "manager",
  position = "position",
  tenure = "tenure",
  totalCash = "totalCash",
}

export enum Employee2sSortDirEnum {
  asc = "asc",
  desc = "desc",
}

export type EmployeeAnalytics = {
  __typename: "EmployeeAnalytics";
  count: Array<LabelValueTuple>;
  quartiles: Array<QuartileResponse>;
  timeSeries: Array<TimeSeriesResponse>;
};

export type EmployeeAnalyticscountArgs = {
  options: GroupByFilterOptions;
};

export type EmployeeAnalyticsquartilesArgs = {
  options: GroupByFilterOptions;
};

export type EmployeeAnalyticstimeSeriesArgs = {
  options: TimeSeriesOptions;
};

export enum EmployeeBandAccessType {
  CURRENT_AND_NEXT_BAND = "CURRENT_AND_NEXT_BAND",
  CURRENT_BAND = "CURRENT_BAND",
  NONE = "NONE",
}

export type EmployeeCompCyclePerfRating = {
  __typename: "EmployeeCompCyclePerfRating";
  createdAt: Scalars["DateTime"]["output"];
  deletedAt: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["Int"]["output"];
  note: Maybe<Scalars["String"]["output"]>;
  perfRating: Maybe<Scalars["String"]["output"]>;
};

export type EmployeeCostToMove = {
  __typename: "EmployeeCostToMove";
  comparatio: Maybe<Scalars["Float"]["output"]>;
  convertedCostToMove: Maybe<Scalars["Money"]["output"]>;
  costToMove: Maybe<Scalars["Money"]["output"]>;
  employee: Employee;
  spend: Scalars["Money"]["output"];
  targetBandPoint: Maybe<Scalars["Money"]["output"]>;
};

export type EmployeeLevelingInfo = {
  __typename: "EmployeeLevelingInfo";
  cashBands: Array<AdjustedCashBand>;
  employee: Employee;
  id: Scalars["String"]["output"];
  leveledBy: Maybe<User>;
  levelingCode: Maybe<Scalars["String"]["output"]>;
  levelingMethod: LevelingMethod;
  position: Maybe<Position>;
};

export type EmployeeNounScope = {
  __typename: "EmployeeNounScope";
  edit: Maybe<EmployeeScope>;
  view: Maybe<EmployeeScope>;
};

export type EmployeeScope = {
  __typename: "EmployeeScope";
  directReportIDs: Array<Scalars["Int"]["output"]>;
  global: Scalars["Boolean"]["output"];
  indirectReportIDs: Array<Scalars["Int"]["output"]>;
  supportingManagerEmployeeIDs: Maybe<Array<Maybe<Scalars["Int"]["output"]>>>;
};

export type EmployeeStatistics = {
  __typename: "EmployeeStatistics";
  averageTenure: Scalars["Int"]["output"];
  count: Scalars["Int"]["output"];
};

export type Employment = {
  __typename: "Employment";
  activeAt: Scalars["Date"]["output"];
  employee: Employee;
  employeeId: Scalars["Int"]["output"];
  employmentType: Maybe<EmploymentType>;
  id: Scalars["Int"]["output"];
  inactiveAt: Maybe<Scalars["Date"]["output"]>;
  jobTitle: Maybe<Scalars["String"]["output"]>;
  levelingCode: Maybe<Scalars["String"]["output"]>;
  levelingMethod: Maybe<LevelingMethod>;
  payCurrencyCode: Maybe<Scalars["String"]["output"]>;
  payPeriod: PayPeriodType;
  payRate: Maybe<Scalars["Money"]["output"]>;
  position: Maybe<Position>;
  positionId: Maybe<Scalars["Int"]["output"]>;
  salary: Maybe<Scalars["Money"]["output"]>;
};

export type Employment2 = {
  __typename: "Employment2";
  activeAt: Scalars["Date"]["output"];
  employeeId: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  inactiveAt: Maybe<Scalars["Date"]["output"]>;
  jobTitle: Maybe<Scalars["String"]["output"]>;
  levelingCode: Maybe<Scalars["String"]["output"]>;
  levelingMethod: Maybe<LevelingMethod>;
  organizationId: Scalars["Int"]["output"];
  payCurrencyCode: Maybe<Scalars["String"]["output"]>;
  payPeriod: PayPeriodType;
  payRate: Maybe<Scalars["Money"]["output"]>;
  position: Maybe<Position>;
  positionId: Maybe<Scalars["Int"]["output"]>;
  salary: Maybe<Scalars["Money"]["output"]>;
};

export type Employment2payRateArgs = {
  currencyCode?: InputMaybe<CurrencyCode>;
};

export type Employment2salaryArgs = {
  currencyCode?: InputMaybe<CurrencyCode>;
};

export enum EmploymentStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  PENDING = "PENDING",
}

export enum EmploymentType {
  CONTRACTOR = "CONTRACTOR",
  FREELANCE = "FREELANCE",
  FULL_TIME = "FULL_TIME",
  INTERN = "INTERN",
  PART_TIME = "PART_TIME",
}

export type EmploymentUpdateInput = {
  positionId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type EquityBandInput = {
  bandPoints: Array<EquityBandPointInput>;
  cuid?: InputMaybe<Scalars["String"]["input"]>;
  currencyCode: CurrencyCode;
  description?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
};

export type EquityBandPointInput = {
  cuid?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  unit: BandUnit;
  value: Scalars["Float"]["input"];
};

/** An EquityBand Point value, which can be a CashValue or UnitValue */
export type EquityBandValue = CashValue | PercentValue | UnitValue;

export type EquityGrant = {
  __typename: "EquityGrant";
  awardType: Maybe<Scalars["String"]["output"]>;
  createdAt: Maybe<Scalars["DateTime"]["output"]>;
  employee: Employee;
  equityType: EquityGrantTypes;
  fromCarta: Scalars["Boolean"]["output"];
  grossValue: Maybe<Scalars["Money"]["output"]>;
  id: Scalars["Int"]["output"];
  issueDate: Scalars["DateTime"]["output"];
  name: Scalars["String"]["output"];
  optionType: Maybe<OptionTypes>;
  price: Scalars["Money"]["output"];
  sharePrice: Maybe<Scalars["Money"]["output"]>;
  unitCount: Scalars["Int"]["output"];
  vestingInformation: Maybe<VestingInformation>;
  vestingScheduleDescription: Scalars["String"]["output"];
  vestingStartDate: Scalars["DateTime"]["output"];
};

export type EquityGrant2 = {
  __typename: "EquityGrant2";
  awardType: Maybe<Scalars["String"]["output"]>;
  employeeId: Scalars["Int"]["output"];
  equityType: EquityGrantTypes;
  id: Scalars["Int"]["output"];
  issueDate: Scalars["Date"]["output"];
  name: Scalars["String"]["output"];
  optionType: Maybe<OptionTypes>;
  organizationId: Scalars["Int"]["output"];
  price: Scalars["Money"]["output"];
  unitCount: Scalars["Int"]["output"];
  vestingScheduleDescription: Scalars["String"]["output"];
  vestingStartDate: Scalars["Date"]["output"];
};

export type EquityGrantInput = {
  awardType?: InputMaybe<Scalars["String"]["input"]>;
  employeeId: Scalars["Int"]["input"];
  equityType: EquityGrantTypes;
  issueDate: Scalars["DateTime"]["input"];
  name: Scalars["String"]["input"];
  price: Scalars["Float"]["input"];
  priceCurrencyCode: CurrencyCode;
  unitCount: Scalars["Int"]["input"];
  vestingScheduleDescription: Scalars["String"]["input"];
  vestingStartDate: Scalars["DateTime"]["input"];
};

export enum EquityGrantMethod {
  CASH = "CASH",
  UNITS = "UNITS",
}

export enum EquityGrantTypes {
  CAPITAL_INTEREST = "CAPITAL_INTEREST",
  RSA = "RSA",
  RSU = "RSU",
  SHARED_PROFIT_INTEREST = "SHARED_PROFIT_INTEREST",
  STOCK_OPTION = "STOCK_OPTION",
  UNKNOWN = "UNKNOWN",
}

export type EquityGrantVestEventSource = {
  __typename: "EquityGrantVestEventSource";
  grant: EquityGrant;
  grantName: Scalars["String"]["output"];
  id: Scalars["BigInt"]["output"];
  unitCount: Scalars["Int"]["output"];
  vestingDate: Scalars["DateTime"]["output"];
};

export type EquityGrantVestEventUpsertInput = {
  employeeId: Scalars["Int"]["input"];
  grantName: Scalars["String"]["input"];
  unitCount: Scalars["Int"]["input"];
  vestingDate: Scalars["DateTime"]["input"];
};

export type EquityGrantVestEventUpsertResponse = {
  __typename: "EquityGrantVestEventUpsertResponse";
  errors: Array<Scalars["String"]["output"]>;
  organization: Organization;
  result: Array<Employee>;
};

export type EquityHoldings = {
  __typename: "EquityHoldings";
  grants: Maybe<Array<EquityGrant>>;
  id: Scalars["Int"]["output"];
  totalGrossHoldingValue: Maybe<Scalars["Money"]["output"]>;
  totalUnitCount: Maybe<Scalars["Int"]["output"]>;
  valuationCurrency: Currency;
  vestingInformation: Maybe<VestingInformation>;
};

export type EquityHoldings2 = {
  __typename: "EquityHoldings2";
  grants: Maybe<Array<EquityGrant2>>;
  id: Scalars["Int"]["output"];
  totalGrossHoldingValue: Maybe<Scalars["Money"]["output"]>;
  totalUnitCount: Scalars["Int"]["output"];
};

export type ExploTokenResponse = {
  __typename: "ExploTokenResponse";
  provided_id: Scalars["String"]["output"];
  token: Scalars["String"]["output"];
};

export type ExportAllEmployeesInput = {
  departmentIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  ladderIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  levelingStatus?: InputMaybe<ExportEmployeeLevelingStatus>;
  levels?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  locationGroupIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  managerIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  reports?: InputMaybe<ExportAllEmployeesInputReports>;
};

export enum ExportAllEmployeesInputReports {
  all = "all",
  direct = "direct",
  indirect = "indirect",
}

export enum ExportEmployeeLevelingStatus {
  active = "active",
  all = "all",
  inactive = "inactive",
  leveled = "leveled",
  leveling = "leveling",
  unleveled = "unleveled",
}

export type GetEmployeesInput = {
  departmentIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  ladderIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  levels?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  locationGroupIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  managerIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
};

export type GetParticipantsInput = {
  bandPlacement?: InputMaybe<Array<BandPlacement>>;
  departmentIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  displayName?: InputMaybe<Scalars["String"]["input"]>;
  ladderIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  levels?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  locationGroupIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  managerIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  perfRatings?: InputMaybe<Array<Scalars["String"]["input"]>>;
  positionType?: InputMaybe<PositionType>;
  recItems?: InputMaybe<Array<RecItemType>>;
  reports?: InputMaybe<GetParticipantsInputReports>;
  statuses?: InputMaybe<Array<RelativeCompRecStatus>>;
};

export enum GetParticipantsInputReports {
  all = "all",
  direct = "direct",
  indirect = "indirect",
}

export type GroupByFilterOptions = {
  department?: InputMaybe<Scalars["String"]["input"]>;
  groupBy?: InputMaybe<Scalars["String"]["input"]>;
  level?: InputMaybe<Scalars["Int"]["input"]>;
};

export type HrisCsvUploadResult = {
  __typename: "HrisCsvUploadResult";
  employeeCount: Scalars["Int"]["output"];
  recordCount: Scalars["Int"]["output"];
};

export type ImportActualVariableCompInput = {
  currencyCode: Scalars["String"]["input"];
  employeeId: Scalars["Int"]["input"];
  paymentDate: Scalars["Date"]["input"];
  provenance: Scalars["String"]["input"];
  subType?: InputMaybe<Scalars["String"]["input"]>;
  type: ActualVariableCashCompType;
  value: Scalars["Float"]["input"];
};

export type ImportActualVariableCompResult = {
  __typename: "ImportActualVariableCompResult";
  invalid: Array<Scalars["String"]["output"]>;
  valid: Array<ActualVariableCashCompensation>;
};

export type ImportLevelingCodesInput = {
  levelingCodes: Array<Scalars["String"]["input"]>;
  positionId: Scalars["Int"]["input"];
};

export type ImportLevelingCodesOutput = {
  __typename: "ImportLevelingCodesOutput";
  entryCount: Scalars["Int"]["output"];
  organization: Organization;
};

export type ImportMarketDataInput = {
  customProvider: Scalars["String"]["input"];
  marketId: Scalars["Int"]["input"];
  name: Scalars["String"]["input"];
  provider: Scalars["String"]["input"];
  rows: Array<ImportMarketDataRow>;
};

export type ImportMarketDataRow = {
  companyStage?: InputMaybe<Scalars["String"]["input"]>;
  currency: Scalars["String"]["input"];
  equity10?: InputMaybe<Scalars["String"]["input"]>;
  equity25?: InputMaybe<Scalars["String"]["input"]>;
  equity40?: InputMaybe<Scalars["String"]["input"]>;
  equity50?: InputMaybe<Scalars["String"]["input"]>;
  equity60?: InputMaybe<Scalars["String"]["input"]>;
  equity75?: InputMaybe<Scalars["String"]["input"]>;
  equity90?: InputMaybe<Scalars["String"]["input"]>;
  jobCode: Scalars["String"]["input"];
  salary10?: InputMaybe<Scalars["String"]["input"]>;
  salary25?: InputMaybe<Scalars["String"]["input"]>;
  salary40?: InputMaybe<Scalars["String"]["input"]>;
  salary50?: InputMaybe<Scalars["String"]["input"]>;
  salary60?: InputMaybe<Scalars["String"]["input"]>;
  salary75?: InputMaybe<Scalars["String"]["input"]>;
  salary90?: InputMaybe<Scalars["String"]["input"]>;
  sampleSize?: InputMaybe<Scalars["String"]["input"]>;
  totalCash10?: InputMaybe<Scalars["String"]["input"]>;
  totalCash25?: InputMaybe<Scalars["String"]["input"]>;
  totalCash40?: InputMaybe<Scalars["String"]["input"]>;
  totalCash50?: InputMaybe<Scalars["String"]["input"]>;
  totalCash60?: InputMaybe<Scalars["String"]["input"]>;
  totalCash75?: InputMaybe<Scalars["String"]["input"]>;
  totalCash90?: InputMaybe<Scalars["String"]["input"]>;
  variable10?: InputMaybe<Scalars["String"]["input"]>;
  variable25?: InputMaybe<Scalars["String"]["input"]>;
  variable40?: InputMaybe<Scalars["String"]["input"]>;
  variable50?: InputMaybe<Scalars["String"]["input"]>;
  variable60?: InputMaybe<Scalars["String"]["input"]>;
  variable75?: InputMaybe<Scalars["String"]["input"]>;
  variable90?: InputMaybe<Scalars["String"]["input"]>;
};

export type ImportVariableCompInput = {
  activeAt: Scalars["Date"]["input"];
  currency?: InputMaybe<Scalars["String"]["input"]>;
  employeeId: Scalars["Int"]["input"];
  type: VariableCompType;
  unit: CompUnit;
  value: Scalars["Float"]["input"];
};

export type ImportVariableCompOutput = {
  __typename: "ImportVariableCompOutput";
  entriesAdded: Scalars["Int"]["output"];
};

export type IndividualMarketJobStructureScope = {
  __typename: "IndividualMarketJobStructureScope";
  marketId: Scalars["Int"]["output"];
  scope: Maybe<JobStructureScope>;
};

export type IntFilter = {
  equals?: InputMaybe<Scalars["Int"]["input"]>;
  gt?: InputMaybe<Scalars["Int"]["input"]>;
  gte?: InputMaybe<Scalars["Int"]["input"]>;
  in?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  lt?: InputMaybe<Scalars["Int"]["input"]>;
  lte?: InputMaybe<Scalars["Int"]["input"]>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars["Int"]["input"]>>;
};

export type InviteUserToAssembleInput = {
  email: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  roleName: Scalars["String"]["input"];
  supportedManagerIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
};

export type InviteUserToAssembleResponse = {
  __typename: "InviteUserToAssembleResponse";
  errors: Maybe<Array<Scalars["String"]["output"]>>;
  invitation: Maybe<UserInvitation>;
};

export type InviteUsersToEmployeePortalInput = {
  email: Scalars["String"]["input"];
  employeeId: Scalars["Int"]["input"];
  name: Scalars["String"]["input"];
};

export type InviteUsersToEmployeePortalInvitations = {
  __typename: "InviteUsersToEmployeePortalInvitations";
  email: Scalars["String"]["output"];
  invite: Maybe<UserInvitation>;
  newUser: Scalars["Boolean"]["output"];
};

export type InviteUsersToEmployeePortalResponse = {
  __typename: "InviteUsersToEmployeePortalResponse";
  errors: Maybe<Array<Scalars["String"]["output"]>>;
  invitations: Maybe<Array<InviteUsersToEmployeePortalInvitations>>;
};

export type JobStructureNounScope = {
  __typename: "JobStructureNounScope";
  edit: Maybe<BaseScope>;
  view: Maybe<JobStructureScope>;
};

export type JobStructureScope = {
  __typename: "JobStructureScope";
  departmentIDs: Array<Scalars["Int"]["output"]>;
  global: Scalars["Boolean"]["output"];
  ladderIDs: Array<Scalars["Int"]["output"]>;
  positionIDs: Array<Scalars["Int"]["output"]>;
};

export type LabelValueTuple = {
  __typename: "LabelValueTuple";
  label: Scalars["String"]["output"];
  value: Scalars["Int"]["output"];
};

export type Ladder = {
  __typename: "Ladder";
  createdAt: Scalars["DateTime"]["output"];
  department: Department;
  description: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
  organization: Organization;
  positions: Array<Position>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type LadderInput = {
  departmentId: Scalars["Int"]["input"];
  description?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
};

export type LadderUpdateInput = {
  departmentId?: InputMaybe<Scalars["Int"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type LadderWhereInput = {
  id?: InputMaybe<IntFilter>;
};

export type LevelEmployeesInput = {
  employeeId: Scalars["Int"]["input"];
  positionId: Scalars["Int"]["input"];
};

export type LevelingCodeMapping = {
  __typename: "LevelingCodeMapping";
  levelingCode: Scalars["String"]["output"];
  positionId: Scalars["Int"]["output"];
};

export enum LevelingMethod {
  AUTO_LEVEL = "AUTO_LEVEL",
  INHERITED = "INHERITED",
  MANUAL = "MANUAL",
}

export type Location = {
  __typename: "Location";
  city: Scalars["String"]["output"];
  country: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["Int"]["output"];
  locationGroup: Maybe<LocationGroup>;
  locationGroupId: Maybe<Scalars["Int"]["output"]>;
  organization: Organization;
  state: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type Location2 = {
  __typename: "Location2";
  city: Scalars["String"]["output"];
  country: Scalars["String"]["output"];
  id: Scalars["Int"]["output"];
  locationGroupId: Maybe<Scalars["Int"]["output"]>;
  state: Scalars["String"]["output"];
};

export type LocationAdjustment = {
  __typename: "LocationAdjustment";
  adjustment: Scalars["Json"]["output"];
  author: Maybe<User>;
  condition: Scalars["Json"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  description: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
  locationGroup: LocationGroup;
  updatedAt: Scalars["DateTime"]["output"];
};

export type LocationAdjustmentDeleteResponse = {
  __typename: "LocationAdjustmentDeleteResponse";
  locationAdjustment: LocationAdjustment;
  locationGroup: LocationGroup;
};

export type LocationAdjustmentInput = {
  adjustment: Scalars["Json"]["input"];
  condition: Scalars["Json"]["input"];
  description?: InputMaybe<Scalars["String"]["input"]>;
  locationGroupId: Scalars["Int"]["input"];
};

export type LocationAdjustmentUpdateInput = {
  adjustment?: InputMaybe<Scalars["Json"]["input"]>;
  condition?: InputMaybe<Scalars["Json"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
};

export type LocationGroup = {
  __typename: "LocationGroup";
  author: Maybe<User>;
  createdAt: Scalars["DateTime"]["output"];
  description: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
  isSourceComp: Scalars["Boolean"]["output"];
  locationAdjustments: Array<LocationAdjustment>;
  locations: Array<Location>;
  market: Market;
  name: Scalars["String"]["output"];
  organization: Organization;
  rankedAdjustmentIds: Array<Scalars["Int"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type LocationGroup2 = {
  __typename: "LocationGroup2";
  description: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
  isSourceComp: Scalars["Boolean"]["output"];
  marketId: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
  organizationId: Scalars["Int"]["output"];
};

export type LocationGroupInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  locationIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  marketId: Scalars["Int"]["input"];
  name: Scalars["String"]["input"];
};

export type LocationGroupUpdateInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  locationIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  rankedAdjustmentIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
};

export type LocationGroupUpdateResponse = {
  __typename: "LocationGroupUpdateResponse";
  locationGroup: LocationGroup;
  market: Market;
  unmappedLocations: Array<Location>;
};

export type LocationWhereInput = {
  city?: InputMaybe<StringFilter>;
  country?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  state?: InputMaybe<StringFilter>;
};

export type Market = {
  __typename: "Market";
  currencyCode: CurrencyCode;
  id: Scalars["Int"]["output"];
  locationGroups: Array<LocationGroup>;
  name: Scalars["String"]["output"];
};

export enum MarketDataCompType {
  EQUITY = "EQUITY",
  SALARY = "SALARY",
  TOTAL = "TOTAL",
  VARIABLE = "VARIABLE",
}

export type MarketDataPoint = {
  __typename: "MarketDataPoint";
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["Int"]["output"];
  marketDataSampleId: Scalars["Int"]["output"];
  percentile: Scalars["Int"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
  value: Maybe<Scalars["Int"]["output"]>;
};

export type MarketDataSample = {
  __typename: "MarketDataSample";
  compType: Scalars["String"]["output"];
  companyFilter: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  currencyCode: Scalars["String"]["output"];
  id: Scalars["Int"]["output"];
  jobCode: Scalars["String"]["output"];
  marketDataPoints: Array<MarketDataPoint>;
  marketDataSetId: Scalars["Int"]["output"];
  sampleSize: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type MarketDataSet = {
  __typename: "MarketDataSet";
  author: User;
  authorId: Scalars["Int"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["Int"]["output"];
  label: Scalars["String"]["output"];
  market: Maybe<Market>;
  marketDataSamples: Array<MarketDataSample>;
  marketId: Maybe<Scalars["Int"]["output"]>;
  provider: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type MarketInput = {
  currencyCode: CurrencyCode;
  name: Scalars["String"]["input"];
};

export type MarketJobStructureNounScope = {
  __typename: "MarketJobStructureNounScope";
  edit: Maybe<BaseScope>;
  view: Maybe<MarketJobStructureScope>;
};

export type MarketJobStructureScope = {
  __typename: "MarketJobStructureScope";
  allMarkets: Maybe<JobStructureScope>;
  departmentIDs: Array<Scalars["Int"]["output"]>;
  global: Scalars["Boolean"]["output"];
  ladderIDs: Array<Scalars["Int"]["output"]>;
  markets: Array<IndividualMarketJobStructureScope>;
  positionIDs: Array<Scalars["Int"]["output"]>;
};

export enum MergeCategory {
  ats = "ats",
  hris = "hris",
}

export type MergeConnectionConfig = {
  __typename: "MergeConnectionConfig";
  author: User;
  authorId: Scalars["Int"]["output"];
  color: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
  imageUrl: Maybe<Scalars["String"]["output"]>;
  integrationName: Scalars["String"]["output"];
  integrationType: Scalars["String"]["output"];
  isManualUpload: Scalars["Boolean"]["output"];
  lastSyncAt: Maybe<Scalars["DateTime"]["output"]>;
  organizationId: Scalars["Int"]["output"];
  originId: Maybe<Scalars["String"]["output"]>;
};

export type MergeConnectionConfigInput = {
  category: MergeCategory;
  originId: Scalars["String"]["input"];
  publicToken: Scalars["String"]["input"];
};

export type MergeImportWhereInput = {
  id?: InputMaybe<IntFilter>;
  mergeObjectId?: InputMaybe<StringFilter>;
  mergeObjectType?: InputMaybe<MergeObjectType>;
};

export type MergeLink = {
  __typename: "MergeLink";
  linkToken: Scalars["String"]["output"];
  originId: Scalars["String"]["output"];
};

export enum MergeObjectType {
  CANDIDATE = "CANDIDATE",
  EMPLOYEE = "EMPLOYEE",
  LOCATION = "LOCATION",
}

export type MeritAdjustmentGuide = {
  __typename: "MeritAdjustmentGuide";
  compCycleId: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  meritAdjustmentRange: MeritAdjustmentRange;
  meritAdjustmentRangeId: Scalars["Int"]["output"];
  organizationId: Scalars["Int"]["output"];
  /** A percent value represented as a number between 0 and 1 */
  percent: Scalars["Float"]["output"];
  perfRatingOption: PerfRatingOption;
  perfRatingOptionId: Scalars["Int"]["output"];
};

export type MeritAdjustmentRange = {
  __typename: "MeritAdjustmentRange";
  compCycleId: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  organizationId: Scalars["Int"]["output"];
  rangeStart: Scalars["Float"]["output"];
};

export enum MetaStatusEnum {
  ACTIVE = "ACTIVE",
  ALL = "ALL",
  INACTIVE = "INACTIVE",
  LEVELING = "LEVELING",
  LEVELING_ISSUES = "LEVELING_ISSUES",
}

export type MinimalEmployee = {
  __typename: "MinimalEmployee";
  activeAt: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  displayName: Scalars["String"]["output"];
  email: Scalars["String"]["output"];
  id: Scalars["Int"]["output"];
  user: Maybe<User>;
};

export type MinimalEmployee2 = {
  __typename: "MinimalEmployee2";
  displayName: Maybe<Scalars["String"]["output"]>;
  email: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
  photoURL: Maybe<Scalars["String"]["output"]>;
  user: Maybe<User2>;
};

export type Mutation = {
  __typename: "Mutation";
  addGuidanceRange: BudgetRange;
  addPerfRatingOption: BudgetPerfRatingOption;
  applyGuidanceToAll: EmplaceCompRecommendationsResponse;
  archiveOneOffer: Offer;
  assignEmployeeBenefits: Array<Employee>;
  buildEmptyMatrix: Scalars["Boolean"]["output"];
  /** Clears the Comp Band Viewer access for a user, across all market scopes. */
  clearUserAccessGrantCompBandAccess: User;
  clearUserInvitationAccessGrantCompBandAccess: UserInvitation;
  completeOneCompCycle: CompleteCompCycleResponse;
  createOneBenefitsPackage: BenefitsPackage;
  createOneCandidate: Candidate;
  createOneCompCycle: CompCycle;
  createOneDepartment: Department;
  createOneLadder: Ladder;
  createOneLocationAdjustment: LocationAdjustment;
  createOneLocationGroup: Market;
  createOneMarket: Market;
  createOneOffer: Offer;
  createOneOrgDomain: OrgDomain;
  createOnePosition: Position;
  createOneValuation: Valuation;
  deleteCompStructure: CompStructure;
  deleteGuidanceRange: Scalars["Boolean"]["output"];
  deleteManyDepartments: BatchPayload;
  deleteManyLadders: BatchPayload;
  deleteManyPositions: BatchPayload;
  deleteMarketDataSet: Scalars["Boolean"]["output"];
  deleteOneBenefitsPackage: BenefitsPackage;
  deleteOneCompCycle: CompCycle;
  deleteOneDepartment: Department;
  deleteOneLadder: Ladder;
  deleteOneLocationAdjustment: LocationAdjustmentDeleteResponse;
  deleteOneLocationGroup: LocationGroupUpdateResponse;
  deleteOneMarket: Scalars["Boolean"]["output"];
  deleteOneOrgDomain: OrgDomain;
  deleteOnePosition: Position;
  deletePerfRatingOption: Scalars["Boolean"]["output"];
  emplaceAllCurrencies: Array<Currency>;
  emplaceCashBands: Position;
  emplaceCompCyclePhases: CompCycle;
  emplaceCompRecommendations: EmplaceCompRecommendationsResponse;
  emplaceEquityBands: Position;
  generateAutoBudget: CompCycleBudgetDraft;
  importActualVariableComp: ImportActualVariableCompResult;
  importEmployeeEquityGrants: Array<Employee>;
  importEmployeePerfScores: ParticipantsResult2;
  importLevelingCodes: ImportLevelingCodesOutput;
  importMarketData: MarketDataSet;
  importOrgJobStructureWithBandsCsv: OrganizationUpdateResult;
  importRipplingPayrollCsv: HrisCsvUploadResult;
  importVariableComp: ImportVariableCompOutput;
  inviteUserToAssemble: InviteUserToAssembleResponse;
  inviteUsersToEmployeePortal: InviteUsersToEmployeePortalResponse;
  levelEmployees: Array<Employment>;
  publishBudgets: PublishBudgetsPayload;
  replaceCompCyclePhaseAssignment: Array<CompCyclePhaseAssignment>;
  revokeUserInvites: Array<UserInvitation>;
  submitRecReviews: SubmitRecReviewsResponse;
  updateBudgetGuide: BudgetGuide;
  updateCompCycleCompComponentSettings: CompCycle;
  updateCompCycleGuidancePreferences: CompCycle;
  updateCompCycleName: CompCycle;
  updateCompCyclePhases: CompCycle;
  updateEligibilityRules: CompCycle;
  updateEmployeePerfScore: CompCycleParticipant;
  updateGuidanceRange: BudgetRange;
  updateMeritGuide: MeritAdjustmentGuide;
  updateOneBenefitsPackage: BenefitsPackage;
  updateOneDepartment: Department;
  updateOneEmployment: Employment;
  updateOneLadder: Ladder;
  updateOneLocationAdjustment: LocationAdjustment;
  updateOneLocationGroup: LocationGroupUpdateResponse;
  updateOneMarket: Market;
  updateOnePosition: Position;
  updatePerfRatingOption: BudgetPerfRatingOption;
  updatePermissionSettings: PermissionSettings;
  /**
   * Updates the Comp Band Viewer access for a user.
   *       If a marketId is provided, it sets the scope only for that Market.
   *       Otherwise, it sets the scope to apply across all Markets.
   */
  updateUserAccessGrantCompBandAccess: User;
  updateUserAccessGrantRole: User;
  /**
   * Updates the Comp Band Viewer access for a user.
   *       If a marketId is provided, it sets the scope only for that Market.
   *       Otherwise, it sets the scope to apply across all Markets.
   */
  updateUserAccessGrantSupportedManagerIds: UserAccessGrant;
  updateUserInvitationAccessGrantCompBandAccess: UserInvitation;
  updateUserInvitationAccessGrantRole: UserInvitation;
  updateUserIsDisabled: Maybe<User>;
  upsertCartaConnectionConfig: Maybe<CartaConnectionConfig>;
  upsertCompStructure: CompStructure;
  upsertEquityGrantVestEvents: EquityGrantVestEventUpsertResponse;
  upsertOfferConfig: OfferConfig;
  upsertOneBudget: UpsertOneBudgetPayload;
  upsertOneManualMergeConnectionConfig: MergeConnectionConfig;
  upsertOneMergeConnectionConfig: MergeConnectionConfig;
  upsertPhilosophy: Philosophy;
  upsertPortalConfig: Maybe<PortalConfig>;
  upsertTableOrder: TableOrder;
  upsertUserView: UserView;
  upsertUsersWithAccessGrants: Array<User>;
};

export type MutationaddGuidanceRangeArgs = {
  data: AddGuidanceInput;
};

export type MutationaddPerfRatingOptionArgs = {
  data: AddGuidanceInput;
};

export type MutationapplyGuidanceToAllArgs = {
  data: ApplyGuidanceToAllInput;
};

export type MutationarchiveOneOfferArgs = {
  id: Scalars["Int"]["input"];
};

export type MutationassignEmployeeBenefitsArgs = {
  data: Array<AssignEmployeeBenefitsInput>;
};

export type MutationbuildEmptyMatrixArgs = {
  data: BuildEmptyMatrixInput;
};

export type MutationclearUserAccessGrantCompBandAccessArgs = {
  grantedUserId: Scalars["Int"]["input"];
};

export type MutationclearUserInvitationAccessGrantCompBandAccessArgs = {
  grantedUserInvitationId: Scalars["Int"]["input"];
};

export type MutationcompleteOneCompCycleArgs = {
  id: Scalars["Int"]["input"];
};

export type MutationcreateOneBenefitsPackageArgs = {
  benefitsPackage: BenefitsPackageInput;
};

export type MutationcreateOneCandidateArgs = {
  data: CandidateInput;
};

export type MutationcreateOneCompCycleArgs = {
  data: CompCycleInput;
};

export type MutationcreateOneDepartmentArgs = {
  data: DepartmentInput;
};

export type MutationcreateOneLadderArgs = {
  data: LadderInput;
};

export type MutationcreateOneLocationAdjustmentArgs = {
  data: LocationAdjustmentInput;
};

export type MutationcreateOneLocationGroupArgs = {
  data: LocationGroupInput;
};

export type MutationcreateOneMarketArgs = {
  data: MarketInput;
};

export type MutationcreateOneOfferArgs = {
  data: OfferInput;
};

export type MutationcreateOneOrgDomainArgs = {
  data: OrgDomainInput;
};

export type MutationcreateOnePositionArgs = {
  data: PositionDirectInput;
};

export type MutationcreateOneValuationArgs = {
  data: ValuationInput;
};

export type MutationdeleteGuidanceRangeArgs = {
  data: DeleteGuidanceItemInput;
};

export type MutationdeleteManyDepartmentsArgs = {
  ids: Array<InputMaybe<Scalars["Int"]["input"]>>;
};

export type MutationdeleteManyLaddersArgs = {
  ids: Array<InputMaybe<Scalars["Int"]["input"]>>;
};

export type MutationdeleteManyPositionsArgs = {
  ids: Array<InputMaybe<Scalars["Int"]["input"]>>;
};

export type MutationdeleteMarketDataSetArgs = {
  id: Scalars["Int"]["input"];
};

export type MutationdeleteOneBenefitsPackageArgs = {
  id: Scalars["Int"]["input"];
};

export type MutationdeleteOneCompCycleArgs = {
  id: Scalars["Int"]["input"];
};

export type MutationdeleteOneDepartmentArgs = {
  id: Scalars["Int"]["input"];
};

export type MutationdeleteOneLadderArgs = {
  id: Scalars["Int"]["input"];
};

export type MutationdeleteOneLocationAdjustmentArgs = {
  id: Scalars["Int"]["input"];
};

export type MutationdeleteOneLocationGroupArgs = {
  id: Scalars["Int"]["input"];
};

export type MutationdeleteOneMarketArgs = {
  marketId: Scalars["Int"]["input"];
};

export type MutationdeleteOneOrgDomainArgs = {
  domain: Scalars["String"]["input"];
};

export type MutationdeleteOnePositionArgs = {
  id: Scalars["Int"]["input"];
};

export type MutationdeletePerfRatingOptionArgs = {
  data: DeleteGuidanceItemInput;
};

export type MutationemplaceAllCurrenciesArgs = {
  data: Array<CurrencyInput>;
};

export type MutationemplaceCashBandsArgs = {
  data: EmplaceCashBandInput;
};

export type MutationemplaceCompCyclePhasesArgs = {
  compCycleId: Scalars["Int"]["input"];
  phases: Array<CreatePhasesInput>;
};

export type MutationemplaceCompRecommendationsArgs = {
  actingManagerEmployeeId?: InputMaybe<Scalars["Int"]["input"]>;
  compCycleId: Scalars["Int"]["input"];
  data: Array<CompRecommendationInput>;
};

export type MutationemplaceEquityBandsArgs = {
  data: EmplaceEquityBandInput;
};

export type MutationgenerateAutoBudgetArgs = {
  compCycleId: Scalars["Int"]["input"];
};

export type MutationimportActualVariableCompArgs = {
  data: Array<ImportActualVariableCompInput>;
};

export type MutationimportEmployeeEquityGrantsArgs = {
  data: Array<EquityGrantInput>;
};

export type MutationimportEmployeePerfScoresArgs = {
  data: CompCyclePerfScoreInput;
};

export type MutationimportLevelingCodesArgs = {
  data: Array<ImportLevelingCodesInput>;
};

export type MutationimportMarketDataArgs = {
  data: ImportMarketDataInput;
};

export type MutationimportOrgJobStructureWithBandsCsvArgs = {
  data: Scalars["String"]["input"];
};

export type MutationimportRipplingPayrollCsvArgs = {
  data: Array<RipplingPayrollCsvInput>;
};

export type MutationimportVariableCompArgs = {
  data: Array<ImportVariableCompInput>;
};

export type MutationinviteUserToAssembleArgs = {
  invite: InviteUserToAssembleInput;
};

export type MutationinviteUsersToEmployeePortalArgs = {
  invites: Array<InviteUsersToEmployeePortalInput>;
};

export type MutationlevelEmployeesArgs = {
  data: Array<LevelEmployeesInput>;
};

export type MutationpublishBudgetsArgs = {
  data: PublishBudgetsInput;
};

export type MutationreplaceCompCyclePhaseAssignmentArgs = {
  assigneeIds: Array<Scalars["Int"]["input"]>;
  compCycleId: Scalars["Int"]["input"];
  compCyclePhaseId: Scalars["Int"]["input"];
};

export type MutationrevokeUserInvitesArgs = {
  emails: Array<Scalars["String"]["input"]>;
};

export type MutationsubmitRecReviewsArgs = {
  actingManagerEmployeeId?: InputMaybe<Scalars["Int"]["input"]>;
  reviews: Array<RecReviewInput>;
};

export type MutationupdateBudgetGuideArgs = {
  data: UpdateBudgetGuidanceInput;
};

export type MutationupdateCompCycleCompComponentSettingsArgs = {
  data: UpdateCompComponentsInput;
};

export type MutationupdateCompCycleGuidancePreferencesArgs = {
  data: UpdateCompCycleGuidancePreferencesInput;
};

export type MutationupdateCompCycleNameArgs = {
  data: UpdateCompCycleNameInput;
};

export type MutationupdateCompCyclePhasesArgs = {
  compCycleId: Scalars["Int"]["input"];
  phases: Array<UpdatePhasesInput>;
};

export type MutationupdateEligibilityRulesArgs = {
  input: UpdateEligibilityRulesInput;
};

export type MutationupdateEmployeePerfScoreArgs = {
  data: CompCycleSingleEmployeePerfScoreInput;
};

export type MutationupdateGuidanceRangeArgs = {
  data: UpdateGuidanceRangeInput;
};

export type MutationupdateMeritGuideArgs = {
  data: UpdateMeritGuidanceInput;
};

export type MutationupdateOneBenefitsPackageArgs = {
  benefitsPackage: BenefitsPackageInput;
  id: Scalars["Int"]["input"];
};

export type MutationupdateOneDepartmentArgs = {
  data: DepartmentUpdateInput;
  id: Scalars["Int"]["input"];
};

export type MutationupdateOneEmploymentArgs = {
  data: EmploymentUpdateInput;
  id: Scalars["Int"]["input"];
};

export type MutationupdateOneLadderArgs = {
  data: LadderUpdateInput;
  id: Scalars["Int"]["input"];
};

export type MutationupdateOneLocationAdjustmentArgs = {
  data: LocationAdjustmentUpdateInput;
  id: Scalars["Int"]["input"];
};

export type MutationupdateOneLocationGroupArgs = {
  data: LocationGroupUpdateInput;
  id: Scalars["Int"]["input"];
};

export type MutationupdateOneMarketArgs = {
  data: MarketInput;
  marketId: Scalars["Int"]["input"];
};

export type MutationupdateOnePositionArgs = {
  data: PositionUpdateInput;
  id: Scalars["Int"]["input"];
};

export type MutationupdatePerfRatingOptionArgs = {
  data: UpdatePerfRatingOptionInput;
};

export type MutationupdatePermissionSettingsArgs = {
  data: PermissionSettingsInput;
};

export type MutationupdateUserAccessGrantCompBandAccessArgs = {
  data: AccessGrantScopesInput;
};

export type MutationupdateUserAccessGrantRoleArgs = {
  data: UserAccessGrantRoleInput;
};

export type MutationupdateUserAccessGrantSupportedManagerIdsArgs = {
  data: AccessGrantSupportedManagerIdsInput;
};

export type MutationupdateUserInvitationAccessGrantCompBandAccessArgs = {
  data: UserInvitationAccessGrantScopesInput;
};

export type MutationupdateUserInvitationAccessGrantRoleArgs = {
  data: UserInvitationAccessGrantRoleInput;
};

export type MutationupdateUserIsDisabledArgs = {
  isDisabled: Scalars["Boolean"]["input"];
  userId: Scalars["Int"]["input"];
};

export type MutationupsertCartaConnectionConfigArgs = {
  refreshToken: Scalars["String"]["input"];
};

export type MutationupsertCompStructureArgs = {
  data: CompStructureInput;
};

export type MutationupsertEquityGrantVestEventsArgs = {
  equityGrantVestEvents: Array<EquityGrantVestEventUpsertInput>;
};

export type MutationupsertOfferConfigArgs = {
  data: OfferConfigInput;
};

export type MutationupsertOneBudgetArgs = {
  input: UpsertOneBudgetInput;
};

export type MutationupsertOneMergeConnectionConfigArgs = {
  data: MergeConnectionConfigInput;
};

export type MutationupsertPhilosophyArgs = {
  data: PhilosophyInput;
};

export type MutationupsertPortalConfigArgs = {
  data?: InputMaybe<PortalConfigInput>;
};

export type MutationupsertTableOrderArgs = {
  data: TableOrderInput;
};

export type MutationupsertUserViewArgs = {
  data: UpsertUserViewInput;
};

export type MutationupsertUsersWithAccessGrantsArgs = {
  users: Array<UserWithAccessGrantsInput>;
};

export type NestedBoolFilter = {
  equals?: InputMaybe<Scalars["Boolean"]["input"]>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type NestedIntFilter = {
  equals?: InputMaybe<Scalars["Int"]["input"]>;
  gt?: InputMaybe<Scalars["Int"]["input"]>;
  gte?: InputMaybe<Scalars["Int"]["input"]>;
  in?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  lt?: InputMaybe<Scalars["Int"]["input"]>;
  lte?: InputMaybe<Scalars["Int"]["input"]>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars["Int"]["input"]>>;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars["String"]["input"]>;
  endsWith?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  gt?: InputMaybe<Scalars["String"]["input"]>;
  gte?: InputMaybe<Scalars["String"]["input"]>;
  in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  lt?: InputMaybe<Scalars["String"]["input"]>;
  lte?: InputMaybe<Scalars["String"]["input"]>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars["String"]["input"]>>;
  startsWith?: InputMaybe<Scalars["String"]["input"]>;
};

export enum NexusTableNameType {
  COMP_CYCLE_PLAN = "COMP_CYCLE_PLAN",
}

export enum Noun {
  AccessControl = "AccessControl",
  CashBand = "CashBand",
  CompCycle = "CompCycle",
  CompCycleBudget = "CompCycleBudget",
  CompRecommendation = "CompRecommendation",
  CompStructure = "CompStructure",
  Employee = "Employee",
  EmployeeCashCompensation = "EmployeeCashCompensation",
  EmployeeEquityCompensation = "EmployeeEquityCompensation",
  EquityBand = "EquityBand",
  Integration = "Integration",
  JobStructure = "JobStructure",
  Market = "Market",
  MarketData = "MarketData",
  Offer = "Offer",
  Philosophy = "Philosophy",
  SensitiveData = "SensitiveData",
}

export type NounScopes = {
  __typename: "NounScopes";
  AccessControl: BaseNounScope;
  CashBand: MarketJobStructureNounScope;
  CompCycle: BaseNounScope;
  CompCycleBudget: BaseNounScope;
  CompRecommendation: EmployeeNounScope;
  CompStructure: BaseNounScope;
  Employee: EmployeeNounScope;
  EmployeeCashCompensation: EmployeeNounScope;
  EmployeeEquityCompensation: EmployeeNounScope;
  EquityBand: MarketJobStructureNounScope;
  Integration: BaseNounScope;
  JobStructure: JobStructureNounScope;
  Market: BaseNounScope;
  MarketData: BaseNounScope;
  Offer: OfferNounScope;
  Philosophy: BaseNounScope;
  SensitiveData: BaseNounScope;
};

export type Offer = {
  __typename: "Offer";
  benefitsPackage: Maybe<BenefitsPackage>;
  candidate: Candidate;
  cash: OfferCash;
  closingMessage: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  equity: OfferEquity;
  expiredAt: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["Int"]["output"];
  isLegacy: Scalars["Boolean"]["output"];
  locationGroup: Maybe<LocationGroup>;
  market: Maybe<Market>;
  message: Maybe<Scalars["String"]["output"]>;
  offeredAt: Scalars["DateTime"]["output"];
  organization: Organization;
  outcomeDescription: Maybe<Scalars["String"]["output"]>;
  position: Maybe<Position>;
  review: Maybe<OfferReview>;
  title: Maybe<Scalars["String"]["output"]>;
  updatedAt: Maybe<Scalars["DateTime"]["output"]>;
};

export type OfferCash = {
  __typename: "OfferCash";
  data: Array<OfferCashBand>;
};

export type OfferCashBand = {
  __typename: "OfferCashBand";
  money: Scalars["Money"]["output"];
  percentOfSalary: Maybe<Scalars["Float"]["output"]>;
  type: Scalars["String"]["output"];
};

export type OfferCashBandInput = {
  currency: CurrencyCode;
  percentOfSalary?: InputMaybe<Scalars["Float"]["input"]>;
  type: Scalars["String"]["input"];
  value: Scalars["Int"]["input"];
};

export type OfferCashInput = {
  data: Array<OfferCashBandInput>;
};

export type OfferConfig = {
  __typename: "OfferConfig";
  backgroundUrlPath: Maybe<Scalars["String"]["output"]>;
  brandColorPrimary: Maybe<Scalars["String"]["output"]>;
  customFields: Array<CustomField>;
  defaultClosingMessage: Maybe<Scalars["String"]["output"]>;
  defaultOutcomeDescription: Scalars["String"]["output"];
  defaultWelcomeMessage: Maybe<Scalars["String"]["output"]>;
  equityCashInValuationCurrency: Scalars["Boolean"]["output"];
  equityFootnote: Maybe<Scalars["String"]["output"]>;
  exitOutcomes: Array<Scalars["Float"]["output"]>;
  greyLogoUrlPath: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
  organization: Organization;
  primaryLogoUrlPath: Maybe<Scalars["String"]["output"]>;
  requireApproval: Scalars["Boolean"]["output"];
  sharePriceLabel: Scalars["String"]["output"];
  showAnnualizedEquity: Scalars["Boolean"]["output"];
  showCurrentEquityValue: Scalars["Boolean"]["output"];
  showEquityInformation: Scalars["Boolean"]["output"];
  showFdso: Scalars["Boolean"]["output"];
  showSharePriceFootnote: Scalars["Boolean"]["output"];
  showStage: Scalars["Boolean"]["output"];
  whiteLogoUrlPath: Maybe<Scalars["String"]["output"]>;
  xAxisAnnotations: Array<Scalars["String"]["output"]>;
};

export type OfferConfigInput = {
  customFields?: InputMaybe<Array<CustomFieldInput>>;
  defaultClosingMessage?: InputMaybe<Scalars["String"]["input"]>;
  defaultOutcomeDescription?: InputMaybe<Scalars["String"]["input"]>;
  defaultWelcomeMessage?: InputMaybe<Scalars["String"]["input"]>;
  equityCashInValuationCurrency?: InputMaybe<Scalars["Boolean"]["input"]>;
  equityFootnote?: InputMaybe<Scalars["String"]["input"]>;
  exitOutcomes?: InputMaybe<Array<Scalars["Float"]["input"]>>;
  requireApproval?: InputMaybe<Scalars["Boolean"]["input"]>;
  sharePriceLabel?: InputMaybe<Scalars["String"]["input"]>;
  showAnnualizedEquity?: InputMaybe<Scalars["Boolean"]["input"]>;
  showCurrentEquityValue?: InputMaybe<Scalars["Boolean"]["input"]>;
  showEquityInformation?: InputMaybe<Scalars["Boolean"]["input"]>;
  showFdso?: InputMaybe<Scalars["Boolean"]["input"]>;
  showSharePriceFootnote?: InputMaybe<Scalars["Boolean"]["input"]>;
  showStage?: InputMaybe<Scalars["Boolean"]["input"]>;
  xAxisAnnotations?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type OfferEquity = {
  __typename: "OfferEquity";
  data: Array<OfferEquityBand>;
};

export type OfferEquityBand = {
  __typename: "OfferEquityBand";
  money: Maybe<Scalars["Money"]["output"]>;
  percentOfSalary: Maybe<Scalars["Float"]["output"]>;
  type: Scalars["String"]["output"];
  units: Maybe<Scalars["Int"]["output"]>;
};

export type OfferEquityBandInput = {
  currency?: InputMaybe<CurrencyCode>;
  percentOfSalary?: InputMaybe<Scalars["Float"]["input"]>;
  type: Scalars["String"]["input"];
  value: Scalars["Int"]["input"];
};

export type OfferEquityInput = {
  data: Array<OfferEquityBandInput>;
};

export type OfferInput = {
  approverName?: InputMaybe<Scalars["String"]["input"]>;
  benefitsPackageId?: InputMaybe<Scalars["Int"]["input"]>;
  candidateId: Scalars["Int"]["input"];
  cash: OfferCashInput;
  closingMessage?: InputMaybe<Scalars["String"]["input"]>;
  customFieldAnswers?: InputMaybe<Array<CustomFieldAnswerInput>>;
  decisionMakerName?: InputMaybe<Scalars["String"]["input"]>;
  equity: OfferEquityInput;
  jobCode?: InputMaybe<Scalars["String"]["input"]>;
  locationGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  managerName?: InputMaybe<Scalars["String"]["input"]>;
  marketId?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  offerId?: InputMaybe<Scalars["Int"]["input"]>;
  offeredAt: Scalars["DateTime"]["input"];
  outcomeDescription?: InputMaybe<Scalars["String"]["input"]>;
  positionId?: InputMaybe<Scalars["Int"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type OfferNounScope = {
  __typename: "OfferNounScope";
  edit: Maybe<OfferScope>;
  view: Maybe<OfferScope>;
};

export type OfferReview = {
  __typename: "OfferReview";
  approverName: Maybe<Scalars["String"]["output"]>;
  customFieldAnswers: Array<CustomFieldAnswer>;
  decisionMakerName: Scalars["String"]["output"];
  id: Scalars["Int"]["output"];
  jobCode: Maybe<Scalars["String"]["output"]>;
  managerName: Scalars["String"]["output"];
};

export type OfferScope = {
  __typename: "OfferScope";
  authored: Scalars["Boolean"]["output"];
  global: Scalars["Boolean"]["output"];
};

export enum OptionTypes {
  INCENTIVE = "INCENTIVE",
  NON_QUALIFIED = "NON_QUALIFIED",
}

export type OrgDomain = {
  __typename: "OrgDomain";
  domain: Scalars["String"]["output"];
  id: Scalars["Int"]["output"];
  organization: Organization;
};

export type OrgDomainInput = {
  domain: Scalars["String"]["input"];
};

export type Organization = {
  __typename: "Organization";
  compStructure: Maybe<CompStructure>;
  defaultCurrency: CurrencyCode;
  departments: Array<Department>;
  domains: Array<OrgDomain>;
  hasActiveCompCycle: Scalars["Boolean"]["output"];
  hasAnyEquityVestEvents: Scalars["Boolean"]["output"];
  hasAtsIntegration: Scalars["Boolean"]["output"];
  id: Scalars["Int"]["output"];
  lastActualVariableCompUploadDate: Maybe<Scalars["DateTime"]["output"]>;
  lastEquityGrantsUploadDate: Maybe<Scalars["DateTime"]["output"]>;
  lastEquityVestingEventsUploadDate: Maybe<Scalars["DateTime"]["output"]>;
  lastLevelingCodeUploadDate: Maybe<Scalars["DateTime"]["output"]>;
  lastVariableUpload: Maybe<Scalars["DateTime"]["output"]>;
  levelingCodeMappings: Array<LevelingCodeMapping>;
  locationGroups: Array<LocationGroup>;
  logoUrlPath: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  permissionSettings: PermissionSettings;
  users: Array<User>;
  valuations: Array<Valuation>;
};

export type OrganizationUpdateResult = {
  __typename: "OrganizationUpdateResult";
  reason: Maybe<Scalars["String"]["output"]>;
  successful: Scalars["Boolean"]["output"];
};

export type ParticipantsResult = {
  __typename: "ParticipantsResult";
  count: Scalars["Int"]["output"];
  employees: Array<Employee>;
};

export type ParticipantsResult2 = {
  __typename: "ParticipantsResult2";
  count: Scalars["Int"]["output"];
  participants: Array<CompCycleParticipant>;
};

export enum PayPeriodType {
  ANNUAL = "ANNUAL",
  HOURLY = "HOURLY",
}

/** % ownership AKA a fraction of FDSO. Only used for Equity comp */
export type PercentValue = {
  __typename: "PercentValue";
  /** The percentage represented as a decimal between 0-1 */
  decimalValue: Maybe<Scalars["Float"]["output"]>;
  /** The percentage represented as a decimal between 0-100 */
  percentValue: Maybe<Scalars["Float"]["output"]>;
};

export type PerfRatingOption = {
  __typename: "PerfRatingOption";
  compCycleId: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
  organizationId: Scalars["Int"]["output"];
  rank: Scalars["Int"]["output"];
};

export type PerfScoreRowInput = {
  email: Scalars["String"]["input"];
  note?: InputMaybe<Scalars["String"]["input"]>;
  perfRating: Scalars["String"]["input"];
};

export type PermissionSettings = {
  __typename: "PermissionSettings";
  anonymizedCompensationEmployeeThreshold: Scalars["Int"]["output"];
  anonymizedCompensationStatisticsAccess: AnonymizedCompensationStatisticsAccessType;
  automatedManagerAccess: AutomatedManagerAccessType;
  canRecruitersViewAnonymizedStatistics: Scalars["Boolean"]["output"];
  employeeBandAccess: EmployeeBandAccessType;
  id: Scalars["Int"]["output"];
  organization: Organization;
  teamCompensationAccess: TeamCompensationAccessType;
};

export type PermissionSettingsInput = {
  anonymizedCompensationEmployeeThreshold?: InputMaybe<Scalars["Int"]["input"]>;
  anonymizedCompensationStatisticsAccess?: InputMaybe<AnonymizedCompensationStatisticsAccessType>;
  automatedManagerAccess?: InputMaybe<AutomatedManagerAccessType>;
  canRecruitersViewAnonymizedStatistics?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  employeeBandAccess?: InputMaybe<EmployeeBandAccessType>;
  teamCompensationAccess?: InputMaybe<TeamCompensationAccessType>;
};

export enum PhaseTimelineStatus {
  DENIED = "DENIED",
  FUTURE_PHASE = "FUTURE_PHASE",
  LATE = "LATE",
  NEEDS_REVIEW = "NEEDS_REVIEW",
  REVIEWED = "REVIEWED",
  SKIPPED = "SKIPPED",
}

export type Philosophy = {
  __typename: "Philosophy";
  author: Maybe<User>;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["Int"]["output"];
  isActive: Scalars["Boolean"]["output"];
  json: Scalars["Json"]["output"];
  organization: Organization;
};

export type PhilosophyInput = {
  json: Scalars["Json"]["input"];
};

export type PingResponse = {
  __typename: "PingResponse";
  status: Scalars["String"]["output"];
};

export type PortalConfig = {
  __typename: "PortalConfig";
  defaultExitOutcome: Scalars["Float"]["output"];
  exitOutcomes: Array<Scalars["Float"]["output"]>;
  id: Scalars["Int"]["output"];
  valuationCurrencyCode: CurrencyCode;
  xAxisAnnotations: Array<Scalars["String"]["output"]>;
};

export type PortalConfigInput = {
  defaultExitOutcome: Scalars["Float"]["input"];
  exitOutcomes: Array<Scalars["Float"]["input"]>;
  xAxisAnnotations: Array<Scalars["String"]["input"]>;
};

export type Position = {
  __typename: "Position";
  adjustedCashBands: Maybe<Array<AdjustedCashBand>>;
  adjustedEquityBands: Maybe<Array<AdjustedEquityBand>>;
  areCashBandsAuthorized: Scalars["Boolean"]["output"];
  areEquityBandsAuthorized: Scalars["Boolean"]["output"];
  author: Maybe<User>;
  createdAt: Scalars["DateTime"]["output"];
  description: Maybe<Scalars["String"]["output"]>;
  descriptionUrl: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
  jobCodes: Array<Scalars["String"]["output"]>;
  ladder: Ladder;
  level: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
  organization: Organization;
  type: PositionType;
  /** The original underlying cash bands, shaped like an AdjustedCashBand */
  unadjustedCashBands: Maybe<Array<AdjustedCashBand>>;
  unadjustedEquityBands: Maybe<Array<AdjustedEquityBand>>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type PositionadjustedCashBandsArgs = {
  currencyCode: CurrencyCode;
  locationGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  marketId: Scalars["Int"]["input"];
};

export type PositionadjustedEquityBandsArgs = {
  currencyCode: CurrencyCode;
  locationGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  marketId: Scalars["Int"]["input"];
};

export type PositionunadjustedCashBandsArgs = {
  marketId: Scalars["Int"]["input"];
};

export type PositionunadjustedEquityBandsArgs = {
  marketId: Scalars["Int"]["input"];
};

export type PositionAndLocationGroupStatistics = {
  __typename: "PositionAndLocationGroupStatistics";
  cashStatistics: Maybe<CashStatistics>;
  employeeStatistics: EmployeeStatistics;
};

export type PositionDirectInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  jobCodes: Array<Scalars["String"]["input"]>;
  ladderId: Scalars["Int"]["input"];
  level: Scalars["Int"]["input"];
  name: Scalars["String"]["input"];
};

export type PositionStatistics = {
  __typename: "PositionStatistics";
  compaRatioStatistics: Maybe<CompaRatioStatistics>;
  employeeStatistics: EmployeeStatistics;
};

export enum PositionType {
  ANNUAL = "ANNUAL",
  HOURLY = "HOURLY",
}

export type PositionUpdateInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  jobCodes: Array<Scalars["String"]["input"]>;
  ladderId?: InputMaybe<Scalars["Int"]["input"]>;
  level?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type PositionWhereInput = {
  id?: InputMaybe<IntFilter>;
  ladderId?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
};

export type PotentialParticipantsCount = {
  __typename: "PotentialParticipantsCount";
  eligible: Scalars["Int"]["output"];
  ineligible: Scalars["Int"]["output"];
};

export type PotentialParticipantsResult = {
  __typename: "PotentialParticipantsResult";
  counts: PotentialParticipantsCount;
  eligible: Array<Employee>;
  ineligible: Array<Employee>;
};

export enum PrimaryRoleName {
  BASIC_VIEWER = "BASIC_VIEWER",
  EMPLOYEE = "EMPLOYEE",
  FULL_ACCESS = "FULL_ACCESS",
  HRBP = "HRBP",
  RECRUITER = "RECRUITER",
  SYSTEM_ADMIN = "SYSTEM_ADMIN",
}

export type PublishBudgetsInput = {
  compCycleId: Scalars["Int"]["input"];
};

export type PublishBudgetsPayload = {
  __typename: "PublishBudgetsPayload";
  draftsPublished: Scalars["Int"]["output"];
};

export type QuartileResponse = {
  __typename: "QuartileResponse";
  label: Scalars["String"]["output"];
  sampleSize: Scalars["Int"]["output"];
  values: Array<Scalars["Float"]["output"]>;
};

export type Query = {
  __typename: "Query";
  actableManagers: Maybe<Array<MinimalEmployee2>>;
  activeAssembleUserInvitations: Array<UserInvitation>;
  activeCompCycles: Array<CompCycle>;
  activeEmployees: Array<Employee>;
  anonymizedCompensation: AnonymizedCompensation;
  atsCandidates: Array<AtsCandidate>;
  benefitsPackages: Array<BenefitsPackage>;
  buildRevision: Scalars["String"]["output"];
  candidate: Maybe<Candidate>;
  candidates: Array<Candidate>;
  cartaConnectionConfig: Maybe<CartaConnectionConfig>;
  cashBandAudit: Array<UserCashBandAccess>;
  compCycle: Maybe<CompCycle>;
  compCycle2: Maybe<CompCycle2>;
  compCycleBudget: Maybe<CompCycleBudget>;
  compCycleBudgetDraft: CompCycleBudgetDraft;
  compCycleBudgets: Maybe<CompCycleBudget>;
  compCyclePhase: Maybe<CompCyclePhase>;
  compCyclePhase2: Maybe<CompCyclePhase2>;
  compCyclePhase2s: Array<Maybe<CompCyclePhase2>>;
  compCyclePhases: Array<CompCyclePhase>;
  compCycles: Array<CompCycle>;
  compStructure: Maybe<CompStructure>;
  costToMoveAnalysis: CostToMoveAnalysis;
  countEmployeesNeedingLeveling: Scalars["Int"]["output"];
  countLocationsNeedingMapping: Scalars["Int"]["output"];
  currencies: Array<Currency>;
  currentPhase2: Maybe<CompCyclePhase2>;
  department: Maybe<Department>;
  departments: Array<Department>;
  employee: Employee;
  employee2: Employee2;
  employee2s: Employee2s;
  employeeAnalytics: EmployeeAnalytics;
  employees: Array<Employee>;
  exploToken: ExploTokenResponse;
  exportAllEmployees: CsvExport;
  exportCompRecommendationsToCsv: CompRecommendationCsvExport;
  exportEmployeeRecommendationsToCsv: CompRecommendationCsvExport;
  exportOrgJobStructureToCsv: CsvExport;
  exportOrgJobStructureWithBandsToCsv: CsvExport;
  exportOrgLevelingCodeMappingsToCsv: CsvExport;
  isDomainValid: Scalars["Boolean"]["output"];
  isEmailNotTaken: Scalars["Boolean"]["output"];
  ladder: Maybe<Ladder>;
  ladders: Array<Ladder>;
  location: Maybe<Location>;
  locationGroup: Maybe<LocationGroup>;
  locations: Array<Location>;
  managers: Array<Employee>;
  market: Market;
  marketDataSample: Maybe<MarketDataSample>;
  marketDataSet: Maybe<MarketDataSet>;
  marketDataSets: Array<MarketDataSet>;
  markets: Array<Market>;
  mergeConnectionConfigs: Array<MergeConnectionConfig>;
  mergeLink: MergeLink;
  meritAdjustmentRanges: Array<MeritAdjustmentRange>;
  offer: Offer;
  offerConfig: Maybe<OfferConfig>;
  offers: Array<Offer>;
  organization: Organization;
  perfRatingOptions: Array<PerfRatingOption>;
  philosophies: Array<Philosophy>;
  philosophy: Maybe<Philosophy>;
  ping: PingResponse;
  portalConfig: Maybe<PortalConfig>;
  position: Maybe<Position>;
  positions: Array<Position>;
  tableOrder: Maybe<TableOrder>;
  userInvitationFromId: Maybe<UserInvitation>;
  userInvitationFromToken: Maybe<UserInvitation>;
  userRecord: Maybe<User>;
  userRecords: Array<User>;
  validateTokenAndEmail: Scalars["Boolean"]["output"];
  valuation: Maybe<Valuation>;
  valuations: Array<Valuation>;
  whoami: Maybe<AuthSubject>;
};

export type QueryactableManagersArgs = {
  compCycleId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryanonymizedCompensationArgs = {
  currencyCode: Scalars["String"]["input"];
  locationGroupId: Scalars["Int"]["input"];
  positionId: Scalars["Int"]["input"];
};

export type QuerycandidateArgs = {
  id: Scalars["Int"]["input"];
};

export type QuerycandidatesArgs = {
  where?: InputMaybe<CandidateWhereInput>;
};

export type QuerycompCycleArgs = {
  id: Scalars["Int"]["input"];
};

export type QuerycompCycle2Args = {
  id: Scalars["Int"]["input"];
};

export type QuerycompCycleBudgetArgs = {
  compCycleId: Scalars["Int"]["input"];
  employeeId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QuerycompCycleBudgetDraftArgs = {
  compCycleId: Scalars["Int"]["input"];
  employeeId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QuerycompCycleBudgetsArgs = {
  compCycleId: Scalars["Int"]["input"];
  currencyCode?: InputMaybe<CurrencyCode>;
  employeeIds: Array<Scalars["Int"]["input"]>;
};

export type QuerycompCyclePhaseArgs = {
  compCycleId: Scalars["Int"]["input"];
  phaseId: Scalars["Int"]["input"];
};

export type QuerycompCyclePhase2Args = {
  compCycleId: Scalars["Int"]["input"];
  phaseId: Scalars["Int"]["input"];
};

export type QuerycompCyclePhase2sArgs = {
  compCycleId: Scalars["Int"]["input"];
};

export type QuerycompCyclePhasesArgs = {
  compCycleId: Scalars["Int"]["input"];
};

export type QuerycostToMoveAnalysisArgs = {
  filter?: InputMaybe<GetEmployeesInput>;
  target: Scalars["String"]["input"];
};

export type QuerycurrentPhase2Args = {
  compCycleId: Scalars["Int"]["input"];
};

export type QuerydepartmentArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryemployeeArgs = {
  id: Scalars["Int"]["input"];
};

export type Queryemployee2Args = {
  id: Scalars["Int"]["input"];
};

export type Queryemployee2sArgs = {
  filter?: InputMaybe<Employee2sFilter>;
  includeInactive?: InputMaybe<Scalars["Boolean"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  managerId?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Employee2sSort>;
};

export type QueryemployeesArgs = {
  includeInactive?: InputMaybe<Scalars["Boolean"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  managerId?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryexploTokenArgs = {
  compCycleId: Scalars["Int"]["input"];
};

export type QueryexportAllEmployeesArgs = {
  input?: InputMaybe<ExportAllEmployeesInput>;
};

export type QueryexportCompRecommendationsToCsvArgs = {
  compCycleId: Scalars["Int"]["input"];
};

export type QueryexportEmployeeRecommendationsToCsvArgs = {
  compCycleId: Scalars["Int"]["input"];
  employeeIds: Array<Scalars["Int"]["input"]>;
};

export type QueryexportOrgJobStructureWithBandsToCsvArgs = {
  includeDatabaseIds?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type QueryisDomainValidArgs = {
  email: Scalars["String"]["input"];
};

export type QueryisEmailNotTakenArgs = {
  email: Scalars["String"]["input"];
};

export type QueryladderArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryladdersArgs = {
  ids?: InputMaybe<Array<Scalars["Int"]["input"]>>;
};

export type QuerylocationArgs = {
  id: Scalars["Int"]["input"];
};

export type QuerylocationGroupArgs = {
  id: Scalars["Int"]["input"];
};

export type QuerylocationsArgs = {
  where?: InputMaybe<LocationWhereInput>;
};

export type QuerymarketArgs = {
  id: Scalars["Int"]["input"];
};

export type QuerymarketDataSampleArgs = {
  id: Scalars["Int"]["input"];
};

export type QuerymarketDataSetArgs = {
  id: Scalars["Int"]["input"];
};

export type QuerymergeConnectionConfigsArgs = {
  isManualUpload?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type QuerymergeLinkArgs = {
  category: MergeCategory;
  originId?: InputMaybe<Scalars["String"]["input"]>;
};

export type QuerymeritAdjustmentRangesArgs = {
  compCycleId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryofferArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryperfRatingOptionsArgs = {
  compCycleId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QuerypositionArgs = {
  id: Scalars["Int"]["input"];
};

export type QuerytableOrderArgs = {
  tableName: NexusTableNameType;
};

export type QueryuserInvitationFromIdArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryuserInvitationFromTokenArgs = {
  token: Scalars["String"]["input"];
};

export type QueryuserRecordArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryuserRecordsArgs = {
  data?: InputMaybe<UserRecordsInput>;
};

export type QueryvalidateTokenAndEmailArgs = {
  email: Scalars["String"]["input"];
  token?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryvaluationsArgs = {
  where?: InputMaybe<ValuationWhereInput>;
};

export type RecItem = {
  __typename: "RecItem";
  author: User;
  authorId: Scalars["Int"]["output"];
  /**
   * The converted cash value of the recommendation. "PERCENT_OF_SALARY"
   *         RecItems will use the Employee's active salary to calculate the cash
   *         value. Optionally accepts a currency code to convert to.
   */
  cashEquivalent: Maybe<Scalars["Money"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["Int"]["output"];
  note: Maybe<Scalars["String"]["output"]>;
  recommendationType: RecItemType;
  /**
   * The value of the recommendation if specified in cash. This reflects
   *         how the recommendation was made; it is null for "PERCENT_OF_SALARY"
   *         RecItems. Optionally accepts a currency code to convert to.
   */
  recommendedCashValue: Maybe<Scalars["Money"]["output"]>;
  recommendedEquityUnitCount: Maybe<Scalars["Int"]["output"]>;
  recommendedPercentValue: Maybe<Scalars["Float"]["output"]>;
  recommendedPosition: Maybe<Position>;
  recommendedTitle: Maybe<Scalars["String"]["output"]>;
  submittedAt: Maybe<Scalars["DateTime"]["output"]>;
  unitType: Maybe<CompUnit>;
};

export type RecItemcashEquivalentArgs = {
  currencyCode?: InputMaybe<CurrencyCode>;
};

export type RecItemrecommendedCashValueArgs = {
  currencyCode?: InputMaybe<CurrencyCode>;
};

export type RecItem2 = {
  __typename: "RecItem2";
  /** The AdjustedCashBands for the employee's recommended position. */
  adjustedCashBands: Maybe<Array<AdjustedCashBand>>;
  author: User2;
  authorId: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  note: Maybe<Scalars["String"]["output"]>;
  recommendationType: RecItemType;
  recommendedCashValue: Maybe<Scalars["Money"]["output"]>;
  recommendedEquityUnitCount: Maybe<Scalars["Int"]["output"]>;
  recommendedPercentValue: Maybe<Scalars["Float"]["output"]>;
  recommendedPosition: Maybe<Position>;
  recommendedTitle: Maybe<Scalars["String"]["output"]>;
  submittedAt: Maybe<Scalars["DateTime"]["output"]>;
  unitType: Maybe<CompUnit>;
};

export type RecItem2adjustedCashBandsArgs = {
  currencyCode?: InputMaybe<CurrencyCode>;
};

export type RecItem2recommendedCashValueArgs = {
  currencyCode?: InputMaybe<CurrencyCode>;
};

export type RecItemInput = {
  note?: InputMaybe<Scalars["String"]["input"]>;
  recommendationType: RecItemType;
  recommendedCashValue?: InputMaybe<Scalars["Money"]["input"]>;
  recommendedPercentValue?: InputMaybe<Scalars["Float"]["input"]>;
  recommendedPositionId?: InputMaybe<Scalars["Int"]["input"]>;
  recommendedTitle?: InputMaybe<Scalars["String"]["input"]>;
  unitType?: InputMaybe<CompUnit>;
};

export enum RecItemType {
  ACTUAL_RECURRING_BONUS = "ACTUAL_RECURRING_BONUS",
  EQUITY_GRANT = "EQUITY_GRANT",
  MARKET = "MARKET",
  MERIT_BONUS = "MERIT_BONUS",
  MERIT_INCREASE = "MERIT_INCREASE",
  PROMOTION = "PROMOTION",
  TARGET_COMMISSION = "TARGET_COMMISSION",
  TARGET_RECURRING_BONUS = "TARGET_RECURRING_BONUS",
}

export type RecReview = {
  __typename: "RecReview";
  author: User;
  authorId: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  note: Maybe<Scalars["String"]["output"]>;
  status: ReviewRequestStatus;
  submittedAt: Maybe<Scalars["DateTime"]["output"]>;
};

export type RecReview2 = {
  __typename: "RecReview2";
  author: User2;
  authorId: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  note: Maybe<Scalars["String"]["output"]>;
  status: RecReviewStatus;
  submittedAt: Maybe<Scalars["DateTime"]["output"]>;
};

export type RecReviewInput = {
  compCycleId: Scalars["Int"]["input"];
  note?: InputMaybe<Scalars["String"]["input"]>;
  status: RecommendationReviewStatus;
  subjectId: Scalars["Int"]["input"];
};

export enum RecReviewStatus {
  APPROVED = "APPROVED",
  DENIED = "DENIED",
}

export enum RecommendationReviewStatus {
  APPROVED = "APPROVED",
  AWAITING_REVIEW = "AWAITING_REVIEW",
  DENIED = "DENIED",
}

export enum RelativeCompRecStatus {
  ACCEPTED = "ACCEPTED",
  AWAITING = "AWAITING",
  FUTURE_PHASE = "FUTURE_PHASE",
  LATE = "LATE",
  NEEDS_REVIEW = "NEEDS_REVIEW",
  REJECTED = "REJECTED",
  REVIEWED = "REVIEWED",
  SKIPPED = "SKIPPED",
}

export enum ReportsEnum {
  direct = "direct",
  indirect = "indirect",
}

export enum ReviewRequestStatus {
  APPROVED = "APPROVED",
  AWAITING_DEPENDENCY = "AWAITING_DEPENDENCY",
  AWAITING_REVIEW = "AWAITING_REVIEW",
  DENIED = "DENIED",
}

export type RipplingPayrollCsvInput = {
  city: Scalars["String"]["input"];
  country: Scalars["String"]["input"];
  displayName: Scalars["String"]["input"];
  effectiveDate: Scalars["String"]["input"];
  employeeNumber: Scalars["String"]["input"];
  employmentStatus: Scalars["String"]["input"];
  employmentType: Scalars["String"]["input"];
  ethnicity?: InputMaybe<Scalars["String"]["input"]>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  gender?: InputMaybe<Scalars["String"]["input"]>;
  jobTitle: Scalars["String"]["input"];
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  managerWorkEmail?: InputMaybe<Scalars["String"]["input"]>;
  payCurrency: Scalars["String"]["input"];
  payPeriod: Scalars["String"]["input"];
  payRate: Scalars["String"]["input"];
  personalEmail?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  terminationDate?: InputMaybe<Scalars["String"]["input"]>;
  workEmail: Scalars["String"]["input"];
  zipCode?: InputMaybe<Scalars["String"]["input"]>;
};

export type SalaryStatistics = {
  __typename: "SalaryStatistics";
  average: Scalars["Money"]["output"];
  max: Scalars["Money"]["output"];
  median: Scalars["Money"]["output"];
  min: Scalars["Money"]["output"];
};

export type ScopeInput = {
  departmentIDs: Array<Scalars["Int"]["input"]>;
  global: Scalars["Boolean"]["input"];
  ladderIDs: Array<Scalars["Int"]["input"]>;
  positionIDs: Array<Scalars["Int"]["input"]>;
};

export type StringFilter = {
  contains?: InputMaybe<Scalars["String"]["input"]>;
  endsWith?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  gt?: InputMaybe<Scalars["String"]["input"]>;
  gte?: InputMaybe<Scalars["String"]["input"]>;
  in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  lt?: InputMaybe<Scalars["String"]["input"]>;
  lte?: InputMaybe<Scalars["String"]["input"]>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars["String"]["input"]>>;
  startsWith?: InputMaybe<Scalars["String"]["input"]>;
};

export type SubmitRecReviewsResponse = {
  __typename: "SubmitRecReviewsResponse";
  participants: Array<CompCycleParticipant>;
};

export type TableOrder = {
  __typename: "TableOrder";
  columnOrder: Array<Scalars["String"]["output"]>;
  hiddenColumns: Array<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
  tableName: NexusTableNameType;
};

export type TableOrderInput = {
  columnOrder: Array<Scalars["String"]["input"]>;
  hiddenColumns: Array<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  tableName: NexusTableNameType;
};

export enum TeamCompensationAccessType {
  DIRECT_REPORTS_CASH = "DIRECT_REPORTS_CASH",
  DIRECT_REPORTS_CASH_AND_EQUITY = "DIRECT_REPORTS_CASH_AND_EQUITY",
  INDIRECT_REPORTS_CASH = "INDIRECT_REPORTS_CASH",
  INDIRECT_REPORTS_CASH_AND_EQUITY = "INDIRECT_REPORTS_CASH_AND_EQUITY",
  NONE = "NONE",
}

export type TimeSeriesOptions = {
  dates: Array<Scalars["Date"]["input"]>;
  groupBy?: InputMaybe<Scalars["String"]["input"]>;
};

export type TimeSeriesResponse = {
  __typename: "TimeSeriesResponse";
  label: Scalars["String"]["output"];
  values: Array<DateValueTuple>;
};

/** A number of equity units. Only used for Equity comp */
export type UnitValue = {
  __typename: "UnitValue";
  unitValue: Maybe<Scalars["Float"]["output"]>;
};

export type UpdateBudgetGuidanceInput = {
  id: Scalars["Int"]["input"];
  percent: Scalars["Float"]["input"];
};

export type UpdateCompComponentsInput = {
  compComponents: CompComponentsInput;
  id: Scalars["Int"]["input"];
};

export type UpdateCompCycleGuidancePreferencesInput = {
  id: Scalars["Int"]["input"];
  recommendationsPreFill: Scalars["Boolean"]["input"];
};

export type UpdateCompCycleNameInput = {
  id: Scalars["Int"]["input"];
  name: Scalars["String"]["input"];
};

export type UpdateEligibilityRulesInput = {
  compCycleId: Scalars["Int"]["input"];
  rules: Array<Array<Scalars["Json"]["input"]>>;
};

export type UpdateGuidanceRangeInput = {
  id: Scalars["Int"]["input"];
  rangeStart: Scalars["Float"]["input"];
};

export type UpdateMeritGuidanceInput = {
  compCycleId: Scalars["Int"]["input"];
  id: Scalars["Int"]["input"];
  percent: Scalars["Float"]["input"];
};

export type UpdatePerfRatingOptionInput = {
  id: Scalars["Int"]["input"];
  name: Scalars["String"]["input"];
};

export type UpdatePhasesInput = {
  id: Scalars["Int"]["input"];
  startDate: Scalars["Date"]["input"];
};

export type UpsertOneBudgetInput = {
  compCycleId: Scalars["Int"]["input"];
  employeeId?: InputMaybe<Scalars["Int"]["input"]>;
  patch: patch_UpsertOneBudgetInput;
  unset?: InputMaybe<unset_UpsertOneBudgetInput>;
};

export type UpsertOneBudgetPayload = {
  __typename: "UpsertOneBudgetPayload";
  budget: Maybe<CompCycleBudgetDraft>;
  successful: Scalars["Boolean"]["output"];
};

export type UpsertUserViewInput = {
  json: Scalars["Json"]["input"];
};

export type User = {
  __typename: "User";
  createdAt: Scalars["DateTime"]["output"];
  email: Scalars["String"]["output"];
  employee: Maybe<Employee>;
  id: Scalars["Int"]["output"];
  isDisabled: Scalars["Boolean"]["output"];
  name: Maybe<Scalars["String"]["output"]>;
  organization: Organization;
  photoURL: Maybe<Scalars["String"]["output"]>;
  userAccessGrant: Maybe<UserAccessGrant>;
};

export type User2 = {
  __typename: "User2";
  email: Scalars["String"]["output"];
  employee: Maybe<Employee2>;
  id: Scalars["Int"]["output"];
  isDisabled: Scalars["Boolean"]["output"];
  name: Maybe<Scalars["String"]["output"]>;
  photoURL: Maybe<Scalars["String"]["output"]>;
};

export type UserAccessGrant = {
  __typename: "UserAccessGrant";
  author: Maybe<User>;
  createdAt: Scalars["DateTime"]["output"];
  deletedAt: Maybe<Scalars["DateTime"]["output"]>;
  grantedUser: User;
  id: Scalars["Int"]["output"];
  nounScopes: NounScopes;
  organization: Organization;
  roleName: PrimaryRoleName;
};

export type UserAccessGrantRoleInput = {
  grantedUserId: Scalars["Int"]["input"];
  role: PrimaryRoleName;
};

export type UserCashBandAccess = {
  __typename: "UserCashBandAccess";
  cashBands: Array<AdjustedCashBand>;
  email: Scalars["String"]["output"];
  id: Scalars["Int"]["output"];
};

export type UserInvitation = {
  __typename: "UserInvitation";
  createdAt: Scalars["DateTime"]["output"];
  deletedAt: Maybe<Scalars["DateTime"]["output"]>;
  email: Scalars["String"]["output"];
  employee: Maybe<Employee>;
  employeeId: Maybe<Scalars["Int"]["output"]>;
  expiredAt: Scalars["DateTime"]["output"];
  grant: Scalars["Json"]["output"];
  id: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
  tokenUsedAt: Maybe<Scalars["DateTime"]["output"]>;
  userInvitationAccessGrant: Maybe<UserInvitationAccessGrant>;
};

export type UserInvitationAccessGrant = {
  __typename: "UserInvitationAccessGrant";
  id: Scalars["Int"]["output"];
  nounScopes: NounScopes;
  roleName: PrimaryRoleName;
};

export type UserInvitationAccessGrantRoleInput = {
  grantedUserInvitationId: Scalars["Int"]["input"];
  role: PrimaryRoleName;
  supportedManagerIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
};

export type UserInvitationAccessGrantScopesInput = {
  grantedUserInvitationId: Scalars["Int"]["input"];
  marketId?: InputMaybe<Scalars["Int"]["input"]>;
  scope?: InputMaybe<ScopeInput>;
};

export type UserRecordsInput = {
  activePermissionRole?: InputMaybe<Scalars["String"]["input"]>;
};

export type UserView = {
  __typename: "UserView";
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["Int"]["output"];
  json: Scalars["Json"]["output"];
  organizationId: Scalars["Int"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
  userId: Scalars["Int"]["output"];
};

export type UserWithAccessGrantsInput = {
  compBandAccess?: InputMaybe<CompBandAccessInput>;
  email: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  roleName: PrimaryRoleName;
};

export type Valuation = {
  __typename: "Valuation";
  activeAt: Scalars["DateTime"]["output"];
  currency: CurrencyCode;
  estimatedDilution: Scalars["Float"]["output"];
  fdso: Scalars["Float"]["output"];
  financingStage: Scalars["String"]["output"];
  id: Scalars["Int"]["output"];
  inactiveAt: Maybe<Scalars["DateTime"]["output"]>;
  organization: Organization;
  strikePrice: Scalars["Money"]["output"];
  valuation: Scalars["Money"]["output"];
  valuationMoney: Scalars["Money"]["output"];
};

export type ValuationInput = {
  activeAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  estimatedDilution?: InputMaybe<Scalars["Float"]["input"]>;
  fdso: Scalars["Float"]["input"];
  financingStage: Scalars["String"]["input"];
  inactiveAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  strikePrice: Scalars["Money"]["input"];
  valuation: Scalars["Money"]["input"];
};

export type ValuationWhereInput = {
  id?: InputMaybe<IntFilter>;
  organizationId?: InputMaybe<IntFilter>;
};

export enum VariableCompType {
  COMMISSION = "COMMISSION",
  RECURRING_BONUS = "RECURRING_BONUS",
}

export enum Verb {
  Edit = "Edit",
  View = "View",
}

export type VestingInformation = {
  __typename: "VestingInformation";
  id: Scalars["Int"]["output"];
  percentUnvested: Maybe<Scalars["Float"]["output"]>;
  percentVested: Maybe<Scalars["Float"]["output"]>;
  unvestedUnits: Maybe<Scalars["Int"]["output"]>;
  vestEvents: Array<EquityGrantVestEventSource>;
  vestedUnits: Maybe<Scalars["Int"]["output"]>;
};

export type patch_UpsertOneBudgetInput = {
  actualRecurringBonus?: InputMaybe<Scalars["Money"]["input"]>;
  bonus?: InputMaybe<Scalars["Money"]["input"]>;
  equity?: InputMaybe<Scalars["Money"]["input"]>;
  salary?: InputMaybe<Scalars["Money"]["input"]>;
  salaryMarket?: InputMaybe<Scalars["Money"]["input"]>;
  salaryMerit?: InputMaybe<Scalars["Money"]["input"]>;
  salaryPromotion?: InputMaybe<Scalars["Money"]["input"]>;
  targetCommission?: InputMaybe<Scalars["Money"]["input"]>;
  targetRecurringBonus?: InputMaybe<Scalars["Money"]["input"]>;
};

export type unset_UpsertOneBudgetInput = {
  actualRecurringBonus?: InputMaybe<Scalars["Boolean"]["input"]>;
  bonus?: InputMaybe<Scalars["Boolean"]["input"]>;
  equity?: InputMaybe<Scalars["Boolean"]["input"]>;
  salary?: InputMaybe<Scalars["Boolean"]["input"]>;
  salaryMarket?: InputMaybe<Scalars["Boolean"]["input"]>;
  salaryMerit?: InputMaybe<Scalars["Boolean"]["input"]>;
  salaryPromotion?: InputMaybe<Scalars["Boolean"]["input"]>;
  targetCommission?: InputMaybe<Scalars["Boolean"]["input"]>;
  targetRecurringBonus?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type AnonymizedCompensationBandVisualization_permissionSettings = {
  __typename: "PermissionSettings";
  id: number;
  anonymizedCompensationEmployeeThreshold: number;
};

export type AnonymizedCompensationBandVisualization_anonymizedCompensation = {
  __typename: "AnonymizedCompensation";
  positionAndLocationGroup: {
    __typename: "PositionAndLocationGroupStatistics";
    cashStatistics: {
      __typename: "CashStatistics";
      anonymizedDataPoints: Array<{
        __typename: "AnonymizedDataPoint";
        salary: GraphQL_Money | null;
        commission: GraphQL_Money | null;
        bonus: GraphQL_Money | null;
      }>;
    } | null;
    employeeStatistics: { __typename: "EmployeeStatistics"; count: number };
  };
};

export type BandVisualization_position = {
  __typename: "Position";
  id: number;
  jobCodes: Array<string>;
  adjustedCashBands: Array<{
    __typename: "AdjustedCashBand";
    id: string;
    name: string;
    bandPoints: Array<{
      __typename: "AdjustedCashBandPoint";
      id: string;
      name: string;
      bandName: string;
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      value: {
        __typename: "CashValue";
        annualRate: GraphQL_Money | null;
        hourlyRate: GraphQL_Money | null;
        currencyCode: CurrencyCode;
      };
    }>;
  }> | null;
  adjustedEquityBands: Array<{
    __typename: "AdjustedEquityBand";
    id: string;
    name: string;
    bandPoints: Array<{
      __typename: "AdjustedEquityBandPoint";
      id: string;
      name: string;
      bandName: string;
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      totalGrossValue: GraphQL_Money;
      totalUnits: number | null;
      vestingMonths: number;
      value:
        | {
            __typename: "CashValue";
            annualRate: GraphQL_Money | null;
            currencyCode: CurrencyCode;
          }
        | { __typename: "PercentValue"; decimalValue: number | null }
        | { __typename: "UnitValue"; unitValue: number | null };
      totalPercentOwnership: {
        __typename: "PercentValue";
        decimalValue: number | null;
      } | null;
    }>;
  }> | null;
};

export type BandVisualization2_position = {
  __typename: "Position";
  id: number;
  jobCodes: Array<string>;
  name: string;
  adjustedCashBands: Array<{
    __typename: "AdjustedCashBand";
    id: string;
    name: string;
    bandPoints: Array<{
      __typename: "AdjustedCashBandPoint";
      id: string;
      name: string;
      bandName: string;
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      value: {
        __typename: "CashValue";
        annualRate: GraphQL_Money | null;
        hourlyRate: GraphQL_Money | null;
        currencyCode: CurrencyCode;
      };
    }>;
  }> | null;
  adjustedEquityBands: Array<{
    __typename: "AdjustedEquityBand";
    id: string;
    name: string;
    bandPoints: Array<{
      __typename: "AdjustedEquityBandPoint";
      id: string;
      name: string;
      bandName: string;
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      totalGrossValue: GraphQL_Money;
      totalUnits: number | null;
      vestingMonths: number;
      value:
        | {
            __typename: "CashValue";
            annualRate: GraphQL_Money | null;
            currencyCode: CurrencyCode;
          }
        | { __typename: "PercentValue"; decimalValue: number | null }
        | { __typename: "UnitValue"; unitValue: number | null };
      totalPercentOwnership: {
        __typename: "PercentValue";
        decimalValue: number | null;
      } | null;
    }>;
  }> | null;
};

export type BandVisualizationTooltip_position = {
  __typename: "Position";
  id: number;
  jobCodes: Array<string>;
  adjustedCashBands: Array<{
    __typename: "AdjustedCashBand";
    id: string;
    name: string;
    bandPoints: Array<{
      __typename: "AdjustedCashBandPoint";
      id: string;
      name: string;
      bandName: string;
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      value: {
        __typename: "CashValue";
        annualRate: GraphQL_Money | null;
        hourlyRate: GraphQL_Money | null;
        currencyCode: CurrencyCode;
      };
    }>;
  }> | null;
  adjustedEquityBands: Array<{
    __typename: "AdjustedEquityBand";
    id: string;
    name: string;
    bandPoints: Array<{
      __typename: "AdjustedEquityBandPoint";
      id: string;
      name: string;
      bandName: string;
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      totalGrossValue: GraphQL_Money;
      totalUnits: number | null;
      vestingMonths: number;
      value:
        | {
            __typename: "CashValue";
            annualRate: GraphQL_Money | null;
            currencyCode: CurrencyCode;
          }
        | { __typename: "PercentValue"; decimalValue: number | null }
        | { __typename: "UnitValue"; unitValue: number | null };
      totalPercentOwnership: {
        __typename: "PercentValue";
        decimalValue: number | null;
      } | null;
    }>;
  }> | null;
};

export type MarketDataComparisonTooltip_position = {
  __typename: "Position";
  name: string;
  jobCodes: Array<string>;
};

export type ActivityLogLoadingBoundaryQueryVariables = Exact<{
  employeeId: Scalars["Int"]["input"];
  compCycleId: Scalars["Int"]["input"];
}>;

export type ActivityLogLoadingBoundaryQuery = {
  compCycle: {
    __typename: "CompCycle";
    id: number;
    finalApproversIds: Array<number>;
    participant: {
      __typename: "Employee";
      id: number;
      displayName: string;
      perfRating: {
        __typename: "EmployeeCompCyclePerfRating";
        id: number;
        perfRating: string | null;
        deletedAt: GraphQL_DateTime | null;
        createdAt: GraphQL_DateTime;
      } | null;
      allPerfRatings: Array<{
        __typename: "EmployeeCompCyclePerfRating";
        id: number;
        perfRating: string | null;
        deletedAt: GraphQL_DateTime | null;
        createdAt: GraphQL_DateTime;
      }>;
      activeEmployment: {
        __typename: "Employment";
        id: number;
        position: {
          __typename: "Position";
          id: number;
          name: string;
          level: number;
          ladder: {
            __typename: "Ladder";
            id: number;
            name: string;
            department: { __typename: "Department"; id: number; name: string };
          };
        } | null;
      } | null;
      activeCashCompensation: Array<{
        __typename: "CashCompensation";
        type: CashCompType;
        annualCashEquivalent: GraphQL_Money;
        hourlyCashEquivalent: GraphQL_Money;
        unit: CompUnit;
        percentOfSalary: number;
      }> | null;
      compRecommendation: {
        __typename: "CompRecommendation";
        subjectId: number;
        compCycleId: number | null;
        allSubmittedReviews: Array<{
          __typename: "RecReview";
          id: number;
          submittedAt: GraphQL_DateTime | null;
          status: ReviewRequestStatus;
          note: string | null;
          author: {
            __typename: "User";
            id: number;
            name: string | null;
            email: string;
            photoURL: string | null;
          };
        }>;
        allSubmittedItems: Array<{
          __typename: "RecItem";
          id: number;
          recommendationType: RecItemType;
          createdAt: GraphQL_DateTime;
          note: string | null;
          submittedAt: GraphQL_DateTime | null;
          recommendedCashValue: GraphQL_Money | null;
          recommendedEquityUnitCount: number | null;
          unitType: CompUnit | null;
          recommendedPercentValue: number | null;
          recommendedPosition: {
            __typename: "Position";
            id: number;
            name: string;
            level: number;
            ladder: {
              __typename: "Ladder";
              id: number;
              name: string;
              department: {
                __typename: "Department";
                id: number;
                name: string;
              };
            };
          } | null;
          author: {
            __typename: "User";
            id: number;
            name: string | null;
            email: string;
            photoURL: string | null;
          };
        }>;
      } | null;
    } | null;
  } | null;
};

export type ActivityLogView_employee = {
  __typename: "Employee";
  id: number;
  displayName: string;
  perfRating: {
    __typename: "EmployeeCompCyclePerfRating";
    id: number;
    perfRating: string | null;
    deletedAt: GraphQL_DateTime | null;
    createdAt: GraphQL_DateTime;
  } | null;
  allPerfRatings: Array<{
    __typename: "EmployeeCompCyclePerfRating";
    id: number;
    perfRating: string | null;
    deletedAt: GraphQL_DateTime | null;
    createdAt: GraphQL_DateTime;
  }>;
  activeEmployment: {
    __typename: "Employment";
    id: number;
    position: {
      __typename: "Position";
      id: number;
      name: string;
      level: number;
      ladder: {
        __typename: "Ladder";
        id: number;
        name: string;
        department: { __typename: "Department"; id: number; name: string };
      };
    } | null;
  } | null;
  activeCashCompensation: Array<{
    __typename: "CashCompensation";
    type: CashCompType;
    annualCashEquivalent: GraphQL_Money;
    hourlyCashEquivalent: GraphQL_Money;
    unit: CompUnit;
    percentOfSalary: number;
  }> | null;
  compRecommendation: {
    __typename: "CompRecommendation";
    subjectId: number;
    compCycleId: number | null;
    allSubmittedReviews: Array<{
      __typename: "RecReview";
      id: number;
      submittedAt: GraphQL_DateTime | null;
      status: ReviewRequestStatus;
      note: string | null;
      author: {
        __typename: "User";
        id: number;
        name: string | null;
        email: string;
        photoURL: string | null;
      };
    }>;
    allSubmittedItems: Array<{
      __typename: "RecItem";
      id: number;
      recommendationType: RecItemType;
      createdAt: GraphQL_DateTime;
      note: string | null;
      submittedAt: GraphQL_DateTime | null;
      recommendedCashValue: GraphQL_Money | null;
      recommendedEquityUnitCount: number | null;
      unitType: CompUnit | null;
      recommendedPercentValue: number | null;
      recommendedPosition: {
        __typename: "Position";
        id: number;
        name: string;
        level: number;
        ladder: {
          __typename: "Ladder";
          id: number;
          name: string;
          department: { __typename: "Department"; id: number; name: string };
        };
      } | null;
      author: {
        __typename: "User";
        id: number;
        name: string | null;
        email: string;
        photoURL: string | null;
      };
    }>;
  } | null;
};

export type ActivityLogView_compCycle = {
  __typename: "CompCycle";
  id: number;
  finalApproversIds: Array<number>;
};

export type ActualVariablePayChange_recItem = {
  __typename: "RecItem";
  recommendationType: RecItemType;
  recommendedCashValue: GraphQL_Money | null;
  recommendedPercentValue: number | null;
};

export type ActualVariablePayChange_cashCompensation = {
  __typename: "CashCompensation";
  type: CashCompType;
  annualCashEquivalent: GraphQL_Money;
  hourlyCashEquivalent: GraphQL_Money;
  unit: CompUnit;
  percentOfSalary: number;
};

export type EquityChange_recItem = {
  __typename: "RecItem";
  recommendationType: RecItemType;
  recommendedCashValue: GraphQL_Money | null;
  recommendedEquityUnitCount: number | null;
};

export type PerfRatingCallout_perfRating = {
  __typename: "EmployeeCompCyclePerfRating";
  perfRating: string | null;
  deletedAt: GraphQL_DateTime | null;
  createdAt: GraphQL_DateTime;
};

export type PositionChange_position = {
  __typename: "Position";
  name: string;
  level: number;
  ladder: {
    __typename: "Ladder";
    id: number;
    name: string;
    department: { __typename: "Department"; id: number; name: string };
  };
};

export type PositionChange_employment = {
  __typename: "Employment";
  position: {
    __typename: "Position";
    id: number;
    name: string;
    level: number;
    ladder: {
      __typename: "Ladder";
      id: number;
      name: string;
      department: { __typename: "Department"; id: number; name: string };
    };
  } | null;
};

export type PositionChange_recItem = {
  __typename: "RecItem";
  recommendationType: RecItemType;
  recommendedPosition: {
    __typename: "Position";
    id: number;
    name: string;
    level: number;
    ladder: {
      __typename: "Ladder";
      id: number;
      name: string;
      department: { __typename: "Department"; id: number; name: string };
    };
  } | null;
};

export type SalaryChange_recItem = {
  __typename: "RecItem";
  recommendationType: RecItemType;
  recommendedCashValue: GraphQL_Money | null;
};

export type SalaryChange_cashCompensation = {
  __typename: "CashCompensation";
  type: CashCompType;
  annualCashEquivalent: GraphQL_Money;
  hourlyCashEquivalent: GraphQL_Money;
  unit: CompUnit;
  percentOfSalary: number;
};

export type SubmittedCardHeader_author = {
  __typename: "User";
  name: string | null;
  email: string;
  photoURL: string | null;
};

export type SubmittedRecommendationCard_submittedItem = {
  __typename: "RecItem";
  recommendationType: RecItemType;
  createdAt: GraphQL_DateTime;
  note: string | null;
  submittedAt: GraphQL_DateTime | null;
  recommendedCashValue: GraphQL_Money | null;
  recommendedEquityUnitCount: number | null;
  unitType: CompUnit | null;
  recommendedPercentValue: number | null;
  recommendedPosition: {
    __typename: "Position";
    id: number;
    name: string;
    level: number;
    ladder: {
      __typename: "Ladder";
      id: number;
      name: string;
      department: { __typename: "Department"; id: number; name: string };
    };
  } | null;
  author: {
    __typename: "User";
    id: number;
    name: string | null;
    email: string;
    photoURL: string | null;
  };
};

export type SubmittedRecommendationCard_activeEmployment = {
  __typename: "Employment";
  position: {
    __typename: "Position";
    id: number;
    name: string;
    level: number;
    ladder: {
      __typename: "Ladder";
      id: number;
      name: string;
      department: { __typename: "Department"; id: number; name: string };
    };
  } | null;
};

export type SubmittedRecommendationCard_cashCompensation = {
  __typename: "CashCompensation";
  type: CashCompType;
  annualCashEquivalent: GraphQL_Money;
  hourlyCashEquivalent: GraphQL_Money;
  unit: CompUnit;
  percentOfSalary: number;
};

export type SubmittedRecommendationCard_perfRating = {
  __typename: "EmployeeCompCyclePerfRating";
  perfRating: string | null;
  deletedAt: GraphQL_DateTime | null;
  createdAt: GraphQL_DateTime;
};

export type SubmittedReviewCard_review = {
  __typename: "RecReview";
  submittedAt: GraphQL_DateTime | null;
  status: ReviewRequestStatus;
  note: string | null;
  author: {
    __typename: "User";
    id: number;
    name: string | null;
    email: string;
    photoURL: string | null;
  };
};

export type SubmittedSummaryCard_submittedReview = {
  __typename: "RecReview";
  id: number;
  submittedAt: GraphQL_DateTime | null;
  status: ReviewRequestStatus;
  note: string | null;
  author: {
    __typename: "User";
    id: number;
    name: string | null;
    email: string;
    photoURL: string | null;
  };
};

export type SubmittedSummaryCard_cashCompensation = {
  __typename: "CashCompensation";
  type: CashCompType;
  annualCashEquivalent: GraphQL_Money;
  hourlyCashEquivalent: GraphQL_Money;
  unit: CompUnit;
  percentOfSalary: number;
};

export type TargetVariablePayChange_recItem = {
  __typename: "RecItem";
  recommendationType: RecItemType;
  recommendedCashValue: GraphQL_Money | null;
  recommendedPercentValue: number | null;
};

export type TargetVariablePayChange_cashCompensation = {
  __typename: "CashCompensation";
  type: CashCompType;
  unit: CompUnit;
  annualCashEquivalent: GraphQL_Money;
  hourlyCashEquivalent: GraphQL_Money;
  percentOfSalary: number;
};

export type TotalTargetCashChange_recItem = {
  __typename: "RecItem";
  unitType: CompUnit | null;
  recommendationType: RecItemType;
  recommendedCashValue: GraphQL_Money | null;
  recommendedPercentValue: number | null;
};

export type TotalTargetCashChange_cashCompensation = {
  __typename: "CashCompensation";
  type: CashCompType;
  annualCashEquivalent: GraphQL_Money;
  hourlyCashEquivalent: GraphQL_Money;
  unit: CompUnit;
  percentOfSalary: number;
};

export type ApprovalSettings_employee = {
  __typename: "Employee2";
  id: number;
  displayName: string;
  parentId: number | null;
  _directSubordinates: number;
  _totalSubordinates: number;
  activeEmployment: {
    __typename: "Employment2";
    id: number;
    jobTitle: string | null;
  } | null;
};

export type ApprovalSettings_compCyclePhase = {
  __typename: "CompCyclePhase2";
  id: number;
  phaseOrder: number;
  startDate: GraphQL_Date;
  compCyclePhaseAssignments: Array<{
    __typename: "CompCyclePhaseAssignment2";
    id: number;
    phaseId: number;
    status: PhaseTimelineStatus | null;
    assigneeId: number;
  } | null>;
};

export type ApprovalsOrganizationChart_employee = {
  __typename: "Employee2";
  id: number;
  displayName: string;
  parentId: number | null;
  _directSubordinates: number;
  _totalSubordinates: number;
  activeEmployment: {
    __typename: "Employment2";
    id: number;
    jobTitle: string | null;
  } | null;
};

export type ApprovalsOrganizationChart_compCyclePhase = {
  __typename: "CompCyclePhase2";
  id: number;
  phaseOrder: number;
  startDate: GraphQL_Date;
  compCyclePhaseAssignments: Array<{
    __typename: "CompCyclePhaseAssignment2";
    id: number;
    phaseId: number;
    status: PhaseTimelineStatus | null;
    assigneeId: number;
  } | null>;
};

export type ApprovalsOrganizationChartLoadingBoundaryQueryVariables = Exact<{
  compCycleId: Scalars["Int"]["input"];
}>;

export type ApprovalsOrganizationChartLoadingBoundaryQuery = {
  employee2s: {
    __typename: "Employee2s";
    employees: Array<{
      __typename: "Employee2";
      id: number;
      displayName: string;
      parentId: number | null;
      _directSubordinates: number;
      _totalSubordinates: number;
      activeEmployment: {
        __typename: "Employment2";
        id: number;
        jobTitle: string | null;
      } | null;
    }>;
  };
  compCyclePhase2s: Array<{
    __typename: "CompCyclePhase2";
    id: number;
    phaseOrder: number;
    startDate: GraphQL_Date;
    compCyclePhaseAssignments: Array<{
      __typename: "CompCyclePhaseAssignment2";
      id: number;
      phaseId: number;
      status: PhaseTimelineStatus | null;
      assigneeId: number;
    } | null>;
  } | null>;
};

export type ApprovalsOrganizationChartView_employee = {
  __typename: "Employee2";
  id: number;
  displayName: string;
  parentId: number | null;
  _directSubordinates: number;
  _totalSubordinates: number;
  activeEmployment: {
    __typename: "Employment2";
    id: number;
    jobTitle: string | null;
  } | null;
};

export type ApprovalsOrganizationChartView_compCyclePhase = {
  __typename: "CompCyclePhase2";
  id: number;
  phaseOrder: number;
  startDate: GraphQL_Date;
  compCyclePhaseAssignments: Array<{
    __typename: "CompCyclePhaseAssignment2";
    id: number;
    phaseId: number;
    status: PhaseTimelineStatus | null;
    assigneeId: number;
  } | null>;
};

export type DraftNoteModalButton2_participant = {
  __typename: "CompCycleParticipant";
  subjectId: number;
  compCycleId: number;
  subject: {
    __typename: "Employee2";
    managerId: number | null;
    id: number;
    displayName: string;
    user: { __typename: "User2"; id: number; photoURL: string | null } | null;
    activeEmployment: {
      __typename: "Employment2";
      id: number;
      jobTitle: string | null;
    } | null;
  };
  compRecommendation: {
    __typename: "CompRecommendation2";
    subjectId: number;
    compCycleId: number;
    allSubmittedItems: Array<{
      __typename: "RecItem2";
      id: number;
      note: string | null;
      submittedAt: GraphQL_DateTime | null;
      recommendedCashValue: GraphQL_Money | null;
      recommendationType: RecItemType;
      author: {
        __typename: "User2";
        id: number;
        email: string;
        name: string | null;
        photoURL: string | null;
      };
    }>;
    allSubmittedReviews: Array<{
      __typename: "RecReview2";
      id: number;
      note: string | null;
      submittedAt: GraphQL_DateTime | null;
      author: {
        __typename: "User2";
        id: number;
        email: string;
        name: string | null;
        photoURL: string | null;
      };
    }>;
  } | null;
};

export type UploadRequestsButton_employee = {
  __typename: "Employee";
  id: number;
  employeeNumber: string | null;
  email: string;
  personalEmail: string | null;
  activeEmployment: {
    __typename: "Employment";
    id: number;
    salary: GraphQL_Money | null;
    payRate: GraphQL_Money | null;
  } | null;
};

export type UploadRequestsButton_position = {
  __typename: "Position";
  id: number;
  name: string;
};

export type UploadRequestsButton_valuation = {
  __typename: "Valuation";
  valuation: GraphQL_Money;
  fdso: number;
};

export type CompCycleSettingsCloseCompCycle_compCycle = {
  __typename: "CompCycle";
  id: number;
  name: string;
  endedAt: GraphQL_DateTime | null;
  awaitingReviewCount: number | null;
};

export type BudgetBarComponents2_compCycle = {
  __typename: "CompCycle2";
  id: number;
  allowSalary: boolean;
  allowSalaryMarket: boolean;
  allowSalaryMerit: boolean;
  allowSalaryPromotion: boolean;
  allowActualRecurringBonus: boolean;
  allowTargetCommission: boolean;
  allowTargetRecurringBonus: boolean;
  allowEquity: boolean;
  allowBonus: boolean;
};

export type BudgetBarComponents2_compCycleBudget = {
  __typename: "CompCycleBudget";
  compCycleId: number;
  employeeId: number | null;
  salary: GraphQL_Money | null;
  salaryMarket: GraphQL_Money | null;
  salaryMerit: GraphQL_Money | null;
  salaryPromotion: GraphQL_Money | null;
  bonus: GraphQL_Money | null;
  equity: GraphQL_Money | null;
  targetRecurringBonus: GraphQL_Money | null;
  targetCommission: GraphQL_Money | null;
  actualRecurringBonus: GraphQL_Money | null;
};

export type BudgetBarComponents2_valuation = {
  __typename: "Valuation";
  id: number;
  fdso: number;
  valuation: GraphQL_Money;
};

export type BudgetEquityDetail_valuation = {
  __typename: "Valuation";
  id: number;
  fdso: number;
  valuation: GraphQL_Money;
};

export type CompCycleBudgetBar2_compCycle = {
  __typename: "CompCycle2";
  id: number;
  allowSalary: boolean;
  allowSalaryMarket: boolean;
  allowSalaryMerit: boolean;
  allowSalaryPromotion: boolean;
  allowActualRecurringBonus: boolean;
  allowTargetCommission: boolean;
  allowTargetRecurringBonus: boolean;
  allowEquity: boolean;
  allowBonus: boolean;
};

export type CompCycleBudgetBar2_compCycleBudget = {
  __typename: "CompCycleBudget";
  compCycleId: number;
  employeeId: number | null;
  salary: GraphQL_Money | null;
  salaryMarket: GraphQL_Money | null;
  salaryMerit: GraphQL_Money | null;
  salaryPromotion: GraphQL_Money | null;
  bonus: GraphQL_Money | null;
  equity: GraphQL_Money | null;
  targetRecurringBonus: GraphQL_Money | null;
  targetCommission: GraphQL_Money | null;
  actualRecurringBonus: GraphQL_Money | null;
};

export type CompCycleBudgetBar2_valuation = {
  __typename: "Valuation";
  id: number;
  fdso: number;
  valuation: GraphQL_Money;
};

export type CompCycleSingleRec_meritAdjustmentGuide = {
  __typename: "MeritAdjustmentGuide";
  id: number;
  perfRatingOptionId: number;
  meritAdjustmentRangeId: number;
  percent: number;
};

export type CompCycleSingleRec_perfRatingOption = {
  __typename: "PerfRatingOption";
  id: number;
  name: string;
  rank: number;
};

export type CompCycleSingleRec_meritAdjustmentRange = {
  __typename: "MeritAdjustmentRange";
  id: number;
  rangeStart: number;
};

export type CompCycleSingleRecQueryVariables = Exact<{
  compCycleId: Scalars["Int"]["input"];
  employeeId: Scalars["Int"]["input"];
  currencyCode?: InputMaybe<CurrencyCode>;
  skipEligibility?: InputMaybe<Scalars["Boolean"]["input"]>;
  actingManagerEmployeeId?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type CompCycleSingleRecQuery = {
  compCycle2: {
    __typename: "CompCycle2";
    id: number;
    recommendationsPreFill: boolean;
    allowEquity: boolean;
    allowTargetCommission: boolean;
    allowTargetRecurringBonus: boolean;
    allowActualRecurringBonus: boolean;
    allowSalary: boolean;
    allowSalaryMerit: boolean;
    allowSalaryMarket: boolean;
    allowSalaryPromotion: boolean;
    allowBonus: boolean;
    participant: {
      __typename: "CompCycleParticipant";
      subjectId: number;
      compCycleId: number;
      perfRating: string | null;
      subject: {
        __typename: "Employee2";
        id: number;
        displayName: string;
        activeAt: GraphQL_Date | null;
        email: string | null;
        managerId: number | null;
        adjustedCashBands: Array<{
          __typename: "AdjustedCashBand";
          id: string;
          name: string;
          bandPoints: Array<{
            __typename: "AdjustedCashBandPoint";
            id: string;
            bandName: string;
            name: string;
            annualCashEquivalent: GraphQL_Money;
            hourlyCashEquivalent: GraphQL_Money;
            value: {
              __typename: "CashValue";
              annualRate: GraphQL_Money | null;
              currencyCode: CurrencyCode;
              hourlyRate: GraphQL_Money | null;
            };
          }>;
        }> | null;
        activeCashCompensation: Array<{
          __typename: "CashCompensation2";
          type: CashCompType;
          annualCashEquivalent: GraphQL_Money;
          hourlyCashEquivalent: GraphQL_Money;
          unit: CompUnit;
          employeeId: number;
          activeAt: GraphQL_Date;
          percentOfSalary: number;
        }> | null;
        activeEmployment: {
          __typename: "Employment2";
          id: number;
          payPeriod: PayPeriodType;
          payCurrencyCode: string | null;
          salary: GraphQL_Money | null;
          jobTitle: string | null;
          payRate: GraphQL_Money | null;
          position: {
            __typename: "Position";
            id: number;
            name: string;
            level: number;
            type: PositionType;
            ladder: {
              __typename: "Ladder";
              id: number;
              name: string;
              department: {
                __typename: "Department";
                id: number;
                name: string;
              };
            };
          } | null;
        } | null;
        adjustedEquityBands: Array<{
          __typename: "AdjustedEquityBand";
          id: string;
          name: string;
          bandPoints: Array<{
            __typename: "AdjustedEquityBandPoint";
            bandName: string;
            annualCashEquivalent: GraphQL_Money;
            value:
              | {
                  __typename: "CashValue";
                  annualRate: GraphQL_Money | null;
                  currencyCode: CurrencyCode;
                }
              | { __typename: "PercentValue" }
              | { __typename: "UnitValue" };
          }>;
        }> | null;
        equityHoldings: {
          __typename: "EquityHoldings2";
          id: number;
          totalUnitCount: number;
          totalGrossHoldingValue: GraphQL_Money | null;
          grants: Array<{ __typename: "EquityGrant2"; id: number }> | null;
        };
        location: {
          __typename: "LocationGroup2";
          id: number;
          name: string;
          marketId: number;
        } | null;
        user: {
          __typename: "User2";
          id: number;
          photoURL: string | null;
        } | null;
        minimalManager: {
          __typename: "MinimalEmployee2";
          id: number;
          displayName: string | null;
          user: {
            __typename: "User2";
            id: number;
            photoURL: string | null;
          } | null;
        } | null;
      };
      managerCompCycleBudget: {
        __typename: "CompCycleBudget";
        compCycleId: number;
        employeeId: number | null;
        salary: GraphQL_Money | null;
        salaryMarket: GraphQL_Money | null;
        salaryMerit: GraphQL_Money | null;
        salaryPromotion: GraphQL_Money | null;
        bonus: GraphQL_Money | null;
        equity: GraphQL_Money | null;
        targetRecurringBonus: GraphQL_Money | null;
        targetCommission: GraphQL_Money | null;
        actualRecurringBonus: GraphQL_Money | null;
      } | null;
      compRecommendation: {
        __typename: "CompRecommendation2";
        subjectId: number;
        compCycleId: number;
        reviewStatus: CompRecommendationStatus;
        canICurrentlyEdit: boolean;
        latestSubmittedPayIncrease: {
          __typename: "CompValue";
          annualCashEquivalent: GraphQL_Money;
          hourlyCashEquivalent: GraphQL_Money;
          unitType: CompUnit | null;
        };
        latestSubmittedItems: Array<{
          __typename: "RecItem2";
          id: number;
          submittedAt: GraphQL_DateTime | null;
          recommendationType: RecItemType;
          recommendedCashValue: GraphQL_Money | null;
          recommendedPercentValue: number | null;
          recommendedTitle: string | null;
          note: string | null;
          unitType: CompUnit | null;
          recommendedPosition: { __typename: "Position"; id: number } | null;
          adjustedCashBands: Array<{
            __typename: "AdjustedCashBand";
            id: string;
            name: string;
            bandPoints: Array<{
              __typename: "AdjustedCashBandPoint";
              value: {
                __typename: "CashValue";
                annualRate: GraphQL_Money | null;
                currencyCode: CurrencyCode;
              };
            }>;
          }> | null;
        }>;
        allSubmittedReviews: Array<{
          __typename: "RecReview2";
          id: number;
          submittedAt: GraphQL_DateTime | null;
          status: RecReviewStatus;
          note: string | null;
          author: {
            __typename: "User2";
            id: number;
            name: string | null;
            email: string;
            photoURL: string | null;
          };
        }>;
        allSubmittedItems: Array<{
          __typename: "RecItem2";
          id: number;
          note: string | null;
          submittedAt: GraphQL_DateTime | null;
          recommendedCashValue: GraphQL_Money | null;
          recommendationType: RecItemType;
          author: {
            __typename: "User2";
            id: number;
            email: string;
            name: string | null;
            photoURL: string | null;
          };
        }>;
      } | null;
    } | null;
    meritAdjustmentGuides: Array<{
      __typename: "MeritAdjustmentGuide";
      id: number;
      perfRatingOptionId: number;
      meritAdjustmentRangeId: number;
      percent: number;
    }>;
    currentPhase: { __typename: "CompCyclePhase2"; id: number } | null;
    phases: Array<{ __typename: "CompCyclePhase2"; id: number }>;
  } | null;
  positions: Array<{
    __typename: "Position";
    id: number;
    level: number;
    type: PositionType;
    name: string;
    ladder: {
      __typename: "Ladder";
      id: number;
      name: string;
      department: { __typename: "Department"; id: number; name: string };
    };
  }>;
  valuation: {
    __typename: "Valuation";
    id: number;
    valuation: GraphQL_Money;
    fdso: number;
  } | null;
  perfRatingOptions: Array<{
    __typename: "PerfRatingOption";
    id: number;
    name: string;
    rank: number;
  }>;
  meritAdjustmentRanges: Array<{
    __typename: "MeritAdjustmentRange";
    id: number;
    rangeStart: number;
  }>;
};

export type CompCycleSingleRecModal_recItem = {
  __typename: "RecItem2";
  id: number;
  submittedAt: GraphQL_DateTime | null;
  recommendationType: RecItemType;
  recommendedCashValue: GraphQL_Money | null;
  recommendedPercentValue: number | null;
  recommendedTitle: string | null;
  note: string | null;
  unitType: CompUnit | null;
  recommendedPosition: { __typename: "Position"; id: number } | null;
  adjustedCashBands: Array<{
    __typename: "AdjustedCashBand";
    id: string;
    name: string;
    bandPoints: Array<{
      __typename: "AdjustedCashBandPoint";
      value: {
        __typename: "CashValue";
        annualRate: GraphQL_Money | null;
        currencyCode: CurrencyCode;
      };
    }>;
  }> | null;
};

export type CompCycleSingleRecModal_participant = {
  __typename: "CompCycleParticipant";
  subjectId: number;
  compCycleId: number;
  perfRating: string | null;
  managerCompCycleBudget: {
    __typename: "CompCycleBudget";
    compCycleId: number;
    employeeId: number | null;
    salary: GraphQL_Money | null;
    salaryMarket: GraphQL_Money | null;
    salaryMerit: GraphQL_Money | null;
    salaryPromotion: GraphQL_Money | null;
    bonus: GraphQL_Money | null;
    equity: GraphQL_Money | null;
    targetRecurringBonus: GraphQL_Money | null;
    targetCommission: GraphQL_Money | null;
    actualRecurringBonus: GraphQL_Money | null;
  } | null;
  subject: {
    __typename: "Employee2";
    id: number;
    displayName: string;
    activeAt: GraphQL_Date | null;
    email: string | null;
    managerId: number | null;
    activeCashCompensation: Array<{
      __typename: "CashCompensation2";
      employeeId: number;
      type: CashCompType;
      activeAt: GraphQL_Date;
      annualCashEquivalent: GraphQL_Money;
      unit: CompUnit;
      hourlyCashEquivalent: GraphQL_Money;
      percentOfSalary: number;
    }> | null;
    activeEmployment: {
      __typename: "Employment2";
      id: number;
      payPeriod: PayPeriodType;
      payCurrencyCode: string | null;
      salary: GraphQL_Money | null;
      jobTitle: string | null;
      payRate: GraphQL_Money | null;
      position: {
        __typename: "Position";
        id: number;
        name: string;
        level: number;
        type: PositionType;
        ladder: {
          __typename: "Ladder";
          id: number;
          name: string;
          department: { __typename: "Department"; id: number; name: string };
        };
      } | null;
    } | null;
    adjustedCashBands: Array<{
      __typename: "AdjustedCashBand";
      id: string;
      name: string;
      bandPoints: Array<{
        __typename: "AdjustedCashBandPoint";
        id: string;
        name: string;
        bandName: string;
        annualCashEquivalent: GraphQL_Money;
        hourlyCashEquivalent: GraphQL_Money;
        value: {
          __typename: "CashValue";
          annualRate: GraphQL_Money | null;
          currencyCode: CurrencyCode;
          hourlyRate: GraphQL_Money | null;
        };
      }>;
    }> | null;
    adjustedEquityBands: Array<{
      __typename: "AdjustedEquityBand";
      id: string;
      name: string;
      bandPoints: Array<{
        __typename: "AdjustedEquityBandPoint";
        bandName: string;
        annualCashEquivalent: GraphQL_Money;
        value:
          | {
              __typename: "CashValue";
              annualRate: GraphQL_Money | null;
              currencyCode: CurrencyCode;
            }
          | { __typename: "PercentValue" }
          | { __typename: "UnitValue" };
      }>;
    }> | null;
    equityHoldings: {
      __typename: "EquityHoldings2";
      id: number;
      totalUnitCount: number;
      totalGrossHoldingValue: GraphQL_Money | null;
      grants: Array<{ __typename: "EquityGrant2"; id: number }> | null;
    };
    location: {
      __typename: "LocationGroup2";
      id: number;
      name: string;
      marketId: number;
    } | null;
    user: { __typename: "User2"; id: number; photoURL: string | null } | null;
    minimalManager: {
      __typename: "MinimalEmployee2";
      id: number;
      displayName: string | null;
      user: { __typename: "User2"; id: number; photoURL: string | null } | null;
    } | null;
  };
  compRecommendation: {
    __typename: "CompRecommendation2";
    subjectId: number;
    compCycleId: number;
    reviewStatus: CompRecommendationStatus;
    canICurrentlyEdit: boolean;
    latestSubmittedPayIncrease: {
      __typename: "CompValue";
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      unitType: CompUnit | null;
    };
    latestSubmittedItems: Array<{
      __typename: "RecItem2";
      id: number;
      submittedAt: GraphQL_DateTime | null;
      recommendationType: RecItemType;
      recommendedCashValue: GraphQL_Money | null;
      recommendedPercentValue: number | null;
      recommendedTitle: string | null;
      note: string | null;
      unitType: CompUnit | null;
      recommendedPosition: { __typename: "Position"; id: number } | null;
      adjustedCashBands: Array<{
        __typename: "AdjustedCashBand";
        id: string;
        name: string;
        bandPoints: Array<{
          __typename: "AdjustedCashBandPoint";
          value: {
            __typename: "CashValue";
            annualRate: GraphQL_Money | null;
            currencyCode: CurrencyCode;
          };
        }>;
      }> | null;
    }>;
    allSubmittedReviews: Array<{
      __typename: "RecReview2";
      id: number;
      submittedAt: GraphQL_DateTime | null;
      status: RecReviewStatus;
      note: string | null;
      author: {
        __typename: "User2";
        id: number;
        name: string | null;
        email: string;
        photoURL: string | null;
      };
    }>;
    allSubmittedItems: Array<{
      __typename: "RecItem2";
      id: number;
      note: string | null;
      submittedAt: GraphQL_DateTime | null;
      recommendedCashValue: GraphQL_Money | null;
      recommendationType: RecItemType;
      author: {
        __typename: "User2";
        id: number;
        email: string;
        name: string | null;
        photoURL: string | null;
      };
    }>;
  } | null;
};

export type CompCycleSingleRecModal_position = {
  __typename: "Position";
  id: number;
  level: number;
  type: PositionType;
  name: string;
  ladder: {
    __typename: "Ladder";
    id: number;
    name: string;
    department: { __typename: "Department"; id: number; name: string };
  };
};

export type CompCycleSingleRecModal_compCycle = {
  __typename: "CompCycle2";
  allowEquity: boolean;
  allowTargetCommission: boolean;
  allowTargetRecurringBonus: boolean;
  allowActualRecurringBonus: boolean;
  id: number;
  allowSalary: boolean;
  allowSalaryMerit: boolean;
  allowSalaryMarket: boolean;
  allowSalaryPromotion: boolean;
  allowBonus: boolean;
  currentPhase: { __typename: "CompCyclePhase2"; id: number } | null;
  phases: Array<{ __typename: "CompCyclePhase2"; id: number }>;
};

export type CompCycleSingleRecModal_valuation = {
  __typename: "Valuation";
  id: number;
  valuation: GraphQL_Money;
  fdso: number;
};

export type CompCycleSingleRecSubHeader_compCycle = {
  __typename: "CompCycle2";
  id: number;
  allowSalary: boolean;
  allowSalaryMerit: boolean;
  allowSalaryMarket: boolean;
  allowSalaryPromotion: boolean;
  allowBonus: boolean;
  allowEquity: boolean;
  allowTargetCommission: boolean;
  allowTargetRecurringBonus: boolean;
  allowActualRecurringBonus: boolean;
  phases: Array<{ __typename: "CompCyclePhase2"; id: number }>;
};

export type CompCycleTableHeaders_employee = {
  __typename: "Employee";
  id: number;
};

export type SupportingManagerBannerLoadingBoundaryQueryVariables = Exact<{
  managerId: Scalars["Int"]["input"];
  filter: Employee2sFilter;
}>;

export type SupportingManagerBannerLoadingBoundaryQuery = {
  employee2s: {
    __typename: "Employee2s";
    employees: Array<{
      __typename: "Employee2";
      id: number;
      minimalManager: {
        __typename: "MinimalEmployee2";
        id: number;
        displayName: string | null;
      } | null;
    }>;
  };
};

export type ActualVariablePayRows_employee = {
  __typename: "Employee2";
  id: number;
  displayName: string;
  activeEmployment: {
    __typename: "Employment2";
    id: number;
    payCurrencyCode: string | null;
    jobTitle: string | null;
  } | null;
  activeCashCompensation: Array<{
    __typename: "CashCompensation2";
    employeeId: number;
    activeAt: GraphQL_Date;
    type: CashCompType;
    annualCashEquivalent: GraphQL_Money;
    percentOfSalary: number;
    unit: CompUnit;
  }> | null;
  user: { __typename: "User2"; id: number; photoURL: string | null } | null;
};

export type ActualVariablePayRows_compCycle = {
  __typename: "CompCycle2";
  id: number;
  allowBonus: boolean;
  allowActualRecurringBonus: boolean;
};

export type CashRows_employee = {
  __typename: "Employee2";
  id: number;
  displayName: string;
  activeCashCompensation: Array<{
    __typename: "CashCompensation2";
    type: CashCompType;
    annualCashEquivalent: GraphQL_Money;
    hourlyCashEquivalent: GraphQL_Money;
    unit: CompUnit;
    employeeId: number;
    activeAt: GraphQL_Date;
  }> | null;
  activeEmployment: {
    __typename: "Employment2";
    id: number;
    salary: GraphQL_Money | null;
    payPeriod: PayPeriodType;
    payRate: GraphQL_Money | null;
    payCurrencyCode: string | null;
    jobTitle: string | null;
    position: {
      __typename: "Position";
      id: number;
      name: string;
      level: number;
      type: PositionType;
      ladder: {
        __typename: "Ladder";
        id: number;
        name: string;
        department: { __typename: "Department"; id: number; name: string };
      };
    } | null;
  } | null;
  adjustedCashBands: Array<{
    __typename: "AdjustedCashBand";
    id: string;
    name: string;
    bandPoints: Array<{
      __typename: "AdjustedCashBandPoint";
      id: string;
      name: string;
      bandName: string;
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      value: {
        __typename: "CashValue";
        annualRate: GraphQL_Money | null;
        hourlyRate: GraphQL_Money | null;
        currencyCode: CurrencyCode;
      };
    }>;
  }> | null;
  user: { __typename: "User2"; id: number; photoURL: string | null } | null;
};

export type CashRows_position = {
  __typename: "Position";
  id: number;
  name: string;
  level: number;
  type: PositionType;
  ladder: {
    __typename: "Ladder";
    id: number;
    name: string;
    department: { __typename: "Department"; id: number; name: string };
  };
};

export type CashRows_promotedPosition = {
  __typename: "Position";
  id: number;
  name: string;
  level: number;
  type: PositionType;
  ladder: {
    __typename: "Ladder";
    id: number;
    name: string;
    department: { __typename: "Department"; id: number; name: string };
  };
  adjustedCashBands: Array<{
    __typename: "AdjustedCashBand";
    id: string;
    name: string;
    bandPoints: Array<{
      __typename: "AdjustedCashBandPoint";
      id: string;
      name: string;
      bandName: string;
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      value: {
        __typename: "CashValue";
        annualRate: GraphQL_Money | null;
        hourlyRate: GraphQL_Money | null;
        currencyCode: CurrencyCode;
      };
    }>;
  }> | null;
};

export type CashRows_compCycle = {
  __typename: "CompCycle2";
  id: number;
  allowSalary: boolean;
  allowSalaryMerit: boolean;
  allowSalaryMarket: boolean;
  allowSalaryPromotion: boolean;
  allowBonus: boolean;
};

export type CompCycleLineItemRowButton_employee = {
  __typename: "Employee2";
  id: number;
  displayName: string;
  user: { __typename: "User2"; id: number; photoURL: string | null } | null;
  activeEmployment: {
    __typename: "Employment2";
    id: number;
    jobTitle: string | null;
  } | null;
};

export type CompCyclePersonRow_participant = {
  __typename: "CompCycleParticipant";
  subjectId: number;
  compCycleId: number;
  perfRating: string | null;
  subject: {
    __typename: "Employee2";
    id: number;
    activeAt: GraphQL_Date | null;
    displayName: string;
    managerId: number | null;
    location: {
      __typename: "LocationGroup2";
      id: number;
      marketId: number;
      name: string;
    } | null;
    user: { __typename: "User2"; id: number; photoURL: string | null } | null;
    minimalManager: {
      __typename: "MinimalEmployee2";
      id: number;
      displayName: string | null;
      user: { __typename: "User2"; id: number; photoURL: string | null } | null;
    } | null;
    activeEmployment: {
      __typename: "Employment2";
      id: number;
      payPeriod: PayPeriodType;
      payCurrencyCode: string | null;
      salary: GraphQL_Money | null;
      jobTitle: string | null;
      payRate: GraphQL_Money | null;
      position: {
        __typename: "Position";
        id: number;
        name: string;
        level: number;
        type: PositionType;
        ladder: {
          __typename: "Ladder";
          id: number;
          name: string;
          department: { __typename: "Department"; id: number; name: string };
        };
      } | null;
    } | null;
    equityHoldings: {
      __typename: "EquityHoldings2";
      id: number;
      totalGrossHoldingValue: GraphQL_Money | null;
      totalUnitCount: number;
    };
    activeCashCompensation: Array<{
      __typename: "CashCompensation2";
      employeeId: number;
      activeAt: GraphQL_Date;
      type: CashCompType;
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      unit: CompUnit;
      percentOfSalary: number;
    }> | null;
    adjustedCashBands: Array<{
      __typename: "AdjustedCashBand";
      id: string;
      name: string;
      bandPoints: Array<{
        __typename: "AdjustedCashBandPoint";
        id: string;
        name: string;
        bandName: string;
        annualCashEquivalent: GraphQL_Money;
        hourlyCashEquivalent: GraphQL_Money;
        value: {
          __typename: "CashValue";
          annualRate: GraphQL_Money | null;
          hourlyRate: GraphQL_Money | null;
          currencyCode: CurrencyCode;
        };
      }>;
    }> | null;
    adjustedEquityBands: Array<{
      __typename: "AdjustedEquityBand";
      id: string;
      name: string;
      bandPoints: Array<{
        __typename: "AdjustedEquityBandPoint";
        bandName: string;
        annualCashEquivalent: GraphQL_Money;
      }>;
    }> | null;
  };
  compRecommendation: {
    __typename: "CompRecommendation2";
    subjectId: number;
    compCycleId: number;
    allSubmittedReviews: Array<{
      __typename: "RecReview2";
      id: number;
      submittedAt: GraphQL_DateTime | null;
      status: RecReviewStatus;
      note: string | null;
      author: {
        __typename: "User2";
        id: number;
        name: string | null;
        email: string;
        photoURL: string | null;
      };
    }>;
    allSubmittedItems: Array<{
      __typename: "RecItem2";
      id: number;
      note: string | null;
      submittedAt: GraphQL_DateTime | null;
      recommendedCashValue: GraphQL_Money | null;
      recommendationType: RecItemType;
      author: {
        __typename: "User2";
        id: number;
        email: string;
        name: string | null;
        photoURL: string | null;
      };
    }>;
  } | null;
};

export type CompCyclePersonRow_position = {
  __typename: "Position";
  id: number;
  name: string;
  level: number;
  type: PositionType;
  ladder: {
    __typename: "Ladder";
    id: number;
    name: string;
    department: { __typename: "Department"; id: number; name: string };
  };
};

export type CompCyclePersonRow_valuation = {
  __typename: "Valuation";
  id: number;
  valuation: GraphQL_Money;
  fdso: number;
};

export type CompCyclePersonRow_compCycle = {
  __typename: "CompCycle2";
  allowEquity: boolean;
  allowTargetCommission: boolean;
  allowTargetRecurringBonus: boolean;
  allowActualRecurringBonus: boolean;
  id: number;
  allowSalary: boolean;
  allowSalaryMerit: boolean;
  allowSalaryMarket: boolean;
  allowSalaryPromotion: boolean;
  allowBonus: boolean;
};

export type EquityRows_employee = {
  __typename: "Employee2";
  id: number;
  displayName: string;
  activeEmployment: {
    __typename: "Employment2";
    id: number;
    payCurrencyCode: string | null;
    jobTitle: string | null;
    position: {
      __typename: "Position";
      id: number;
      name: string;
      level: number;
      type: PositionType;
    } | null;
  } | null;
  adjustedEquityBands: Array<{
    __typename: "AdjustedEquityBand";
    id: string;
    name: string;
    bandPoints: Array<{
      __typename: "AdjustedEquityBandPoint";
      bandName: string;
      annualCashEquivalent: GraphQL_Money;
    }>;
  }> | null;
  user: { __typename: "User2"; id: number; photoURL: string | null } | null;
};

export type EquityRows_valuation = {
  __typename: "Valuation";
  id: number;
  valuation: GraphQL_Money;
  fdso: number;
};

export type ActualTargetPayRow_employee = {
  __typename: "Employee2";
  id: number;
  displayName: string;
  activeCashCompensation: Array<{
    __typename: "CashCompensation2";
    employeeId: number;
    activeAt: GraphQL_Date;
    type: CashCompType;
    annualCashEquivalent: GraphQL_Money;
    percentOfSalary: number;
    unit: CompUnit;
  }> | null;
  user: { __typename: "User2"; id: number; photoURL: string | null } | null;
  activeEmployment: {
    __typename: "Employment2";
    id: number;
    jobTitle: string | null;
  } | null;
};

export type EquityGrantRow_employee = {
  __typename: "Employee2";
  id: number;
  displayName: string;
  adjustedEquityBands: Array<{
    __typename: "AdjustedEquityBand";
    id: string;
    name: string;
    bandPoints: Array<{
      __typename: "AdjustedEquityBandPoint";
      bandName: string;
      annualCashEquivalent: GraphQL_Money;
    }>;
  }> | null;
  activeEmployment: {
    __typename: "Employment2";
    id: number;
    jobTitle: string | null;
    position: {
      __typename: "Position";
      id: number;
      name: string;
      level: number;
      type: PositionType;
    } | null;
  } | null;
  user: { __typename: "User2"; id: number; photoURL: string | null } | null;
};

export type EquityGrantRow_valuation = {
  __typename: "Valuation";
  id: number;
  valuation: GraphQL_Money;
  fdso: number;
};

export type NewSalaryRow_employee = {
  __typename: "Employee2";
  id: number;
  activeCashCompensation: Array<{
    __typename: "CashCompensation2";
    employeeId: number;
    type: CashCompType;
    activeAt: GraphQL_Date;
    annualCashEquivalent: GraphQL_Money;
    hourlyCashEquivalent: GraphQL_Money;
    unit: CompUnit;
  }> | null;
  activeEmployment: {
    __typename: "Employment2";
    id: number;
    position: {
      __typename: "Position";
      id: number;
      name: string;
      level: number;
      type: PositionType;
      ladder: {
        __typename: "Ladder";
        id: number;
        name: string;
        department: { __typename: "Department"; id: number; name: string };
      };
    } | null;
  } | null;
  adjustedCashBands: Array<{
    __typename: "AdjustedCashBand";
    id: string;
    name: string;
    bandPoints: Array<{
      __typename: "AdjustedCashBandPoint";
      id: string;
      name: string;
      bandName: string;
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      value: {
        __typename: "CashValue";
        annualRate: GraphQL_Money | null;
        hourlyRate: GraphQL_Money | null;
        currencyCode: CurrencyCode;
      };
    }>;
  }> | null;
};

export type NewSalaryRow_position = {
  __typename: "Position";
  id: number;
  name: string;
  level: number;
  type: PositionType;
  ladder: {
    __typename: "Ladder";
    id: number;
    name: string;
    department: { __typename: "Department"; id: number; name: string };
  };
  adjustedCashBands: Array<{
    __typename: "AdjustedCashBand";
    id: string;
    name: string;
    bandPoints: Array<{
      __typename: "AdjustedCashBandPoint";
      id: string;
      name: string;
      bandName: string;
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      value: {
        __typename: "CashValue";
        annualRate: GraphQL_Money | null;
        hourlyRate: GraphQL_Money | null;
        currencyCode: CurrencyCode;
      };
    }>;
  }> | null;
};

export type OneTimeBonusRow_employee = {
  __typename: "Employee2";
  id: number;
  displayName: string;
  user: { __typename: "User2"; id: number; photoURL: string | null } | null;
  activeEmployment: {
    __typename: "Employment2";
    id: number;
    jobTitle: string | null;
  } | null;
};

export type PositionRow_employee = {
  __typename: "Employee2";
  id: number;
  displayName: string;
  user: { __typename: "User2"; id: number; photoURL: string | null } | null;
  activeEmployment: {
    __typename: "Employment2";
    id: number;
    jobTitle: string | null;
  } | null;
};

export type PositionRow_position = {
  __typename: "Position";
  id: number;
  name: string;
  level: number;
  type: PositionType;
  ladder: {
    __typename: "Ladder";
    id: number;
    name: string;
    department: { __typename: "Department"; id: number; name: string };
  };
};

export type PositionRow_promotedPosition = {
  __typename: "Position";
  id: number;
  type: PositionType;
  adjustedCashBands: Array<{
    __typename: "AdjustedCashBand";
    id: string;
    name: string;
    bandPoints: Array<{
      __typename: "AdjustedCashBandPoint";
      id: string;
      name: string;
      bandName: string;
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      value: {
        __typename: "CashValue";
        annualRate: GraphQL_Money | null;
        hourlyRate: GraphQL_Money | null;
        currencyCode: CurrencyCode;
      };
    }>;
  }> | null;
};

export type ReviewerNoteRow_participant = {
  __typename: "CompCycleParticipant";
  subjectId: number;
  compCycleId: number;
  compRecommendation: {
    __typename: "CompRecommendation2";
    subjectId: number;
    compCycleId: number;
    allSubmittedReviews: Array<{
      __typename: "RecReview2";
      id: number;
      submittedAt: GraphQL_DateTime | null;
      status: RecReviewStatus;
      note: string | null;
      author: {
        __typename: "User2";
        id: number;
        name: string | null;
        email: string;
        photoURL: string | null;
      };
    }>;
    allSubmittedItems: Array<{
      __typename: "RecItem2";
      id: number;
      note: string | null;
      submittedAt: GraphQL_DateTime | null;
      recommendedCashValue: GraphQL_Money | null;
      recommendationType: RecItemType;
      author: {
        __typename: "User2";
        id: number;
        email: string;
        name: string | null;
        photoURL: string | null;
      };
    }>;
  } | null;
  subject: {
    __typename: "Employee2";
    managerId: number | null;
    id: number;
    displayName: string;
    user: { __typename: "User2"; id: number; photoURL: string | null } | null;
    activeEmployment: {
      __typename: "Employment2";
      id: number;
      jobTitle: string | null;
    } | null;
  };
};

export type SalaryIncreaseRow_employee = {
  __typename: "Employee2";
  id: number;
  displayName: string;
  activeEmployment: {
    __typename: "Employment2";
    id: number;
    salary: GraphQL_Money | null;
    payPeriod: PayPeriodType;
    payRate: GraphQL_Money | null;
    payCurrencyCode: string | null;
    jobTitle: string | null;
  } | null;
  activeCashCompensation: Array<{
    __typename: "CashCompensation2";
    type: CashCompType;
    annualCashEquivalent: GraphQL_Money;
    hourlyCashEquivalent: GraphQL_Money;
    unit: CompUnit;
  }> | null;
  user: { __typename: "User2"; id: number; photoURL: string | null } | null;
};

export type TargetIncreaseRow_employee = {
  __typename: "Employee2";
  id: number;
  displayName: string;
  user: { __typename: "User2"; id: number; photoURL: string | null } | null;
  activeEmployment: {
    __typename: "Employment2";
    id: number;
    jobTitle: string | null;
    salary: GraphQL_Money | null;
    payPeriod: PayPeriodType;
    payRate: GraphQL_Money | null;
    payCurrencyCode: string | null;
    position: {
      __typename: "Position";
      id: number;
      name: string;
      level: number;
      type: PositionType;
      ladder: {
        __typename: "Ladder";
        id: number;
        name: string;
        department: { __typename: "Department"; id: number; name: string };
      };
    } | null;
  } | null;
  activeCashCompensation: Array<{
    __typename: "CashCompensation2";
    activeAt: GraphQL_Date;
    type: CashCompType;
    annualCashEquivalent: GraphQL_Money;
    hourlyCashEquivalent: GraphQL_Money;
    percentOfSalary: number;
    unit: CompUnit;
    employeeId: number;
  }> | null;
  adjustedCashBands: Array<{
    __typename: "AdjustedCashBand";
    id: string;
    name: string;
    bandPoints: Array<{
      __typename: "AdjustedCashBandPoint";
      id: string;
      name: string;
      bandName: string;
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      value: {
        __typename: "CashValue";
        annualRate: GraphQL_Money | null;
        hourlyRate: GraphQL_Money | null;
        currencyCode: CurrencyCode;
      };
    }>;
  }> | null;
};

export type TargetIncreaseRow_position = {
  __typename: "Position";
  id: number;
  name: string;
  level: number;
  type: PositionType;
  ladder: {
    __typename: "Ladder";
    id: number;
    name: string;
    department: { __typename: "Department"; id: number; name: string };
  };
  adjustedCashBands: Array<{
    __typename: "AdjustedCashBand";
    id: string;
    name: string;
    bandPoints: Array<{
      __typename: "AdjustedCashBandPoint";
      id: string;
      name: string;
      bandName: string;
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      value: {
        __typename: "CashValue";
        annualRate: GraphQL_Money | null;
        hourlyRate: GraphQL_Money | null;
        currencyCode: CurrencyCode;
      };
    }>;
  }> | null;
};

export type PersonRowHeaders_participant = {
  __typename: "CompCycleParticipant";
  subjectId: number;
  compCycleId: number;
  perfRating: string | null;
  subject: {
    __typename: "Employee2";
    id: number;
    activeAt: GraphQL_Date | null;
    displayName: string;
    user: { __typename: "User2"; id: number; photoURL: string | null } | null;
    minimalManager: {
      __typename: "MinimalEmployee2";
      id: number;
      displayName: string | null;
      user: { __typename: "User2"; id: number; photoURL: string | null } | null;
    } | null;
    activeEmployment: {
      __typename: "Employment2";
      id: number;
      payPeriod: PayPeriodType;
      position: {
        __typename: "Position";
        id: number;
        name: string;
        level: number;
        type: PositionType;
        ladder: {
          __typename: "Ladder";
          id: number;
          name: string;
          department: { __typename: "Department"; id: number; name: string };
        };
      } | null;
    } | null;
    equityHoldings: {
      __typename: "EquityHoldings2";
      id: number;
      totalGrossHoldingValue: GraphQL_Money | null;
      totalUnitCount: number;
    };
    location: { __typename: "LocationGroup2"; id: number; name: string } | null;
    activeCashCompensation: Array<{
      __typename: "CashCompensation2";
      employeeId: number;
      activeAt: GraphQL_Date;
      type: CashCompType;
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      unit: CompUnit;
      percentOfSalary: number;
    }> | null;
    adjustedCashBands: Array<{
      __typename: "AdjustedCashBand";
      id: string;
      name: string;
      bandPoints: Array<{
        __typename: "AdjustedCashBandPoint";
        id: string;
        name: string;
        bandName: string;
        annualCashEquivalent: GraphQL_Money;
        hourlyCashEquivalent: GraphQL_Money;
        value: {
          __typename: "CashValue";
          annualRate: GraphQL_Money | null;
          hourlyRate: GraphQL_Money | null;
          currencyCode: CurrencyCode;
        };
      }>;
    }> | null;
  };
};

export type PersonRowHeaders_position = {
  __typename: "Position";
  id: number;
  type: PositionType;
  name: string;
  level: number;
  adjustedCashBands: Array<{
    __typename: "AdjustedCashBand";
    id: string;
    name: string;
    bandPoints: Array<{
      __typename: "AdjustedCashBandPoint";
      id: string;
      name: string;
      bandName: string;
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      value: {
        __typename: "CashValue";
        annualRate: GraphQL_Money | null;
        hourlyRate: GraphQL_Money | null;
        currencyCode: CurrencyCode;
      };
    }>;
  }> | null;
};

export type PromotionPositionQueryVariables = Exact<{
  promotedPositionId: Scalars["Int"]["input"];
  currencyCode: CurrencyCode;
  marketId: Scalars["Int"]["input"];
  locationGroupId?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type PromotionPositionQuery = {
  promotedPosition: {
    __typename: "Position";
    id: number;
    name: string;
    level: number;
    type: PositionType;
    ladder: {
      __typename: "Ladder";
      id: number;
      name: string;
      department: { __typename: "Department"; id: number; name: string };
    };
    adjustedCashBands: Array<{
      __typename: "AdjustedCashBand";
      id: string;
      name: string;
      bandPoints: Array<{
        __typename: "AdjustedCashBandPoint";
        id: string;
        name: string;
        bandName: string;
        annualCashEquivalent: GraphQL_Money;
        hourlyCashEquivalent: GraphQL_Money;
        value: {
          __typename: "CashValue";
          annualRate: GraphQL_Money | null;
          hourlyRate: GraphQL_Money | null;
          currencyCode: CurrencyCode;
        };
      }>;
    }> | null;
  } | null;
};

export type TargetVariablePayRows_employee = {
  __typename: "Employee2";
  id: number;
  displayName: string;
  activeEmployment: {
    __typename: "Employment2";
    id: number;
    salary: GraphQL_Money | null;
    jobTitle: string | null;
    payPeriod: PayPeriodType;
    payRate: GraphQL_Money | null;
    payCurrencyCode: string | null;
    position: {
      __typename: "Position";
      id: number;
      name: string;
      level: number;
      type: PositionType;
      ladder: {
        __typename: "Ladder";
        id: number;
        name: string;
        department: { __typename: "Department"; id: number; name: string };
      };
    } | null;
  } | null;
  user: { __typename: "User2"; id: number; photoURL: string | null } | null;
  activeCashCompensation: Array<{
    __typename: "CashCompensation2";
    activeAt: GraphQL_Date;
    type: CashCompType;
    annualCashEquivalent: GraphQL_Money;
    hourlyCashEquivalent: GraphQL_Money;
    percentOfSalary: number;
    unit: CompUnit;
    employeeId: number;
  }> | null;
  adjustedCashBands: Array<{
    __typename: "AdjustedCashBand";
    id: string;
    name: string;
    bandPoints: Array<{
      __typename: "AdjustedCashBandPoint";
      id: string;
      name: string;
      bandName: string;
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      value: {
        __typename: "CashValue";
        annualRate: GraphQL_Money | null;
        hourlyRate: GraphQL_Money | null;
        currencyCode: CurrencyCode;
      };
    }>;
  }> | null;
};

export type TargetVariablePayRows_position = {
  __typename: "Position";
  id: number;
  name: string;
  level: number;
  type: PositionType;
  ladder: {
    __typename: "Ladder";
    id: number;
    name: string;
    department: { __typename: "Department"; id: number; name: string };
  };
  adjustedCashBands: Array<{
    __typename: "AdjustedCashBand";
    id: string;
    name: string;
    bandPoints: Array<{
      __typename: "AdjustedCashBandPoint";
      id: string;
      name: string;
      bandName: string;
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      value: {
        __typename: "CashValue";
        annualRate: GraphQL_Money | null;
        hourlyRate: GraphQL_Money | null;
        currencyCode: CurrencyCode;
      };
    }>;
  }> | null;
};

export type TargetVariablePayRows_compCycle = {
  __typename: "CompCycle2";
  id: number;
  allowTargetCommission: boolean;
  allowTargetRecurringBonus: boolean;
};

export type CycleBudgetAppBar2_compCycle = {
  __typename: "CompCycle2";
  id: number;
  allowSalary: boolean;
  allowSalaryMarket: boolean;
  allowSalaryMerit: boolean;
  allowSalaryPromotion: boolean;
  allowActualRecurringBonus: boolean;
  allowTargetCommission: boolean;
  allowTargetRecurringBonus: boolean;
  allowEquity: boolean;
  allowBonus: boolean;
};

export type CycleBudgetAppBar2_compCycleBudget = {
  __typename: "CompCycleBudget";
  compCycleId: number;
  employeeId: number | null;
  salary: GraphQL_Money | null;
  salaryMarket: GraphQL_Money | null;
  salaryMerit: GraphQL_Money | null;
  salaryPromotion: GraphQL_Money | null;
  bonus: GraphQL_Money | null;
  equity: GraphQL_Money | null;
  targetRecurringBonus: GraphQL_Money | null;
  targetCommission: GraphQL_Money | null;
  actualRecurringBonus: GraphQL_Money | null;
};

export type CycleBudgetAppBar2_valuation = {
  __typename: "Valuation";
  id: number;
  fdso: number;
  valuation: GraphQL_Money;
};

export type CompleteReviewDialog_compCycleSettings = {
  __typename: "CompCycle";
  id: number;
  name: string;
  endedAt: GraphQL_DateTime | null;
};

export type MeritGuidance_perfRatingOption = {
  __typename: "PerfRatingOption";
  id: number;
  name: string;
  rank: number;
};

export type MeritGuidance_meritAdjustmentRange = {
  __typename: "MeritAdjustmentRange";
  id: number;
  rangeStart: number;
};

export type MeritGuidance_meritAdjustmentGuide = {
  __typename: "MeritAdjustmentGuide";
  id: number;
  perfRatingOptionId: number;
  meritAdjustmentRangeId: number;
  percent: number;
  perfRatingOption: {
    __typename: "PerfRatingOption";
    id: number;
    name: string;
    rank: number;
  };
  meritAdjustmentRange: {
    __typename: "MeritAdjustmentRange";
    id: number;
    rangeStart: number;
  };
};

export type BudgetGuidance_budgetRange = {
  __typename: "BudgetRange";
  id: number;
  rangeStart: number;
};

export type BudgetGuidance_budgetPerfRatingOption = {
  __typename: "BudgetPerfRatingOption";
  id: number;
  name: string;
  rank: number;
};

export type BudgetGuidance_budgetGuide = {
  __typename: "BudgetGuide";
  id: number;
  percent: number;
  budgetPerfRatingOptionId: number;
  budgetRangeId: number;
  budgetRange: { __typename: "BudgetRange"; id: number; rangeStart: number };
  budgetPerfRatingOption: {
    __typename: "BudgetPerfRatingOption";
    id: number;
    name: string;
    rank: number;
  };
};

export type Guidance_compCycle = {
  __typename: "CompCycle";
  id: number;
  estimatedSalaryBudget: GraphQL_Money;
  estimatedSalaryMerit: GraphQL_Money;
  recommendationsPreFill: boolean;
  totalSpend: GraphQL_Money;
  budgetGuides: Array<{
    __typename: "BudgetGuide";
    id: number;
    percent: number;
    budgetPerfRatingOptionId: number;
    budgetRangeId: number;
    budgetRange: { __typename: "BudgetRange"; id: number; rangeStart: number };
    budgetPerfRatingOption: {
      __typename: "BudgetPerfRatingOption";
      id: number;
      name: string;
      rank: number;
    };
  }>;
  meritAdjustmentGuides: Array<{
    __typename: "MeritAdjustmentGuide";
    id: number;
    perfRatingOptionId: number;
    meritAdjustmentRangeId: number;
    percent: number;
    perfRatingOption: {
      __typename: "PerfRatingOption";
      id: number;
      name: string;
      rank: number;
    };
    meritAdjustmentRange: {
      __typename: "MeritAdjustmentRange";
      id: number;
      rangeStart: number;
    };
  }>;
};

export type GetGuidanceVariables = Exact<{
  compCycleId: Scalars["Int"]["input"];
}>;

export type GetGuidance = {
  compCycle: {
    __typename: "CompCycle";
    id: number;
    estimatedSalaryBudget: GraphQL_Money;
    estimatedSalaryMerit: GraphQL_Money;
    recommendationsPreFill: boolean;
    totalSpend: GraphQL_Money;
    budgetGuides: Array<{
      __typename: "BudgetGuide";
      id: number;
      percent: number;
      budgetPerfRatingOptionId: number;
      budgetRangeId: number;
      budgetRange: {
        __typename: "BudgetRange";
        id: number;
        rangeStart: number;
      };
      budgetPerfRatingOption: {
        __typename: "BudgetPerfRatingOption";
        id: number;
        name: string;
        rank: number;
      };
    }>;
    meritAdjustmentGuides: Array<{
      __typename: "MeritAdjustmentGuide";
      id: number;
      perfRatingOptionId: number;
      meritAdjustmentRangeId: number;
      percent: number;
      perfRatingOption: {
        __typename: "PerfRatingOption";
        id: number;
        name: string;
        rank: number;
      };
      meritAdjustmentRange: {
        __typename: "MeritAdjustmentRange";
        id: number;
        rangeStart: number;
      };
    }>;
  } | null;
};

export type CompCycleUploadRequests_employee = {
  __typename: "Employee";
  id: number;
  employeeNumber: string | null;
  email: string;
  personalEmail: string | null;
  activeEmployment: {
    __typename: "Employment";
    id: number;
    salary: GraphQL_Money | null;
    payRate: GraphQL_Money | null;
  } | null;
};

export type CompCycleUploadRequests_valuation = {
  __typename: "Valuation";
  id: number;
  valuation: GraphQL_Money;
  fdso: number;
};

export type CompCycleUploadRequests_position = {
  __typename: "Position";
  id: number;
  name: string;
};

export type CompCycleUploadRequestsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CompCycleUploadRequestsQuery = {
  employees: Array<{
    __typename: "Employee";
    id: number;
    employeeNumber: string | null;
    email: string;
    personalEmail: string | null;
    activeEmployment: {
      __typename: "Employment";
      id: number;
      salary: GraphQL_Money | null;
      payRate: GraphQL_Money | null;
    } | null;
  }>;
  valuation: {
    __typename: "Valuation";
    id: number;
    valuation: GraphQL_Money;
    fdso: number;
  } | null;
  positions: Array<{ __typename: "Position"; id: number; name: string }>;
};

export type CompCycleOrganizationChart_employee = {
  __typename: "Employee2";
  id: number;
  displayName: string;
  parentId: number | null;
  _directSubordinates: number;
  _totalSubordinates: number;
  activeEmployment: {
    __typename: "Employment2";
    id: number;
    jobTitle: string | null;
  } | null;
};

export type CompCycleOrganizationChartLoadingBoundaryQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CompCycleOrganizationChartLoadingBoundaryQuery = {
  employee2s: {
    __typename: "Employee2s";
    employees: Array<{
      __typename: "Employee2";
      id: number;
      displayName: string;
      parentId: number | null;
      _directSubordinates: number;
      _totalSubordinates: number;
      activeEmployment: {
        __typename: "Employment2";
        id: number;
        jobTitle: string | null;
      } | null;
    }>;
  };
};

export type CompCycleSummaryContent2_recItem = {
  __typename: "RecItem2";
  id: number;
  note: string | null;
  submittedAt: GraphQL_DateTime | null;
  recommendedCashValue: GraphQL_Money | null;
  recommendationType: RecItemType;
  author: {
    __typename: "User2";
    id: number;
    email: string;
    name: string | null;
    photoURL: string | null;
  };
};

export type CompCycleSummaryContent2_compRecommendation = {
  __typename: "CompRecommendation2";
  subjectId: number;
  compCycleId: number;
  allSubmittedItems: Array<{
    __typename: "RecItem2";
    id: number;
    note: string | null;
    submittedAt: GraphQL_DateTime | null;
    recommendedCashValue: GraphQL_Money | null;
    recommendationType: RecItemType;
    author: {
      __typename: "User2";
      id: number;
      email: string;
      name: string | null;
      photoURL: string | null;
    };
  }>;
  allSubmittedReviews: Array<{
    __typename: "RecReview2";
    id: number;
    note: string | null;
    submittedAt: GraphQL_DateTime | null;
    author: {
      __typename: "User2";
      id: number;
      email: string;
      name: string | null;
      photoURL: string | null;
    };
  }>;
};

export type CompNoteModal2_employee = {
  __typename: "Employee2";
  id: number;
  displayName: string;
  user: { __typename: "User2"; id: number; photoURL: string | null } | null;
  activeEmployment: {
    __typename: "Employment2";
    id: number;
    jobTitle: string | null;
  } | null;
};

export type EquityRefreshCondensedSlider_equityBand = {
  __typename: "AdjustedEquityBand";
  id: string;
  name: string;
  bandPoints: Array<{
    __typename: "AdjustedEquityBandPoint";
    bandName: string;
    annualCashEquivalent: GraphQL_Money;
  }>;
};

export type EquityRefreshCondensedSlider_position = {
  __typename: "Position";
  id: number;
  name: string;
  level: number;
  type: PositionType;
};

export type ValuationQueryVariables = Exact<{ [key: string]: never }>;

export type ValuationQuery = {
  valuation: {
    __typename: "Valuation";
    id: number;
    fdso: number;
    valuation: GraphQL_Money;
  } | null;
};

export type Subcomponent_cash = {
  __typename: "CashCompensation2";
  type: CashCompType;
  annualCashEquivalent: GraphQL_Money;
  hourlyCashEquivalent: GraphQL_Money;
  unit: CompUnit;
  percentOfSalary: number;
};

export type Subcomponent_band = {
  __typename: "AdjustedCashBand";
  name: string;
  bandPoints: Array<{
    __typename: "AdjustedCashBandPoint";
    name: string;
    value: {
      __typename: "CashValue";
      annualRate: GraphQL_Money | null;
      hourlyRate: GraphQL_Money | null;
      currencyCode: CurrencyCode;
    };
  }>;
};

export type SubcomponentSlider_bandPoint = {
  __typename: "AdjustedCashBandPoint";
  name: string;
  value: {
    __typename: "CashValue";
    annualRate: GraphQL_Money | null;
    hourlyRate: GraphQL_Money | null;
    currencyCode: CurrencyCode;
  };
};

export type TotalCashCondensedSlider_cash = {
  __typename: "CashCompensation2";
  type: CashCompType;
  annualCashEquivalent: GraphQL_Money;
  hourlyCashEquivalent: GraphQL_Money;
  unit: CompUnit;
  percentOfSalary: number;
};

export type TotalCashCondensedSlider_band = {
  __typename: "AdjustedCashBand";
  name: string;
  bandPoints: Array<{
    __typename: "AdjustedCashBandPoint";
    name: string;
    value: {
      __typename: "CashValue";
      annualRate: GraphQL_Money | null;
      hourlyRate: GraphQL_Money | null;
      currencyCode: CurrencyCode;
    };
  }>;
};

export type TotalCashCondensedSlider_position = {
  __typename: "Position";
  name: string;
  level: number;
};

export type TotalCashPopover_cash = {
  __typename: "CashCompensation2";
  type: CashCompType;
  annualCashEquivalent: GraphQL_Money;
  hourlyCashEquivalent: GraphQL_Money;
  unit: CompUnit;
  percentOfSalary: number;
};

export type TotalCashPopover_band = {
  __typename: "AdjustedCashBand";
  name: string;
  bandPoints: Array<{
    __typename: "AdjustedCashBandPoint";
    name: string;
    value: {
      __typename: "CashValue";
      annualRate: GraphQL_Money | null;
      hourlyRate: GraphQL_Money | null;
      currencyCode: CurrencyCode;
    };
  }>;
};

export type TotalCashPopover_position = {
  __typename: "Position";
  name: string;
  level: number;
};

export type BioHeading_employee = {
  __typename: "Employee";
  displayName: string;
  email: string;
  user: { __typename: "User"; id: number; photoURL: string | null } | null;
  activeEmployment: {
    __typename: "Employment";
    id: number;
    jobTitle: string | null;
  } | null;
};

export type BioHeading_equityHoldings = {
  __typename: "EquityHoldings";
  id: number;
  totalUnitCount: number | null;
  grants: Array<{ __typename: "EquityGrant"; id: number }> | null;
  vestingInformation: {
    __typename: "VestingInformation";
    id: number;
    vestEvents: Array<{
      __typename: "EquityGrantVestEventSource";
      id: bigint;
      unitCount: number;
    }>;
  } | null;
};

export type BioHeadingBanner_equityHoldings = {
  __typename: "EquityHoldings";
  id: number;
  totalUnitCount: number | null;
  grants: Array<{ __typename: "EquityGrant"; id: number }> | null;
  vestingInformation: {
    __typename: "VestingInformation";
    id: number;
    vestEvents: Array<{
      __typename: "EquityGrantVestEventSource";
      id: bigint;
      unitCount: number;
    }>;
  } | null;
};

export type LocationBubble_employee = {
  __typename: "Employee";
  homeLocation: {
    __typename: "Location";
    id: number;
    city: string;
    state: string;
    country: string;
  } | null;
};

export type ManagerBubble_employee = {
  __typename: "Employee";
  id: number;
  manager: {
    __typename: "MinimalEmployee";
    id: number;
    displayName: string;
  } | null;
};

export type StartDateBubble_employee = {
  __typename: "Employee";
  activeAt: GraphQL_DateTime | null;
};

export type TenureBubble_employee = {
  __typename: "Employee";
  activeAt: GraphQL_DateTime | null;
};

export type GetGlobalWarningInfoVariables = Exact<{ [key: string]: never }>;

export type GetGlobalWarningInfo = {
  cartaConnectionConfig: {
    __typename: "CartaConnectionConfig";
    id: number;
    isActive: boolean;
  } | null;
};

export type LiveLocationsProvider_locationGroup = {
  __typename: "LocationGroup";
  id: number;
  name: string;
  description: string | null;
  isSourceComp: boolean;
};

export type LiveLocationsProvider_market = {
  __typename: "Market";
  id: number;
  name: string;
  currencyCode: CurrencyCode;
  locationGroups: Array<{
    __typename: "LocationGroup";
    id: number;
    isSourceComp: boolean;
    name: string;
    description: string | null;
  }>;
};

export type GetMarketsAndLocationGroupsVariables = Exact<{
  [key: string]: never;
}>;

export type GetMarketsAndLocationGroups = {
  markets: Array<{
    __typename: "Market";
    id: number;
    name: string;
    currencyCode: CurrencyCode;
    locationGroups: Array<{
      __typename: "LocationGroup";
      id: number;
      isSourceComp: boolean;
      name: string;
      description: string | null;
    }>;
  }>;
};

export type ManagerSelectQueryVariables = Exact<{ [key: string]: never }>;

export type ManagerSelectQuery = {
  employees: Array<{
    __typename: "Employee";
    id: number;
    isManager: boolean;
    displayName: string;
  }>;
};

export type ApprovalSheet_position = {
  __typename: "Position";
  id: number;
  name: string;
  description: string | null;
  level: number;
  jobCodes: Array<string>;
  type: PositionType;
  adjustedCashBands: Array<{
    __typename: "AdjustedCashBand";
    id: string;
    name: string;
    bandPoints: Array<{
      __typename: "AdjustedCashBandPoint";
      name: string;
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      value: {
        __typename: "CashValue";
        annualRate: GraphQL_Money | null;
        currencyCode: CurrencyCode;
      };
    }>;
  }> | null;
  adjustedEquityBands: Array<{
    __typename: "AdjustedEquityBand";
    id: string;
    name: string;
    bandPoints: Array<{
      __typename: "AdjustedEquityBandPoint";
      name: string;
      totalGrossValue: GraphQL_Money;
      totalUnits: number | null;
      value:
        | {
            __typename: "CashValue";
            annualRate: GraphQL_Money | null;
            currencyCode: CurrencyCode;
          }
        | { __typename: "PercentValue"; decimalValue: number | null }
        | { __typename: "UnitValue"; unitValue: number | null };
    }>;
  }> | null;
};

export type BandLabelValue_position = {
  __typename: "Position";
  id: number;
  name: string;
  description: string | null;
  level: number;
  jobCodes: Array<string>;
  type: PositionType;
  adjustedCashBands: Array<{
    __typename: "AdjustedCashBand";
    id: string;
    name: string;
    bandPoints: Array<{
      __typename: "AdjustedCashBandPoint";
      name: string;
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      value: {
        __typename: "CashValue";
        annualRate: GraphQL_Money | null;
        currencyCode: CurrencyCode;
      };
    }>;
  }> | null;
  adjustedEquityBands: Array<{
    __typename: "AdjustedEquityBand";
    id: string;
    name: string;
    bandPoints: Array<{
      __typename: "AdjustedEquityBandPoint";
      name: string;
      totalGrossValue: GraphQL_Money;
      totalUnits: number | null;
      value:
        | {
            __typename: "CashValue";
            annualRate: GraphQL_Money | null;
            currencyCode: CurrencyCode;
          }
        | { __typename: "PercentValue"; decimalValue: number | null }
        | { __typename: "UnitValue"; unitValue: number | null };
    }>;
  }> | null;
};

export type GetHasAtsIntegrationVariables = Exact<{ [key: string]: never }>;

export type GetHasAtsIntegration = {
  organization: {
    __typename: "Organization";
    id: number;
    hasAtsIntegration: boolean;
  };
};

export type GetAtsCandidatesVariables = Exact<{ [key: string]: never }>;

export type GetAtsCandidates = {
  atsCandidates: Array<{
    __typename: "AtsCandidate";
    firstName: string;
    lastName: string;
    email: string;
    jobTitle: string;
  }>;
};

export type CompStatistics_anonymizedCompensation = {
  __typename: "AnonymizedCompensation";
  position: {
    __typename: "PositionStatistics";
    employeeStatistics: {
      __typename: "EmployeeStatistics";
      averageTenure: number;
      count: number;
    };
    compaRatioStatistics: {
      __typename: "CompaRatioStatistics";
      min: number;
      max: number;
      average: number;
      median: number;
    } | null;
  };
  positionAndLocationGroup: {
    __typename: "PositionAndLocationGroupStatistics";
    employeeStatistics: {
      __typename: "EmployeeStatistics";
      averageTenure: number;
      count: number;
    };
    cashStatistics: {
      __typename: "CashStatistics";
      salaryStatistics: {
        __typename: "SalaryStatistics";
        min: GraphQL_Money;
        max: GraphQL_Money;
        average: GraphQL_Money;
        median: GraphQL_Money;
      };
    } | null;
  };
};

export type GetOfferStatisticsDataVariables = Exact<{
  currencyCode: Scalars["String"]["input"];
  positionId: Scalars["Int"]["input"];
  locationGroupId: Scalars["Int"]["input"];
}>;

export type GetOfferStatisticsData = {
  anonymizedCompensation: {
    __typename: "AnonymizedCompensation";
    position: {
      __typename: "PositionStatistics";
      employeeStatistics: {
        __typename: "EmployeeStatistics";
        averageTenure: number;
        count: number;
      };
      compaRatioStatistics: {
        __typename: "CompaRatioStatistics";
        min: number;
        max: number;
        average: number;
        median: number;
      } | null;
    };
    positionAndLocationGroup: {
      __typename: "PositionAndLocationGroupStatistics";
      employeeStatistics: {
        __typename: "EmployeeStatistics";
        averageTenure: number;
        count: number;
      };
      cashStatistics: {
        __typename: "CashStatistics";
        salaryStatistics: {
          __typename: "SalaryStatistics";
          min: GraphQL_Money;
          max: GraphQL_Money;
          average: GraphQL_Money;
          median: GraphQL_Money;
        };
      } | null;
    };
  };
};

export type Compensation_position = {
  __typename: "Position";
  id: number;
  name: string;
  description: string | null;
  level: number;
  jobCodes: Array<string>;
  type: PositionType;
  adjustedCashBands: Array<{
    __typename: "AdjustedCashBand";
    id: string;
    name: string;
    bandPoints: Array<{
      __typename: "AdjustedCashBandPoint";
      name: string;
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      value: {
        __typename: "CashValue";
        annualRate: GraphQL_Money | null;
        currencyCode: CurrencyCode;
      };
    }>;
  }> | null;
  adjustedEquityBands: Array<{
    __typename: "AdjustedEquityBand";
    id: string;
    name: string;
    bandPoints: Array<{
      __typename: "AdjustedEquityBandPoint";
      name: string;
      totalGrossValue: GraphQL_Money;
      totalUnits: number | null;
      value:
        | {
            __typename: "CashValue";
            annualRate: GraphQL_Money | null;
            currencyCode: CurrencyCode;
          }
        | { __typename: "PercentValue"; decimalValue: number | null }
        | { __typename: "UnitValue"; unitValue: number | null };
    }>;
  }> | null;
};

export type CompensationFormInput_equityBand = {
  __typename: "AdjustedEquityBand";
  id: string;
  name: string;
  bandPoints: Array<{
    __typename: "AdjustedEquityBandPoint";
    name: string;
    totalGrossValue: GraphQL_Money;
    totalUnits: number | null;
    value:
      | {
          __typename: "CashValue";
          annualRate: GraphQL_Money | null;
          currencyCode: CurrencyCode;
        }
      | { __typename: "PercentValue"; decimalValue: number | null }
      | { __typename: "UnitValue"; unitValue: number | null };
  }>;
};

export type Confirmation_position = {
  __typename: "Position";
  id: number;
  name: string;
  description: string | null;
  level: number;
  jobCodes: Array<string>;
  type: PositionType;
  adjustedCashBands: Array<{
    __typename: "AdjustedCashBand";
    id: string;
    name: string;
    bandPoints: Array<{
      __typename: "AdjustedCashBandPoint";
      name: string;
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      value: {
        __typename: "CashValue";
        annualRate: GraphQL_Money | null;
        currencyCode: CurrencyCode;
      };
    }>;
  }> | null;
  adjustedEquityBands: Array<{
    __typename: "AdjustedEquityBand";
    id: string;
    name: string;
    bandPoints: Array<{
      __typename: "AdjustedEquityBandPoint";
      name: string;
      totalGrossValue: GraphQL_Money;
      totalUnits: number | null;
      value:
        | {
            __typename: "CashValue";
            annualRate: GraphQL_Money | null;
            currencyCode: CurrencyCode;
          }
        | { __typename: "PercentValue"; decimalValue: number | null }
        | { __typename: "UnitValue"; unitValue: number | null };
    }>;
  }> | null;
};

export type OfferEditOfferConfig = {
  __typename: "OfferConfig";
  defaultWelcomeMessage: string | null;
  defaultClosingMessage: string | null;
  requireApproval: boolean;
  customFields: Array<{
    __typename: "CustomField";
    name: string;
    description: string;
    variant: CustomFieldVariant;
    options: Array<string>;
  }>;
};

export type OfferEditValuation = {
  __typename: "Valuation";
  fdso: number;
  valuation: GraphQL_Money;
};

export type OfferEditCompStructure = {
  __typename: "CompStructure";
  id: number;
  cashBandTypes: Array<string>;
  equityBandTypes: Array<string>;
  equityGrantMethod: EquityGrantMethod;
};

export type OfferEditGetOfferVariables = Exact<{
  id: Scalars["Int"]["input"];
}>;

export type OfferEditGetOffer = {
  offer: {
    __typename: "Offer";
    id: number;
    isLegacy: boolean;
    message: string | null;
    closingMessage: string | null;
    outcomeDescription: string | null;
    offeredAt: GraphQL_DateTime;
    title: string | null;
    candidate: {
      __typename: "Candidate";
      id: number;
      candidateName: string;
      candidateEmail: string;
    };
    cash: {
      __typename: "OfferCash";
      data: Array<{
        __typename: "OfferCashBand";
        type: string;
        money: GraphQL_Money;
        percentOfSalary: number | null;
      }>;
    };
    equity: {
      __typename: "OfferEquity";
      data: Array<{
        __typename: "OfferEquityBand";
        type: string;
        money: GraphQL_Money | null;
        units: number | null;
        percentOfSalary: number | null;
      }>;
    };
    position: {
      __typename: "Position";
      id: number;
      name: string;
      level: number;
      jobCodes: Array<string>;
      type: PositionType;
      ladder: {
        __typename: "Ladder";
        id: number;
        name: string;
        department: { __typename: "Department"; id: number; name: string };
      };
    } | null;
    market: {
      __typename: "Market";
      id: number;
      name: string;
      currencyCode: CurrencyCode;
      locationGroups: Array<{
        __typename: "LocationGroup";
        id: number;
        isSourceComp: boolean;
        name: string;
        description: string | null;
      }>;
    } | null;
    locationGroup: {
      __typename: "LocationGroup";
      id: number;
      isSourceComp: boolean;
      name: string;
      description: string | null;
    } | null;
    organization: { __typename: "Organization"; id: number; name: string };
    benefitsPackage: {
      __typename: "BenefitsPackage";
      id: number;
      name: string;
      currencyCode: CurrencyCode;
      totalValue: GraphQL_Money;
      benefits: Array<{
        __typename: "Benefit";
        id: number;
        benefitsPackageId: number;
        name: string;
        value: GraphQL_Money | null;
        description: string;
        rank: string;
      }>;
    } | null;
    review: {
      __typename: "OfferReview";
      managerName: string;
      decisionMakerName: string;
      approverName: string | null;
      jobCode: string | null;
      customFieldAnswers: Array<{
        __typename: "CustomFieldAnswer";
        name: string;
        answer: string;
      }>;
    } | null;
  };
  compStructure: {
    __typename: "CompStructure";
    id: number;
    cashBandTypes: Array<string>;
    equityBandTypes: Array<string>;
    equityGrantMethod: EquityGrantMethod;
  } | null;
  valuation: {
    __typename: "Valuation";
    id: number;
    fdso: number;
    valuation: GraphQL_Money;
  } | null;
  offerConfig: {
    __typename: "OfferConfig";
    id: number;
    defaultWelcomeMessage: string | null;
    defaultClosingMessage: string | null;
    requireApproval: boolean;
    customFields: Array<{
      __typename: "CustomField";
      name: string;
      description: string;
      variant: CustomFieldVariant;
      options: Array<string>;
    }>;
  } | null;
};

export type GetAdjustedPositionVariables = Exact<{
  positionId: Scalars["Int"]["input"];
  cashCurrencyCode: CurrencyCode;
  equityCurrencyCode: CurrencyCode;
  marketId: Scalars["Int"]["input"];
  locationGroupId?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type GetAdjustedPosition = {
  position: {
    __typename: "Position";
    id: number;
    name: string;
    description: string | null;
    level: number;
    jobCodes: Array<string>;
    type: PositionType;
    adjustedCashBands: Array<{
      __typename: "AdjustedCashBand";
      id: string;
      name: string;
      bandPoints: Array<{
        __typename: "AdjustedCashBandPoint";
        name: string;
        annualCashEquivalent: GraphQL_Money;
        hourlyCashEquivalent: GraphQL_Money;
        value: {
          __typename: "CashValue";
          annualRate: GraphQL_Money | null;
          currencyCode: CurrencyCode;
        };
      }>;
    }> | null;
    adjustedEquityBands: Array<{
      __typename: "AdjustedEquityBand";
      id: string;
      name: string;
      bandPoints: Array<{
        __typename: "AdjustedEquityBandPoint";
        name: string;
        totalGrossValue: GraphQL_Money;
        totalUnits: number | null;
        value:
          | {
              __typename: "CashValue";
              annualRate: GraphQL_Money | null;
              currencyCode: CurrencyCode;
            }
          | { __typename: "PercentValue"; decimalValue: number | null }
          | { __typename: "UnitValue"; unitValue: number | null };
      }>;
    }> | null;
  } | null;
};

export type GetAllOfferDataVariables = Exact<{ [key: string]: never }>;

export type GetAllOfferData = {
  organization: {
    __typename: "Organization";
    id: number;
    name: string;
    hasAtsIntegration: boolean;
    permissionSettings: {
      __typename: "PermissionSettings";
      id: number;
      canRecruitersViewAnonymizedStatistics: boolean;
    };
  };
  positions: Array<{
    __typename: "Position";
    jobCodes: Array<string>;
    id: number;
    name: string;
    level: number;
    type: PositionType;
    ladder: {
      __typename: "Ladder";
      id: number;
      name: string;
      department: { __typename: "Department"; id: number; name: string };
    };
  }>;
  compStructure: {
    __typename: "CompStructure";
    id: number;
    bandPointTypes: Array<string>;
    cashBandTypes: Array<string>;
    equityBandTypes: Array<string>;
    allBandTypes: Array<string>;
    availableCashBandTypes: Array<string>;
    availableEquityBandTypes: Array<string>;
    businessStructure: BusinessStructure | null;
    companyDescription: string | null;
    equityGrantTypes: Array<EquityGrantTypes>;
    showPercentOwnership: boolean;
    showValuation: boolean;
    vestingCliff: number;
    vestingMonths: number;
    levels: Array<number>;
    equityGrantMethod: EquityGrantMethod;
    allowHourlyEmployees: boolean;
    employmentHoursPerWeek: number;
    employmentWeeksPerYear: number;
  } | null;
  valuation: {
    __typename: "Valuation";
    id: number;
    activeAt: GraphQL_DateTime;
    estimatedDilution: number;
    financingStage: string;
    fdso: number;
    strikePrice: GraphQL_Money;
    valuation: GraphQL_Money;
  } | null;
  offerConfig: {
    __typename: "OfferConfig";
    id: number;
    exitOutcomes: Array<number>;
    xAxisAnnotations: Array<string>;
    defaultOutcomeDescription: string;
    equityFootnote: string | null;
    defaultWelcomeMessage: string | null;
    defaultClosingMessage: string | null;
    sharePriceLabel: string;
    showEquityInformation: boolean;
    showFdso: boolean;
    showStage: boolean;
    showCurrentEquityValue: boolean;
    showSharePriceFootnote: boolean;
    showAnnualizedEquity: boolean;
    requireApproval: boolean;
    brandColorPrimary: string | null;
    primaryLogoUrlPath: string | null;
    whiteLogoUrlPath: string | null;
    greyLogoUrlPath: string | null;
    backgroundUrlPath: string | null;
    equityCashInValuationCurrency: boolean;
    customFields: Array<{
      __typename: "CustomField";
      name: string;
      description: string;
      variant: CustomFieldVariant;
      options: Array<string>;
    }>;
  } | null;
  benefitsPackages: Array<{
    __typename: "BenefitsPackage";
    id: number;
    name: string;
    currencyCode: CurrencyCode;
    totalValue: GraphQL_Money;
    benefits: Array<{
      __typename: "Benefit";
      id: number;
      benefitsPackageId: number;
      name: string;
      value: GraphQL_Money | null;
      description: string;
      rank: string;
    }>;
  }>;
};

export type OfferNewOfferConfig = {
  __typename: "OfferConfig";
  defaultOutcomeDescription: string;
  defaultWelcomeMessage: string | null;
  defaultClosingMessage: string | null;
  requireApproval: boolean;
  customFields: Array<{
    __typename: "CustomField";
    name: string;
    description: string;
    variant: CustomFieldVariant;
    options: Array<string>;
  }>;
};

export type OfferNewValuation = {
  __typename: "Valuation";
  fdso: number;
  valuation: GraphQL_Money;
};

export type OfferNewCompStructure = {
  __typename: "CompStructure";
  id: number;
  cashBandTypes: Array<string>;
  equityBandTypes: Array<string>;
  equityGrantMethod: EquityGrantMethod;
};

export type OfferNewLoadingBoundaryGetOfferConfigVariables = Exact<{
  [key: string]: never;
}>;

export type OfferNewLoadingBoundaryGetOfferConfig = {
  offerConfig: {
    __typename: "OfferConfig";
    id: number;
    defaultOutcomeDescription: string;
    defaultWelcomeMessage: string | null;
    defaultClosingMessage: string | null;
    requireApproval: boolean;
    customFields: Array<{
      __typename: "CustomField";
      name: string;
      description: string;
      variant: CustomFieldVariant;
      options: Array<string>;
    }>;
  } | null;
  valuation: {
    __typename: "Valuation";
    id: number;
    fdso: number;
    valuation: GraphQL_Money;
  } | null;
  compStructure: {
    __typename: "CompStructure";
    id: number;
    cashBandTypes: Array<string>;
    equityBandTypes: Array<string>;
    equityGrantMethod: EquityGrantMethod;
  } | null;
};

export type ImportOrganizationCsvVariables = Exact<{
  data: Scalars["String"]["input"];
}>;

export type ImportOrganizationCsv = {
  importOrgJobStructureWithBandsCsv: {
    __typename: "OrganizationUpdateResult";
    successful: boolean;
    reason: string | null;
  };
};

export type OrganizationHierarchy_employee = {
  __typename: "Employee2";
  id: number;
  displayName: string;
  employmentStatus: EmploymentStatus;
  parentId: number | null;
  _directSubordinates: number;
  _totalSubordinates: number;
  activeEmployment: {
    __typename: "Employment2";
    id: number;
    jobTitle: string | null;
  } | null;
};

export type OrganizationHierarchyLoadingBoundaryQueryVariables = Exact<{
  includeInactive?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type OrganizationHierarchyLoadingBoundaryQuery = {
  employee2s: {
    __typename: "Employee2s";
    employees: Array<{
      __typename: "Employee2";
      id: number;
      displayName: string;
      employmentStatus: EmploymentStatus;
      parentId: number | null;
      _directSubordinates: number;
      _totalSubordinates: number;
      activeEmployment: {
        __typename: "Employment2";
        id: number;
        jobTitle: string | null;
      } | null;
    }>;
  };
};

export type CommissionCard_employee = {
  __typename: "Employee";
  activeCashCompensation: Array<{
    __typename: "CashCompensation";
    employeeId: number;
    type: CashCompType;
    activeAt: GraphQL_Date;
    annualCashEquivalent: GraphQL_Money;
    percentOfSalary: number;
    unit: CompUnit;
  }> | null;
};

export type BenefitsValueCard_employee = {
  __typename: "Employee";
  benefitsPackage: {
    __typename: "BenefitsPackage";
    id: number;
    name: string;
    totalValue: GraphQL_Money;
  } | null;
};

export type BonusCard_employee = {
  __typename: "Employee";
  activeCashCompensation: Array<{
    __typename: "CashCompensation";
    employeeId: number;
    type: CashCompType;
    activeAt: GraphQL_Date;
    annualCashEquivalent: GraphQL_Money;
  }> | null;
};

export type EquityCard_equityHoldings = {
  __typename: "EquityHoldings";
  id: number;
  valuationCurrency: {
    __typename: "Currency";
    id: number;
    code: CurrencyCode;
    exchangeRate: number;
  };
  grants: Array<{
    __typename: "EquityGrant";
    id: number;
    sharePrice: GraphQL_Money | null;
  }> | null;
  vestingInformation: {
    __typename: "VestingInformation";
    id: number;
    vestEvents: Array<{
      __typename: "EquityGrantVestEventSource";
      id: bigint;
      vestingDate: GraphQL_DateTime;
      unitCount: number;
      grant: {
        __typename: "EquityGrant";
        id: number;
        sharePrice: GraphQL_Money | null;
      };
    }>;
  } | null;
};

export type SalaryCard_employee = {
  __typename: "Employee";
  activeEmployment: {
    __typename: "Employment";
    id: number;
    salary: GraphQL_Money | null;
  } | null;
};

export type TotalCompPie_employee = {
  __typename: "Employee";
  totalBenefitsValue: GraphQL_Money | null;
};

export type TotalCompPie_cashCompensation = {
  __typename: "CashCompensation";
  type: CashCompType;
  annualCashEquivalent: GraphQL_Money;
};

export type TotalCompPie_equityHoldings = {
  __typename: "EquityHoldings";
  grants: Array<{ __typename: "EquityGrant"; id: number }> | null;
  valuationCurrency: {
    __typename: "Currency";
    id: number;
    code: CurrencyCode;
    exchangeRate: number;
  };
  vestingInformation: {
    __typename: "VestingInformation";
    id: number;
    vestEvents: Array<{
      __typename: "EquityGrantVestEventSource";
      id: bigint;
      unitCount: number;
      vestingDate: GraphQL_DateTime;
      grant: {
        __typename: "EquityGrant";
        id: number;
        sharePrice: GraphQL_Money | null;
      };
    }>;
  } | null;
};

export type CompBandRow_employee = {
  __typename: "Employee";
  id: number;
  displayName: string;
  compaRatio: number | null;
  user: { __typename: "User"; id: number; photoURL: string | null } | null;
  adjustedCashBands: Array<{
    __typename: "AdjustedCashBand";
    id: string;
    name: string;
    bandPoints: Array<{
      __typename: "AdjustedCashBandPoint";
      id: string;
      name: string;
      bandName: string;
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      value: {
        __typename: "CashValue";
        annualRate: GraphQL_Money | null;
        hourlyRate: GraphQL_Money | null;
        currencyCode: CurrencyCode;
      };
    }>;
  }> | null;
  activeCashCompensation: Array<{
    __typename: "CashCompensation";
    employeeId: number;
    activeAt: GraphQL_Date;
    type: CashCompType;
    annualCashEquivalent: GraphQL_Money;
    percentOfSalary: number;
    unit: CompUnit;
    hourlyCashEquivalent: GraphQL_Money;
  }> | null;
  activeEmployment: {
    __typename: "Employment";
    id: number;
    position: {
      __typename: "Position";
      id: number;
      name: string;
      level: number;
    } | null;
  } | null;
};

export type CompBandTable_employee = {
  __typename: "Employee";
  id: number;
  displayName: string;
  compaRatio: number | null;
  adjustedCashBands: Array<{
    __typename: "AdjustedCashBand";
    id: string;
    name: string;
    bandPoints: Array<{
      __typename: "AdjustedCashBandPoint";
      id: string;
      name: string;
      bandName: string;
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      value: {
        __typename: "CashValue";
        annualRate: GraphQL_Money | null;
        hourlyRate: GraphQL_Money | null;
        currencyCode: CurrencyCode;
      };
    }>;
  }> | null;
  nextCashBands: Array<{
    __typename: "EmployeeLevelingInfo";
    id: string;
    position: {
      __typename: "Position";
      id: number;
      name: string;
      level: number;
    } | null;
    cashBands: Array<{
      __typename: "AdjustedCashBand";
      id: string;
      name: string;
      bandPoints: Array<{
        __typename: "AdjustedCashBandPoint";
        id: string;
        name: string;
        bandName: string;
        annualCashEquivalent: GraphQL_Money;
        hourlyCashEquivalent: GraphQL_Money;
        value: {
          __typename: "CashValue";
          annualRate: GraphQL_Money | null;
          hourlyRate: GraphQL_Money | null;
          currencyCode: CurrencyCode;
        };
      }>;
    }>;
  }> | null;
  activeEmployment: {
    __typename: "Employment";
    id: number;
    salary: GraphQL_Money | null;
    position: {
      __typename: "Position";
      id: number;
      name: string;
      level: number;
    } | null;
  } | null;
  user: { __typename: "User"; id: number; photoURL: string | null } | null;
  activeCashCompensation: Array<{
    __typename: "CashCompensation";
    employeeId: number;
    activeAt: GraphQL_Date;
    type: CashCompType;
    annualCashEquivalent: GraphQL_Money;
    percentOfSalary: number;
    unit: CompUnit;
    hourlyCashEquivalent: GraphQL_Money;
  }> | null;
};

export type CompensationTimelineChart_cashCompensation = {
  __typename: "CashCompensation";
  employeeId: number;
  type: CashCompType;
  activeAt: GraphQL_Date;
  annualCashEquivalent: GraphQL_Money;
  hourlyCashEquivalent: GraphQL_Money;
  percentOfSalary: number;
  unit: CompUnit;
};

export type CompensationTimelineChart_valuationCurrency = {
  __typename: "EquityHoldings";
  id: number;
  valuationCurrency: { __typename: "Currency"; id: number; code: CurrencyCode };
};

export type EquityGrantTimelineChart_equityGrant = {
  __typename: "EquityGrant";
  id: number;
  vestingStartDate: GraphQL_DateTime;
  unitCount: number;
  issueDate: GraphQL_DateTime;
  sharePrice: GraphQL_Money | null;
  vestingInformation: {
    __typename: "VestingInformation";
    vestedUnits: number | null;
    unvestedUnits: number | null;
    vestEvents: Array<{
      __typename: "EquityGrantVestEventSource";
      id: bigint;
      grantName: string;
      unitCount: number;
      vestingDate: GraphQL_DateTime;
      grant: {
        __typename: "EquityGrant";
        id: number;
        vestingStartDate: GraphQL_DateTime;
      };
    }>;
  } | null;
};

export type EquityChartVestingSummary_equityGrant = {
  __typename: "EquityGrant";
  id: number;
  grossValue: GraphQL_Money | null;
  vestingInformation: {
    __typename: "VestingInformation";
    vestedUnits: number | null;
    unvestedUnits: number | null;
    vestEvents: Array<{
      __typename: "EquityGrantVestEventSource";
      id: bigint;
      grantName: string;
      unitCount: number;
      vestingDate: GraphQL_DateTime;
    }>;
  } | null;
};

export type EquityGrantChartTooltip_equityGrant = {
  __typename: "EquityGrant";
  id: number;
  issueDate: GraphQL_DateTime;
  vestingStartDate: GraphQL_DateTime;
  unitCount: number;
  sharePrice: GraphQL_Money | null;
  vestingInformation: {
    __typename: "VestingInformation";
    vestedUnits: number | null;
    unvestedUnits: number | null;
    vestEvents: Array<{
      __typename: "EquityGrantVestEventSource";
      id: bigint;
      grantName: string;
      unitCount: number;
      vestingDate: GraphQL_DateTime;
    }>;
  } | null;
};

export type EquityGrantInfoSummary_equityGrant = {
  __typename: "EquityGrant";
  id: number;
  grossValue: GraphQL_Money | null;
  unitCount: number;
  price: GraphQL_Money;
  fromCarta: boolean;
  vestingInformation: {
    __typename: "VestingInformation";
    vestedUnits: number | null;
    unvestedUnits: number | null;
  } | null;
};

export type EquityGrantInfoSummary_equityHoldings = {
  __typename: "EquityHoldings";
  id: number;
  valuationCurrency: {
    __typename: "Currency";
    id: number;
    code: CurrencyCode;
    exchangeRate: number;
  };
};

export type TotalEquityTable_equityGrant = {
  __typename: "EquityGrant";
  id: number;
  name: string;
  issueDate: GraphQL_DateTime;
  vestingStartDate: GraphQL_DateTime;
  vestingScheduleDescription: string;
  unitCount: number;
  price: GraphQL_Money;
  awardType: string | null;
  grossValue: GraphQL_Money | null;
  fromCarta: boolean;
  sharePrice: GraphQL_Money | null;
  vestingInformation: {
    __typename: "VestingInformation";
    vestedUnits: number | null;
    unvestedUnits: number | null;
    percentVested: number | null;
    vestEvents: Array<{
      __typename: "EquityGrantVestEventSource";
      id: bigint;
      grantName: string;
      unitCount: number;
      vestingDate: GraphQL_DateTime;
      grant: {
        __typename: "EquityGrant";
        id: number;
        vestingStartDate: GraphQL_DateTime;
      };
    }>;
  } | null;
};

export type TotalEquityTable_equityHoldings = {
  __typename: "EquityHoldings";
  id: number;
  valuationCurrency: {
    __typename: "Currency";
    id: number;
    code: CurrencyCode;
    exchangeRate: number;
  };
};

export type EquityTableChartRow_equityGrant = {
  __typename: "EquityGrant";
  id: number;
  vestingStartDate: GraphQL_DateTime;
  unitCount: number;
  grossValue: GraphQL_Money | null;
  price: GraphQL_Money;
  fromCarta: boolean;
  issueDate: GraphQL_DateTime;
  sharePrice: GraphQL_Money | null;
  vestingInformation: {
    __typename: "VestingInformation";
    vestedUnits: number | null;
    unvestedUnits: number | null;
    vestEvents: Array<{
      __typename: "EquityGrantVestEventSource";
      id: bigint;
      grantName: string;
      unitCount: number;
      vestingDate: GraphQL_DateTime;
      grant: {
        __typename: "EquityGrant";
        id: number;
        vestingStartDate: GraphQL_DateTime;
      };
    }>;
  } | null;
};

export type EquityTableChartRow_equityHoldings = {
  __typename: "EquityHoldings";
  id: number;
  valuationCurrency: {
    __typename: "Currency";
    id: number;
    code: CurrencyCode;
    exchangeRate: number;
  };
};

export type TotalEquityGrid_portalConfig = {
  __typename: "PortalConfig";
  defaultExitOutcome: number;
};

export type TotalEquityGrid_equityHoldings = {
  __typename: "EquityHoldings";
  id: number;
  totalUnitCount: number | null;
  valuationCurrency: {
    __typename: "Currency";
    id: number;
    code: CurrencyCode;
    exchangeRate: number;
  };
  grants: Array<{
    __typename: "EquityGrant";
    id: number;
    fromCarta: boolean;
    sharePrice: GraphQL_Money | null;
    unitCount: number;
    name: string;
    issueDate: GraphQL_DateTime;
    vestingStartDate: GraphQL_DateTime;
    vestingScheduleDescription: string;
    price: GraphQL_Money;
    awardType: string | null;
    grossValue: GraphQL_Money | null;
    vestingInformation: {
      __typename: "VestingInformation";
      vestedUnits: number | null;
      unvestedUnits: number | null;
      percentVested: number | null;
      vestEvents: Array<{
        __typename: "EquityGrantVestEventSource";
        id: bigint;
        grantName: string;
        unitCount: number;
        vestingDate: GraphQL_DateTime;
        grant: {
          __typename: "EquityGrant";
          id: number;
          vestingStartDate: GraphQL_DateTime;
        };
      }>;
    } | null;
  }> | null;
  vestingInformation: {
    __typename: "VestingInformation";
    id: number;
    vestedUnits: number | null;
    unvestedUnits: number | null;
    vestEvents: Array<{
      __typename: "EquityGrantVestEventSource";
      id: bigint;
      grantName: string;
      unitCount: number;
      vestingDate: GraphQL_DateTime;
      grant: {
        __typename: "EquityGrant";
        id: number;
        vestingStartDate: GraphQL_DateTime;
        sharePrice: GraphQL_Money | null;
        unitCount: number;
      };
    }>;
  } | null;
};

export type TotalEquityIllustrativeUnitPrice_equityGrants = {
  __typename: "EquityGrant";
  id: number;
  sharePrice: GraphQL_Money | null;
};

export type TotalEquityIllustrativeUnitPrice_equityHoldings = {
  __typename: "EquityHoldings";
  id: number;
  valuationCurrency: {
    __typename: "Currency";
    id: number;
    code: CurrencyCode;
    exchangeRate: number;
  };
};

export type TotalEquityNext12MonthsChartHeader_equityGrantVestEvent = {
  __typename: "EquityGrantVestEventSource";
  id: bigint;
  vestingDate: GraphQL_DateTime;
  unitCount: number;
  grant: {
    __typename: "EquityGrant";
    id: number;
    sharePrice: GraphQL_Money | null;
    unitCount: number;
  };
};

export type TotalEquityNext12MonthsChartHeader_equityHoldings = {
  __typename: "EquityHoldings";
  id: number;
  valuationCurrency: {
    __typename: "Currency";
    id: number;
    code: CurrencyCode;
    exchangeRate: number;
  };
};

export type TotalEquityTimeline_equityHoldings = {
  __typename: "EquityHoldings";
  id: number;
  totalUnitCount: number | null;
  grants: Array<{
    __typename: "EquityGrant";
    id: number;
    fromCarta: boolean;
    sharePrice: GraphQL_Money | null;
    unitCount: number;
  }> | null;
  vestingInformation: {
    __typename: "VestingInformation";
    id: number;
    vestedUnits: number | null;
    unvestedUnits: number | null;
    vestEvents: Array<{
      __typename: "EquityGrantVestEventSource";
      id: bigint;
      grantName: string;
      unitCount: number;
      vestingDate: GraphQL_DateTime;
      grant: {
        __typename: "EquityGrant";
        id: number;
        vestingStartDate: GraphQL_DateTime;
        sharePrice: GraphQL_Money | null;
        unitCount: number;
      };
    }>;
  } | null;
  valuationCurrency: {
    __typename: "Currency";
    id: number;
    code: CurrencyCode;
    exchangeRate: number;
  };
};

export type TotalEquityTimelineChart_equityHoldings = {
  __typename: "EquityHoldings";
  id: number;
  totalUnitCount: number | null;
  vestingInformation: {
    __typename: "VestingInformation";
    id: number;
    vestedUnits: number | null;
    unvestedUnits: number | null;
    vestEvents: Array<{
      __typename: "EquityGrantVestEventSource";
      id: bigint;
      grantName: string;
      unitCount: number;
      vestingDate: GraphQL_DateTime;
      grant: {
        __typename: "EquityGrant";
        id: number;
        vestingStartDate: GraphQL_DateTime;
      };
    }>;
  } | null;
  grants: Array<{
    __typename: "EquityGrant";
    id: number;
    sharePrice: GraphQL_Money | null;
  }> | null;
};

export type TotalEquityTimelineChartHeader_equityHoldings = {
  __typename: "EquityHoldings";
  id: number;
  totalUnitCount: number | null;
  valuationCurrency: {
    __typename: "Currency";
    id: number;
    code: CurrencyCode;
    exchangeRate: number;
  };
  vestingInformation: {
    __typename: "VestingInformation";
    id: number;
    vestEvents: Array<{
      __typename: "EquityGrantVestEventSource";
      id: bigint;
      vestingDate: GraphQL_DateTime;
      unitCount: number;
      grant: {
        __typename: "EquityGrant";
        id: number;
        sharePrice: GraphQL_Money | null;
        unitCount: number;
      };
    }>;
  } | null;
  grants: Array<{
    __typename: "EquityGrant";
    id: number;
    sharePrice: GraphQL_Money | null;
    unitCount: number;
  }> | null;
};

export type TotalEquityTimelineChartTooltip_equityHoldings = {
  __typename: "EquityHoldings";
  id: number;
  totalUnitCount: number | null;
  grants: Array<{
    __typename: "EquityGrant";
    id: number;
    sharePrice: GraphQL_Money | null;
  }> | null;
};

export type TotalGrantedEquityChartHeader_equityHoldings = {
  __typename: "EquityHoldings";
  id: number;
  totalUnitCount: number | null;
  grants: Array<{
    __typename: "EquityGrant";
    id: number;
    sharePrice: GraphQL_Money | null;
    unitCount: number;
  }> | null;
  valuationCurrency: {
    __typename: "Currency";
    id: number;
    code: CurrencyCode;
    exchangeRate: number;
  };
};

export type TotalUnvestedEquityChartHeader_equityHoldings = {
  __typename: "EquityHoldings";
  id: number;
  valuationCurrency: {
    __typename: "Currency";
    id: number;
    code: CurrencyCode;
    exchangeRate: number;
  };
};

export type TotalUnvestedEquityChartHeader_equityGrantVestEvent = {
  __typename: "EquityGrantVestEventSource";
  id: bigint;
  vestingDate: GraphQL_DateTime;
  unitCount: number;
  grant: {
    __typename: "EquityGrant";
    id: number;
    sharePrice: GraphQL_Money | null;
    unitCount: number;
  };
};

export type TotalVestedEquityChartHeader_equityHoldings = {
  __typename: "EquityHoldings";
  id: number;
  valuationCurrency: {
    __typename: "Currency";
    id: number;
    code: CurrencyCode;
    exchangeRate: number;
  };
};

export type TotalVestedEquityChartHeader_equityGrantVestEvent = {
  __typename: "EquityGrantVestEventSource";
  id: bigint;
  vestingDate: GraphQL_DateTime;
  unitCount: number;
  grant: {
    __typename: "EquityGrant";
    id: number;
    sharePrice: GraphQL_Money | null;
    unitCount: number;
  };
};

export type TotalEquitySummary_portalConfig = {
  __typename: "PortalConfig";
  exitOutcomes: Array<number>;
  defaultExitOutcome: number;
  xAxisAnnotations: Array<string>;
};

export type PositionVisualization_position = {
  __typename: "Position";
  type: PositionType;
  adjustedCashBands: Array<{
    __typename: "AdjustedCashBand";
    id: string;
    name: string;
    bandPoints: Array<{
      __typename: "AdjustedCashBandPoint";
      id: string;
      name: string;
      bandName: string;
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      value: {
        __typename: "CashValue";
        annualRate: GraphQL_Money | null;
        hourlyRate: GraphQL_Money | null;
        currencyCode: CurrencyCode;
      };
    }>;
  }> | null;
  adjustedEquityBands: Array<{
    __typename: "AdjustedEquityBand";
    id: string;
    name: string;
    bandPoints: Array<{
      __typename: "AdjustedEquityBandPoint";
      id: string;
      name: string;
      bandName: string;
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      totalGrossValue: GraphQL_Money;
      totalUnits: number | null;
      vestingMonths: number;
      value:
        | {
            __typename: "CashValue";
            annualRate: GraphQL_Money | null;
            currencyCode: CurrencyCode;
          }
        | { __typename: "PercentValue"; decimalValue: number | null }
        | { __typename: "UnitValue"; unitValue: number | null };
      totalPercentOwnership: {
        __typename: "PercentValue";
        decimalValue: number | null;
      } | null;
    }>;
  }> | null;
};

export type RefreshPromptQueryVariables = Exact<{ [key: string]: never }>;

export type RefreshPromptQuery = { buildRevision: string };

export type AccessControlQueryVariables = Exact<{ [key: string]: never }>;

export type AccessControlQuery = {
  userRecords: Array<{
    __typename: "User";
    id: number;
    createdAt: GraphQL_DateTime;
    email: string;
    name: string | null;
    photoURL: string | null;
    isDisabled: boolean;
    userAccessGrant: {
      __typename: "UserAccessGrant";
      id: number;
      roleName: PrimaryRoleName;
      nounScopes: {
        __typename: "NounScopes";
        AccessControl: {
          __typename: "BaseNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: { __typename: "BaseScope"; global: boolean } | null;
        };
        CashBand: {
          __typename: "MarketJobStructureNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: {
            __typename: "MarketJobStructureScope";
            allMarkets: {
              __typename: "JobStructureScope";
              global: boolean;
              departmentIDs: Array<number>;
              ladderIDs: Array<number>;
              positionIDs: Array<number>;
            } | null;
            markets: Array<{
              __typename: "IndividualMarketJobStructureScope";
              marketId: number;
              scope: {
                __typename: "JobStructureScope";
                global: boolean;
                departmentIDs: Array<number>;
                ladderIDs: Array<number>;
                positionIDs: Array<number>;
              } | null;
            }>;
          } | null;
        };
        CompCycle: {
          __typename: "BaseNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: { __typename: "BaseScope"; global: boolean } | null;
        };
        CompCycleBudget: {
          __typename: "BaseNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: { __typename: "BaseScope"; global: boolean } | null;
        };
        CompRecommendation: {
          __typename: "EmployeeNounScope";
          edit: {
            __typename: "EmployeeScope";
            global: boolean;
            directReportIDs: Array<number>;
            indirectReportIDs: Array<number>;
            supportingManagerEmployeeIDs: Array<number | null> | null;
          } | null;
          view: {
            __typename: "EmployeeScope";
            global: boolean;
            directReportIDs: Array<number>;
            indirectReportIDs: Array<number>;
            supportingManagerEmployeeIDs: Array<number | null> | null;
          } | null;
        };
        CompStructure: {
          __typename: "BaseNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: { __typename: "BaseScope"; global: boolean } | null;
        };
        Employee: {
          __typename: "EmployeeNounScope";
          edit: {
            __typename: "EmployeeScope";
            global: boolean;
            directReportIDs: Array<number>;
            indirectReportIDs: Array<number>;
            supportingManagerEmployeeIDs: Array<number | null> | null;
          } | null;
          view: {
            __typename: "EmployeeScope";
            global: boolean;
            directReportIDs: Array<number>;
            indirectReportIDs: Array<number>;
            supportingManagerEmployeeIDs: Array<number | null> | null;
          } | null;
        };
        EmployeeCashCompensation: {
          __typename: "EmployeeNounScope";
          edit: {
            __typename: "EmployeeScope";
            global: boolean;
            directReportIDs: Array<number>;
            indirectReportIDs: Array<number>;
            supportingManagerEmployeeIDs: Array<number | null> | null;
          } | null;
          view: {
            __typename: "EmployeeScope";
            global: boolean;
            directReportIDs: Array<number>;
            indirectReportIDs: Array<number>;
            supportingManagerEmployeeIDs: Array<number | null> | null;
          } | null;
        };
        EmployeeEquityCompensation: {
          __typename: "EmployeeNounScope";
          edit: {
            __typename: "EmployeeScope";
            global: boolean;
            directReportIDs: Array<number>;
            indirectReportIDs: Array<number>;
            supportingManagerEmployeeIDs: Array<number | null> | null;
          } | null;
          view: {
            __typename: "EmployeeScope";
            global: boolean;
            directReportIDs: Array<number>;
            indirectReportIDs: Array<number>;
            supportingManagerEmployeeIDs: Array<number | null> | null;
          } | null;
        };
        EquityBand: {
          __typename: "MarketJobStructureNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: {
            __typename: "MarketJobStructureScope";
            allMarkets: {
              __typename: "JobStructureScope";
              global: boolean;
              departmentIDs: Array<number>;
              ladderIDs: Array<number>;
              positionIDs: Array<number>;
            } | null;
            markets: Array<{
              __typename: "IndividualMarketJobStructureScope";
              marketId: number;
              scope: {
                __typename: "JobStructureScope";
                global: boolean;
                departmentIDs: Array<number>;
                ladderIDs: Array<number>;
                positionIDs: Array<number>;
              } | null;
            }>;
          } | null;
        };
        Integration: {
          __typename: "BaseNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: { __typename: "BaseScope"; global: boolean } | null;
        };
        JobStructure: {
          __typename: "JobStructureNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: {
            __typename: "JobStructureScope";
            global: boolean;
            departmentIDs: Array<number>;
            ladderIDs: Array<number>;
            positionIDs: Array<number>;
          } | null;
        };
        Market: {
          __typename: "BaseNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: { __typename: "BaseScope"; global: boolean } | null;
        };
        MarketData: {
          __typename: "BaseNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: { __typename: "BaseScope"; global: boolean } | null;
        };
        Offer: {
          __typename: "OfferNounScope";
          edit: {
            __typename: "OfferScope";
            global: boolean;
            authored: boolean;
          } | null;
          view: {
            __typename: "OfferScope";
            global: boolean;
            authored: boolean;
          } | null;
        };
        Philosophy: {
          __typename: "BaseNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: { __typename: "BaseScope"; global: boolean } | null;
        };
        SensitiveData: {
          __typename: "BaseNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: { __typename: "BaseScope"; global: boolean } | null;
        };
      };
    } | null;
    employee: {
      __typename: "Employee";
      id: number;
      isManager: boolean;
      totalReportsCount: number;
      activeEmployment: {
        __typename: "Employment";
        id: number;
        jobTitle: string | null;
      } | null;
    } | null;
  }>;
  activeAssembleUserInvitations: Array<{
    __typename: "UserInvitation";
    id: number;
    createdAt: GraphQL_DateTime;
    email: string;
    name: string;
    userInvitationAccessGrant: {
      __typename: "UserInvitationAccessGrant";
      id: number;
      roleName: PrimaryRoleName;
      nounScopes: {
        __typename: "NounScopes";
        AccessControl: {
          __typename: "BaseNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: { __typename: "BaseScope"; global: boolean } | null;
        };
        CashBand: {
          __typename: "MarketJobStructureNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: {
            __typename: "MarketJobStructureScope";
            allMarkets: {
              __typename: "JobStructureScope";
              global: boolean;
              departmentIDs: Array<number>;
              ladderIDs: Array<number>;
              positionIDs: Array<number>;
            } | null;
            markets: Array<{
              __typename: "IndividualMarketJobStructureScope";
              marketId: number;
              scope: {
                __typename: "JobStructureScope";
                global: boolean;
                departmentIDs: Array<number>;
                ladderIDs: Array<number>;
                positionIDs: Array<number>;
              } | null;
            }>;
          } | null;
        };
        CompCycle: {
          __typename: "BaseNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: { __typename: "BaseScope"; global: boolean } | null;
        };
        CompCycleBudget: {
          __typename: "BaseNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: { __typename: "BaseScope"; global: boolean } | null;
        };
        CompRecommendation: {
          __typename: "EmployeeNounScope";
          edit: {
            __typename: "EmployeeScope";
            global: boolean;
            directReportIDs: Array<number>;
            indirectReportIDs: Array<number>;
            supportingManagerEmployeeIDs: Array<number | null> | null;
          } | null;
          view: {
            __typename: "EmployeeScope";
            global: boolean;
            directReportIDs: Array<number>;
            indirectReportIDs: Array<number>;
            supportingManagerEmployeeIDs: Array<number | null> | null;
          } | null;
        };
        CompStructure: {
          __typename: "BaseNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: { __typename: "BaseScope"; global: boolean } | null;
        };
        Employee: {
          __typename: "EmployeeNounScope";
          edit: {
            __typename: "EmployeeScope";
            global: boolean;
            directReportIDs: Array<number>;
            indirectReportIDs: Array<number>;
            supportingManagerEmployeeIDs: Array<number | null> | null;
          } | null;
          view: {
            __typename: "EmployeeScope";
            global: boolean;
            directReportIDs: Array<number>;
            indirectReportIDs: Array<number>;
            supportingManagerEmployeeIDs: Array<number | null> | null;
          } | null;
        };
        EmployeeCashCompensation: {
          __typename: "EmployeeNounScope";
          edit: {
            __typename: "EmployeeScope";
            global: boolean;
            directReportIDs: Array<number>;
            indirectReportIDs: Array<number>;
            supportingManagerEmployeeIDs: Array<number | null> | null;
          } | null;
          view: {
            __typename: "EmployeeScope";
            global: boolean;
            directReportIDs: Array<number>;
            indirectReportIDs: Array<number>;
            supportingManagerEmployeeIDs: Array<number | null> | null;
          } | null;
        };
        EmployeeEquityCompensation: {
          __typename: "EmployeeNounScope";
          edit: {
            __typename: "EmployeeScope";
            global: boolean;
            directReportIDs: Array<number>;
            indirectReportIDs: Array<number>;
            supportingManagerEmployeeIDs: Array<number | null> | null;
          } | null;
          view: {
            __typename: "EmployeeScope";
            global: boolean;
            directReportIDs: Array<number>;
            indirectReportIDs: Array<number>;
            supportingManagerEmployeeIDs: Array<number | null> | null;
          } | null;
        };
        EquityBand: {
          __typename: "MarketJobStructureNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: {
            __typename: "MarketJobStructureScope";
            allMarkets: {
              __typename: "JobStructureScope";
              global: boolean;
              departmentIDs: Array<number>;
              ladderIDs: Array<number>;
              positionIDs: Array<number>;
            } | null;
            markets: Array<{
              __typename: "IndividualMarketJobStructureScope";
              marketId: number;
              scope: {
                __typename: "JobStructureScope";
                global: boolean;
                departmentIDs: Array<number>;
                ladderIDs: Array<number>;
                positionIDs: Array<number>;
              } | null;
            }>;
          } | null;
        };
        Integration: {
          __typename: "BaseNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: { __typename: "BaseScope"; global: boolean } | null;
        };
        JobStructure: {
          __typename: "JobStructureNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: {
            __typename: "JobStructureScope";
            global: boolean;
            departmentIDs: Array<number>;
            ladderIDs: Array<number>;
            positionIDs: Array<number>;
          } | null;
        };
        Market: {
          __typename: "BaseNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: { __typename: "BaseScope"; global: boolean } | null;
        };
        MarketData: {
          __typename: "BaseNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: { __typename: "BaseScope"; global: boolean } | null;
        };
        Offer: {
          __typename: "OfferNounScope";
          edit: {
            __typename: "OfferScope";
            global: boolean;
            authored: boolean;
          } | null;
          view: {
            __typename: "OfferScope";
            global: boolean;
            authored: boolean;
          } | null;
        };
        Philosophy: {
          __typename: "BaseNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: { __typename: "BaseScope"; global: boolean } | null;
        };
        SensitiveData: {
          __typename: "BaseNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: { __typename: "BaseScope"; global: boolean } | null;
        };
      };
    } | null;
    employee: {
      __typename: "Employee";
      id: number;
      isManager: boolean;
      totalReportsCount: number;
      activeEmployment: {
        __typename: "Employment";
        id: number;
        jobTitle: string | null;
      } | null;
    } | null;
  }>;
  organization: {
    __typename: "Organization";
    id: number;
    permissionSettings: {
      __typename: "PermissionSettings";
      id: number;
      teamCompensationAccess: TeamCompensationAccessType;
    };
  };
};

export type AccessControlSubheader_userRecord = {
  __typename: "User";
  id: number;
};

export type AccessControlTable_activeUserInvitations = {
  __typename: "UserInvitation";
  id: number;
  createdAt: GraphQL_DateTime;
  email: string;
  name: string;
  userInvitationAccessGrant: {
    __typename: "UserInvitationAccessGrant";
    id: number;
    roleName: PrimaryRoleName;
    nounScopes: {
      __typename: "NounScopes";
      AccessControl: {
        __typename: "BaseNounScope";
        edit: { __typename: "BaseScope"; global: boolean } | null;
        view: { __typename: "BaseScope"; global: boolean } | null;
      };
      CashBand: {
        __typename: "MarketJobStructureNounScope";
        edit: { __typename: "BaseScope"; global: boolean } | null;
        view: {
          __typename: "MarketJobStructureScope";
          allMarkets: {
            __typename: "JobStructureScope";
            global: boolean;
            departmentIDs: Array<number>;
            ladderIDs: Array<number>;
            positionIDs: Array<number>;
          } | null;
          markets: Array<{
            __typename: "IndividualMarketJobStructureScope";
            marketId: number;
            scope: {
              __typename: "JobStructureScope";
              global: boolean;
              departmentIDs: Array<number>;
              ladderIDs: Array<number>;
              positionIDs: Array<number>;
            } | null;
          }>;
        } | null;
      };
      CompCycle: {
        __typename: "BaseNounScope";
        edit: { __typename: "BaseScope"; global: boolean } | null;
        view: { __typename: "BaseScope"; global: boolean } | null;
      };
      CompCycleBudget: {
        __typename: "BaseNounScope";
        edit: { __typename: "BaseScope"; global: boolean } | null;
        view: { __typename: "BaseScope"; global: boolean } | null;
      };
      CompRecommendation: {
        __typename: "EmployeeNounScope";
        edit: {
          __typename: "EmployeeScope";
          global: boolean;
          directReportIDs: Array<number>;
          indirectReportIDs: Array<number>;
          supportingManagerEmployeeIDs: Array<number | null> | null;
        } | null;
        view: {
          __typename: "EmployeeScope";
          global: boolean;
          directReportIDs: Array<number>;
          indirectReportIDs: Array<number>;
          supportingManagerEmployeeIDs: Array<number | null> | null;
        } | null;
      };
      CompStructure: {
        __typename: "BaseNounScope";
        edit: { __typename: "BaseScope"; global: boolean } | null;
        view: { __typename: "BaseScope"; global: boolean } | null;
      };
      Employee: {
        __typename: "EmployeeNounScope";
        edit: {
          __typename: "EmployeeScope";
          global: boolean;
          directReportIDs: Array<number>;
          indirectReportIDs: Array<number>;
          supportingManagerEmployeeIDs: Array<number | null> | null;
        } | null;
        view: {
          __typename: "EmployeeScope";
          global: boolean;
          directReportIDs: Array<number>;
          indirectReportIDs: Array<number>;
          supportingManagerEmployeeIDs: Array<number | null> | null;
        } | null;
      };
      EmployeeCashCompensation: {
        __typename: "EmployeeNounScope";
        edit: {
          __typename: "EmployeeScope";
          global: boolean;
          directReportIDs: Array<number>;
          indirectReportIDs: Array<number>;
          supportingManagerEmployeeIDs: Array<number | null> | null;
        } | null;
        view: {
          __typename: "EmployeeScope";
          global: boolean;
          directReportIDs: Array<number>;
          indirectReportIDs: Array<number>;
          supportingManagerEmployeeIDs: Array<number | null> | null;
        } | null;
      };
      EmployeeEquityCompensation: {
        __typename: "EmployeeNounScope";
        edit: {
          __typename: "EmployeeScope";
          global: boolean;
          directReportIDs: Array<number>;
          indirectReportIDs: Array<number>;
          supportingManagerEmployeeIDs: Array<number | null> | null;
        } | null;
        view: {
          __typename: "EmployeeScope";
          global: boolean;
          directReportIDs: Array<number>;
          indirectReportIDs: Array<number>;
          supportingManagerEmployeeIDs: Array<number | null> | null;
        } | null;
      };
      EquityBand: {
        __typename: "MarketJobStructureNounScope";
        edit: { __typename: "BaseScope"; global: boolean } | null;
        view: {
          __typename: "MarketJobStructureScope";
          allMarkets: {
            __typename: "JobStructureScope";
            global: boolean;
            departmentIDs: Array<number>;
            ladderIDs: Array<number>;
            positionIDs: Array<number>;
          } | null;
          markets: Array<{
            __typename: "IndividualMarketJobStructureScope";
            marketId: number;
            scope: {
              __typename: "JobStructureScope";
              global: boolean;
              departmentIDs: Array<number>;
              ladderIDs: Array<number>;
              positionIDs: Array<number>;
            } | null;
          }>;
        } | null;
      };
      Integration: {
        __typename: "BaseNounScope";
        edit: { __typename: "BaseScope"; global: boolean } | null;
        view: { __typename: "BaseScope"; global: boolean } | null;
      };
      JobStructure: {
        __typename: "JobStructureNounScope";
        edit: { __typename: "BaseScope"; global: boolean } | null;
        view: {
          __typename: "JobStructureScope";
          global: boolean;
          departmentIDs: Array<number>;
          ladderIDs: Array<number>;
          positionIDs: Array<number>;
        } | null;
      };
      Market: {
        __typename: "BaseNounScope";
        edit: { __typename: "BaseScope"; global: boolean } | null;
        view: { __typename: "BaseScope"; global: boolean } | null;
      };
      MarketData: {
        __typename: "BaseNounScope";
        edit: { __typename: "BaseScope"; global: boolean } | null;
        view: { __typename: "BaseScope"; global: boolean } | null;
      };
      Offer: {
        __typename: "OfferNounScope";
        edit: {
          __typename: "OfferScope";
          global: boolean;
          authored: boolean;
        } | null;
        view: {
          __typename: "OfferScope";
          global: boolean;
          authored: boolean;
        } | null;
      };
      Philosophy: {
        __typename: "BaseNounScope";
        edit: { __typename: "BaseScope"; global: boolean } | null;
        view: { __typename: "BaseScope"; global: boolean } | null;
      };
      SensitiveData: {
        __typename: "BaseNounScope";
        edit: { __typename: "BaseScope"; global: boolean } | null;
        view: { __typename: "BaseScope"; global: boolean } | null;
      };
    };
  } | null;
  employee: {
    __typename: "Employee";
    id: number;
    isManager: boolean;
    totalReportsCount: number;
    activeEmployment: {
      __typename: "Employment";
      id: number;
      jobTitle: string | null;
    } | null;
  } | null;
};

export type AccessControlTable_userRecords = {
  __typename: "User";
  id: number;
  createdAt: GraphQL_DateTime;
  email: string;
  name: string | null;
  photoURL: string | null;
  isDisabled: boolean;
  userAccessGrant: {
    __typename: "UserAccessGrant";
    id: number;
    roleName: PrimaryRoleName;
    nounScopes: {
      __typename: "NounScopes";
      AccessControl: {
        __typename: "BaseNounScope";
        edit: { __typename: "BaseScope"; global: boolean } | null;
        view: { __typename: "BaseScope"; global: boolean } | null;
      };
      CashBand: {
        __typename: "MarketJobStructureNounScope";
        edit: { __typename: "BaseScope"; global: boolean } | null;
        view: {
          __typename: "MarketJobStructureScope";
          allMarkets: {
            __typename: "JobStructureScope";
            global: boolean;
            departmentIDs: Array<number>;
            ladderIDs: Array<number>;
            positionIDs: Array<number>;
          } | null;
          markets: Array<{
            __typename: "IndividualMarketJobStructureScope";
            marketId: number;
            scope: {
              __typename: "JobStructureScope";
              global: boolean;
              departmentIDs: Array<number>;
              ladderIDs: Array<number>;
              positionIDs: Array<number>;
            } | null;
          }>;
        } | null;
      };
      CompCycle: {
        __typename: "BaseNounScope";
        edit: { __typename: "BaseScope"; global: boolean } | null;
        view: { __typename: "BaseScope"; global: boolean } | null;
      };
      CompCycleBudget: {
        __typename: "BaseNounScope";
        edit: { __typename: "BaseScope"; global: boolean } | null;
        view: { __typename: "BaseScope"; global: boolean } | null;
      };
      CompRecommendation: {
        __typename: "EmployeeNounScope";
        edit: {
          __typename: "EmployeeScope";
          global: boolean;
          directReportIDs: Array<number>;
          indirectReportIDs: Array<number>;
          supportingManagerEmployeeIDs: Array<number | null> | null;
        } | null;
        view: {
          __typename: "EmployeeScope";
          global: boolean;
          directReportIDs: Array<number>;
          indirectReportIDs: Array<number>;
          supportingManagerEmployeeIDs: Array<number | null> | null;
        } | null;
      };
      CompStructure: {
        __typename: "BaseNounScope";
        edit: { __typename: "BaseScope"; global: boolean } | null;
        view: { __typename: "BaseScope"; global: boolean } | null;
      };
      Employee: {
        __typename: "EmployeeNounScope";
        edit: {
          __typename: "EmployeeScope";
          global: boolean;
          directReportIDs: Array<number>;
          indirectReportIDs: Array<number>;
          supportingManagerEmployeeIDs: Array<number | null> | null;
        } | null;
        view: {
          __typename: "EmployeeScope";
          global: boolean;
          directReportIDs: Array<number>;
          indirectReportIDs: Array<number>;
          supportingManagerEmployeeIDs: Array<number | null> | null;
        } | null;
      };
      EmployeeCashCompensation: {
        __typename: "EmployeeNounScope";
        edit: {
          __typename: "EmployeeScope";
          global: boolean;
          directReportIDs: Array<number>;
          indirectReportIDs: Array<number>;
          supportingManagerEmployeeIDs: Array<number | null> | null;
        } | null;
        view: {
          __typename: "EmployeeScope";
          global: boolean;
          directReportIDs: Array<number>;
          indirectReportIDs: Array<number>;
          supportingManagerEmployeeIDs: Array<number | null> | null;
        } | null;
      };
      EmployeeEquityCompensation: {
        __typename: "EmployeeNounScope";
        edit: {
          __typename: "EmployeeScope";
          global: boolean;
          directReportIDs: Array<number>;
          indirectReportIDs: Array<number>;
          supportingManagerEmployeeIDs: Array<number | null> | null;
        } | null;
        view: {
          __typename: "EmployeeScope";
          global: boolean;
          directReportIDs: Array<number>;
          indirectReportIDs: Array<number>;
          supportingManagerEmployeeIDs: Array<number | null> | null;
        } | null;
      };
      EquityBand: {
        __typename: "MarketJobStructureNounScope";
        edit: { __typename: "BaseScope"; global: boolean } | null;
        view: {
          __typename: "MarketJobStructureScope";
          allMarkets: {
            __typename: "JobStructureScope";
            global: boolean;
            departmentIDs: Array<number>;
            ladderIDs: Array<number>;
            positionIDs: Array<number>;
          } | null;
          markets: Array<{
            __typename: "IndividualMarketJobStructureScope";
            marketId: number;
            scope: {
              __typename: "JobStructureScope";
              global: boolean;
              departmentIDs: Array<number>;
              ladderIDs: Array<number>;
              positionIDs: Array<number>;
            } | null;
          }>;
        } | null;
      };
      Integration: {
        __typename: "BaseNounScope";
        edit: { __typename: "BaseScope"; global: boolean } | null;
        view: { __typename: "BaseScope"; global: boolean } | null;
      };
      JobStructure: {
        __typename: "JobStructureNounScope";
        edit: { __typename: "BaseScope"; global: boolean } | null;
        view: {
          __typename: "JobStructureScope";
          global: boolean;
          departmentIDs: Array<number>;
          ladderIDs: Array<number>;
          positionIDs: Array<number>;
        } | null;
      };
      Market: {
        __typename: "BaseNounScope";
        edit: { __typename: "BaseScope"; global: boolean } | null;
        view: { __typename: "BaseScope"; global: boolean } | null;
      };
      MarketData: {
        __typename: "BaseNounScope";
        edit: { __typename: "BaseScope"; global: boolean } | null;
        view: { __typename: "BaseScope"; global: boolean } | null;
      };
      Offer: {
        __typename: "OfferNounScope";
        edit: {
          __typename: "OfferScope";
          global: boolean;
          authored: boolean;
        } | null;
        view: {
          __typename: "OfferScope";
          global: boolean;
          authored: boolean;
        } | null;
      };
      Philosophy: {
        __typename: "BaseNounScope";
        edit: { __typename: "BaseScope"; global: boolean } | null;
        view: { __typename: "BaseScope"; global: boolean } | null;
      };
      SensitiveData: {
        __typename: "BaseNounScope";
        edit: { __typename: "BaseScope"; global: boolean } | null;
        view: { __typename: "BaseScope"; global: boolean } | null;
      };
    };
  } | null;
  employee: {
    __typename: "Employee";
    id: number;
    isManager: boolean;
    totalReportsCount: number;
    activeEmployment: {
      __typename: "Employment";
      id: number;
      jobTitle: string | null;
    } | null;
  } | null;
};

export type AccessControlTable_permissionSettings = {
  __typename: "PermissionSettings";
  id: number;
  teamCompensationAccess: TeamCompensationAccessType;
};

export type AccessControlTableRow_activeUserInvitations = {
  __typename: "UserInvitation";
  id: number;
  email: string;
  name: string;
  createdAt: GraphQL_DateTime;
  userInvitationAccessGrant: {
    __typename: "UserInvitationAccessGrant";
    id: number;
    roleName: PrimaryRoleName;
    nounScopes: {
      __typename: "NounScopes";
      AccessControl: {
        __typename: "BaseNounScope";
        edit: { __typename: "BaseScope"; global: boolean } | null;
        view: { __typename: "BaseScope"; global: boolean } | null;
      };
      CashBand: {
        __typename: "MarketJobStructureNounScope";
        edit: { __typename: "BaseScope"; global: boolean } | null;
        view: {
          __typename: "MarketJobStructureScope";
          allMarkets: {
            __typename: "JobStructureScope";
            global: boolean;
            departmentIDs: Array<number>;
            ladderIDs: Array<number>;
            positionIDs: Array<number>;
          } | null;
          markets: Array<{
            __typename: "IndividualMarketJobStructureScope";
            marketId: number;
            scope: {
              __typename: "JobStructureScope";
              global: boolean;
              departmentIDs: Array<number>;
              ladderIDs: Array<number>;
              positionIDs: Array<number>;
            } | null;
          }>;
        } | null;
      };
      CompCycle: {
        __typename: "BaseNounScope";
        edit: { __typename: "BaseScope"; global: boolean } | null;
        view: { __typename: "BaseScope"; global: boolean } | null;
      };
      CompCycleBudget: {
        __typename: "BaseNounScope";
        edit: { __typename: "BaseScope"; global: boolean } | null;
        view: { __typename: "BaseScope"; global: boolean } | null;
      };
      CompRecommendation: {
        __typename: "EmployeeNounScope";
        edit: {
          __typename: "EmployeeScope";
          global: boolean;
          directReportIDs: Array<number>;
          indirectReportIDs: Array<number>;
          supportingManagerEmployeeIDs: Array<number | null> | null;
        } | null;
        view: {
          __typename: "EmployeeScope";
          global: boolean;
          directReportIDs: Array<number>;
          indirectReportIDs: Array<number>;
          supportingManagerEmployeeIDs: Array<number | null> | null;
        } | null;
      };
      CompStructure: {
        __typename: "BaseNounScope";
        edit: { __typename: "BaseScope"; global: boolean } | null;
        view: { __typename: "BaseScope"; global: boolean } | null;
      };
      Employee: {
        __typename: "EmployeeNounScope";
        edit: {
          __typename: "EmployeeScope";
          global: boolean;
          directReportIDs: Array<number>;
          indirectReportIDs: Array<number>;
          supportingManagerEmployeeIDs: Array<number | null> | null;
        } | null;
        view: {
          __typename: "EmployeeScope";
          global: boolean;
          directReportIDs: Array<number>;
          indirectReportIDs: Array<number>;
          supportingManagerEmployeeIDs: Array<number | null> | null;
        } | null;
      };
      EmployeeCashCompensation: {
        __typename: "EmployeeNounScope";
        edit: {
          __typename: "EmployeeScope";
          global: boolean;
          directReportIDs: Array<number>;
          indirectReportIDs: Array<number>;
          supportingManagerEmployeeIDs: Array<number | null> | null;
        } | null;
        view: {
          __typename: "EmployeeScope";
          global: boolean;
          directReportIDs: Array<number>;
          indirectReportIDs: Array<number>;
          supportingManagerEmployeeIDs: Array<number | null> | null;
        } | null;
      };
      EmployeeEquityCompensation: {
        __typename: "EmployeeNounScope";
        edit: {
          __typename: "EmployeeScope";
          global: boolean;
          directReportIDs: Array<number>;
          indirectReportIDs: Array<number>;
          supportingManagerEmployeeIDs: Array<number | null> | null;
        } | null;
        view: {
          __typename: "EmployeeScope";
          global: boolean;
          directReportIDs: Array<number>;
          indirectReportIDs: Array<number>;
          supportingManagerEmployeeIDs: Array<number | null> | null;
        } | null;
      };
      EquityBand: {
        __typename: "MarketJobStructureNounScope";
        edit: { __typename: "BaseScope"; global: boolean } | null;
        view: {
          __typename: "MarketJobStructureScope";
          allMarkets: {
            __typename: "JobStructureScope";
            global: boolean;
            departmentIDs: Array<number>;
            ladderIDs: Array<number>;
            positionIDs: Array<number>;
          } | null;
          markets: Array<{
            __typename: "IndividualMarketJobStructureScope";
            marketId: number;
            scope: {
              __typename: "JobStructureScope";
              global: boolean;
              departmentIDs: Array<number>;
              ladderIDs: Array<number>;
              positionIDs: Array<number>;
            } | null;
          }>;
        } | null;
      };
      Integration: {
        __typename: "BaseNounScope";
        edit: { __typename: "BaseScope"; global: boolean } | null;
        view: { __typename: "BaseScope"; global: boolean } | null;
      };
      JobStructure: {
        __typename: "JobStructureNounScope";
        edit: { __typename: "BaseScope"; global: boolean } | null;
        view: {
          __typename: "JobStructureScope";
          global: boolean;
          departmentIDs: Array<number>;
          ladderIDs: Array<number>;
          positionIDs: Array<number>;
        } | null;
      };
      Market: {
        __typename: "BaseNounScope";
        edit: { __typename: "BaseScope"; global: boolean } | null;
        view: { __typename: "BaseScope"; global: boolean } | null;
      };
      MarketData: {
        __typename: "BaseNounScope";
        edit: { __typename: "BaseScope"; global: boolean } | null;
        view: { __typename: "BaseScope"; global: boolean } | null;
      };
      Offer: {
        __typename: "OfferNounScope";
        edit: {
          __typename: "OfferScope";
          global: boolean;
          authored: boolean;
        } | null;
        view: {
          __typename: "OfferScope";
          global: boolean;
          authored: boolean;
        } | null;
      };
      Philosophy: {
        __typename: "BaseNounScope";
        edit: { __typename: "BaseScope"; global: boolean } | null;
        view: { __typename: "BaseScope"; global: boolean } | null;
      };
      SensitiveData: {
        __typename: "BaseNounScope";
        edit: { __typename: "BaseScope"; global: boolean } | null;
        view: { __typename: "BaseScope"; global: boolean } | null;
      };
    };
  } | null;
  employee: {
    __typename: "Employee";
    id: number;
    isManager: boolean;
    totalReportsCount: number;
    activeEmployment: {
      __typename: "Employment";
      id: number;
      jobTitle: string | null;
    } | null;
  } | null;
};

export type AccessControlTableRow_userRecords = {
  __typename: "User";
  id: number;
  email: string;
  name: string | null;
  createdAt: GraphQL_DateTime;
  photoURL: string | null;
  isDisabled: boolean;
  userAccessGrant: {
    __typename: "UserAccessGrant";
    id: number;
    roleName: PrimaryRoleName;
    nounScopes: {
      __typename: "NounScopes";
      AccessControl: {
        __typename: "BaseNounScope";
        edit: { __typename: "BaseScope"; global: boolean } | null;
        view: { __typename: "BaseScope"; global: boolean } | null;
      };
      CashBand: {
        __typename: "MarketJobStructureNounScope";
        edit: { __typename: "BaseScope"; global: boolean } | null;
        view: {
          __typename: "MarketJobStructureScope";
          allMarkets: {
            __typename: "JobStructureScope";
            global: boolean;
            departmentIDs: Array<number>;
            ladderIDs: Array<number>;
            positionIDs: Array<number>;
          } | null;
          markets: Array<{
            __typename: "IndividualMarketJobStructureScope";
            marketId: number;
            scope: {
              __typename: "JobStructureScope";
              global: boolean;
              departmentIDs: Array<number>;
              ladderIDs: Array<number>;
              positionIDs: Array<number>;
            } | null;
          }>;
        } | null;
      };
      CompCycle: {
        __typename: "BaseNounScope";
        edit: { __typename: "BaseScope"; global: boolean } | null;
        view: { __typename: "BaseScope"; global: boolean } | null;
      };
      CompCycleBudget: {
        __typename: "BaseNounScope";
        edit: { __typename: "BaseScope"; global: boolean } | null;
        view: { __typename: "BaseScope"; global: boolean } | null;
      };
      CompRecommendation: {
        __typename: "EmployeeNounScope";
        edit: {
          __typename: "EmployeeScope";
          global: boolean;
          directReportIDs: Array<number>;
          indirectReportIDs: Array<number>;
          supportingManagerEmployeeIDs: Array<number | null> | null;
        } | null;
        view: {
          __typename: "EmployeeScope";
          global: boolean;
          directReportIDs: Array<number>;
          indirectReportIDs: Array<number>;
          supportingManagerEmployeeIDs: Array<number | null> | null;
        } | null;
      };
      CompStructure: {
        __typename: "BaseNounScope";
        edit: { __typename: "BaseScope"; global: boolean } | null;
        view: { __typename: "BaseScope"; global: boolean } | null;
      };
      Employee: {
        __typename: "EmployeeNounScope";
        edit: {
          __typename: "EmployeeScope";
          global: boolean;
          directReportIDs: Array<number>;
          indirectReportIDs: Array<number>;
          supportingManagerEmployeeIDs: Array<number | null> | null;
        } | null;
        view: {
          __typename: "EmployeeScope";
          global: boolean;
          directReportIDs: Array<number>;
          indirectReportIDs: Array<number>;
          supportingManagerEmployeeIDs: Array<number | null> | null;
        } | null;
      };
      EmployeeCashCompensation: {
        __typename: "EmployeeNounScope";
        edit: {
          __typename: "EmployeeScope";
          global: boolean;
          directReportIDs: Array<number>;
          indirectReportIDs: Array<number>;
          supportingManagerEmployeeIDs: Array<number | null> | null;
        } | null;
        view: {
          __typename: "EmployeeScope";
          global: boolean;
          directReportIDs: Array<number>;
          indirectReportIDs: Array<number>;
          supportingManagerEmployeeIDs: Array<number | null> | null;
        } | null;
      };
      EmployeeEquityCompensation: {
        __typename: "EmployeeNounScope";
        edit: {
          __typename: "EmployeeScope";
          global: boolean;
          directReportIDs: Array<number>;
          indirectReportIDs: Array<number>;
          supportingManagerEmployeeIDs: Array<number | null> | null;
        } | null;
        view: {
          __typename: "EmployeeScope";
          global: boolean;
          directReportIDs: Array<number>;
          indirectReportIDs: Array<number>;
          supportingManagerEmployeeIDs: Array<number | null> | null;
        } | null;
      };
      EquityBand: {
        __typename: "MarketJobStructureNounScope";
        edit: { __typename: "BaseScope"; global: boolean } | null;
        view: {
          __typename: "MarketJobStructureScope";
          allMarkets: {
            __typename: "JobStructureScope";
            global: boolean;
            departmentIDs: Array<number>;
            ladderIDs: Array<number>;
            positionIDs: Array<number>;
          } | null;
          markets: Array<{
            __typename: "IndividualMarketJobStructureScope";
            marketId: number;
            scope: {
              __typename: "JobStructureScope";
              global: boolean;
              departmentIDs: Array<number>;
              ladderIDs: Array<number>;
              positionIDs: Array<number>;
            } | null;
          }>;
        } | null;
      };
      Integration: {
        __typename: "BaseNounScope";
        edit: { __typename: "BaseScope"; global: boolean } | null;
        view: { __typename: "BaseScope"; global: boolean } | null;
      };
      JobStructure: {
        __typename: "JobStructureNounScope";
        edit: { __typename: "BaseScope"; global: boolean } | null;
        view: {
          __typename: "JobStructureScope";
          global: boolean;
          departmentIDs: Array<number>;
          ladderIDs: Array<number>;
          positionIDs: Array<number>;
        } | null;
      };
      Market: {
        __typename: "BaseNounScope";
        edit: { __typename: "BaseScope"; global: boolean } | null;
        view: { __typename: "BaseScope"; global: boolean } | null;
      };
      MarketData: {
        __typename: "BaseNounScope";
        edit: { __typename: "BaseScope"; global: boolean } | null;
        view: { __typename: "BaseScope"; global: boolean } | null;
      };
      Offer: {
        __typename: "OfferNounScope";
        edit: {
          __typename: "OfferScope";
          global: boolean;
          authored: boolean;
        } | null;
        view: {
          __typename: "OfferScope";
          global: boolean;
          authored: boolean;
        } | null;
      };
      Philosophy: {
        __typename: "BaseNounScope";
        edit: { __typename: "BaseScope"; global: boolean } | null;
        view: { __typename: "BaseScope"; global: boolean } | null;
      };
      SensitiveData: {
        __typename: "BaseNounScope";
        edit: { __typename: "BaseScope"; global: boolean } | null;
        view: { __typename: "BaseScope"; global: boolean } | null;
      };
    };
  } | null;
  employee: {
    __typename: "Employee";
    id: number;
    isManager: boolean;
    totalReportsCount: number;
    activeEmployment: {
      __typename: "Employment";
      id: number;
      jobTitle: string | null;
    } | null;
  } | null;
};

export type AccessControlTableRow_permissionSettings = {
  __typename: "PermissionSettings";
  id: number;
  teamCompensationAccess: TeamCompensationAccessType;
};

export type CompDataTooltip_permissionSettings = {
  __typename: "PermissionSettings";
  id: number;
  teamCompensationAccess: TeamCompensationAccessType;
};

export type SupportedManagersInviteQueryVariables = Exact<{
  [key: string]: never;
}>;

export type SupportedManagersInviteQuery = {
  managers: Array<{
    __typename: "Employee";
    id: number;
    displayName: string;
    managerId: number | null;
    activeEmployment: {
      __typename: "Employment";
      id: number;
      jobTitle: string | null;
    } | null;
    directReports: Array<{ __typename: "Employee"; id: number }>;
    indirectReports: Array<{ __typename: "Employee"; id: number }>;
    user: {
      __typename: "User";
      id: number;
      userAccessGrant: {
        __typename: "UserAccessGrant";
        id: number;
        nounScopes: {
          __typename: "NounScopes";
          Employee: {
            __typename: "EmployeeNounScope";
            view: {
              __typename: "EmployeeScope";
              supportingManagerEmployeeIDs: Array<number | null> | null;
            } | null;
          };
        };
      } | null;
    } | null;
  }>;
};

export type UserInviteCompBandAccess_organization = {
  __typename: "Organization";
  id: number;
  name: string;
};

export type UserInviteCompBandAccess_departments = {
  __typename: "Department";
  id: number;
  name: string;
  ladders: Array<{
    __typename: "Ladder";
    id: number;
    name: string;
    positions: Array<{
      __typename: "Position";
      id: number;
      level: number;
      name: string;
    }>;
  }>;
};

export type ValidateEmailVariables = Exact<{
  email: Scalars["String"]["input"];
}>;

export type ValidateEmail = {
  isDomainValid: boolean;
  isEmailNotTaken: boolean;
};

export type UserInviteModal_organization = {
  __typename: "Organization";
  id: number;
  name: string;
};

export type UserInviteModal_departments = {
  __typename: "Department";
  id: number;
  name: string;
  ladders: Array<{
    __typename: "Ladder";
    id: number;
    name: string;
    positions: Array<{
      __typename: "Position";
      id: number;
      level: number;
      name: string;
    }>;
  }>;
};

export type UserInviteModalLoadingBoundaryQueryVariables = Exact<{
  [key: string]: never;
}>;

export type UserInviteModalLoadingBoundaryQuery = {
  organization: { __typename: "Organization"; id: number; name: string };
  departments: Array<{
    __typename: "Department";
    id: number;
    name: string;
    ladders: Array<{
      __typename: "Ladder";
      id: number;
      name: string;
      positions: Array<{
        __typename: "Position";
        id: number;
        level: number;
        name: string;
      }>;
    }>;
  }>;
};

export type AutomatedManagerAccessSection_mergeConnectionConfig = {
  __typename: "MergeConnectionConfig";
  id: number;
  integrationType: string;
};

export type AutomatedManagerAccessSectionLoadingBoundaryQueryVariables = Exact<{
  [key: string]: never;
}>;

export type AutomatedManagerAccessSectionLoadingBoundaryQuery = {
  mergeConnectionConfigs: Array<{
    __typename: "MergeConnectionConfig";
    id: number;
    integrationType: string;
  }>;
};

export type ActualVariableCompImportButton_employee = {
  __typename: "Employee";
  id: number;
  employeeNumber: string | null;
  email: string;
  personalEmail: string | null;
};

export type AnonymizedDataSection_permissionSettings = {
  __typename: "PermissionSettings";
  id: number;
  teamCompensationAccess: TeamCompensationAccessType;
  canRecruitersViewAnonymizedStatistics: boolean;
  anonymizedCompensationStatisticsAccess: AnonymizedCompensationStatisticsAccessType;
  anonymizedCompensationEmployeeThreshold: number;
  automatedManagerAccess: AutomatedManagerAccessType;
  employeeBandAccess: EmployeeBandAccessType;
};

export type AnonymizedDataSectionLoadingBoundaryQueryVariables = Exact<{
  [key: string]: never;
}>;

export type AnonymizedDataSectionLoadingBoundaryQuery = {
  organization: {
    __typename: "Organization";
    id: number;
    permissionSettings: {
      __typename: "PermissionSettings";
      id: number;
      teamCompensationAccess: TeamCompensationAccessType;
      canRecruitersViewAnonymizedStatistics: boolean;
      anonymizedCompensationStatisticsAccess: AnonymizedCompensationStatisticsAccessType;
      anonymizedCompensationEmployeeThreshold: number;
      automatedManagerAccess: AutomatedManagerAccessType;
      employeeBandAccess: EmployeeBandAccessType;
    };
  };
};

export type EquityImportButton_employee = {
  __typename: "Employee";
  id: number;
  employeeNumber: string | null;
  email: string;
  personalEmail: string | null;
};

export type EquityImportManagement_employee = {
  __typename: "Employee";
  id: number;
  employeeNumber: string | null;
  email: string;
  personalEmail: string | null;
  equityHoldings: {
    __typename: "EquityHoldings";
    id: number;
    grants: Array<{
      __typename: "EquityGrant";
      id: number;
      name: string;
      unitCount: number;
    }> | null;
  };
};

export type EquityImportManagement_organization = {
  __typename: "Organization";
  id: number;
  lastEquityGrantsUploadDate: GraphQL_DateTime | null;
  lastEquityVestingEventsUploadDate: GraphQL_DateTime | null;
};

export type EquityVestingImportButton_employee = {
  __typename: "Employee";
  id: number;
  employeeNumber: string | null;
  email: string;
  personalEmail: string | null;
  equityHoldings: {
    __typename: "EquityHoldings";
    id: number;
    grants: Array<{
      __typename: "EquityGrant";
      id: number;
      name: string;
      unitCount: number;
    }> | null;
  };
};

export type EquityVestingImportButton_organization = {
  __typename: "Organization";
  id: number;
  lastEquityVestingEventsUploadDate: GraphQL_DateTime | null;
};

export type EquityVestingImportDialog_employee = {
  __typename: "Employee";
  id: number;
  employeeNumber: string | null;
  email: string;
  personalEmail: string | null;
  equityHoldings: {
    __typename: "EquityHoldings";
    id: number;
    grants: Array<{
      __typename: "EquityGrant";
      id: number;
      name: string;
      unitCount: number;
    }> | null;
  };
};

export type EquityVestingImportDialog_organization = {
  __typename: "Organization";
  id: number;
  lastEquityGrantsUploadDate: GraphQL_DateTime | null;
  lastEquityVestingEventsUploadDate: GraphQL_DateTime | null;
};

export type Integrations_cartaConnectionConfig = {
  __typename: "CartaConnectionConfig";
  id: number;
  isActive: boolean;
  lastSyncAt: GraphQL_DateTime | null;
  author: {
    __typename: "User";
    id: number;
    name: string | null;
    photoURL: string | null;
  };
};

export type IntegrationsTable_cartaConnectionConfig = {
  __typename: "CartaConnectionConfig";
  id: number;
  isActive: boolean;
  lastSyncAt: GraphQL_DateTime | null;
  author: {
    __typename: "User";
    id: number;
    name: string | null;
    photoURL: string | null;
  };
};

export type GetMergeTokenVariables = Exact<{
  originId?: InputMaybe<Scalars["String"]["input"]>;
  category: MergeCategory;
}>;

export type GetMergeToken = {
  mergeLink: { __typename: "MergeLink"; linkToken: string; originId: string };
};

export type LevelingCodesImportButton_position = {
  __typename: "Position";
  id: number;
  name: string;
  level: number;
  ladder: {
    __typename: "Ladder";
    id: number;
    name: string;
    department: { __typename: "Department"; id: number; name: string };
  };
};

export type LevelingCodesImportButton_levelingCodeMapping = {
  __typename: "LevelingCodeMapping";
  positionId: number;
  levelingCode: string;
};

export type LevelingCodesImportDialog_position = {
  __typename: "Position";
  id: number;
  name: string;
  level: number;
  ladder: {
    __typename: "Ladder";
    id: number;
    name: string;
    department: { __typename: "Department"; id: number; name: string };
  };
};

export type LevelingCodesImportDialog_levelingCodeMapping = {
  __typename: "LevelingCodeMapping";
  positionId: number;
  levelingCode: string;
};

export type AdjustmentConditionCreator_compStructure = {
  __typename: "CompStructure";
  id: number;
  levels: Array<number>;
};

export type AdjustmentConditionCreator_departments = {
  __typename: "Department";
  id: number;
  name: string;
  ladders: Array<{ __typename: "Ladder"; id: number; name: string }>;
};

export type LocationAdjustmentEditor_compStructure = {
  __typename: "CompStructure";
  cashBandTypes: Array<string>;
  equityBandTypes: Array<string>;
  id: number;
  levels: Array<number>;
};

export type LocationAdjustmentEditor_departments = {
  __typename: "Department";
  id: number;
  name: string;
  ladders: Array<{ __typename: "Ladder"; id: number; name: string }>;
};

export type LocationAdjustmentEditor_locationGroup = {
  __typename: "LocationGroup";
  id: number;
  name: string;
};

export type SourceLocationAdjustmentTable_locationGroup = {
  __typename: "LocationGroup";
  locationAdjustments: Array<{
    __typename: "LocationAdjustment";
    id: number;
    condition: GraphQL_Json;
    adjustment: GraphQL_Json;
    description: string | null;
  }>;
};

export type LocationAdjustmentTable_locationGroup = {
  __typename: "LocationGroup";
  id: number;
  rankedAdjustmentIds: Array<number>;
  createdAt: GraphQL_DateTime;
  updatedAt: GraphQL_DateTime;
  locationAdjustments: Array<{
    __typename: "LocationAdjustment";
    id: number;
    condition: GraphQL_Json;
    adjustment: GraphQL_Json;
    description: string | null;
  }>;
};

export type LocationAdjustmentTable_locationAdjustment = {
  __typename: "LocationAdjustment";
  id: number;
  description: string | null;
  condition: GraphQL_Json;
  adjustment: GraphQL_Json;
};

export type LocationAdjustmentTable_departments = {
  __typename: "Department";
  id: number;
  name: string;
  ladders: Array<{ __typename: "Ladder"; id: number; name: string }>;
};

export type LocationGroupCard_compStructure = {
  __typename: "CompStructure";
  id: number;
  cashBandTypes: Array<string>;
  equityBandTypes: Array<string>;
  levels: Array<number>;
};

export type LocationGroupCard_locations = {
  __typename: "Location";
  id: number;
  city: string;
  state: string;
  country: string;
  locationGroup: { __typename: "LocationGroup"; id: number } | null;
};

export type LocationGroupCard_locationGroup = {
  __typename: "LocationGroup";
  id: number;
  name: string;
  createdAt: GraphQL_DateTime;
  updatedAt: GraphQL_DateTime;
  rankedAdjustmentIds: Array<number>;
  description: string | null;
  isSourceComp: boolean;
  locationAdjustments: Array<{
    __typename: "LocationAdjustment";
    id: number;
    condition: GraphQL_Json;
    adjustment: GraphQL_Json;
    description: string | null;
  }>;
  locations: Array<{ __typename: "Location"; id: number; city: string }>;
  author: { __typename: "User"; id: number; name: string | null } | null;
};

export type LocationGroupCard_departments = {
  __typename: "Department";
  id: number;
  name: string;
  ladders: Array<{ __typename: "Ladder"; id: number; name: string }>;
};

export type LocationGroupCard_markets = { __typename: "Market"; id: number };

export type LocationGroupDetails_locations = {
  __typename: "Location";
  id: number;
  city: string;
  state: string;
  country: string;
  locationGroup: { __typename: "LocationGroup"; id: number } | null;
};

export type LocationGroupDetails_locationGroup = {
  __typename: "LocationGroup";
  id: number;
  createdAt: GraphQL_DateTime;
  updatedAt: GraphQL_DateTime;
  name: string;
  description: string | null;
  isSourceComp: boolean;
  author: { __typename: "User"; id: number; name: string | null } | null;
  locations: Array<{ __typename: "Location"; id: number }>;
};

export type LocationGroupDetails_markets = { __typename: "Market"; id: number };

export type LocationGroupEditorDialog_locations = {
  __typename: "Location";
  id: number;
  city: string;
  state: string;
  country: string;
  locationGroup: { __typename: "LocationGroup"; id: number } | null;
};

export type LocationGroupEditorDialog_locationGroup = {
  __typename: "LocationGroup";
  id: number;
  name: string;
  description: string | null;
  isSourceComp: boolean;
  locations: Array<{ __typename: "Location"; id: number }>;
};

export type LocationGroupEditorDialog_markets = {
  __typename: "Market";
  id: number;
};

export type LocationGroupEditor_locations = {
  __typename: "Location";
  id: number;
  city: string;
  state: string;
  country: string;
  locationGroup: { __typename: "LocationGroup"; id: number } | null;
};

export type LocationGroupEditor_locationGroup = {
  __typename: "LocationGroup";
  id: number;
  name: string;
  description: string | null;
  isSourceComp: boolean;
  locations: Array<{ __typename: "Location"; id: number }>;
};

export type LocationGroupEditor_markets = { __typename: "Market"; id: number };

export type LocationMappingInfoBanner_locations = {
  __typename: "Location";
  id: number;
  locationGroup: { __typename: "LocationGroup"; id: number } | null;
};

export type LocationsDisplayList_locations = {
  __typename: "Location";
  id: number;
  city: string;
  state: string;
  country: string;
  locationGroup: { __typename: "LocationGroup"; id: number } | null;
};

export type LocationsFormList_locations = {
  __typename: "Location";
  id: number;
  city: string;
  state: string;
  country: string;
};

export type LocationsTable_mergeConfigs = {
  __typename: "MergeConnectionConfig";
  id: number;
};

export type LocationsTable_locations = {
  __typename: "Location";
  id: number;
  city: string;
  state: string;
  country: string;
  locationGroup: {
    __typename: "LocationGroup";
    id: number;
    name: string;
    market: { __typename: "Market"; id: number; name: string };
    author: {
      __typename: "User";
      id: number;
      name: string | null;
      photoURL: string | null;
    } | null;
  } | null;
};

export type LocationsTableEmpty_mergeConfigs = {
  __typename: "MergeConnectionConfig";
  id: number;
};

export type LocationsTableView_mergeConfigs = {
  __typename: "MergeConnectionConfig";
  id: number;
};

export type LocationsTableView_locations = {
  __typename: "Location";
  id: number;
  city: string;
  state: string;
  country: string;
  locationGroup: {
    __typename: "LocationGroup";
    id: number;
    name: string;
    market: { __typename: "Market"; id: number; name: string };
    author: {
      __typename: "User";
      id: number;
      name: string | null;
      photoURL: string | null;
    } | null;
  } | null;
};

export type LocationsTableMergeConfigsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type LocationsTableMergeConfigsQuery = {
  mergeConnectionConfigs: Array<{
    __typename: "MergeConnectionConfig";
    id: number;
  }>;
  locations: Array<{
    __typename: "Location";
    id: number;
    city: string;
    state: string;
    country: string;
    locationGroup: {
      __typename: "LocationGroup";
      id: number;
      name: string;
      market: { __typename: "Market"; id: number; name: string };
      author: {
        __typename: "User";
        id: number;
        name: string | null;
        photoURL: string | null;
      } | null;
    } | null;
  }>;
};

export type UnmappedLocationsBanner_locations = {
  __typename: "Location";
  id: number;
  locationGroup: { __typename: "LocationGroup"; id: number } | null;
};

export type AutomatedManagerAccess_report = {
  __typename: "Employee";
  id: number;
  activeEmployment: {
    __typename: "Employment";
    id: number;
    positionId: number | null;
  } | null;
  nextPositions: Array<{ __typename: "Position"; id: number }>;
};

export type AutomatedManagerAccess_organization = {
  __typename: "Organization";
  id: number;
  permissionSettings: {
    __typename: "PermissionSettings";
    id: number;
    automatedManagerAccess: AutomatedManagerAccessType;
  };
};

export type AutomatedManagerAccessMarketRow_organization = {
  __typename: "Organization";
  id: number;
  permissionSettings: {
    __typename: "PermissionSettings";
    id: number;
    automatedManagerAccess: AutomatedManagerAccessType;
  };
};

export type AutomatedManagerAccessMarketRow_report = {
  __typename: "Employee";
  id: number;
  activeEmployment: {
    __typename: "Employment";
    id: number;
    positionId: number | null;
  } | null;
  nextPositions: Array<{ __typename: "Position"; id: number }>;
};

export type AutomatedManagerAccessTable_organization = {
  __typename: "Organization";
  id: number;
  permissionSettings: {
    __typename: "PermissionSettings";
    id: number;
    automatedManagerAccess: AutomatedManagerAccessType;
  };
};

export type AutomatedManagerAccessTable_report = {
  __typename: "Employee";
  id: number;
  activeEmployment: {
    __typename: "Employment";
    id: number;
    positionId: number | null;
  } | null;
  nextPositions: Array<{ __typename: "Position"; id: number }>;
};

export type CompensationBandSection_report = {
  __typename: "Employee";
  id: number;
  activeEmployment: {
    __typename: "Employment";
    id: number;
    positionId: number | null;
  } | null;
  nextPositions: Array<{ __typename: "Position"; id: number }>;
};

export type CompensationBandSection_organization = {
  __typename: "Organization";
  id: number;
  permissionSettings: {
    __typename: "PermissionSettings";
    id: number;
    automatedManagerAccess: AutomatedManagerAccessType;
  };
};

export type ReportsSection_reports = {
  __typename: "Employee";
  id: number;
  displayName: string;
  activeEmployment: {
    __typename: "Employment";
    id: number;
    position: {
      __typename: "Position";
      id: number;
      name: string;
      level: number;
      type: PositionType;
      ladder: {
        __typename: "Ladder";
        id: number;
        name: string;
        department: { __typename: "Department"; id: number; name: string };
      };
    } | null;
  } | null;
};

export type ReportsSection_organization = {
  __typename: "Organization";
  id: number;
  permissionSettings: {
    __typename: "PermissionSettings";
    id: number;
    teamCompensationAccess: TeamCompensationAccessType;
  };
};

export type ReportsSectionTable_reports = {
  __typename: "Employee";
  id: number;
  displayName: string;
  activeEmployment: {
    __typename: "Employment";
    id: number;
    position: {
      __typename: "Position";
      id: number;
      name: string;
      level: number;
      type: PositionType;
      ladder: {
        __typename: "Ladder";
        id: number;
        name: string;
        department: { __typename: "Department"; id: number; name: string };
      };
    } | null;
  } | null;
};

export type SupportedManagersDrawer_manager = {
  __typename: "Employee";
  id: number;
  displayName: string;
  managerId: number | null;
  activeEmployment: {
    __typename: "Employment";
    id: number;
    jobTitle: string | null;
  } | null;
  directReports: Array<{ __typename: "Employee"; id: number }>;
  indirectReports: Array<{ __typename: "Employee"; id: number }>;
  user: {
    __typename: "User";
    id: number;
    userAccessGrant: {
      __typename: "UserAccessGrant";
      id: number;
      nounScopes: {
        __typename: "NounScopes";
        Employee: {
          __typename: "EmployeeNounScope";
          view: {
            __typename: "EmployeeScope";
            supportingManagerEmployeeIDs: Array<number | null> | null;
          } | null;
        };
      };
    } | null;
  } | null;
};

export type SupportedManagersRow_manager = {
  __typename: "Employee";
  id: number;
  displayName: string;
  managerId: number | null;
  activeEmployment: {
    __typename: "Employment";
    id: number;
    jobTitle: string | null;
    position: {
      __typename: "Position";
      id: number;
      name: string;
      ladder: {
        __typename: "Ladder";
        id: number;
        department: { __typename: "Department"; id: number; name: string };
      };
    } | null;
  } | null;
  directReports: Array<{ __typename: "Employee"; id: number }>;
  indirectReports: Array<{ __typename: "Employee"; id: number }>;
};

export type SupportedManagersQueryVariables = Exact<{ [key: string]: never }>;

export type SupportedManagersQuery = {
  managers: Array<{
    __typename: "Employee";
    id: number;
    displayName: string;
    managerId: number | null;
    activeEmployment: {
      __typename: "Employment";
      id: number;
      jobTitle: string | null;
      position: {
        __typename: "Position";
        id: number;
        name: string;
        ladder: {
          __typename: "Ladder";
          id: number;
          department: { __typename: "Department"; id: number; name: string };
        };
      } | null;
    } | null;
    directReports: Array<{ __typename: "Employee"; id: number }>;
    indirectReports: Array<{ __typename: "Employee"; id: number }>;
    user: {
      __typename: "User";
      id: number;
      userAccessGrant: {
        __typename: "UserAccessGrant";
        id: number;
        nounScopes: {
          __typename: "NounScopes";
          Employee: {
            __typename: "EmployeeNounScope";
            view: {
              __typename: "EmployeeScope";
              supportingManagerEmployeeIDs: Array<number | null> | null;
            } | null;
          };
        };
      } | null;
    } | null;
  }>;
};

export type SupportedManagersTable_manager = {
  __typename: "Employee";
  id: number;
  displayName: string;
  managerId: number | null;
  activeEmployment: {
    __typename: "Employment";
    id: number;
    jobTitle: string | null;
    position: {
      __typename: "Position";
      id: number;
      name: string;
      ladder: {
        __typename: "Ladder";
        id: number;
        department: { __typename: "Department"; id: number; name: string };
      };
    } | null;
  } | null;
  directReports: Array<{ __typename: "Employee"; id: number }>;
  indirectReports: Array<{ __typename: "Employee"; id: number }>;
};

export type UserAccessControl_user = {
  __typename: "User";
  id: number;
  name: string | null;
  email: string;
  photoURL: string | null;
  isDisabled: boolean;
  createdAt: GraphQL_DateTime;
  userAccessGrant: {
    __typename: "UserAccessGrant";
    id: number;
    roleName: PrimaryRoleName;
    nounScopes: {
      __typename: "NounScopes";
      AccessControl: {
        __typename: "BaseNounScope";
        edit: { __typename: "BaseScope"; global: boolean } | null;
        view: { __typename: "BaseScope"; global: boolean } | null;
      };
      CashBand: {
        __typename: "MarketJobStructureNounScope";
        edit: { __typename: "BaseScope"; global: boolean } | null;
        view: {
          __typename: "MarketJobStructureScope";
          allMarkets: {
            __typename: "JobStructureScope";
            global: boolean;
            departmentIDs: Array<number>;
            ladderIDs: Array<number>;
            positionIDs: Array<number>;
          } | null;
          markets: Array<{
            __typename: "IndividualMarketJobStructureScope";
            marketId: number;
            scope: {
              __typename: "JobStructureScope";
              global: boolean;
              departmentIDs: Array<number>;
              ladderIDs: Array<number>;
              positionIDs: Array<number>;
            } | null;
          }>;
        } | null;
      };
      CompCycle: {
        __typename: "BaseNounScope";
        edit: { __typename: "BaseScope"; global: boolean } | null;
        view: { __typename: "BaseScope"; global: boolean } | null;
      };
      CompCycleBudget: {
        __typename: "BaseNounScope";
        edit: { __typename: "BaseScope"; global: boolean } | null;
        view: { __typename: "BaseScope"; global: boolean } | null;
      };
      CompRecommendation: {
        __typename: "EmployeeNounScope";
        edit: {
          __typename: "EmployeeScope";
          global: boolean;
          directReportIDs: Array<number>;
          indirectReportIDs: Array<number>;
          supportingManagerEmployeeIDs: Array<number | null> | null;
        } | null;
        view: {
          __typename: "EmployeeScope";
          global: boolean;
          directReportIDs: Array<number>;
          indirectReportIDs: Array<number>;
          supportingManagerEmployeeIDs: Array<number | null> | null;
        } | null;
      };
      CompStructure: {
        __typename: "BaseNounScope";
        edit: { __typename: "BaseScope"; global: boolean } | null;
        view: { __typename: "BaseScope"; global: boolean } | null;
      };
      Employee: {
        __typename: "EmployeeNounScope";
        edit: {
          __typename: "EmployeeScope";
          global: boolean;
          directReportIDs: Array<number>;
          indirectReportIDs: Array<number>;
          supportingManagerEmployeeIDs: Array<number | null> | null;
        } | null;
        view: {
          __typename: "EmployeeScope";
          global: boolean;
          directReportIDs: Array<number>;
          indirectReportIDs: Array<number>;
          supportingManagerEmployeeIDs: Array<number | null> | null;
        } | null;
      };
      EmployeeCashCompensation: {
        __typename: "EmployeeNounScope";
        edit: {
          __typename: "EmployeeScope";
          global: boolean;
          directReportIDs: Array<number>;
          indirectReportIDs: Array<number>;
          supportingManagerEmployeeIDs: Array<number | null> | null;
        } | null;
        view: {
          __typename: "EmployeeScope";
          global: boolean;
          directReportIDs: Array<number>;
          indirectReportIDs: Array<number>;
          supportingManagerEmployeeIDs: Array<number | null> | null;
        } | null;
      };
      EmployeeEquityCompensation: {
        __typename: "EmployeeNounScope";
        edit: {
          __typename: "EmployeeScope";
          global: boolean;
          directReportIDs: Array<number>;
          indirectReportIDs: Array<number>;
          supportingManagerEmployeeIDs: Array<number | null> | null;
        } | null;
        view: {
          __typename: "EmployeeScope";
          global: boolean;
          directReportIDs: Array<number>;
          indirectReportIDs: Array<number>;
          supportingManagerEmployeeIDs: Array<number | null> | null;
        } | null;
      };
      EquityBand: {
        __typename: "MarketJobStructureNounScope";
        edit: { __typename: "BaseScope"; global: boolean } | null;
        view: {
          __typename: "MarketJobStructureScope";
          allMarkets: {
            __typename: "JobStructureScope";
            global: boolean;
            departmentIDs: Array<number>;
            ladderIDs: Array<number>;
            positionIDs: Array<number>;
          } | null;
          markets: Array<{
            __typename: "IndividualMarketJobStructureScope";
            marketId: number;
            scope: {
              __typename: "JobStructureScope";
              global: boolean;
              departmentIDs: Array<number>;
              ladderIDs: Array<number>;
              positionIDs: Array<number>;
            } | null;
          }>;
        } | null;
      };
      Integration: {
        __typename: "BaseNounScope";
        edit: { __typename: "BaseScope"; global: boolean } | null;
        view: { __typename: "BaseScope"; global: boolean } | null;
      };
      JobStructure: {
        __typename: "JobStructureNounScope";
        edit: { __typename: "BaseScope"; global: boolean } | null;
        view: {
          __typename: "JobStructureScope";
          global: boolean;
          departmentIDs: Array<number>;
          ladderIDs: Array<number>;
          positionIDs: Array<number>;
        } | null;
      };
      Market: {
        __typename: "BaseNounScope";
        edit: { __typename: "BaseScope"; global: boolean } | null;
        view: { __typename: "BaseScope"; global: boolean } | null;
      };
      MarketData: {
        __typename: "BaseNounScope";
        edit: { __typename: "BaseScope"; global: boolean } | null;
        view: { __typename: "BaseScope"; global: boolean } | null;
      };
      Offer: {
        __typename: "OfferNounScope";
        edit: {
          __typename: "OfferScope";
          global: boolean;
          authored: boolean;
        } | null;
        view: {
          __typename: "OfferScope";
          global: boolean;
          authored: boolean;
        } | null;
      };
      Philosophy: {
        __typename: "BaseNounScope";
        edit: { __typename: "BaseScope"; global: boolean } | null;
        view: { __typename: "BaseScope"; global: boolean } | null;
      };
      SensitiveData: {
        __typename: "BaseNounScope";
        edit: { __typename: "BaseScope"; global: boolean } | null;
        view: { __typename: "BaseScope"; global: boolean } | null;
      };
    };
  } | null;
  employee: {
    __typename: "Employee";
    id: number;
    isManager: boolean;
    reports: Array<{
      __typename: "Employee";
      id: number;
      displayName: string;
      activeEmployment: {
        __typename: "Employment";
        id: number;
        positionId: number | null;
        position: {
          __typename: "Position";
          id: number;
          name: string;
          level: number;
          type: PositionType;
          ladder: {
            __typename: "Ladder";
            id: number;
            name: string;
            department: { __typename: "Department"; id: number; name: string };
          };
        } | null;
      } | null;
      nextPositions: Array<{ __typename: "Position"; id: number }>;
    }> | null;
  } | null;
};

export type UserAccessControl_userInvitation = {
  __typename: "UserInvitation";
  id: number;
  name: string;
  email: string;
  createdAt: GraphQL_DateTime;
  deletedAt: GraphQL_DateTime | null;
  userInvitationAccessGrant: {
    __typename: "UserInvitationAccessGrant";
    id: number;
    roleName: PrimaryRoleName;
    nounScopes: {
      __typename: "NounScopes";
      AccessControl: {
        __typename: "BaseNounScope";
        edit: { __typename: "BaseScope"; global: boolean } | null;
        view: { __typename: "BaseScope"; global: boolean } | null;
      };
      CashBand: {
        __typename: "MarketJobStructureNounScope";
        edit: { __typename: "BaseScope"; global: boolean } | null;
        view: {
          __typename: "MarketJobStructureScope";
          allMarkets: {
            __typename: "JobStructureScope";
            global: boolean;
            departmentIDs: Array<number>;
            ladderIDs: Array<number>;
            positionIDs: Array<number>;
          } | null;
          markets: Array<{
            __typename: "IndividualMarketJobStructureScope";
            marketId: number;
            scope: {
              __typename: "JobStructureScope";
              global: boolean;
              departmentIDs: Array<number>;
              ladderIDs: Array<number>;
              positionIDs: Array<number>;
            } | null;
          }>;
        } | null;
      };
      CompCycle: {
        __typename: "BaseNounScope";
        edit: { __typename: "BaseScope"; global: boolean } | null;
        view: { __typename: "BaseScope"; global: boolean } | null;
      };
      CompCycleBudget: {
        __typename: "BaseNounScope";
        edit: { __typename: "BaseScope"; global: boolean } | null;
        view: { __typename: "BaseScope"; global: boolean } | null;
      };
      CompRecommendation: {
        __typename: "EmployeeNounScope";
        edit: {
          __typename: "EmployeeScope";
          global: boolean;
          directReportIDs: Array<number>;
          indirectReportIDs: Array<number>;
          supportingManagerEmployeeIDs: Array<number | null> | null;
        } | null;
        view: {
          __typename: "EmployeeScope";
          global: boolean;
          directReportIDs: Array<number>;
          indirectReportIDs: Array<number>;
          supportingManagerEmployeeIDs: Array<number | null> | null;
        } | null;
      };
      CompStructure: {
        __typename: "BaseNounScope";
        edit: { __typename: "BaseScope"; global: boolean } | null;
        view: { __typename: "BaseScope"; global: boolean } | null;
      };
      Employee: {
        __typename: "EmployeeNounScope";
        edit: {
          __typename: "EmployeeScope";
          global: boolean;
          directReportIDs: Array<number>;
          indirectReportIDs: Array<number>;
          supportingManagerEmployeeIDs: Array<number | null> | null;
        } | null;
        view: {
          __typename: "EmployeeScope";
          global: boolean;
          directReportIDs: Array<number>;
          indirectReportIDs: Array<number>;
          supportingManagerEmployeeIDs: Array<number | null> | null;
        } | null;
      };
      EmployeeCashCompensation: {
        __typename: "EmployeeNounScope";
        edit: {
          __typename: "EmployeeScope";
          global: boolean;
          directReportIDs: Array<number>;
          indirectReportIDs: Array<number>;
          supportingManagerEmployeeIDs: Array<number | null> | null;
        } | null;
        view: {
          __typename: "EmployeeScope";
          global: boolean;
          directReportIDs: Array<number>;
          indirectReportIDs: Array<number>;
          supportingManagerEmployeeIDs: Array<number | null> | null;
        } | null;
      };
      EmployeeEquityCompensation: {
        __typename: "EmployeeNounScope";
        edit: {
          __typename: "EmployeeScope";
          global: boolean;
          directReportIDs: Array<number>;
          indirectReportIDs: Array<number>;
          supportingManagerEmployeeIDs: Array<number | null> | null;
        } | null;
        view: {
          __typename: "EmployeeScope";
          global: boolean;
          directReportIDs: Array<number>;
          indirectReportIDs: Array<number>;
          supportingManagerEmployeeIDs: Array<number | null> | null;
        } | null;
      };
      EquityBand: {
        __typename: "MarketJobStructureNounScope";
        edit: { __typename: "BaseScope"; global: boolean } | null;
        view: {
          __typename: "MarketJobStructureScope";
          allMarkets: {
            __typename: "JobStructureScope";
            global: boolean;
            departmentIDs: Array<number>;
            ladderIDs: Array<number>;
            positionIDs: Array<number>;
          } | null;
          markets: Array<{
            __typename: "IndividualMarketJobStructureScope";
            marketId: number;
            scope: {
              __typename: "JobStructureScope";
              global: boolean;
              departmentIDs: Array<number>;
              ladderIDs: Array<number>;
              positionIDs: Array<number>;
            } | null;
          }>;
        } | null;
      };
      Integration: {
        __typename: "BaseNounScope";
        edit: { __typename: "BaseScope"; global: boolean } | null;
        view: { __typename: "BaseScope"; global: boolean } | null;
      };
      JobStructure: {
        __typename: "JobStructureNounScope";
        edit: { __typename: "BaseScope"; global: boolean } | null;
        view: {
          __typename: "JobStructureScope";
          global: boolean;
          departmentIDs: Array<number>;
          ladderIDs: Array<number>;
          positionIDs: Array<number>;
        } | null;
      };
      Market: {
        __typename: "BaseNounScope";
        edit: { __typename: "BaseScope"; global: boolean } | null;
        view: { __typename: "BaseScope"; global: boolean } | null;
      };
      MarketData: {
        __typename: "BaseNounScope";
        edit: { __typename: "BaseScope"; global: boolean } | null;
        view: { __typename: "BaseScope"; global: boolean } | null;
      };
      Offer: {
        __typename: "OfferNounScope";
        edit: {
          __typename: "OfferScope";
          global: boolean;
          authored: boolean;
        } | null;
        view: {
          __typename: "OfferScope";
          global: boolean;
          authored: boolean;
        } | null;
      };
      Philosophy: {
        __typename: "BaseNounScope";
        edit: { __typename: "BaseScope"; global: boolean } | null;
        view: { __typename: "BaseScope"; global: boolean } | null;
      };
      SensitiveData: {
        __typename: "BaseNounScope";
        edit: { __typename: "BaseScope"; global: boolean } | null;
        view: { __typename: "BaseScope"; global: boolean } | null;
      };
    };
  } | null;
  employee: {
    __typename: "Employee";
    id: number;
    isManager: boolean;
    reports: Array<{
      __typename: "Employee";
      id: number;
      displayName: string;
      activeEmployment: {
        __typename: "Employment";
        id: number;
        positionId: number | null;
        position: {
          __typename: "Position";
          id: number;
          name: string;
          level: number;
          type: PositionType;
          ladder: {
            __typename: "Ladder";
            id: number;
            name: string;
            department: { __typename: "Department"; id: number; name: string };
          };
        } | null;
      } | null;
      nextPositions: Array<{ __typename: "Position"; id: number }>;
    }> | null;
  } | null;
};

export type UserAccessControl_organization = {
  __typename: "Organization";
  id: number;
  permissionSettings: {
    __typename: "PermissionSettings";
    id: number;
    teamCompensationAccess: TeamCompensationAccessType;
    automatedManagerAccess: AutomatedManagerAccessType;
  };
};

export type UserAccessControlLoadingBoundaryQueryVariables = Exact<{
  userId: Scalars["Int"]["input"];
}>;

export type UserAccessControlLoadingBoundaryQuery = {
  userRecord: {
    __typename: "User";
    id: number;
    name: string | null;
    email: string;
    photoURL: string | null;
    isDisabled: boolean;
    createdAt: GraphQL_DateTime;
    userAccessGrant: {
      __typename: "UserAccessGrant";
      id: number;
      roleName: PrimaryRoleName;
      nounScopes: {
        __typename: "NounScopes";
        AccessControl: {
          __typename: "BaseNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: { __typename: "BaseScope"; global: boolean } | null;
        };
        CashBand: {
          __typename: "MarketJobStructureNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: {
            __typename: "MarketJobStructureScope";
            allMarkets: {
              __typename: "JobStructureScope";
              global: boolean;
              departmentIDs: Array<number>;
              ladderIDs: Array<number>;
              positionIDs: Array<number>;
            } | null;
            markets: Array<{
              __typename: "IndividualMarketJobStructureScope";
              marketId: number;
              scope: {
                __typename: "JobStructureScope";
                global: boolean;
                departmentIDs: Array<number>;
                ladderIDs: Array<number>;
                positionIDs: Array<number>;
              } | null;
            }>;
          } | null;
        };
        CompCycle: {
          __typename: "BaseNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: { __typename: "BaseScope"; global: boolean } | null;
        };
        CompCycleBudget: {
          __typename: "BaseNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: { __typename: "BaseScope"; global: boolean } | null;
        };
        CompRecommendation: {
          __typename: "EmployeeNounScope";
          edit: {
            __typename: "EmployeeScope";
            global: boolean;
            directReportIDs: Array<number>;
            indirectReportIDs: Array<number>;
            supportingManagerEmployeeIDs: Array<number | null> | null;
          } | null;
          view: {
            __typename: "EmployeeScope";
            global: boolean;
            directReportIDs: Array<number>;
            indirectReportIDs: Array<number>;
            supportingManagerEmployeeIDs: Array<number | null> | null;
          } | null;
        };
        CompStructure: {
          __typename: "BaseNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: { __typename: "BaseScope"; global: boolean } | null;
        };
        Employee: {
          __typename: "EmployeeNounScope";
          edit: {
            __typename: "EmployeeScope";
            global: boolean;
            directReportIDs: Array<number>;
            indirectReportIDs: Array<number>;
            supportingManagerEmployeeIDs: Array<number | null> | null;
          } | null;
          view: {
            __typename: "EmployeeScope";
            global: boolean;
            directReportIDs: Array<number>;
            indirectReportIDs: Array<number>;
            supportingManagerEmployeeIDs: Array<number | null> | null;
          } | null;
        };
        EmployeeCashCompensation: {
          __typename: "EmployeeNounScope";
          edit: {
            __typename: "EmployeeScope";
            global: boolean;
            directReportIDs: Array<number>;
            indirectReportIDs: Array<number>;
            supportingManagerEmployeeIDs: Array<number | null> | null;
          } | null;
          view: {
            __typename: "EmployeeScope";
            global: boolean;
            directReportIDs: Array<number>;
            indirectReportIDs: Array<number>;
            supportingManagerEmployeeIDs: Array<number | null> | null;
          } | null;
        };
        EmployeeEquityCompensation: {
          __typename: "EmployeeNounScope";
          edit: {
            __typename: "EmployeeScope";
            global: boolean;
            directReportIDs: Array<number>;
            indirectReportIDs: Array<number>;
            supportingManagerEmployeeIDs: Array<number | null> | null;
          } | null;
          view: {
            __typename: "EmployeeScope";
            global: boolean;
            directReportIDs: Array<number>;
            indirectReportIDs: Array<number>;
            supportingManagerEmployeeIDs: Array<number | null> | null;
          } | null;
        };
        EquityBand: {
          __typename: "MarketJobStructureNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: {
            __typename: "MarketJobStructureScope";
            allMarkets: {
              __typename: "JobStructureScope";
              global: boolean;
              departmentIDs: Array<number>;
              ladderIDs: Array<number>;
              positionIDs: Array<number>;
            } | null;
            markets: Array<{
              __typename: "IndividualMarketJobStructureScope";
              marketId: number;
              scope: {
                __typename: "JobStructureScope";
                global: boolean;
                departmentIDs: Array<number>;
                ladderIDs: Array<number>;
                positionIDs: Array<number>;
              } | null;
            }>;
          } | null;
        };
        Integration: {
          __typename: "BaseNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: { __typename: "BaseScope"; global: boolean } | null;
        };
        JobStructure: {
          __typename: "JobStructureNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: {
            __typename: "JobStructureScope";
            global: boolean;
            departmentIDs: Array<number>;
            ladderIDs: Array<number>;
            positionIDs: Array<number>;
          } | null;
        };
        Market: {
          __typename: "BaseNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: { __typename: "BaseScope"; global: boolean } | null;
        };
        MarketData: {
          __typename: "BaseNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: { __typename: "BaseScope"; global: boolean } | null;
        };
        Offer: {
          __typename: "OfferNounScope";
          edit: {
            __typename: "OfferScope";
            global: boolean;
            authored: boolean;
          } | null;
          view: {
            __typename: "OfferScope";
            global: boolean;
            authored: boolean;
          } | null;
        };
        Philosophy: {
          __typename: "BaseNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: { __typename: "BaseScope"; global: boolean } | null;
        };
        SensitiveData: {
          __typename: "BaseNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: { __typename: "BaseScope"; global: boolean } | null;
        };
      };
    } | null;
    employee: {
      __typename: "Employee";
      id: number;
      isManager: boolean;
      reports: Array<{
        __typename: "Employee";
        id: number;
        displayName: string;
        activeEmployment: {
          __typename: "Employment";
          id: number;
          positionId: number | null;
          position: {
            __typename: "Position";
            id: number;
            name: string;
            level: number;
            type: PositionType;
            ladder: {
              __typename: "Ladder";
              id: number;
              name: string;
              department: {
                __typename: "Department";
                id: number;
                name: string;
              };
            };
          } | null;
        } | null;
        nextPositions: Array<{ __typename: "Position"; id: number }>;
      }> | null;
    } | null;
  } | null;
  organization: {
    __typename: "Organization";
    id: number;
    permissionSettings: {
      __typename: "PermissionSettings";
      id: number;
      teamCompensationAccess: TeamCompensationAccessType;
      automatedManagerAccess: AutomatedManagerAccessType;
    };
  };
};

export type UserInvitationAccessControlLoadingBoundaryQueryVariables = Exact<{
  userInvitationId: Scalars["Int"]["input"];
}>;

export type UserInvitationAccessControlLoadingBoundaryQuery = {
  userInvitationFromId: {
    __typename: "UserInvitation";
    id: number;
    name: string;
    email: string;
    createdAt: GraphQL_DateTime;
    deletedAt: GraphQL_DateTime | null;
    userInvitationAccessGrant: {
      __typename: "UserInvitationAccessGrant";
      id: number;
      roleName: PrimaryRoleName;
      nounScopes: {
        __typename: "NounScopes";
        AccessControl: {
          __typename: "BaseNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: { __typename: "BaseScope"; global: boolean } | null;
        };
        CashBand: {
          __typename: "MarketJobStructureNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: {
            __typename: "MarketJobStructureScope";
            allMarkets: {
              __typename: "JobStructureScope";
              global: boolean;
              departmentIDs: Array<number>;
              ladderIDs: Array<number>;
              positionIDs: Array<number>;
            } | null;
            markets: Array<{
              __typename: "IndividualMarketJobStructureScope";
              marketId: number;
              scope: {
                __typename: "JobStructureScope";
                global: boolean;
                departmentIDs: Array<number>;
                ladderIDs: Array<number>;
                positionIDs: Array<number>;
              } | null;
            }>;
          } | null;
        };
        CompCycle: {
          __typename: "BaseNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: { __typename: "BaseScope"; global: boolean } | null;
        };
        CompCycleBudget: {
          __typename: "BaseNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: { __typename: "BaseScope"; global: boolean } | null;
        };
        CompRecommendation: {
          __typename: "EmployeeNounScope";
          edit: {
            __typename: "EmployeeScope";
            global: boolean;
            directReportIDs: Array<number>;
            indirectReportIDs: Array<number>;
            supportingManagerEmployeeIDs: Array<number | null> | null;
          } | null;
          view: {
            __typename: "EmployeeScope";
            global: boolean;
            directReportIDs: Array<number>;
            indirectReportIDs: Array<number>;
            supportingManagerEmployeeIDs: Array<number | null> | null;
          } | null;
        };
        CompStructure: {
          __typename: "BaseNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: { __typename: "BaseScope"; global: boolean } | null;
        };
        Employee: {
          __typename: "EmployeeNounScope";
          edit: {
            __typename: "EmployeeScope";
            global: boolean;
            directReportIDs: Array<number>;
            indirectReportIDs: Array<number>;
            supportingManagerEmployeeIDs: Array<number | null> | null;
          } | null;
          view: {
            __typename: "EmployeeScope";
            global: boolean;
            directReportIDs: Array<number>;
            indirectReportIDs: Array<number>;
            supportingManagerEmployeeIDs: Array<number | null> | null;
          } | null;
        };
        EmployeeCashCompensation: {
          __typename: "EmployeeNounScope";
          edit: {
            __typename: "EmployeeScope";
            global: boolean;
            directReportIDs: Array<number>;
            indirectReportIDs: Array<number>;
            supportingManagerEmployeeIDs: Array<number | null> | null;
          } | null;
          view: {
            __typename: "EmployeeScope";
            global: boolean;
            directReportIDs: Array<number>;
            indirectReportIDs: Array<number>;
            supportingManagerEmployeeIDs: Array<number | null> | null;
          } | null;
        };
        EmployeeEquityCompensation: {
          __typename: "EmployeeNounScope";
          edit: {
            __typename: "EmployeeScope";
            global: boolean;
            directReportIDs: Array<number>;
            indirectReportIDs: Array<number>;
            supportingManagerEmployeeIDs: Array<number | null> | null;
          } | null;
          view: {
            __typename: "EmployeeScope";
            global: boolean;
            directReportIDs: Array<number>;
            indirectReportIDs: Array<number>;
            supportingManagerEmployeeIDs: Array<number | null> | null;
          } | null;
        };
        EquityBand: {
          __typename: "MarketJobStructureNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: {
            __typename: "MarketJobStructureScope";
            allMarkets: {
              __typename: "JobStructureScope";
              global: boolean;
              departmentIDs: Array<number>;
              ladderIDs: Array<number>;
              positionIDs: Array<number>;
            } | null;
            markets: Array<{
              __typename: "IndividualMarketJobStructureScope";
              marketId: number;
              scope: {
                __typename: "JobStructureScope";
                global: boolean;
                departmentIDs: Array<number>;
                ladderIDs: Array<number>;
                positionIDs: Array<number>;
              } | null;
            }>;
          } | null;
        };
        Integration: {
          __typename: "BaseNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: { __typename: "BaseScope"; global: boolean } | null;
        };
        JobStructure: {
          __typename: "JobStructureNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: {
            __typename: "JobStructureScope";
            global: boolean;
            departmentIDs: Array<number>;
            ladderIDs: Array<number>;
            positionIDs: Array<number>;
          } | null;
        };
        Market: {
          __typename: "BaseNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: { __typename: "BaseScope"; global: boolean } | null;
        };
        MarketData: {
          __typename: "BaseNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: { __typename: "BaseScope"; global: boolean } | null;
        };
        Offer: {
          __typename: "OfferNounScope";
          edit: {
            __typename: "OfferScope";
            global: boolean;
            authored: boolean;
          } | null;
          view: {
            __typename: "OfferScope";
            global: boolean;
            authored: boolean;
          } | null;
        };
        Philosophy: {
          __typename: "BaseNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: { __typename: "BaseScope"; global: boolean } | null;
        };
        SensitiveData: {
          __typename: "BaseNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: { __typename: "BaseScope"; global: boolean } | null;
        };
      };
    } | null;
    employee: {
      __typename: "Employee";
      id: number;
      isManager: boolean;
      reports: Array<{
        __typename: "Employee";
        id: number;
        displayName: string;
        activeEmployment: {
          __typename: "Employment";
          id: number;
          positionId: number | null;
          position: {
            __typename: "Position";
            id: number;
            name: string;
            level: number;
            type: PositionType;
            ladder: {
              __typename: "Ladder";
              id: number;
              name: string;
              department: {
                __typename: "Department";
                id: number;
                name: string;
              };
            };
          } | null;
        } | null;
        nextPositions: Array<{ __typename: "Position"; id: number }>;
      }> | null;
    } | null;
  } | null;
  organization: {
    __typename: "Organization";
    id: number;
    permissionSettings: {
      __typename: "PermissionSettings";
      id: number;
      teamCompensationAccess: TeamCompensationAccessType;
      automatedManagerAccess: AutomatedManagerAccessType;
    };
  };
};

export type VariableCompImportButton_employee = {
  __typename: "Employee";
  id: number;
  employeeNumber: string | null;
  email: string;
  personalEmail: string | null;
};

export type VariableCompImportDialog_employee = {
  __typename: "Employee";
  id: number;
  employeeNumber: string | null;
  email: string;
  personalEmail: string | null;
};

export type BandPlacementCell_employee = {
  __typename: "Employee2";
  id: number;
  activeEmployment: {
    __typename: "Employment2";
    id: number;
    payPeriod: PayPeriodType;
    position: {
      __typename: "Position";
      id: number;
      type: PositionType;
      name: string;
      level: number;
    } | null;
  } | null;
  activeCashCompensation: Array<{
    __typename: "CashCompensation2";
    employeeId: number;
    activeAt: GraphQL_Date;
    type: CashCompType;
    annualCashEquivalent: GraphQL_Money;
    hourlyCashEquivalent: GraphQL_Money;
    unit: CompUnit;
    percentOfSalary: number;
  }> | null;
  adjustedCashBands: Array<{
    __typename: "AdjustedCashBand";
    id: string;
    name: string;
    bandPoints: Array<{
      __typename: "AdjustedCashBandPoint";
      id: string;
      name: string;
      bandName: string;
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      value: {
        __typename: "CashValue";
        annualRate: GraphQL_Money | null;
        hourlyRate: GraphQL_Money | null;
        currencyCode: CurrencyCode;
      };
    }>;
  }> | null;
};

export type BandPlacementCell_position = {
  __typename: "Position";
  id: number;
  type: PositionType;
  name: string;
  level: number;
  adjustedCashBands: Array<{
    __typename: "AdjustedCashBand";
    id: string;
    name: string;
    bandPoints: Array<{
      __typename: "AdjustedCashBandPoint";
      id: string;
      name: string;
      bandName: string;
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      value: {
        __typename: "CashValue";
        annualRate: GraphQL_Money | null;
        hourlyRate: GraphQL_Money | null;
        currencyCode: CurrencyCode;
      };
    }>;
  }> | null;
};

export type CompaRatioCell_employee = {
  __typename: "Employee2";
  id: number;
  activeEmployment: {
    __typename: "Employment2";
    salary: GraphQL_Money | null;
    payRate: GraphQL_Money | null;
    payPeriod: PayPeriodType;
  } | null;
  activeCashCompensation: Array<{
    __typename: "CashCompensation2";
    employeeId: number;
    type: CashCompType;
    activeAt: GraphQL_Date;
    annualCashEquivalent: GraphQL_Money;
    hourlyCashEquivalent: GraphQL_Money;
    unit: CompUnit;
  }> | null;
  adjustedCashBands: Array<{
    __typename: "AdjustedCashBand";
    id: string;
    name: string;
    bandPoints: Array<{
      __typename: "AdjustedCashBandPoint";
      value: {
        __typename: "CashValue";
        annualRate: GraphQL_Money | null;
        hourlyRate: GraphQL_Money | null;
        currencyCode: CurrencyCode;
      };
    }>;
  }> | null;
};

export type CompensationTimelineTable_cashCompensation = {
  __typename: "CashCompensation";
  activeAt: GraphQL_Date;
  employeeId: number;
  type: CashCompType;
  annualCashEquivalent: GraphQL_Money;
  hourlyCashEquivalent: GraphQL_Money;
  percentOfSalary: number;
  jobTitle: string | null;
  unit: CompUnit;
};

export type CondensedBandPlacementCell_employee = {
  __typename: "Employee2";
  activeEmployment: {
    __typename: "Employment2";
    id: number;
    payPeriod: PayPeriodType;
    position: { __typename: "Position"; name: string; level: number } | null;
  } | null;
  adjustedCashBands: Array<{
    __typename: "AdjustedCashBand";
    name: string;
    bandPoints: Array<{
      __typename: "AdjustedCashBandPoint";
      name: string;
      value: {
        __typename: "CashValue";
        annualRate: GraphQL_Money | null;
        hourlyRate: GraphQL_Money | null;
        currencyCode: CurrencyCode;
      };
    }>;
  }> | null;
  activeCashCompensation: Array<{
    __typename: "CashCompensation2";
    annualCashEquivalent: GraphQL_Money;
    hourlyCashEquivalent: GraphQL_Money;
    unit: CompUnit;
    type: CashCompType;
    percentOfSalary: number;
  }> | null;
};

export type CondensedBandPlacementCell_cash = {
  __typename: "CashCompensation2";
  type: CashCompType;
  annualCashEquivalent: GraphQL_Money;
  hourlyCashEquivalent: GraphQL_Money;
  unit: CompUnit;
  percentOfSalary: number;
};

export type CondensedBandPlacementCell_band = {
  __typename: "AdjustedCashBand";
  name: string;
  bandPoints: Array<{
    __typename: "AdjustedCashBandPoint";
    name: string;
    value: {
      __typename: "CashValue";
      annualRate: GraphQL_Money | null;
      hourlyRate: GraphQL_Money | null;
      currencyCode: CurrencyCode;
    };
  }>;
};

export type EquityDisplayCell_EquityHoldings = {
  __typename: "EquityHoldings2";
  totalGrossHoldingValue: GraphQL_Money | null;
  totalUnitCount: number;
};

export type TotalCashCell_cash = {
  __typename: "CashCompensation2";
  type: CashCompType;
  annualCashEquivalent: GraphQL_Money;
  hourlyCashEquivalent: GraphQL_Money;
  unit: CompUnit;
  percentOfSalary: number;
};

export type TotalEquitySliderProvider_portalConfig = {
  __typename: "PortalConfig";
  id: number;
  exitOutcomes: Array<number>;
  defaultExitOutcome: number;
  xAxisAnnotations: Array<string>;
};

export type TotalEquitySliderLoadingBoundaryQueryVariables = Exact<{
  [key: string]: never;
}>;

export type TotalEquitySliderLoadingBoundaryQuery = {
  portalConfig: {
    __typename: "PortalConfig";
    id: number;
    exitOutcomes: Array<number>;
    defaultExitOutcome: number;
    xAxisAnnotations: Array<string>;
  } | null;
};

export type UploadLevelsButton_organization = {
  __typename: "Organization";
  id: number;
  name: string;
};

export type UploadLevelsButton_employee = {
  __typename: "Employee";
  id: number;
  employeeNumber: string | null;
  email: string;
  personalEmail: string | null;
};

export type UploadLevelsButton_position = {
  __typename: "Position";
  id: number;
  name: string;
  level: number;
  ladder: {
    __typename: "Ladder";
    id: number;
    name: string;
    department: { __typename: "Department"; id: number; name: string };
  };
};

export type UploadUsersWithGrantsButton_organization = {
  __typename: "Organization";
  id: number;
  users: Array<{
    __typename: "User";
    id: number;
    email: string;
    name: string | null;
  }>;
  departments: Array<{
    __typename: "Department";
    id: number;
    ladders: Array<{
      __typename: "Ladder";
      id: number;
      positions: Array<{ __typename: "Position"; id: number }>;
    }>;
  }>;
};

export type UserViewContextQueryVariables = Exact<{ [key: string]: never }>;

export type UserViewContextQuery = {
  whoami: {
    __typename: "AuthSubject";
    userView: { __typename: "UserView"; id: number; json: GraphQL_Json } | null;
  } | null;
};

export type BenefitFields = {
  __typename: "Benefit";
  id: number;
  benefitsPackageId: number;
  name: string;
  value: GraphQL_Money | null;
  description: string;
  rank: string;
};

export type BenefitsPackageFields = {
  __typename: "BenefitsPackage";
  id: number;
  name: string;
  currencyCode: CurrencyCode;
  totalValue: GraphQL_Money;
  benefits: Array<{
    __typename: "Benefit";
    id: number;
    benefitsPackageId: number;
    name: string;
    value: GraphQL_Money | null;
    description: string;
    rank: string;
  }>;
};

export type AdjustedCashBandPointFields = {
  __typename: "AdjustedCashBandPoint";
  id: string;
  name: string;
  bandName: string;
  annualCashEquivalent: GraphQL_Money;
  hourlyCashEquivalent: GraphQL_Money;
  value: {
    __typename: "CashValue";
    annualRate: GraphQL_Money | null;
    hourlyRate: GraphQL_Money | null;
    currencyCode: CurrencyCode;
  };
};

export type AdjustedEquityBandPointFields = {
  __typename: "AdjustedEquityBandPoint";
  id: string;
  name: string;
  bandName: string;
  annualCashEquivalent: GraphQL_Money;
  hourlyCashEquivalent: GraphQL_Money;
  totalGrossValue: GraphQL_Money;
  totalUnits: number | null;
  vestingMonths: number;
  value:
    | {
        __typename: "CashValue";
        annualRate: GraphQL_Money | null;
        currencyCode: CurrencyCode;
      }
    | { __typename: "PercentValue"; decimalValue: number | null }
    | { __typename: "UnitValue"; unitValue: number | null };
  totalPercentOwnership: {
    __typename: "PercentValue";
    decimalValue: number | null;
  } | null;
};

export type AdjustedCashBandFields = {
  __typename: "AdjustedCashBand";
  id: string;
  name: string;
  bandPoints: Array<{
    __typename: "AdjustedCashBandPoint";
    id: string;
    name: string;
    bandName: string;
    annualCashEquivalent: GraphQL_Money;
    hourlyCashEquivalent: GraphQL_Money;
    value: {
      __typename: "CashValue";
      annualRate: GraphQL_Money | null;
      hourlyRate: GraphQL_Money | null;
      currencyCode: CurrencyCode;
    };
  }>;
};

export type AdjustedEquityBandFields = {
  __typename: "AdjustedEquityBand";
  id: string;
  name: string;
  bandPoints: Array<{
    __typename: "AdjustedEquityBandPoint";
    id: string;
    name: string;
    bandName: string;
    annualCashEquivalent: GraphQL_Money;
    hourlyCashEquivalent: GraphQL_Money;
    totalGrossValue: GraphQL_Money;
    totalUnits: number | null;
    vestingMonths: number;
    value:
      | {
          __typename: "CashValue";
          annualRate: GraphQL_Money | null;
          currencyCode: CurrencyCode;
        }
      | { __typename: "PercentValue"; decimalValue: number | null }
      | { __typename: "UnitValue"; unitValue: number | null };
    totalPercentOwnership: {
      __typename: "PercentValue";
      decimalValue: number | null;
    } | null;
  }>;
};

export type PositionFieldsMinimal = {
  __typename: "Position";
  id: number;
  name: string;
  level: number;
  type: PositionType;
  ladder: {
    __typename: "Ladder";
    id: number;
    name: string;
    department: { __typename: "Department"; id: number; name: string };
  };
};

export type OfferConfigFields = {
  __typename: "OfferConfig";
  id: number;
  exitOutcomes: Array<number>;
  xAxisAnnotations: Array<string>;
  defaultOutcomeDescription: string;
  equityFootnote: string | null;
  defaultWelcomeMessage: string | null;
  defaultClosingMessage: string | null;
  sharePriceLabel: string;
  showEquityInformation: boolean;
  showFdso: boolean;
  showStage: boolean;
  showCurrentEquityValue: boolean;
  showSharePriceFootnote: boolean;
  showAnnualizedEquity: boolean;
  requireApproval: boolean;
  brandColorPrimary: string | null;
  primaryLogoUrlPath: string | null;
  whiteLogoUrlPath: string | null;
  greyLogoUrlPath: string | null;
  backgroundUrlPath: string | null;
  equityCashInValuationCurrency: boolean;
  customFields: Array<{
    __typename: "CustomField";
    name: string;
    description: string;
    variant: CustomFieldVariant;
    options: Array<string>;
  }>;
};

export type ValuationFields = {
  __typename: "Valuation";
  id: number;
  activeAt: GraphQL_DateTime;
  estimatedDilution: number;
  financingStage: string;
  fdso: number;
  strikePrice: GraphQL_Money;
  valuation: GraphQL_Money;
};

export type CompStructureFields = {
  __typename: "CompStructure";
  id: number;
  bandPointTypes: Array<string>;
  cashBandTypes: Array<string>;
  equityBandTypes: Array<string>;
  allBandTypes: Array<string>;
  availableCashBandTypes: Array<string>;
  availableEquityBandTypes: Array<string>;
  businessStructure: BusinessStructure | null;
  companyDescription: string | null;
  equityGrantTypes: Array<EquityGrantTypes>;
  showPercentOwnership: boolean;
  showValuation: boolean;
  vestingCliff: number;
  vestingMonths: number;
  levels: Array<number>;
  equityGrantMethod: EquityGrantMethod;
  allowHourlyEmployees: boolean;
  employmentHoursPerWeek: number;
  employmentWeeksPerYear: number;
};

export type EmailValidatorQueryVariables = Exact<{
  email: Scalars["String"]["input"];
}>;

export type EmailValidatorQuery = {
  isDomainValid: boolean;
  isEmailNotTaken: boolean;
};

export type UpsertOfferConfigVariables = Exact<{
  data: OfferConfigInput;
}>;

export type UpsertOfferConfig = {
  upsertOfferConfig: {
    __typename: "OfferConfig";
    id: number;
    exitOutcomes: Array<number>;
    xAxisAnnotations: Array<string>;
    defaultOutcomeDescription: string;
    equityCashInValuationCurrency: boolean;
    organization: { __typename: "Organization"; id: number };
    customFields: Array<{
      __typename: "CustomField";
      name: string;
      description: string;
      variant: CustomFieldVariant;
      options: Array<string>;
    }>;
  };
};

export type ArchiveOneOfferVariables = Exact<{
  id: Scalars["Int"]["input"];
}>;

export type ArchiveOneOffer = {
  archiveOneOffer: { __typename: "Offer"; id: number };
};

export type CreateValuationVariables = Exact<{
  data: ValuationInput;
}>;

export type CreateValuation = {
  createOneValuation: { __typename: "Valuation"; id: number };
};

export type UpsertCompStructureVariables = Exact<{
  data: CompStructureInput;
}>;

export type UpsertCompStructure = {
  upsertCompStructure: {
    __typename: "CompStructure";
    id: number;
    cashBandTypes: Array<string>;
    equityBandTypes: Array<string>;
    employmentHoursPerWeek: number;
    employmentWeeksPerYear: number;
    allowHourlyEmployees: boolean;
  };
};

export type UpsertPhilosophyVariables = Exact<{
  compPhilosophy: Scalars["Json"]["input"];
}>;

export type UpsertPhilosophy = {
  upsertPhilosophy: {
    __typename: "Philosophy";
    id: number;
    json: GraphQL_Json;
    isActive: boolean;
    createdAt: GraphQL_DateTime;
    author: {
      __typename: "User";
      id: number;
      name: string | null;
      email: string;
    } | null;
  };
};

export type EmplaceAllCurrenciesVariables = Exact<{
  currencies: Array<CurrencyInput> | CurrencyInput;
}>;

export type EmplaceAllCurrencies = {
  emplaceAllCurrencies: Array<{
    __typename: "Currency";
    id: number;
    code: CurrencyCode;
    exchangeRate: number;
  }>;
};

export type UpdateEmploymentVariables = Exact<{
  id: Scalars["Int"]["input"];
  data: EmploymentUpdateInput;
}>;

export type UpdateEmployment = {
  updateOneEmployment: {
    __typename: "Employment";
    id: number;
    positionId: number | null;
  };
};

export type UpdatePermissionSettingsVariables = Exact<{
  data: PermissionSettingsInput;
}>;

export type UpdatePermissionSettings = {
  updatePermissionSettings: {
    __typename: "PermissionSettings";
    id: number;
    canRecruitersViewAnonymizedStatistics: boolean;
    teamCompensationAccess: TeamCompensationAccessType;
    employeeBandAccess: EmployeeBandAccessType;
  };
};

export type ImportActualVariableCompVariables = Exact<{
  data: Array<ImportActualVariableCompInput> | ImportActualVariableCompInput;
}>;

export type ImportActualVariableComp = {
  importActualVariableComp: {
    __typename: "ImportActualVariableCompResult";
    invalid: Array<string>;
    valid: Array<{ __typename: "ActualVariableCashCompensation"; id: number }>;
  };
};

export type CreateBenefitsPackageVariables = Exact<{
  benefitsPackage: BenefitsPackageInput;
}>;

export type CreateBenefitsPackage = {
  createOneBenefitsPackage: {
    __typename: "BenefitsPackage";
    id: number;
    name: string;
    currencyCode: CurrencyCode;
    totalValue: GraphQL_Money;
    benefits: Array<{
      __typename: "Benefit";
      id: number;
      benefitsPackageId: number;
      name: string;
      value: GraphQL_Money | null;
      description: string;
      rank: string;
    }>;
  };
};

export type UpdateBenefitsPackageVariables = Exact<{
  id: Scalars["Int"]["input"];
  benefitsPackage: BenefitsPackageInput;
}>;

export type UpdateBenefitsPackage = {
  updateOneBenefitsPackage: {
    __typename: "BenefitsPackage";
    id: number;
    name: string;
    currencyCode: CurrencyCode;
    totalValue: GraphQL_Money;
    benefits: Array<{
      __typename: "Benefit";
      id: number;
      benefitsPackageId: number;
      name: string;
      value: GraphQL_Money | null;
      description: string;
      rank: string;
    }>;
  };
};

export type DeleteBenefitsPackageVariables = Exact<{
  id: Scalars["Int"]["input"];
}>;

export type DeleteBenefitsPackage = {
  deleteOneBenefitsPackage: { __typename: "BenefitsPackage"; id: number };
};

export type UpdateBudgetGuidanceVariables = Exact<{
  data: UpdateBudgetGuidanceInput;
}>;

export type UpdateBudgetGuidance = {
  updateBudgetGuide: { __typename: "BudgetGuide"; id: number; percent: number };
};

export type AddPerfRatingOptionVariables = Exact<{
  data: AddGuidanceInput;
}>;

export type AddPerfRatingOption = {
  addPerfRatingOption: {
    __typename: "BudgetPerfRatingOption";
    id: number;
    rank: number;
  };
};

export type AddGuidanceRangeVariables = Exact<{
  data: AddGuidanceInput;
}>;

export type AddGuidanceRange = {
  addGuidanceRange: {
    __typename: "BudgetRange";
    id: number;
    rangeStart: number;
  };
};

export type UpdatePerfRatingOptionVariables = Exact<{
  data: UpdatePerfRatingOptionInput;
}>;

export type UpdatePerfRatingOption = {
  updatePerfRatingOption: {
    __typename: "BudgetPerfRatingOption";
    id: number;
    name: string;
  };
};

export type UpdateGuidanceRangeVariables = Exact<{
  data: UpdateGuidanceRangeInput;
}>;

export type UpdateGuidanceRange = {
  updateGuidanceRange: {
    __typename: "BudgetRange";
    id: number;
    rangeStart: number;
  };
};

export type ImportVariableCompVariables = Exact<{
  data: Array<ImportVariableCompInput> | ImportVariableCompInput;
}>;

export type ImportVariableComp = {
  importVariableComp: {
    __typename: "ImportVariableCompOutput";
    entriesAdded: number;
  };
};

export type CreateCompCycleVariables = Exact<{
  data: CompCycleInput;
}>;

export type CreateCompCycle = {
  createOneCompCycle: {
    __typename: "CompCycle";
    id: number;
    name: string;
    endedAt: GraphQL_DateTime | null;
  };
};

export type ImportEmployeePerfScoresVariables = Exact<{
  data: CompCyclePerfScoreInput;
}>;

export type ImportEmployeePerfScores = {
  importEmployeePerfScores: {
    __typename: "ParticipantsResult2";
    count: number;
    participants: Array<{
      __typename: "CompCycleParticipant";
      compCycleId: number;
      subjectId: number;
      perfRating: string | null;
    }>;
  };
};

export type UpdateSingleEmployeePerfScoreVariables = Exact<{
  data: CompCycleSingleEmployeePerfScoreInput;
}>;

export type UpdateSingleEmployeePerfScore = {
  updateEmployeePerfScore: {
    __typename: "CompCycleParticipant";
    compCycleId: number;
    subjectId: number;
    perfRating: string | null;
  };
};

export type updateCompCycleNameVariables = Exact<{
  data: UpdateCompCycleNameInput;
}>;

export type updateCompCycleName = {
  updateCompCycleName: { __typename: "CompCycle"; id: number; name: string };
};

export type updateCompCycleGuidancePreferencesVariables = Exact<{
  data: UpdateCompCycleGuidancePreferencesInput;
}>;

export type updateCompCycleGuidancePreferences = {
  updateCompCycleGuidancePreferences: {
    __typename: "CompCycle";
    id: number;
    recommendationsPreFill: boolean;
  };
};

export type CompleteCompCycleVariables = Exact<{
  id: Scalars["Int"]["input"];
}>;

export type CompleteCompCycle = {
  completeOneCompCycle: {
    __typename: "CompleteCompCycleResponse";
    compCycle: {
      __typename: "CompCycle";
      id: number;
      endedAt: GraphQL_DateTime | null;
    };
    autoApprovedCompRecommendations: Array<{
      __typename: "CompRecommendation";
      subjectId: number;
      compCycleId: number | null;
      updatedAt: GraphQL_DateTime;
      reviewStatus: ReviewRequestStatus | null;
      allSubmittedReviews: Array<{
        __typename: "RecReview";
        id: number;
        submittedAt: GraphQL_DateTime | null;
        status: ReviewRequestStatus;
        note: string | null;
        author: { __typename: "User"; id: number };
      }>;
    }>;
  };
};

export type DeleteCompCycleVariables = Exact<{
  id: Scalars["Int"]["input"];
}>;

export type DeleteCompCycle = {
  deleteOneCompCycle: {
    __typename: "CompCycle";
    id: number;
    deletedAt: GraphQL_DateTime | null;
    name: string;
    endedAt: GraphQL_DateTime | null;
    createdAt: GraphQL_DateTime;
  };
};

export type UpdateEligibilityRulesVariables = Exact<{
  input: UpdateEligibilityRulesInput;
}>;

export type UpdateEligibilityRules = {
  updateEligibilityRules: {
    __typename: "CompCycle";
    id: number;
    eligibilityRules: Array<Array<GraphQL_Json>> | null;
  };
};

export type DraftBudgetFragment = {
  __typename: "CompCycleBudgetDraft";
  compCycleId: number;
  employeeId: number | null;
  salary: GraphQL_Money | null;
  salaryMarket: GraphQL_Money | null;
  salaryMerit: GraphQL_Money | null;
  salaryPromotion: GraphQL_Money | null;
  equity: GraphQL_Money | null;
  bonus: GraphQL_Money | null;
};

export type DraftBudgetVariables = Exact<{
  input: UpsertOneBudgetInput;
}>;

export type DraftBudget = {
  upsertOneBudget: {
    __typename: "UpsertOneBudgetPayload";
    successful: boolean;
    budget: {
      __typename: "CompCycleBudgetDraft";
      compCycleId: number;
      employeeId: number | null;
      salary: GraphQL_Money | null;
      salaryMarket: GraphQL_Money | null;
      salaryMerit: GraphQL_Money | null;
      salaryPromotion: GraphQL_Money | null;
      equity: GraphQL_Money | null;
      bonus: GraphQL_Money | null;
    } | null;
  };
};

export type PublishCompCycleBudgetsVariables = Exact<{
  data: PublishBudgetsInput;
}>;

export type PublishCompCycleBudgets = {
  publishBudgets: {
    __typename: "PublishBudgetsPayload";
    draftsPublished: number;
  };
};

export type GenerateBudgetFromMatrixVariables = Exact<{
  compCycleId: Scalars["Int"]["input"];
}>;

export type GenerateBudgetFromMatrix = {
  generateAutoBudget: {
    __typename: "CompCycleBudgetDraft";
    compCycleId: number;
    employeeId: number | null;
    salary: GraphQL_Money | null;
    salaryMarket: GraphQL_Money | null;
    salaryMerit: GraphQL_Money | null;
    salaryPromotion: GraphQL_Money | null;
    equity: GraphQL_Money | null;
    bonus: GraphQL_Money | null;
  };
};

export type EmplaceCompCyclePhasesVariables = Exact<{
  compCycleId: Scalars["Int"]["input"];
  phases: Array<CreatePhasesInput> | CreatePhasesInput;
}>;

export type EmplaceCompCyclePhases = {
  emplaceCompCyclePhases: {
    __typename: "CompCycle";
    id: number;
    phases: Array<{
      __typename: "CompCyclePhase";
      id: number;
      startDate: GraphQL_Date;
      phaseOrder: number;
    }>;
  };
};

export type UpdateCompCyclePhasesVariables = Exact<{
  compCycleId: Scalars["Int"]["input"];
  phases: Array<UpdatePhasesInput> | UpdatePhasesInput;
}>;

export type UpdateCompCyclePhases = {
  updateCompCyclePhases: {
    __typename: "CompCycle";
    id: number;
    phases: Array<{
      __typename: "CompCyclePhase";
      id: number;
      startDate: GraphQL_Date;
      phaseOrder: number;
    }>;
  };
};

export type ReplaceCompCyclePhaseAssignmentVariables = Exact<{
  compCycleId: Scalars["Int"]["input"];
  compCyclePhaseId: Scalars["Int"]["input"];
  assigneeIds: Array<Scalars["Int"]["input"]> | Scalars["Int"]["input"];
}>;

export type ReplaceCompCyclePhaseAssignment = {
  replaceCompCyclePhaseAssignment: Array<{
    __typename: "CompCyclePhaseAssignment";
    id: number;
    phaseId: number;
    status: RelativeCompRecStatus | null;
  }>;
};

export type EmplaceCompRecommendationsVariables = Exact<{
  compCycleId: Scalars["Int"]["input"];
  data: Array<CompRecommendationInput> | CompRecommendationInput;
  actingManagerEmployeeId?: InputMaybe<Scalars["Int"]["input"]>;
  skipEligibility?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type EmplaceCompRecommendations = {
  emplaceCompRecommendations: {
    __typename: "EmplaceCompRecommendationsResponse";
    participants: Array<{
      __typename: "CompCycleParticipant";
      compCycleId: number;
      subjectId: number;
      compRecommendation: {
        __typename: "CompRecommendation2";
        subjectId: number;
        compCycleId: number;
        canICurrentlyReview: boolean;
        canICurrentlyEdit: boolean;
        reviewStatus: CompRecommendationStatus;
        allSubmittedItems: Array<{
          __typename: "RecItem2";
          id: number;
          authorId: number;
        }>;
        latestSubmittedItems: Array<{
          __typename: "RecItem2";
          id: number;
          recommendationType: RecItemType;
          note: string | null;
          submittedAt: GraphQL_DateTime | null;
          unitType: CompUnit | null;
          recommendedCashValue: GraphQL_Money | null;
          recommendedPercentValue: number | null;
          recommendedEquityUnitCount: number | null;
          recommendedTitle: string | null;
          recommendedPosition: {
            __typename: "Position";
            id: number;
            name: string;
            level: number;
            ladder: {
              __typename: "Ladder";
              id: number;
              name: string;
              department: {
                __typename: "Department";
                id: number;
                name: string;
              };
            };
          } | null;
          adjustedCashBands: Array<{
            __typename: "AdjustedCashBand";
            id: string;
            name: string;
            bandPoints: Array<{
              __typename: "AdjustedCashBandPoint";
              name: string;
              value: {
                __typename: "CashValue";
                annualRate: GraphQL_Money | null;
                currencyCode: CurrencyCode;
              };
            }>;
          }> | null;
          author: {
            __typename: "User2";
            employee: { __typename: "Employee2"; id: number } | null;
          };
        }>;
        latestSubmittedPayIncrease: {
          __typename: "CompValue";
          annualCashEquivalent: GraphQL_Money;
          hourlyCashEquivalent: GraphQL_Money;
          unitType: CompUnit | null;
        };
        latestSubmittedReviews: Array<{
          __typename: "RecReview2";
          id: number;
          status: RecReviewStatus;
          submittedAt: GraphQL_DateTime | null;
          author: { __typename: "User2"; id: number };
        }>;
        allSubmittedReviews: Array<{
          __typename: "RecReview2";
          id: number;
          authorId: number;
        }>;
      } | null;
    }>;
  };
};

export type SubmitRecReviewsVariables = Exact<{
  reviews: Array<RecReviewInput> | RecReviewInput;
  actingManagerEmployeeId?: InputMaybe<Scalars["Int"]["input"]>;
  skipEligibility?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type SubmitRecReviews = {
  submitRecReviews: {
    __typename: "SubmitRecReviewsResponse";
    participants: Array<{
      __typename: "CompCycleParticipant";
      subjectId: number;
      compCycleId: number;
      compRecommendation: {
        __typename: "CompRecommendation2";
        subjectId: number;
        compCycleId: number;
        canICurrentlyReview: boolean;
        canICurrentlyEdit: boolean;
        reviewStatus: CompRecommendationStatus;
        allSubmittedReviews: Array<{
          __typename: "RecReview2";
          id: number;
          submittedAt: GraphQL_DateTime | null;
          status: RecReviewStatus;
          note: string | null;
          author: { __typename: "User2"; id: number };
        }>;
        latestSubmittedReviews: Array<{
          __typename: "RecReview2";
          id: number;
          submittedAt: GraphQL_DateTime | null;
          status: RecReviewStatus;
          note: string | null;
          author: { __typename: "User2"; id: number };
        }>;
        latestSubmittedItems: Array<{
          __typename: "RecItem2";
          id: number;
          recommendationType: RecItemType;
          note: string | null;
          submittedAt: GraphQL_DateTime | null;
          unitType: CompUnit | null;
          recommendedCashValue: GraphQL_Money | null;
          recommendedPercentValue: number | null;
          recommendedEquityUnitCount: number | null;
          recommendedTitle: string | null;
          recommendedPosition: { __typename: "Position"; id: number } | null;
          adjustedCashBands: Array<{
            __typename: "AdjustedCashBand";
            id: string;
            name: string;
            bandPoints: Array<{
              __typename: "AdjustedCashBandPoint";
              name: string;
              value: {
                __typename: "CashValue";
                annualRate: GraphQL_Money | null;
                currencyCode: CurrencyCode;
              };
            }>;
          }> | null;
        }>;
        latestSubmittedPayIncrease: {
          __typename: "CompValue";
          annualCashEquivalent: GraphQL_Money;
          hourlyCashEquivalent: GraphQL_Money;
          unitType: CompUnit | null;
        };
      } | null;
    }>;
  };
};

export type DepartmentFields = {
  __typename: "Department";
  id: number;
  name: string;
};

export type CreateDepartmentVariables = Exact<{
  departmentName: Scalars["String"]["input"];
}>;

export type CreateDepartment = {
  createOneDepartment: {
    __typename: "Department";
    id: number;
    name: string;
    organization: {
      __typename: "Organization";
      id: number;
      name: string;
      departments: Array<{
        __typename: "Department";
        id: number;
        name: string;
      }>;
    };
  };
};

export type UpdateDepartmentVariables = Exact<{
  departmentId: Scalars["Int"]["input"];
  departmentName?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type UpdateDepartment = {
  updateOneDepartment: { __typename: "Department"; id: number; name: string };
};

export type DeleteDepartmentVariables = Exact<{
  departmentId: Scalars["Int"]["input"];
}>;

export type DeleteDepartment = {
  deleteOneDepartment: { __typename: "Department"; id: number; name: string };
};

export type AssignEmployeeBenefitsVariables = Exact<{
  data: Array<AssignEmployeeBenefitsInput> | AssignEmployeeBenefitsInput;
}>;

export type AssignEmployeeBenefits = {
  assignEmployeeBenefits: Array<{
    __typename: "Employee";
    id: number;
    benefitsPackage: {
      __typename: "BenefitsPackage";
      id: number;
      name: string;
      benefits: Array<{
        __typename: "Benefit";
        id: number;
        name: string;
        description: string;
      }>;
    } | null;
  }>;
};

export type LevelEmployeesVariables = Exact<{
  data: Array<LevelEmployeesInput> | LevelEmployeesInput;
}>;

export type LevelEmployees = {
  levelEmployees: Array<{
    __typename: "Employment";
    id: number;
    employeeId: number;
    positionId: number | null;
    employee: { __typename: "Employee"; id: number };
    position: { __typename: "Position"; id: number } | null;
  }>;
};

export type ImportEmployeeEquityGrantsVariables = Exact<{
  data: Array<EquityGrantInput> | EquityGrantInput;
}>;

export type ImportEmployeeEquityGrants = {
  importEmployeeEquityGrants: Array<{
    __typename: "Employee";
    id: number;
    employeeNumber: string | null;
    email: string;
    personalEmail: string | null;
    equityHoldings: {
      __typename: "EquityHoldings";
      id: number;
      grants: Array<{
        __typename: "EquityGrant";
        id: number;
        name: string;
        unitCount: number;
      }> | null;
    };
  }>;
};

export type UpsertEquityGrantEventsVariables = Exact<{
  equityGrantVestEvents:
    | Array<EquityGrantVestEventUpsertInput>
    | EquityGrantVestEventUpsertInput;
}>;

export type UpsertEquityGrantEvents = {
  upsertEquityGrantVestEvents: {
    __typename: "EquityGrantVestEventUpsertResponse";
    errors: Array<string>;
    result: Array<{ __typename: "Employee"; id: number }>;
    organization: {
      __typename: "Organization";
      id: number;
      hasAnyEquityVestEvents: boolean;
      lastEquityVestingEventsUploadDate: GraphQL_DateTime | null;
    };
  };
};

export type UpsertMergeConnectionConfigVariables = Exact<{
  data: MergeConnectionConfigInput;
}>;

export type UpsertMergeConnectionConfig = {
  upsertOneMergeConnectionConfig: {
    __typename: "MergeConnectionConfig";
    id: number;
  };
};

export type UpsertCartaConnectionConfigVariables = Exact<{
  cartaRefreshToken: Scalars["String"]["input"];
}>;

export type UpsertCartaConnectionConfig = {
  upsertCartaConnectionConfig: {
    __typename: "CartaConnectionConfig";
    id: number;
  } | null;
};

export type UpsertManualMergeConnectionConfigVariables = Exact<{
  [key: string]: never;
}>;

export type UpsertManualMergeConnectionConfig = {
  upsertOneManualMergeConnectionConfig: {
    __typename: "MergeConnectionConfig";
    id: number;
    integrationName: string;
  };
};

export type ImportRipplingPayrollCsvVariables = Exact<{
  data: Array<RipplingPayrollCsvInput> | RipplingPayrollCsvInput;
}>;

export type ImportRipplingPayrollCsv = {
  importRipplingPayrollCsv: {
    __typename: "HrisCsvUploadResult";
    recordCount: number;
    employeeCount: number;
  };
};

export type LadderFields = { __typename: "Ladder"; id: number; name: string };

export type CreateLadderVariables = Exact<{
  ladderName: Scalars["String"]["input"];
  departmentId: Scalars["Int"]["input"];
}>;

export type CreateLadder = {
  createOneLadder: {
    __typename: "Ladder";
    id: number;
    name: string;
    department: {
      __typename: "Department";
      id: number;
      name: string;
      ladders: Array<{ __typename: "Ladder"; id: number; name: string }>;
    };
    organization: { __typename: "Organization"; id: number; name: string };
  };
};

export type UpdateLadderVariables = Exact<{
  ladderId: Scalars["Int"]["input"];
  ladderName?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type UpdateLadder = {
  updateOneLadder: { __typename: "Ladder"; id: number; name: string };
};

export type DeleteLadderVariables = Exact<{
  ladderId: Scalars["Int"]["input"];
}>;

export type DeleteLadder = {
  deleteOneLadder: { __typename: "Ladder"; id: number; name: string };
};

export type LocationGroupFields = {
  __typename: "LocationGroup";
  id: number;
  name: string;
  description: string | null;
  rankedAdjustmentIds: Array<number>;
  createdAt: GraphQL_DateTime;
  updatedAt: GraphQL_DateTime;
  organization: { __typename: "Organization"; id: number };
  author: { __typename: "User"; id: number } | null;
  locations: Array<{
    __typename: "Location";
    id: number;
    locationGroupId: number | null;
    locationGroup: { __typename: "LocationGroup"; id: number } | null;
  }>;
  locationAdjustments: Array<{ __typename: "LocationAdjustment"; id: number }>;
};

export type CreateLocationGroupVariables = Exact<{
  data: LocationGroupInput;
}>;

export type CreateLocationGroup = {
  createOneLocationGroup: {
    __typename: "Market";
    id: number;
    locationGroups: Array<{
      __typename: "LocationGroup";
      id: number;
      name: string;
      description: string | null;
      rankedAdjustmentIds: Array<number>;
      createdAt: GraphQL_DateTime;
      updatedAt: GraphQL_DateTime;
      organization: { __typename: "Organization"; id: number };
      author: { __typename: "User"; id: number } | null;
      locations: Array<{
        __typename: "Location";
        id: number;
        locationGroupId: number | null;
        locationGroup: { __typename: "LocationGroup"; id: number } | null;
      }>;
      locationAdjustments: Array<{
        __typename: "LocationAdjustment";
        id: number;
      }>;
    }>;
  };
};

export type UpdateLocationGroupVariables = Exact<{
  id: Scalars["Int"]["input"];
  data: LocationGroupUpdateInput;
}>;

export type UpdateLocationGroup = {
  updateOneLocationGroup: {
    __typename: "LocationGroupUpdateResponse";
    locationGroup: {
      __typename: "LocationGroup";
      id: number;
      name: string;
      description: string | null;
      rankedAdjustmentIds: Array<number>;
      createdAt: GraphQL_DateTime;
      updatedAt: GraphQL_DateTime;
      organization: { __typename: "Organization"; id: number };
      author: { __typename: "User"; id: number } | null;
      locations: Array<{
        __typename: "Location";
        id: number;
        locationGroupId: number | null;
        locationGroup: { __typename: "LocationGroup"; id: number } | null;
      }>;
      locationAdjustments: Array<{
        __typename: "LocationAdjustment";
        id: number;
      }>;
    };
    unmappedLocations: Array<{
      __typename: "Location";
      id: number;
      locationGroupId: number | null;
      locationGroup: { __typename: "LocationGroup"; id: number } | null;
    }>;
  };
};

export type DeleteLocationGroupVariables = Exact<{
  id: Scalars["Int"]["input"];
}>;

export type DeleteLocationGroup = {
  deleteOneLocationGroup: {
    __typename: "LocationGroupUpdateResponse";
    locationGroup: {
      __typename: "LocationGroup";
      id: number;
      name: string;
      description: string | null;
      rankedAdjustmentIds: Array<number>;
      createdAt: GraphQL_DateTime;
      updatedAt: GraphQL_DateTime;
      organization: { __typename: "Organization"; id: number };
      author: { __typename: "User"; id: number } | null;
      locations: Array<{
        __typename: "Location";
        id: number;
        locationGroupId: number | null;
        locationGroup: { __typename: "LocationGroup"; id: number } | null;
      }>;
      locationAdjustments: Array<{
        __typename: "LocationAdjustment";
        id: number;
      }>;
    };
    market: {
      __typename: "Market";
      id: number;
      locationGroups: Array<{
        __typename: "LocationGroup";
        id: number;
        name: string;
        description: string | null;
        rankedAdjustmentIds: Array<number>;
        createdAt: GraphQL_DateTime;
        updatedAt: GraphQL_DateTime;
        organization: { __typename: "Organization"; id: number };
        author: { __typename: "User"; id: number } | null;
        locations: Array<{
          __typename: "Location";
          id: number;
          locationGroupId: number | null;
          locationGroup: { __typename: "LocationGroup"; id: number } | null;
        }>;
        locationAdjustments: Array<{
          __typename: "LocationAdjustment";
          id: number;
        }>;
      }>;
    };
    unmappedLocations: Array<{
      __typename: "Location";
      id: number;
      locationGroupId: number | null;
      locationGroup: { __typename: "LocationGroup"; id: number } | null;
    }>;
  };
};

export type CreateLocationAdjustmentVariables = Exact<{
  data: LocationAdjustmentInput;
}>;

export type CreateLocationAdjustment = {
  createOneLocationAdjustment: {
    __typename: "LocationAdjustment";
    id: number;
    adjustment: GraphQL_Json;
    condition: GraphQL_Json;
    description: string | null;
    createdAt: GraphQL_DateTime;
    updatedAt: GraphQL_DateTime;
    locationGroup: {
      __typename: "LocationGroup";
      id: number;
      name: string;
      rankedAdjustmentIds: Array<number>;
      locationAdjustments: Array<{
        __typename: "LocationAdjustment";
        id: number;
      }>;
    };
    author: {
      __typename: "User";
      id: number;
      name: string | null;
      email: string;
    } | null;
  };
};

export type UpdateLocationAdjustmentVariables = Exact<{
  id: Scalars["Int"]["input"];
  data: LocationAdjustmentUpdateInput;
}>;

export type UpdateLocationAdjustment = {
  updateOneLocationAdjustment: {
    __typename: "LocationAdjustment";
    id: number;
    adjustment: GraphQL_Json;
    condition: GraphQL_Json;
    description: string | null;
    updatedAt: GraphQL_DateTime;
    author: { __typename: "User"; id: number } | null;
  };
};

export type DeleteLocationAdjustmentVariables = Exact<{
  id: Scalars["Int"]["input"];
}>;

export type DeleteLocationAdjustment = {
  deleteOneLocationAdjustment: {
    __typename: "LocationAdjustmentDeleteResponse";
    locationGroup: {
      __typename: "LocationGroup";
      id: number;
      rankedAdjustmentIds: Array<number>;
      locationAdjustments: Array<{
        __typename: "LocationAdjustment";
        id: number;
      }>;
    };
  };
};

export type MarketFields = {
  __typename: "Market";
  id: number;
  name: string;
  currencyCode: CurrencyCode;
};

export type CreateMarketVariables = Exact<{
  data: MarketInput;
}>;

export type CreateMarket = {
  createOneMarket: {
    __typename: "Market";
    id: number;
    name: string;
    currencyCode: CurrencyCode;
  };
};

export type UpdateMarketVariables = Exact<{
  marketId: Scalars["Int"]["input"];
  data: MarketInput;
}>;

export type UpdateMarket = {
  updateOneMarket: {
    __typename: "Market";
    id: number;
    name: string;
    currencyCode: CurrencyCode;
  };
};

export type DeleteMarketVariables = Exact<{
  marketId: Scalars["Int"]["input"];
}>;

export type DeleteMarket = { deleteOneMarket: boolean };

export type ImportMarketDataVariables = Exact<{
  data: ImportMarketDataInput;
}>;

export type ImportMarketData = {
  importMarketData: { __typename: "MarketDataSet"; id: number };
};

export type DeleteMarketDataSetVariables = Exact<{
  id: Scalars["Int"]["input"];
}>;

export type DeleteMarketDataSet = { deleteMarketDataSet: boolean };

export type UpdateMeritGuidanceVariables = Exact<{
  data: UpdateMeritGuidanceInput;
}>;

export type UpdateMeritGuidance = {
  updateMeritGuide: {
    __typename: "MeritAdjustmentGuide";
    id: number;
    percent: number;
  };
};

export type DeletePerfRatingOptionVariables = Exact<{
  data: DeleteGuidanceItemInput;
}>;

export type DeletePerfRatingOption = { deletePerfRatingOption: boolean };

export type DeleteGuidanceRangeVariables = Exact<{
  data: DeleteGuidanceItemInput;
}>;

export type DeleteGuidanceRange = { deleteGuidanceRange: boolean };

export type BuildEmptyMatrixVariables = Exact<{
  data: BuildEmptyMatrixInput;
}>;

export type BuildEmptyMatrix = { buildEmptyMatrix: boolean };

export type ApplyGuidanceToAllVariables = Exact<{
  data: ApplyGuidanceToAllInput;
}>;

export type ApplyGuidanceToAll = {
  applyGuidanceToAll: {
    __typename: "EmplaceCompRecommendationsResponse";
    participants: Array<{
      __typename: "CompCycleParticipant";
      subjectId: number;
      compCycleId: number;
    }>;
  };
};

export type UpsertOfferVariables = Exact<{
  approverName?: InputMaybe<Scalars["String"]["input"]>;
  benefitsPackageId?: InputMaybe<Scalars["Int"]["input"]>;
  candidateId: Scalars["Int"]["input"];
  cash: OfferCashInput;
  closingMessage?: InputMaybe<Scalars["String"]["input"]>;
  customFieldAnswers?: InputMaybe<
    Array<CustomFieldAnswerInput> | CustomFieldAnswerInput
  >;
  decisionMakerName?: InputMaybe<Scalars["String"]["input"]>;
  equity: OfferEquityInput;
  jobCode?: InputMaybe<Scalars["String"]["input"]>;
  locationGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  managerName?: InputMaybe<Scalars["String"]["input"]>;
  marketId?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  offeredAt: Scalars["DateTime"]["input"];
  offerId?: InputMaybe<Scalars["Int"]["input"]>;
  outcomeDescription?: InputMaybe<Scalars["String"]["input"]>;
  positionId?: InputMaybe<Scalars["Int"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type UpsertOffer = {
  createOneOffer: { __typename: "Offer"; id: number };
};

export type CreateCandidateVariables = Exact<{
  candidateEmail: Scalars["String"]["input"];
  candidateName: Scalars["String"]["input"];
}>;

export type CreateCandidate = {
  createOneCandidate: {
    __typename: "Candidate";
    id: number;
    candidateName: string;
    candidateEmail: string;
  };
};

export type UpdateOrganizationPermissionSettingsVariables = Exact<{
  data: PermissionSettingsInput;
}>;

export type UpdateOrganizationPermissionSettings = {
  updatePermissionSettings: {
    __typename: "PermissionSettings";
    id: number;
    canRecruitersViewAnonymizedStatistics: boolean;
    teamCompensationAccess: TeamCompensationAccessType;
    anonymizedCompensationStatisticsAccess: AnonymizedCompensationStatisticsAccessType;
    anonymizedCompensationEmployeeThreshold: number;
    automatedManagerAccess: AutomatedManagerAccessType;
  };
};

export type upsertPortalConfigVariables = Exact<{
  data?: InputMaybe<PortalConfigInput>;
}>;

export type upsertPortalConfig = {
  upsertPortalConfig: {
    __typename: "PortalConfig";
    id: number;
    defaultExitOutcome: number;
    exitOutcomes: Array<number>;
    xAxisAnnotations: Array<string>;
  } | null;
};

export type CreatePositionVariables = Exact<{
  name: Scalars["String"]["input"];
  description?: InputMaybe<Scalars["String"]["input"]>;
  level: Scalars["Int"]["input"];
  ladderId: Scalars["Int"]["input"];
  jobCodes: Array<Scalars["String"]["input"]> | Scalars["String"]["input"];
}>;

export type CreatePosition = {
  createOnePosition: {
    __typename: "Position";
    id: number;
    name: string;
    description: string | null;
    level: number;
    jobCodes: Array<string>;
    createdAt: GraphQL_DateTime;
    updatedAt: GraphQL_DateTime;
    ladder: {
      __typename: "Ladder";
      id: number;
      name: string;
      positions: Array<{ __typename: "Position"; id: number; name: string }>;
    };
  };
};

export type UpdatePositionVariables = Exact<{
  positionId: Scalars["Int"]["input"];
  positionName?: InputMaybe<Scalars["String"]["input"]>;
  positionDescription?: InputMaybe<Scalars["String"]["input"]>;
  positionLevel?: InputMaybe<Scalars["Int"]["input"]>;
  positionJobCodes:
    | Array<Scalars["String"]["input"]>
    | Scalars["String"]["input"];
}>;

export type UpdatePosition = {
  updateOnePosition: {
    __typename: "Position";
    id: number;
    name: string;
    description: string | null;
    level: number;
    jobCodes: Array<string>;
  };
};

export type DeletePositionVariables = Exact<{
  positionId: Scalars["Int"]["input"];
}>;

export type DeletePosition = {
  deleteOnePosition: {
    __typename: "Position";
    id: number;
    name: string;
    ladder: {
      __typename: "Ladder";
      id: number;
      positions: Array<{ __typename: "Position"; id: number; name: string }>;
    };
  };
};

export type EmplaceBandsVariables = Exact<{
  cashBands: Array<CashBandInput> | CashBandInput;
  equityBands: Array<EquityBandInput> | EquityBandInput;
  positionId: Scalars["Int"]["input"];
  currencyCode: CurrencyCode;
  marketId: Scalars["Int"]["input"];
  locationGroupId?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type EmplaceBands = {
  emplaceCashBands: { __typename: "Position"; id: number };
  emplaceEquityBands: {
    __typename: "Position";
    id: number;
    unadjustedCashBands: Array<{
      __typename: "AdjustedCashBand";
      id: string;
      name: string;
      bandPoints: Array<{
        __typename: "AdjustedCashBandPoint";
        id: string;
        name: string;
        bandName: string;
        annualCashEquivalent: GraphQL_Money;
        hourlyCashEquivalent: GraphQL_Money;
        value: {
          __typename: "CashValue";
          annualRate: GraphQL_Money | null;
          hourlyRate: GraphQL_Money | null;
          currencyCode: CurrencyCode;
        };
      }>;
    }> | null;
    adjustedCashBands: Array<{
      __typename: "AdjustedCashBand";
      id: string;
      name: string;
      bandPoints: Array<{
        __typename: "AdjustedCashBandPoint";
        id: string;
        name: string;
        bandName: string;
        annualCashEquivalent: GraphQL_Money;
        hourlyCashEquivalent: GraphQL_Money;
        value: {
          __typename: "CashValue";
          annualRate: GraphQL_Money | null;
          hourlyRate: GraphQL_Money | null;
          currencyCode: CurrencyCode;
        };
      }>;
    }> | null;
    unadjustedEquityBands: Array<{
      __typename: "AdjustedEquityBand";
      id: string;
      name: string;
      bandPoints: Array<{
        __typename: "AdjustedEquityBandPoint";
        id: string;
        name: string;
        bandName: string;
        annualCashEquivalent: GraphQL_Money;
        hourlyCashEquivalent: GraphQL_Money;
        totalGrossValue: GraphQL_Money;
        totalUnits: number | null;
        vestingMonths: number;
        value:
          | {
              __typename: "CashValue";
              annualRate: GraphQL_Money | null;
              currencyCode: CurrencyCode;
            }
          | { __typename: "PercentValue"; decimalValue: number | null }
          | { __typename: "UnitValue"; unitValue: number | null };
        totalPercentOwnership: {
          __typename: "PercentValue";
          decimalValue: number | null;
        } | null;
      }>;
    }> | null;
    adjustedEquityBands: Array<{
      __typename: "AdjustedEquityBand";
      id: string;
      name: string;
      bandPoints: Array<{
        __typename: "AdjustedEquityBandPoint";
        id: string;
        name: string;
        bandName: string;
        annualCashEquivalent: GraphQL_Money;
        hourlyCashEquivalent: GraphQL_Money;
        totalGrossValue: GraphQL_Money;
        totalUnits: number | null;
        vestingMonths: number;
        value:
          | {
              __typename: "CashValue";
              annualRate: GraphQL_Money | null;
              currencyCode: CurrencyCode;
            }
          | { __typename: "PercentValue"; decimalValue: number | null }
          | { __typename: "UnitValue"; unitValue: number | null };
        totalPercentOwnership: {
          __typename: "PercentValue";
          decimalValue: number | null;
        } | null;
      }>;
    }> | null;
  };
};

export type ImportLevelingCodesVariables = Exact<{
  data: Array<ImportLevelingCodesInput> | ImportLevelingCodesInput;
}>;

export type ImportLevelingCodes = {
  importLevelingCodes: {
    __typename: "ImportLevelingCodesOutput";
    entryCount: number;
    organization: {
      __typename: "Organization";
      id: number;
      lastLevelingCodeUploadDate: GraphQL_DateTime | null;
      levelingCodeMappings: Array<{
        __typename: "LevelingCodeMapping";
        positionId: number;
        levelingCode: string;
      }>;
    };
  };
};

export type NounScopesFields = {
  __typename: "NounScopes";
  AccessControl: {
    __typename: "BaseNounScope";
    edit: { __typename: "BaseScope"; global: boolean } | null;
    view: { __typename: "BaseScope"; global: boolean } | null;
  };
  CashBand: {
    __typename: "MarketJobStructureNounScope";
    edit: { __typename: "BaseScope"; global: boolean } | null;
    view: {
      __typename: "MarketJobStructureScope";
      allMarkets: {
        __typename: "JobStructureScope";
        global: boolean;
        departmentIDs: Array<number>;
        ladderIDs: Array<number>;
        positionIDs: Array<number>;
      } | null;
      markets: Array<{
        __typename: "IndividualMarketJobStructureScope";
        marketId: number;
        scope: {
          __typename: "JobStructureScope";
          global: boolean;
          departmentIDs: Array<number>;
          ladderIDs: Array<number>;
          positionIDs: Array<number>;
        } | null;
      }>;
    } | null;
  };
  CompCycle: {
    __typename: "BaseNounScope";
    edit: { __typename: "BaseScope"; global: boolean } | null;
    view: { __typename: "BaseScope"; global: boolean } | null;
  };
  CompCycleBudget: {
    __typename: "BaseNounScope";
    edit: { __typename: "BaseScope"; global: boolean } | null;
    view: { __typename: "BaseScope"; global: boolean } | null;
  };
  CompRecommendation: {
    __typename: "EmployeeNounScope";
    edit: {
      __typename: "EmployeeScope";
      global: boolean;
      directReportIDs: Array<number>;
      indirectReportIDs: Array<number>;
      supportingManagerEmployeeIDs: Array<number | null> | null;
    } | null;
    view: {
      __typename: "EmployeeScope";
      global: boolean;
      directReportIDs: Array<number>;
      indirectReportIDs: Array<number>;
      supportingManagerEmployeeIDs: Array<number | null> | null;
    } | null;
  };
  CompStructure: {
    __typename: "BaseNounScope";
    edit: { __typename: "BaseScope"; global: boolean } | null;
    view: { __typename: "BaseScope"; global: boolean } | null;
  };
  Employee: {
    __typename: "EmployeeNounScope";
    edit: {
      __typename: "EmployeeScope";
      global: boolean;
      directReportIDs: Array<number>;
      indirectReportIDs: Array<number>;
      supportingManagerEmployeeIDs: Array<number | null> | null;
    } | null;
    view: {
      __typename: "EmployeeScope";
      global: boolean;
      directReportIDs: Array<number>;
      indirectReportIDs: Array<number>;
      supportingManagerEmployeeIDs: Array<number | null> | null;
    } | null;
  };
  EmployeeCashCompensation: {
    __typename: "EmployeeNounScope";
    edit: {
      __typename: "EmployeeScope";
      global: boolean;
      directReportIDs: Array<number>;
      indirectReportIDs: Array<number>;
      supportingManagerEmployeeIDs: Array<number | null> | null;
    } | null;
    view: {
      __typename: "EmployeeScope";
      global: boolean;
      directReportIDs: Array<number>;
      indirectReportIDs: Array<number>;
      supportingManagerEmployeeIDs: Array<number | null> | null;
    } | null;
  };
  EmployeeEquityCompensation: {
    __typename: "EmployeeNounScope";
    edit: {
      __typename: "EmployeeScope";
      global: boolean;
      directReportIDs: Array<number>;
      indirectReportIDs: Array<number>;
      supportingManagerEmployeeIDs: Array<number | null> | null;
    } | null;
    view: {
      __typename: "EmployeeScope";
      global: boolean;
      directReportIDs: Array<number>;
      indirectReportIDs: Array<number>;
      supportingManagerEmployeeIDs: Array<number | null> | null;
    } | null;
  };
  EquityBand: {
    __typename: "MarketJobStructureNounScope";
    edit: { __typename: "BaseScope"; global: boolean } | null;
    view: {
      __typename: "MarketJobStructureScope";
      allMarkets: {
        __typename: "JobStructureScope";
        global: boolean;
        departmentIDs: Array<number>;
        ladderIDs: Array<number>;
        positionIDs: Array<number>;
      } | null;
      markets: Array<{
        __typename: "IndividualMarketJobStructureScope";
        marketId: number;
        scope: {
          __typename: "JobStructureScope";
          global: boolean;
          departmentIDs: Array<number>;
          ladderIDs: Array<number>;
          positionIDs: Array<number>;
        } | null;
      }>;
    } | null;
  };
  Integration: {
    __typename: "BaseNounScope";
    edit: { __typename: "BaseScope"; global: boolean } | null;
    view: { __typename: "BaseScope"; global: boolean } | null;
  };
  JobStructure: {
    __typename: "JobStructureNounScope";
    edit: { __typename: "BaseScope"; global: boolean } | null;
    view: {
      __typename: "JobStructureScope";
      global: boolean;
      departmentIDs: Array<number>;
      ladderIDs: Array<number>;
      positionIDs: Array<number>;
    } | null;
  };
  Market: {
    __typename: "BaseNounScope";
    edit: { __typename: "BaseScope"; global: boolean } | null;
    view: { __typename: "BaseScope"; global: boolean } | null;
  };
  MarketData: {
    __typename: "BaseNounScope";
    edit: { __typename: "BaseScope"; global: boolean } | null;
    view: { __typename: "BaseScope"; global: boolean } | null;
  };
  Offer: {
    __typename: "OfferNounScope";
    edit: {
      __typename: "OfferScope";
      global: boolean;
      authored: boolean;
    } | null;
    view: {
      __typename: "OfferScope";
      global: boolean;
      authored: boolean;
    } | null;
  };
  Philosophy: {
    __typename: "BaseNounScope";
    edit: { __typename: "BaseScope"; global: boolean } | null;
    view: { __typename: "BaseScope"; global: boolean } | null;
  };
  SensitiveData: {
    __typename: "BaseNounScope";
    edit: { __typename: "BaseScope"; global: boolean } | null;
    view: { __typename: "BaseScope"; global: boolean } | null;
  };
};

export type UpdatePrimaryAccessGrantsVariables = Exact<{
  grantedUserId: Scalars["Int"]["input"];
  role: PrimaryRoleName;
}>;

export type UpdatePrimaryAccessGrants = {
  updateUserAccessGrantRole: {
    __typename: "User";
    id: number;
    name: string | null;
    email: string;
    userAccessGrant: {
      __typename: "UserAccessGrant";
      roleName: PrimaryRoleName;
      nounScopes: {
        __typename: "NounScopes";
        AccessControl: {
          __typename: "BaseNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: { __typename: "BaseScope"; global: boolean } | null;
        };
        CashBand: {
          __typename: "MarketJobStructureNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: {
            __typename: "MarketJobStructureScope";
            allMarkets: {
              __typename: "JobStructureScope";
              global: boolean;
              departmentIDs: Array<number>;
              ladderIDs: Array<number>;
              positionIDs: Array<number>;
            } | null;
            markets: Array<{
              __typename: "IndividualMarketJobStructureScope";
              marketId: number;
              scope: {
                __typename: "JobStructureScope";
                global: boolean;
                departmentIDs: Array<number>;
                ladderIDs: Array<number>;
                positionIDs: Array<number>;
              } | null;
            }>;
          } | null;
        };
        CompCycle: {
          __typename: "BaseNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: { __typename: "BaseScope"; global: boolean } | null;
        };
        CompCycleBudget: {
          __typename: "BaseNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: { __typename: "BaseScope"; global: boolean } | null;
        };
        CompRecommendation: {
          __typename: "EmployeeNounScope";
          edit: {
            __typename: "EmployeeScope";
            global: boolean;
            directReportIDs: Array<number>;
            indirectReportIDs: Array<number>;
            supportingManagerEmployeeIDs: Array<number | null> | null;
          } | null;
          view: {
            __typename: "EmployeeScope";
            global: boolean;
            directReportIDs: Array<number>;
            indirectReportIDs: Array<number>;
            supportingManagerEmployeeIDs: Array<number | null> | null;
          } | null;
        };
        CompStructure: {
          __typename: "BaseNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: { __typename: "BaseScope"; global: boolean } | null;
        };
        Employee: {
          __typename: "EmployeeNounScope";
          edit: {
            __typename: "EmployeeScope";
            global: boolean;
            directReportIDs: Array<number>;
            indirectReportIDs: Array<number>;
            supportingManagerEmployeeIDs: Array<number | null> | null;
          } | null;
          view: {
            __typename: "EmployeeScope";
            global: boolean;
            directReportIDs: Array<number>;
            indirectReportIDs: Array<number>;
            supportingManagerEmployeeIDs: Array<number | null> | null;
          } | null;
        };
        EmployeeCashCompensation: {
          __typename: "EmployeeNounScope";
          edit: {
            __typename: "EmployeeScope";
            global: boolean;
            directReportIDs: Array<number>;
            indirectReportIDs: Array<number>;
            supportingManagerEmployeeIDs: Array<number | null> | null;
          } | null;
          view: {
            __typename: "EmployeeScope";
            global: boolean;
            directReportIDs: Array<number>;
            indirectReportIDs: Array<number>;
            supportingManagerEmployeeIDs: Array<number | null> | null;
          } | null;
        };
        EmployeeEquityCompensation: {
          __typename: "EmployeeNounScope";
          edit: {
            __typename: "EmployeeScope";
            global: boolean;
            directReportIDs: Array<number>;
            indirectReportIDs: Array<number>;
            supportingManagerEmployeeIDs: Array<number | null> | null;
          } | null;
          view: {
            __typename: "EmployeeScope";
            global: boolean;
            directReportIDs: Array<number>;
            indirectReportIDs: Array<number>;
            supportingManagerEmployeeIDs: Array<number | null> | null;
          } | null;
        };
        EquityBand: {
          __typename: "MarketJobStructureNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: {
            __typename: "MarketJobStructureScope";
            allMarkets: {
              __typename: "JobStructureScope";
              global: boolean;
              departmentIDs: Array<number>;
              ladderIDs: Array<number>;
              positionIDs: Array<number>;
            } | null;
            markets: Array<{
              __typename: "IndividualMarketJobStructureScope";
              marketId: number;
              scope: {
                __typename: "JobStructureScope";
                global: boolean;
                departmentIDs: Array<number>;
                ladderIDs: Array<number>;
                positionIDs: Array<number>;
              } | null;
            }>;
          } | null;
        };
        Integration: {
          __typename: "BaseNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: { __typename: "BaseScope"; global: boolean } | null;
        };
        JobStructure: {
          __typename: "JobStructureNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: {
            __typename: "JobStructureScope";
            global: boolean;
            departmentIDs: Array<number>;
            ladderIDs: Array<number>;
            positionIDs: Array<number>;
          } | null;
        };
        Market: {
          __typename: "BaseNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: { __typename: "BaseScope"; global: boolean } | null;
        };
        MarketData: {
          __typename: "BaseNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: { __typename: "BaseScope"; global: boolean } | null;
        };
        Offer: {
          __typename: "OfferNounScope";
          edit: {
            __typename: "OfferScope";
            global: boolean;
            authored: boolean;
          } | null;
          view: {
            __typename: "OfferScope";
            global: boolean;
            authored: boolean;
          } | null;
        };
        Philosophy: {
          __typename: "BaseNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: { __typename: "BaseScope"; global: boolean } | null;
        };
        SensitiveData: {
          __typename: "BaseNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: { __typename: "BaseScope"; global: boolean } | null;
        };
      };
    } | null;
  };
};

export type EmplaceCompensationUserAccessGrantVariables = Exact<{
  grantedUserId: Scalars["Int"]["input"];
  marketId?: InputMaybe<Scalars["Int"]["input"]>;
  scope?: InputMaybe<ScopeInput>;
}>;

export type EmplaceCompensationUserAccessGrant = {
  updateUserAccessGrantCompBandAccess: {
    __typename: "User";
    id: number;
    name: string | null;
    email: string;
    userAccessGrant: {
      __typename: "UserAccessGrant";
      roleName: PrimaryRoleName;
      nounScopes: {
        __typename: "NounScopes";
        AccessControl: {
          __typename: "BaseNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: { __typename: "BaseScope"; global: boolean } | null;
        };
        CashBand: {
          __typename: "MarketJobStructureNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: {
            __typename: "MarketJobStructureScope";
            allMarkets: {
              __typename: "JobStructureScope";
              global: boolean;
              departmentIDs: Array<number>;
              ladderIDs: Array<number>;
              positionIDs: Array<number>;
            } | null;
            markets: Array<{
              __typename: "IndividualMarketJobStructureScope";
              marketId: number;
              scope: {
                __typename: "JobStructureScope";
                global: boolean;
                departmentIDs: Array<number>;
                ladderIDs: Array<number>;
                positionIDs: Array<number>;
              } | null;
            }>;
          } | null;
        };
        CompCycle: {
          __typename: "BaseNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: { __typename: "BaseScope"; global: boolean } | null;
        };
        CompCycleBudget: {
          __typename: "BaseNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: { __typename: "BaseScope"; global: boolean } | null;
        };
        CompRecommendation: {
          __typename: "EmployeeNounScope";
          edit: {
            __typename: "EmployeeScope";
            global: boolean;
            directReportIDs: Array<number>;
            indirectReportIDs: Array<number>;
            supportingManagerEmployeeIDs: Array<number | null> | null;
          } | null;
          view: {
            __typename: "EmployeeScope";
            global: boolean;
            directReportIDs: Array<number>;
            indirectReportIDs: Array<number>;
            supportingManagerEmployeeIDs: Array<number | null> | null;
          } | null;
        };
        CompStructure: {
          __typename: "BaseNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: { __typename: "BaseScope"; global: boolean } | null;
        };
        Employee: {
          __typename: "EmployeeNounScope";
          edit: {
            __typename: "EmployeeScope";
            global: boolean;
            directReportIDs: Array<number>;
            indirectReportIDs: Array<number>;
            supportingManagerEmployeeIDs: Array<number | null> | null;
          } | null;
          view: {
            __typename: "EmployeeScope";
            global: boolean;
            directReportIDs: Array<number>;
            indirectReportIDs: Array<number>;
            supportingManagerEmployeeIDs: Array<number | null> | null;
          } | null;
        };
        EmployeeCashCompensation: {
          __typename: "EmployeeNounScope";
          edit: {
            __typename: "EmployeeScope";
            global: boolean;
            directReportIDs: Array<number>;
            indirectReportIDs: Array<number>;
            supportingManagerEmployeeIDs: Array<number | null> | null;
          } | null;
          view: {
            __typename: "EmployeeScope";
            global: boolean;
            directReportIDs: Array<number>;
            indirectReportIDs: Array<number>;
            supportingManagerEmployeeIDs: Array<number | null> | null;
          } | null;
        };
        EmployeeEquityCompensation: {
          __typename: "EmployeeNounScope";
          edit: {
            __typename: "EmployeeScope";
            global: boolean;
            directReportIDs: Array<number>;
            indirectReportIDs: Array<number>;
            supportingManagerEmployeeIDs: Array<number | null> | null;
          } | null;
          view: {
            __typename: "EmployeeScope";
            global: boolean;
            directReportIDs: Array<number>;
            indirectReportIDs: Array<number>;
            supportingManagerEmployeeIDs: Array<number | null> | null;
          } | null;
        };
        EquityBand: {
          __typename: "MarketJobStructureNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: {
            __typename: "MarketJobStructureScope";
            allMarkets: {
              __typename: "JobStructureScope";
              global: boolean;
              departmentIDs: Array<number>;
              ladderIDs: Array<number>;
              positionIDs: Array<number>;
            } | null;
            markets: Array<{
              __typename: "IndividualMarketJobStructureScope";
              marketId: number;
              scope: {
                __typename: "JobStructureScope";
                global: boolean;
                departmentIDs: Array<number>;
                ladderIDs: Array<number>;
                positionIDs: Array<number>;
              } | null;
            }>;
          } | null;
        };
        Integration: {
          __typename: "BaseNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: { __typename: "BaseScope"; global: boolean } | null;
        };
        JobStructure: {
          __typename: "JobStructureNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: {
            __typename: "JobStructureScope";
            global: boolean;
            departmentIDs: Array<number>;
            ladderIDs: Array<number>;
            positionIDs: Array<number>;
          } | null;
        };
        Market: {
          __typename: "BaseNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: { __typename: "BaseScope"; global: boolean } | null;
        };
        MarketData: {
          __typename: "BaseNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: { __typename: "BaseScope"; global: boolean } | null;
        };
        Offer: {
          __typename: "OfferNounScope";
          edit: {
            __typename: "OfferScope";
            global: boolean;
            authored: boolean;
          } | null;
          view: {
            __typename: "OfferScope";
            global: boolean;
            authored: boolean;
          } | null;
        };
        Philosophy: {
          __typename: "BaseNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: { __typename: "BaseScope"; global: boolean } | null;
        };
        SensitiveData: {
          __typename: "BaseNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: { __typename: "BaseScope"; global: boolean } | null;
        };
      };
    } | null;
  };
};

export type ClearCompensationUserAccessGrantVariables = Exact<{
  grantedUserId: Scalars["Int"]["input"];
}>;

export type ClearCompensationUserAccessGrant = {
  clearUserAccessGrantCompBandAccess: {
    __typename: "User";
    id: number;
    name: string | null;
    email: string;
    userAccessGrant: {
      __typename: "UserAccessGrant";
      roleName: PrimaryRoleName;
      nounScopes: {
        __typename: "NounScopes";
        AccessControl: {
          __typename: "BaseNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: { __typename: "BaseScope"; global: boolean } | null;
        };
        CashBand: {
          __typename: "MarketJobStructureNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: {
            __typename: "MarketJobStructureScope";
            allMarkets: {
              __typename: "JobStructureScope";
              global: boolean;
              departmentIDs: Array<number>;
              ladderIDs: Array<number>;
              positionIDs: Array<number>;
            } | null;
            markets: Array<{
              __typename: "IndividualMarketJobStructureScope";
              marketId: number;
              scope: {
                __typename: "JobStructureScope";
                global: boolean;
                departmentIDs: Array<number>;
                ladderIDs: Array<number>;
                positionIDs: Array<number>;
              } | null;
            }>;
          } | null;
        };
        CompCycle: {
          __typename: "BaseNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: { __typename: "BaseScope"; global: boolean } | null;
        };
        CompCycleBudget: {
          __typename: "BaseNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: { __typename: "BaseScope"; global: boolean } | null;
        };
        CompRecommendation: {
          __typename: "EmployeeNounScope";
          edit: {
            __typename: "EmployeeScope";
            global: boolean;
            directReportIDs: Array<number>;
            indirectReportIDs: Array<number>;
            supportingManagerEmployeeIDs: Array<number | null> | null;
          } | null;
          view: {
            __typename: "EmployeeScope";
            global: boolean;
            directReportIDs: Array<number>;
            indirectReportIDs: Array<number>;
            supportingManagerEmployeeIDs: Array<number | null> | null;
          } | null;
        };
        CompStructure: {
          __typename: "BaseNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: { __typename: "BaseScope"; global: boolean } | null;
        };
        Employee: {
          __typename: "EmployeeNounScope";
          edit: {
            __typename: "EmployeeScope";
            global: boolean;
            directReportIDs: Array<number>;
            indirectReportIDs: Array<number>;
            supportingManagerEmployeeIDs: Array<number | null> | null;
          } | null;
          view: {
            __typename: "EmployeeScope";
            global: boolean;
            directReportIDs: Array<number>;
            indirectReportIDs: Array<number>;
            supportingManagerEmployeeIDs: Array<number | null> | null;
          } | null;
        };
        EmployeeCashCompensation: {
          __typename: "EmployeeNounScope";
          edit: {
            __typename: "EmployeeScope";
            global: boolean;
            directReportIDs: Array<number>;
            indirectReportIDs: Array<number>;
            supportingManagerEmployeeIDs: Array<number | null> | null;
          } | null;
          view: {
            __typename: "EmployeeScope";
            global: boolean;
            directReportIDs: Array<number>;
            indirectReportIDs: Array<number>;
            supportingManagerEmployeeIDs: Array<number | null> | null;
          } | null;
        };
        EmployeeEquityCompensation: {
          __typename: "EmployeeNounScope";
          edit: {
            __typename: "EmployeeScope";
            global: boolean;
            directReportIDs: Array<number>;
            indirectReportIDs: Array<number>;
            supportingManagerEmployeeIDs: Array<number | null> | null;
          } | null;
          view: {
            __typename: "EmployeeScope";
            global: boolean;
            directReportIDs: Array<number>;
            indirectReportIDs: Array<number>;
            supportingManagerEmployeeIDs: Array<number | null> | null;
          } | null;
        };
        EquityBand: {
          __typename: "MarketJobStructureNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: {
            __typename: "MarketJobStructureScope";
            allMarkets: {
              __typename: "JobStructureScope";
              global: boolean;
              departmentIDs: Array<number>;
              ladderIDs: Array<number>;
              positionIDs: Array<number>;
            } | null;
            markets: Array<{
              __typename: "IndividualMarketJobStructureScope";
              marketId: number;
              scope: {
                __typename: "JobStructureScope";
                global: boolean;
                departmentIDs: Array<number>;
                ladderIDs: Array<number>;
                positionIDs: Array<number>;
              } | null;
            }>;
          } | null;
        };
        Integration: {
          __typename: "BaseNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: { __typename: "BaseScope"; global: boolean } | null;
        };
        JobStructure: {
          __typename: "JobStructureNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: {
            __typename: "JobStructureScope";
            global: boolean;
            departmentIDs: Array<number>;
            ladderIDs: Array<number>;
            positionIDs: Array<number>;
          } | null;
        };
        Market: {
          __typename: "BaseNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: { __typename: "BaseScope"; global: boolean } | null;
        };
        MarketData: {
          __typename: "BaseNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: { __typename: "BaseScope"; global: boolean } | null;
        };
        Offer: {
          __typename: "OfferNounScope";
          edit: {
            __typename: "OfferScope";
            global: boolean;
            authored: boolean;
          } | null;
          view: {
            __typename: "OfferScope";
            global: boolean;
            authored: boolean;
          } | null;
        };
        Philosophy: {
          __typename: "BaseNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: { __typename: "BaseScope"; global: boolean } | null;
        };
        SensitiveData: {
          __typename: "BaseNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: { __typename: "BaseScope"; global: boolean } | null;
        };
      };
    } | null;
  };
};

export type UserInviteFields = {
  __typename: "UserInvitation";
  id: number;
  name: string;
  email: string;
  createdAt: GraphQL_DateTime;
  expiredAt: GraphQL_DateTime;
  deletedAt: GraphQL_DateTime | null;
  tokenUsedAt: GraphQL_DateTime | null;
  employee: {
    __typename: "Employee";
    id: number;
    latestUserInvite: { __typename: "UserInvitation"; id: number } | null;
  } | null;
};

export type RevokeInvitesVariables = Exact<{
  emails: Array<Scalars["String"]["input"]> | Scalars["String"]["input"];
}>;

export type RevokeInvites = {
  revokeUserInvites: Array<{
    __typename: "UserInvitation";
    id: number;
    name: string;
    email: string;
    createdAt: GraphQL_DateTime;
    expiredAt: GraphQL_DateTime;
    deletedAt: GraphQL_DateTime | null;
    tokenUsedAt: GraphQL_DateTime | null;
    employee: {
      __typename: "Employee";
      id: number;
      latestUserInvite: { __typename: "UserInvitation"; id: number } | null;
    } | null;
  }>;
};

export type UpsertUsersWithAccessGrantsVariables = Exact<{
  users: Array<UserWithAccessGrantsInput> | UserWithAccessGrantsInput;
}>;

export type UpsertUsersWithAccessGrants = {
  upsertUsersWithAccessGrants: Array<{
    __typename: "User";
    id: number;
    email: string;
    userAccessGrant: { __typename: "UserAccessGrant"; id: number } | null;
  }>;
};

export type InviteUsersToEmployeePortalVariables = Exact<{
  invites:
    | Array<InviteUsersToEmployeePortalInput>
    | InviteUsersToEmployeePortalInput;
}>;

export type InviteUsersToEmployeePortal = {
  inviteUsersToEmployeePortal: {
    __typename: "InviteUsersToEmployeePortalResponse";
    errors: Array<string> | null;
    invitations: Array<{
      __typename: "InviteUsersToEmployeePortalInvitations";
      invite: {
        __typename: "UserInvitation";
        id: number;
        name: string;
        email: string;
        createdAt: GraphQL_DateTime;
        expiredAt: GraphQL_DateTime;
        deletedAt: GraphQL_DateTime | null;
        tokenUsedAt: GraphQL_DateTime | null;
        employee: {
          __typename: "Employee";
          id: number;
          latestUserInvite: { __typename: "UserInvitation"; id: number } | null;
        } | null;
      } | null;
    }> | null;
  };
};

export type InviteUserToAssembleVariables = Exact<{
  invite: InviteUserToAssembleInput;
}>;

export type InviteUserToAssemble = {
  inviteUserToAssemble: {
    __typename: "InviteUserToAssembleResponse";
    errors: Array<string> | null;
    invitation: {
      __typename: "UserInvitation";
      email: string;
      id: number;
      name: string;
      createdAt: GraphQL_DateTime;
      expiredAt: GraphQL_DateTime;
      deletedAt: GraphQL_DateTime | null;
      tokenUsedAt: GraphQL_DateTime | null;
      employee: {
        __typename: "Employee";
        id: number;
        latestUserInvite: { __typename: "UserInvitation"; id: number } | null;
      } | null;
    } | null;
  };
};

export type UpdateUserIsDisabledVariables = Exact<{
  userId: Scalars["Int"]["input"];
  isDisabled: Scalars["Boolean"]["input"];
}>;

export type UpdateUserIsDisabled = {
  updateUserIsDisabled: {
    __typename: "User";
    id: number;
    isDisabled: boolean;
  } | null;
};

export type AddSupportedManagersToUserAccessGrantVariables = Exact<{
  data: AccessGrantSupportedManagerIdsInput;
}>;

export type AddSupportedManagersToUserAccessGrant = {
  updateUserAccessGrantSupportedManagerIds: {
    __typename: "UserAccessGrant";
    id: number;
  };
};

export type UpdateUserInvitationAccessGrantVariables = Exact<{
  data: UserInvitationAccessGrantRoleInput;
}>;

export type UpdateUserInvitationAccessGrant = {
  updateUserInvitationAccessGrantRole: {
    __typename: "UserInvitation";
    id: number;
    name: string;
    email: string;
    grant: GraphQL_Json;
  };
};

export type EmplaceCompensationUserInviteAccessGrantVariables = Exact<{
  grantedUserInvitationId: Scalars["Int"]["input"];
  marketId?: InputMaybe<Scalars["Int"]["input"]>;
  scope?: InputMaybe<ScopeInput>;
}>;

export type EmplaceCompensationUserInviteAccessGrant = {
  updateUserInvitationAccessGrantCompBandAccess: {
    __typename: "UserInvitation";
    id: number;
    name: string;
    email: string;
    grant: GraphQL_Json;
  };
};

export type ClearCompensationUserInvitationAccessGrantVariables = Exact<{
  grantedUserInvitationId: Scalars["Int"]["input"];
}>;

export type ClearCompensationUserInvitationAccessGrant = {
  clearUserInvitationAccessGrantCompBandAccess: {
    __typename: "UserInvitation";
    id: number;
    name: string;
    email: string;
    grant: GraphQL_Json;
  };
};

export type UpsertUserView_userView = {
  __typename: "UserView";
  id: number;
  json: GraphQL_Json;
};

export type UpsertUserViewVariables = Exact<{
  data: UpsertUserViewInput;
}>;

export type UpsertUserView = {
  upsertUserView: { __typename: "UserView"; id: number; json: GraphQL_Json };
};

export type Authentication_userInvitation = {
  __typename: "UserInvitation";
  id: number;
  email: string;
  name: string;
};

export type ValidateTokenAndEmailVariables = Exact<{
  email: Scalars["String"]["input"];
  token?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type ValidateTokenAndEmail = { validateTokenAndEmail: boolean };

export type GetUserInvitationFromTokenVariables = Exact<{
  token: Scalars["String"]["input"];
}>;

export type GetUserInvitationFromToken = {
  userInvitationFromToken: {
    __typename: "UserInvitation";
    id: number;
    email: string;
    name: string;
  } | null;
};

export type GetCsvDataWithDatabaseIdsVariables = Exact<{
  [key: string]: never;
}>;

export type GetCsvDataWithDatabaseIds = {
  exportOrgJobStructureWithBandsToCsv: {
    __typename: "CsvExport";
    data: string;
  };
};

export type BulkEditOrgQueryVariables = Exact<{ [key: string]: never }>;

export type BulkEditOrgQuery = {
  organization: {
    __typename: "Organization";
    id: number;
    name: string;
    users: Array<{
      __typename: "User";
      id: number;
      email: string;
      name: string | null;
    }>;
    departments: Array<{
      __typename: "Department";
      id: number;
      ladders: Array<{
        __typename: "Ladder";
        id: number;
        positions: Array<{ __typename: "Position"; id: number }>;
      }>;
    }>;
  };
  employees: Array<{
    __typename: "Employee";
    id: number;
    employeeNumber: string | null;
    email: string;
    personalEmail: string | null;
  }>;
  positions: Array<{
    __typename: "Position";
    id: number;
    name: string;
    level: number;
    ladder: {
      __typename: "Ladder";
      id: number;
      name: string;
      department: { __typename: "Department"; id: number; name: string };
    };
  }>;
};

export type AllocateBudgets_compCycleBudgetDraft = {
  __typename: "CompCycleBudgetDraft";
  employeeId: number | null;
  compCycleId: number;
  salary: GraphQL_Money | null;
  salaryMarket: GraphQL_Money | null;
  salaryPromotion: GraphQL_Money | null;
  salaryMerit: GraphQL_Money | null;
  equity: GraphQL_Money | null;
  bonus: GraphQL_Money | null;
  targetCommission: GraphQL_Money | null;
  targetRecurringBonus: GraphQL_Money | null;
  actualRecurringBonus: GraphQL_Money | null;
  employee: {
    __typename: "Employee";
    id: number;
    indirectReportsCount: number;
    displayName: string;
  } | null;
  reportsDrafts: Array<{
    __typename: "CompCycleBudgetDraft";
    compCycleId: number;
    employeeId: number | null;
    salary: GraphQL_Money | null;
    salaryMarket: GraphQL_Money | null;
    salaryPromotion: GraphQL_Money | null;
    salaryMerit: GraphQL_Money | null;
    equity: GraphQL_Money | null;
    bonus: GraphQL_Money | null;
    targetCommission: GraphQL_Money | null;
    targetRecurringBonus: GraphQL_Money | null;
    actualRecurringBonus: GraphQL_Money | null;
    employee: {
      __typename: "Employee";
      id: number;
      indirectReportsCount: number;
      directReportsCount: number;
      displayName: string;
      compCycleBudget: {
        __typename: "CompCycleBudget";
        compCycleId: number;
        employeeId: number | null;
        salary: GraphQL_Money | null;
        salaryMarket: GraphQL_Money | null;
        salaryPromotion: GraphQL_Money | null;
        salaryMerit: GraphQL_Money | null;
        equity: GraphQL_Money | null;
        bonus: GraphQL_Money | null;
        targetCommission: GraphQL_Money | null;
        targetRecurringBonus: GraphQL_Money | null;
        actualRecurringBonus: GraphQL_Money | null;
      } | null;
      user: { __typename: "User"; id: number; photoURL: string | null } | null;
      activeEmployment: {
        __typename: "Employment";
        id: number;
        jobTitle: string | null;
      } | null;
    } | null;
  }>;
};

export type AllocateBudgets_compCycleBudget = {
  __typename: "CompCycleBudget";
  compCycleId: number;
  employeeId: number | null;
  salary: GraphQL_Money | null;
  salaryMarket: GraphQL_Money | null;
  salaryPromotion: GraphQL_Money | null;
  salaryMerit: GraphQL_Money | null;
  equity: GraphQL_Money | null;
  bonus: GraphQL_Money | null;
  targetCommission: GraphQL_Money | null;
  targetRecurringBonus: GraphQL_Money | null;
  actualRecurringBonus: GraphQL_Money | null;
  employee: {
    __typename: "Employee";
    id: number;
    indirectReportsCount: number;
    displayName: string;
  } | null;
  reportsBudgets: Array<{
    __typename: "CompCycleBudget";
    compCycleId: number;
    employeeId: number | null;
    salary: GraphQL_Money | null;
    salaryMarket: GraphQL_Money | null;
    salaryPromotion: GraphQL_Money | null;
    salaryMerit: GraphQL_Money | null;
    equity: GraphQL_Money | null;
    bonus: GraphQL_Money | null;
    targetCommission: GraphQL_Money | null;
    targetRecurringBonus: GraphQL_Money | null;
    actualRecurringBonus: GraphQL_Money | null;
    employee: {
      __typename: "Employee";
      id: number;
      indirectReportsCount: number;
      directReportsCount: number;
      displayName: string;
      user: { __typename: "User"; id: number; photoURL: string | null } | null;
      activeEmployment: {
        __typename: "Employment";
        id: number;
        jobTitle: string | null;
      } | null;
    } | null;
  } | null>;
};

export type AllocateBudgets_compCycleSettings = {
  __typename: "CompCycle";
  id: number;
  name: string;
  endedAt: GraphQL_DateTime | null;
  allowSalary: boolean;
  allowEquity: boolean;
  allowBonus: boolean;
  allowTargetCommission: boolean;
  allowTargetRecurringBonus: boolean;
  allowActualRecurringBonus: boolean;
  allowSalaryMarket: boolean;
  allowSalaryPromotion: boolean;
  allowSalaryMerit: boolean;
  budgetGuides: Array<{ __typename: "BudgetGuide"; id: number }>;
};

export type AllocateBudgets_valuation = {
  __typename: "Valuation";
  id: number;
  fdso: number;
  valuation: GraphQL_Money;
};

export type GetCompCycleBudgetDraftAndSettingsVariables = Exact<{
  compCycleId: Scalars["Int"]["input"];
  employeeId?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type GetCompCycleBudgetDraftAndSettings = {
  compCycleBudgetDraft: {
    __typename: "CompCycleBudgetDraft";
    compCycleId: number;
    employeeId: number | null;
    salary: GraphQL_Money | null;
    salaryMarket: GraphQL_Money | null;
    salaryPromotion: GraphQL_Money | null;
    salaryMerit: GraphQL_Money | null;
    equity: GraphQL_Money | null;
    bonus: GraphQL_Money | null;
    targetCommission: GraphQL_Money | null;
    targetRecurringBonus: GraphQL_Money | null;
    actualRecurringBonus: GraphQL_Money | null;
    employee: {
      __typename: "Employee";
      id: number;
      indirectReportsCount: number;
      displayName: string;
    } | null;
    reportsDrafts: Array<{
      __typename: "CompCycleBudgetDraft";
      compCycleId: number;
      employeeId: number | null;
      salary: GraphQL_Money | null;
      salaryMarket: GraphQL_Money | null;
      salaryPromotion: GraphQL_Money | null;
      salaryMerit: GraphQL_Money | null;
      equity: GraphQL_Money | null;
      bonus: GraphQL_Money | null;
      targetCommission: GraphQL_Money | null;
      targetRecurringBonus: GraphQL_Money | null;
      actualRecurringBonus: GraphQL_Money | null;
      employee: {
        __typename: "Employee";
        id: number;
        indirectReportsCount: number;
        directReportsCount: number;
        displayName: string;
        compCycleBudget: {
          __typename: "CompCycleBudget";
          compCycleId: number;
          employeeId: number | null;
          salary: GraphQL_Money | null;
          salaryMarket: GraphQL_Money | null;
          salaryPromotion: GraphQL_Money | null;
          salaryMerit: GraphQL_Money | null;
          equity: GraphQL_Money | null;
          bonus: GraphQL_Money | null;
          targetCommission: GraphQL_Money | null;
          targetRecurringBonus: GraphQL_Money | null;
          actualRecurringBonus: GraphQL_Money | null;
        } | null;
        user: {
          __typename: "User";
          id: number;
          photoURL: string | null;
        } | null;
        activeEmployment: {
          __typename: "Employment";
          id: number;
          jobTitle: string | null;
        } | null;
      } | null;
    }>;
  };
  compCycleBudget: {
    __typename: "CompCycleBudget";
    compCycleId: number;
    employeeId: number | null;
    salary: GraphQL_Money | null;
    salaryMarket: GraphQL_Money | null;
    salaryPromotion: GraphQL_Money | null;
    salaryMerit: GraphQL_Money | null;
    equity: GraphQL_Money | null;
    bonus: GraphQL_Money | null;
    targetCommission: GraphQL_Money | null;
    targetRecurringBonus: GraphQL_Money | null;
    actualRecurringBonus: GraphQL_Money | null;
    employee: {
      __typename: "Employee";
      id: number;
      indirectReportsCount: number;
      displayName: string;
    } | null;
    reportsBudgets: Array<{
      __typename: "CompCycleBudget";
      compCycleId: number;
      employeeId: number | null;
      salary: GraphQL_Money | null;
      salaryMarket: GraphQL_Money | null;
      salaryPromotion: GraphQL_Money | null;
      salaryMerit: GraphQL_Money | null;
      equity: GraphQL_Money | null;
      bonus: GraphQL_Money | null;
      targetCommission: GraphQL_Money | null;
      targetRecurringBonus: GraphQL_Money | null;
      actualRecurringBonus: GraphQL_Money | null;
      employee: {
        __typename: "Employee";
        id: number;
        indirectReportsCount: number;
        directReportsCount: number;
        displayName: string;
        user: {
          __typename: "User";
          id: number;
          photoURL: string | null;
        } | null;
        activeEmployment: {
          __typename: "Employment";
          id: number;
          jobTitle: string | null;
        } | null;
      } | null;
    } | null>;
  } | null;
  compCycle: {
    __typename: "CompCycle";
    id: number;
    name: string;
    endedAt: GraphQL_DateTime | null;
    allowSalary: boolean;
    allowEquity: boolean;
    allowBonus: boolean;
    allowTargetCommission: boolean;
    allowTargetRecurringBonus: boolean;
    allowActualRecurringBonus: boolean;
    allowSalaryMarket: boolean;
    allowSalaryPromotion: boolean;
    allowSalaryMerit: boolean;
    budgetOverages: Array<{
      __typename: "BudgetOverage";
      overage: GraphQL_Money;
      variant: CompComponent;
      employee: {
        __typename: "Employee";
        id: number;
        displayName: string;
        user: { __typename: "User"; photoURL: string | null } | null;
        activeEmployment: {
          __typename: "Employment";
          id: number;
          jobTitle: string | null;
        } | null;
      } | null;
    }>;
    budgetGuides: Array<{ __typename: "BudgetGuide"; id: number }>;
  } | null;
  valuation: {
    __typename: "Valuation";
    id: number;
    fdso: number;
    valuation: GraphQL_Money;
  } | null;
};

export type GetBudgetOveragesVariables = Exact<{
  compCycleId: Scalars["Int"]["input"];
}>;

export type GetBudgetOverages = {
  compCycle: {
    __typename: "CompCycle";
    id: number;
    budgetOverages: Array<{
      __typename: "BudgetOverage";
      overage: GraphQL_Money;
      variant: CompComponent;
      employee: {
        __typename: "Employee";
        id: number;
        displayName: string;
        user: { __typename: "User"; photoURL: string | null } | null;
        activeEmployment: {
          __typename: "Employment";
          id: number;
          jobTitle: string | null;
        } | null;
      } | null;
    }>;
  } | null;
};

export type AllocateBudgetsHeader_compCycleBudgetDraft = {
  __typename: "CompCycleBudgetDraft";
  compCycleId: number;
  employeeId: number | null;
  employee: { __typename: "Employee"; id: number; displayName: string } | null;
  reportsDrafts: Array<{
    __typename: "CompCycleBudgetDraft";
    compCycleId: number;
    employeeId: number | null;
    salary: GraphQL_Money | null;
  }>;
};

export type AllocateBudgetsHeader_compCycleSettings = {
  __typename: "CompCycle";
  budgetGuides: Array<{ __typename: "BudgetGuide"; id: number }>;
};

export type OveragesSelector_budgetOverage = {
  __typename: "BudgetOverage";
  overage: GraphQL_Money;
  variant: CompComponent;
  employee: {
    __typename: "Employee";
    id: number;
    displayName: string;
    user: { __typename: "User"; photoURL: string | null } | null;
    activeEmployment: {
      __typename: "Employment";
      id: number;
      jobTitle: string | null;
    } | null;
  } | null;
};

export type BudgetAllocationTable_compCycleBudgetDraft = {
  __typename: "CompCycleBudgetDraft";
  compCycleId: number;
  employeeId: number | null;
  salary: GraphQL_Money | null;
  salaryMarket: GraphQL_Money | null;
  salaryPromotion: GraphQL_Money | null;
  salaryMerit: GraphQL_Money | null;
  equity: GraphQL_Money | null;
  bonus: GraphQL_Money | null;
  targetCommission: GraphQL_Money | null;
  targetRecurringBonus: GraphQL_Money | null;
  actualRecurringBonus: GraphQL_Money | null;
  employee: {
    __typename: "Employee";
    id: number;
    indirectReportsCount: number;
    displayName: string;
  } | null;
  reportsDrafts: Array<{
    __typename: "CompCycleBudgetDraft";
    compCycleId: number;
    employeeId: number | null;
    salary: GraphQL_Money | null;
    salaryMarket: GraphQL_Money | null;
    salaryPromotion: GraphQL_Money | null;
    salaryMerit: GraphQL_Money | null;
    equity: GraphQL_Money | null;
    bonus: GraphQL_Money | null;
    targetCommission: GraphQL_Money | null;
    targetRecurringBonus: GraphQL_Money | null;
    actualRecurringBonus: GraphQL_Money | null;
    employee: {
      __typename: "Employee";
      id: number;
      indirectReportsCount: number;
      directReportsCount: number;
      displayName: string;
      compCycleBudget: {
        __typename: "CompCycleBudget";
        compCycleId: number;
        employeeId: number | null;
        salary: GraphQL_Money | null;
        salaryMarket: GraphQL_Money | null;
        salaryPromotion: GraphQL_Money | null;
        salaryMerit: GraphQL_Money | null;
        equity: GraphQL_Money | null;
        bonus: GraphQL_Money | null;
        targetCommission: GraphQL_Money | null;
        targetRecurringBonus: GraphQL_Money | null;
        actualRecurringBonus: GraphQL_Money | null;
      } | null;
      user: { __typename: "User"; id: number; photoURL: string | null } | null;
      activeEmployment: {
        __typename: "Employment";
        id: number;
        jobTitle: string | null;
      } | null;
    } | null;
  }>;
};

export type BudgetAllocationTable_compCycleBudget = {
  __typename: "CompCycleBudget";
  compCycleId: number;
  employeeId: number | null;
  salary: GraphQL_Money | null;
  salaryMarket: GraphQL_Money | null;
  salaryPromotion: GraphQL_Money | null;
  salaryMerit: GraphQL_Money | null;
  equity: GraphQL_Money | null;
  bonus: GraphQL_Money | null;
  targetCommission: GraphQL_Money | null;
  targetRecurringBonus: GraphQL_Money | null;
  actualRecurringBonus: GraphQL_Money | null;
  employee: {
    __typename: "Employee";
    id: number;
    indirectReportsCount: number;
    displayName: string;
  } | null;
  reportsBudgets: Array<{
    __typename: "CompCycleBudget";
    compCycleId: number;
    employeeId: number | null;
    salary: GraphQL_Money | null;
    salaryMarket: GraphQL_Money | null;
    salaryPromotion: GraphQL_Money | null;
    salaryMerit: GraphQL_Money | null;
    equity: GraphQL_Money | null;
    bonus: GraphQL_Money | null;
    targetCommission: GraphQL_Money | null;
    targetRecurringBonus: GraphQL_Money | null;
    actualRecurringBonus: GraphQL_Money | null;
    employee: {
      __typename: "Employee";
      id: number;
      indirectReportsCount: number;
      directReportsCount: number;
      displayName: string;
      user: { __typename: "User"; id: number; photoURL: string | null } | null;
      activeEmployment: {
        __typename: "Employment";
        id: number;
        jobTitle: string | null;
      } | null;
    } | null;
  } | null>;
};

export type BudgetAllocationTable_compCycleSettings = {
  __typename: "CompCycle";
  id: number;
  allowSalaryMarket: boolean;
  allowSalaryPromotion: boolean;
  allowSalaryMerit: boolean;
  allowBonus: boolean;
  allowTargetCommission: boolean;
  allowTargetRecurringBonus: boolean;
  allowActualRecurringBonus: boolean;
};

export type BudgetAllocationTable_valuation = {
  __typename: "Valuation";
  id: number;
  fdso: number;
  valuation: GraphQL_Money;
};

export type BudgetNameCell_employee = {
  __typename: "Employee";
  id: number;
  displayName: string;
  user: { __typename: "User"; id: number; photoURL: string | null } | null;
  activeEmployment: {
    __typename: "Employment";
    id: number;
    jobTitle: string | null;
  } | null;
};

export type EmployeeAllocationRow_employee = {
  __typename: "Employee";
  id: number;
  directReportsCount: number;
  indirectReportsCount: number;
  displayName: string;
  user: { __typename: "User"; id: number; photoURL: string | null } | null;
  activeEmployment: {
    __typename: "Employment";
    id: number;
    jobTitle: string | null;
  } | null;
};

export type EmployeeAllocationRow_valuation = {
  __typename: "Valuation";
  id: number;
  fdso: number;
  valuation: GraphQL_Money;
};

export type GetReportingTierVariables = Exact<{
  compCycleId: Scalars["Int"]["input"];
  employeeId: Scalars["Int"]["input"];
}>;

export type GetReportingTier = {
  compCycleBudgetDraft: {
    __typename: "CompCycleBudgetDraft";
    compCycleId: number;
    employeeId: number | null;
    salary: GraphQL_Money | null;
    salaryMarket: GraphQL_Money | null;
    salaryPromotion: GraphQL_Money | null;
    salaryMerit: GraphQL_Money | null;
    equity: GraphQL_Money | null;
    bonus: GraphQL_Money | null;
    targetCommission: GraphQL_Money | null;
    targetRecurringBonus: GraphQL_Money | null;
    actualRecurringBonus: GraphQL_Money | null;
    employee: {
      __typename: "Employee";
      id: number;
      indirectReportsCount: number;
      displayName: string;
    } | null;
    reportsDrafts: Array<{
      __typename: "CompCycleBudgetDraft";
      compCycleId: number;
      employeeId: number | null;
      salary: GraphQL_Money | null;
      salaryMarket: GraphQL_Money | null;
      salaryPromotion: GraphQL_Money | null;
      salaryMerit: GraphQL_Money | null;
      equity: GraphQL_Money | null;
      bonus: GraphQL_Money | null;
      targetCommission: GraphQL_Money | null;
      targetRecurringBonus: GraphQL_Money | null;
      actualRecurringBonus: GraphQL_Money | null;
      employee: {
        __typename: "Employee";
        id: number;
        indirectReportsCount: number;
        directReportsCount: number;
        displayName: string;
        compCycleBudget: {
          __typename: "CompCycleBudget";
          compCycleId: number;
          employeeId: number | null;
          salary: GraphQL_Money | null;
          salaryMarket: GraphQL_Money | null;
          salaryPromotion: GraphQL_Money | null;
          salaryMerit: GraphQL_Money | null;
          equity: GraphQL_Money | null;
          bonus: GraphQL_Money | null;
          targetCommission: GraphQL_Money | null;
          targetRecurringBonus: GraphQL_Money | null;
          actualRecurringBonus: GraphQL_Money | null;
        } | null;
        user: {
          __typename: "User";
          id: number;
          photoURL: string | null;
        } | null;
        activeEmployment: {
          __typename: "Employment";
          id: number;
          jobTitle: string | null;
        } | null;
      } | null;
    }>;
  };
  compCycleBudget: {
    __typename: "CompCycleBudget";
    compCycleId: number;
    employeeId: number | null;
    salary: GraphQL_Money | null;
    salaryMarket: GraphQL_Money | null;
    salaryPromotion: GraphQL_Money | null;
    salaryMerit: GraphQL_Money | null;
    equity: GraphQL_Money | null;
    bonus: GraphQL_Money | null;
    targetCommission: GraphQL_Money | null;
    targetRecurringBonus: GraphQL_Money | null;
    actualRecurringBonus: GraphQL_Money | null;
    employee: {
      __typename: "Employee";
      id: number;
      indirectReportsCount: number;
      displayName: string;
    } | null;
    reportsBudgets: Array<{
      __typename: "CompCycleBudget";
      compCycleId: number;
      employeeId: number | null;
      salary: GraphQL_Money | null;
      salaryMarket: GraphQL_Money | null;
      salaryPromotion: GraphQL_Money | null;
      salaryMerit: GraphQL_Money | null;
      equity: GraphQL_Money | null;
      bonus: GraphQL_Money | null;
      targetCommission: GraphQL_Money | null;
      targetRecurringBonus: GraphQL_Money | null;
      actualRecurringBonus: GraphQL_Money | null;
      employee: {
        __typename: "Employee";
        id: number;
        indirectReportsCount: number;
        directReportsCount: number;
        displayName: string;
        user: {
          __typename: "User";
          id: number;
          photoURL: string | null;
        } | null;
        activeEmployment: {
          __typename: "Employment";
          id: number;
          jobTitle: string | null;
        } | null;
      } | null;
    } | null>;
  } | null;
  compCycle: {
    __typename: "CompCycle";
    id: number;
    allowSalaryMarket: boolean;
    allowSalaryPromotion: boolean;
    allowSalaryMerit: boolean;
    allowBonus: boolean;
    allowTargetCommission: boolean;
    allowTargetRecurringBonus: boolean;
    allowActualRecurringBonus: boolean;
  } | null;
};

export type ReportingTier_compCycleBudgetDraft = {
  __typename: "CompCycleBudgetDraft";
  compCycleId: number;
  employeeId: number | null;
  salary: GraphQL_Money | null;
  salaryMarket: GraphQL_Money | null;
  salaryPromotion: GraphQL_Money | null;
  salaryMerit: GraphQL_Money | null;
  equity: GraphQL_Money | null;
  bonus: GraphQL_Money | null;
  targetCommission: GraphQL_Money | null;
  targetRecurringBonus: GraphQL_Money | null;
  actualRecurringBonus: GraphQL_Money | null;
  employee: {
    __typename: "Employee";
    id: number;
    indirectReportsCount: number;
    displayName: string;
  } | null;
  reportsDrafts: Array<{
    __typename: "CompCycleBudgetDraft";
    compCycleId: number;
    employeeId: number | null;
    salary: GraphQL_Money | null;
    salaryMarket: GraphQL_Money | null;
    salaryPromotion: GraphQL_Money | null;
    salaryMerit: GraphQL_Money | null;
    equity: GraphQL_Money | null;
    bonus: GraphQL_Money | null;
    targetCommission: GraphQL_Money | null;
    targetRecurringBonus: GraphQL_Money | null;
    actualRecurringBonus: GraphQL_Money | null;
    employee: {
      __typename: "Employee";
      id: number;
      indirectReportsCount: number;
      directReportsCount: number;
      displayName: string;
      compCycleBudget: {
        __typename: "CompCycleBudget";
        compCycleId: number;
        employeeId: number | null;
        salary: GraphQL_Money | null;
        salaryMarket: GraphQL_Money | null;
        salaryPromotion: GraphQL_Money | null;
        salaryMerit: GraphQL_Money | null;
        equity: GraphQL_Money | null;
        bonus: GraphQL_Money | null;
        targetCommission: GraphQL_Money | null;
        targetRecurringBonus: GraphQL_Money | null;
        actualRecurringBonus: GraphQL_Money | null;
      } | null;
      user: { __typename: "User"; id: number; photoURL: string | null } | null;
      activeEmployment: {
        __typename: "Employment";
        id: number;
        jobTitle: string | null;
      } | null;
    } | null;
  }>;
};

export type ReportingTier_compCycleBudget = {
  __typename: "CompCycleBudget";
  compCycleId: number;
  employeeId: number | null;
  salary: GraphQL_Money | null;
  salaryMarket: GraphQL_Money | null;
  salaryPromotion: GraphQL_Money | null;
  salaryMerit: GraphQL_Money | null;
  equity: GraphQL_Money | null;
  bonus: GraphQL_Money | null;
  targetCommission: GraphQL_Money | null;
  targetRecurringBonus: GraphQL_Money | null;
  actualRecurringBonus: GraphQL_Money | null;
  employee: {
    __typename: "Employee";
    id: number;
    indirectReportsCount: number;
    displayName: string;
  } | null;
  reportsBudgets: Array<{
    __typename: "CompCycleBudget";
    compCycleId: number;
    employeeId: number | null;
    salary: GraphQL_Money | null;
    salaryMarket: GraphQL_Money | null;
    salaryPromotion: GraphQL_Money | null;
    salaryMerit: GraphQL_Money | null;
    equity: GraphQL_Money | null;
    bonus: GraphQL_Money | null;
    targetCommission: GraphQL_Money | null;
    targetRecurringBonus: GraphQL_Money | null;
    actualRecurringBonus: GraphQL_Money | null;
    employee: {
      __typename: "Employee";
      id: number;
      indirectReportsCount: number;
      directReportsCount: number;
      displayName: string;
      user: { __typename: "User"; id: number; photoURL: string | null } | null;
      activeEmployment: {
        __typename: "Employment";
        id: number;
        jobTitle: string | null;
      } | null;
    } | null;
  } | null>;
};

export type ReportingTier_compCycleSettings = {
  __typename: "CompCycle";
  id: number;
  allowSalaryMarket: boolean;
  allowSalaryPromotion: boolean;
  allowSalaryMerit: boolean;
  allowBonus: boolean;
  allowTargetCommission: boolean;
  allowTargetRecurringBonus: boolean;
  allowActualRecurringBonus: boolean;
};

export type ReportingTier_valuation = {
  __typename: "Valuation";
  id: number;
  fdso: number;
  valuation: GraphQL_Money;
};

export type TableHeader_compCycleBudgetDraft = {
  __typename: "CompCycleBudgetDraft";
  employeeId: number | null;
  employee: { __typename: "Employee"; id: number } | null;
};

export type TableHeader_compCycleSettings = {
  __typename: "CompCycle";
  allowSalary: boolean;
  allowEquity: boolean;
  allowBonus: boolean;
  allowTargetCommission: boolean;
  allowTargetRecurringBonus: boolean;
  allowActualRecurringBonus: boolean;
};

export type BudgetsVsRequests_manager = {
  __typename: "Employee";
  id: number;
  displayName: string;
  compCycleBudget: {
    __typename: "CompCycleBudget";
    compCycleId: number;
    employeeId: number | null;
    bonus: GraphQL_Money | null;
    actualRecurringBonus: GraphQL_Money | null;
    equity: GraphQL_Money | null;
    salary: GraphQL_Money | null;
    salaryMarket: GraphQL_Money | null;
    salaryMerit: GraphQL_Money | null;
    salaryPromotion: GraphQL_Money | null;
    targetCommission: GraphQL_Money | null;
    targetRecurringBonus: GraphQL_Money | null;
  } | null;
};

export type BudgetsVsRequests_reports = {
  __typename: "Employee";
  id: number;
  displayName: string;
  directReports: Array<{
    __typename: "Employee";
    id: number;
    compRecommendation: {
      __typename: "CompRecommendation";
      subjectId: number;
      compCycleId: number | null;
      reviewStatus: ReviewRequestStatus | null;
      latestSubmittedItems: Array<{
        __typename: "RecItem";
        id: number;
        submittedAt: GraphQL_DateTime | null;
        recommendationType: RecItemType;
        cashEquivalent: GraphQL_Money | null;
      }>;
    } | null;
  }>;
  indirectReports: Array<{
    __typename: "Employee";
    id: number;
    compRecommendation: {
      __typename: "CompRecommendation";
      subjectId: number;
      compCycleId: number | null;
      reviewStatus: ReviewRequestStatus | null;
      latestSubmittedItems: Array<{
        __typename: "RecItem";
        id: number;
        submittedAt: GraphQL_DateTime | null;
        recommendationType: RecItemType;
        cashEquivalent: GraphQL_Money | null;
      }>;
    } | null;
  }>;
  activeEmployment: {
    __typename: "Employment";
    id: number;
    salary: GraphQL_Money | null;
    jobTitle: string | null;
  } | null;
  compCycleBudget: {
    __typename: "CompCycleBudget";
    compCycleId: number;
    employeeId: number | null;
    bonus: GraphQL_Money | null;
    actualRecurringBonus: GraphQL_Money | null;
    equity: GraphQL_Money | null;
    salary: GraphQL_Money | null;
    salaryMarket: GraphQL_Money | null;
    salaryMerit: GraphQL_Money | null;
    salaryPromotion: GraphQL_Money | null;
    targetCommission: GraphQL_Money | null;
    targetRecurringBonus: GraphQL_Money | null;
  } | null;
  compRecommendation: {
    __typename: "CompRecommendation";
    subjectId: number;
    compCycleId: number | null;
    reviewStatus: ReviewRequestStatus | null;
    latestSubmittedItems: Array<{
      __typename: "RecItem";
      id: number;
      submittedAt: GraphQL_DateTime | null;
      recommendationType: RecItemType;
      cashEquivalent: GraphQL_Money | null;
    }>;
  } | null;
  user: { __typename: "User"; id: number; photoURL: string | null } | null;
};

export type BudgetsVsRequests_compCycleBudget = {
  __typename: "CompCycleBudget";
  compCycleId: number;
  employeeId: number | null;
  salary: GraphQL_Money | null;
  salaryMarket: GraphQL_Money | null;
  salaryMerit: GraphQL_Money | null;
  salaryPromotion: GraphQL_Money | null;
  equity: GraphQL_Money | null;
  bonus: GraphQL_Money | null;
  actualRecurringBonus: GraphQL_Money | null;
  targetCommission: GraphQL_Money | null;
  targetRecurringBonus: GraphQL_Money | null;
  employee: { __typename: "Employee"; displayName: string } | null;
};

export type BudgetsVsRequests_compCycleData = {
  __typename: "CompCycle";
  id: number;
  allowSalary: boolean;
  allowBonus: boolean;
  allowEquity: boolean;
  name: string;
  endedAt: GraphQL_DateTime | null;
  allowSalaryMarket: boolean;
  allowSalaryMerit: boolean;
  allowSalaryPromotion: boolean;
  allowActualRecurringBonus: boolean;
  allowTargetCommission: boolean;
  allowTargetRecurringBonus: boolean;
};

export type BudgetsVsRequests_valuation = {
  __typename: "Valuation";
  id: number;
  valuation: GraphQL_Money;
  fdso: number;
};

export type GetManagerVariables = Exact<{
  compCycleId: Scalars["Int"]["input"];
  employeeId: Scalars["Int"]["input"];
}>;

export type GetManager = {
  employee: {
    __typename: "Employee";
    id: number;
    displayName: string;
    compCycleBudget: {
      __typename: "CompCycleBudget";
      compCycleId: number;
      employeeId: number | null;
      bonus: GraphQL_Money | null;
      actualRecurringBonus: GraphQL_Money | null;
      equity: GraphQL_Money | null;
      salary: GraphQL_Money | null;
      salaryMarket: GraphQL_Money | null;
      salaryMerit: GraphQL_Money | null;
      salaryPromotion: GraphQL_Money | null;
      targetCommission: GraphQL_Money | null;
      targetRecurringBonus: GraphQL_Money | null;
    } | null;
  };
};

export type GetBudgetReportsVariables = Exact<{
  compCycleId: Scalars["Int"]["input"];
  currencyCode: CurrencyCode;
  employeeId?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type GetBudgetReports = {
  employees: Array<{
    __typename: "Employee";
    id: number;
    displayName: string;
    directReports: Array<{
      __typename: "Employee";
      id: number;
      compRecommendation: {
        __typename: "CompRecommendation";
        subjectId: number;
        compCycleId: number | null;
        reviewStatus: ReviewRequestStatus | null;
        latestSubmittedItems: Array<{
          __typename: "RecItem";
          id: number;
          submittedAt: GraphQL_DateTime | null;
          recommendationType: RecItemType;
          cashEquivalent: GraphQL_Money | null;
        }>;
      } | null;
    }>;
    indirectReports: Array<{
      __typename: "Employee";
      id: number;
      compRecommendation: {
        __typename: "CompRecommendation";
        subjectId: number;
        compCycleId: number | null;
        reviewStatus: ReviewRequestStatus | null;
        latestSubmittedItems: Array<{
          __typename: "RecItem";
          id: number;
          submittedAt: GraphQL_DateTime | null;
          recommendationType: RecItemType;
          cashEquivalent: GraphQL_Money | null;
        }>;
      } | null;
    }>;
    activeEmployment: {
      __typename: "Employment";
      id: number;
      salary: GraphQL_Money | null;
      jobTitle: string | null;
    } | null;
    compCycleBudget: {
      __typename: "CompCycleBudget";
      compCycleId: number;
      employeeId: number | null;
      bonus: GraphQL_Money | null;
      actualRecurringBonus: GraphQL_Money | null;
      equity: GraphQL_Money | null;
      salary: GraphQL_Money | null;
      salaryMarket: GraphQL_Money | null;
      salaryMerit: GraphQL_Money | null;
      salaryPromotion: GraphQL_Money | null;
      targetCommission: GraphQL_Money | null;
      targetRecurringBonus: GraphQL_Money | null;
    } | null;
    compRecommendation: {
      __typename: "CompRecommendation";
      subjectId: number;
      compCycleId: number | null;
      reviewStatus: ReviewRequestStatus | null;
      latestSubmittedItems: Array<{
        __typename: "RecItem";
        id: number;
        submittedAt: GraphQL_DateTime | null;
        recommendationType: RecItemType;
        cashEquivalent: GraphQL_Money | null;
      }>;
    } | null;
    user: { __typename: "User"; id: number; photoURL: string | null } | null;
  }>;
};

export type GetBudgetsAndRequestDataAndSettingsVariables = Exact<{
  compCycleId: Scalars["Int"]["input"];
  employeeId?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type GetBudgetsAndRequestDataAndSettings = {
  compCycleBudget: {
    __typename: "CompCycleBudget";
    compCycleId: number;
    employeeId: number | null;
    salary: GraphQL_Money | null;
    salaryMarket: GraphQL_Money | null;
    salaryMerit: GraphQL_Money | null;
    salaryPromotion: GraphQL_Money | null;
    equity: GraphQL_Money | null;
    bonus: GraphQL_Money | null;
    actualRecurringBonus: GraphQL_Money | null;
    targetCommission: GraphQL_Money | null;
    targetRecurringBonus: GraphQL_Money | null;
    employee: { __typename: "Employee"; displayName: string } | null;
  } | null;
  compCycle: {
    __typename: "CompCycle";
    id: number;
    allowSalary: boolean;
    allowBonus: boolean;
    allowEquity: boolean;
    name: string;
    endedAt: GraphQL_DateTime | null;
    allowSalaryMarket: boolean;
    allowSalaryMerit: boolean;
    allowSalaryPromotion: boolean;
    allowActualRecurringBonus: boolean;
    allowTargetCommission: boolean;
    allowTargetRecurringBonus: boolean;
  } | null;
  valuation: {
    __typename: "Valuation";
    id: number;
    valuation: GraphQL_Money;
    fdso: number;
  } | null;
};

export type Actual_compCycleBudget = {
  __typename: "CompCycleBudget";
  compCycleId: number;
  employeeId: number | null;
  bonus: GraphQL_Money | null;
  actualRecurringBonus: GraphQL_Money | null;
};

export type Actual_manager = {
  __typename: "Employee";
  id: number;
  displayName: string;
  compCycleBudget: {
    __typename: "CompCycleBudget";
    compCycleId: number;
    employeeId: number | null;
    bonus: GraphQL_Money | null;
    actualRecurringBonus: GraphQL_Money | null;
  } | null;
};

export type Actual_reports = {
  __typename: "Employee";
  id: number;
  displayName: string;
  directReports: Array<{
    __typename: "Employee";
    id: number;
    compRecommendation: {
      __typename: "CompRecommendation";
      subjectId: number;
      compCycleId: number | null;
      reviewStatus: ReviewRequestStatus | null;
      latestSubmittedItems: Array<{
        __typename: "RecItem";
        id: number;
        submittedAt: GraphQL_DateTime | null;
        recommendationType: RecItemType;
        cashEquivalent: GraphQL_Money | null;
      }>;
    } | null;
  }>;
  indirectReports: Array<{
    __typename: "Employee";
    id: number;
    compRecommendation: {
      __typename: "CompRecommendation";
      subjectId: number;
      compCycleId: number | null;
      reviewStatus: ReviewRequestStatus | null;
      latestSubmittedItems: Array<{
        __typename: "RecItem";
        id: number;
        submittedAt: GraphQL_DateTime | null;
        recommendationType: RecItemType;
        cashEquivalent: GraphQL_Money | null;
      }>;
    } | null;
  }>;
  activeEmployment: {
    __typename: "Employment";
    id: number;
    salary: GraphQL_Money | null;
    jobTitle: string | null;
  } | null;
  compCycleBudget: {
    __typename: "CompCycleBudget";
    compCycleId: number;
    employeeId: number | null;
    bonus: GraphQL_Money | null;
    actualRecurringBonus: GraphQL_Money | null;
  } | null;
  compRecommendation: {
    __typename: "CompRecommendation";
    subjectId: number;
    compCycleId: number | null;
    reviewStatus: ReviewRequestStatus | null;
    latestSubmittedItems: Array<{
      __typename: "RecItem";
      id: number;
      submittedAt: GraphQL_DateTime | null;
      recommendationType: RecItemType;
      cashEquivalent: GraphQL_Money | null;
    }>;
  } | null;
  user: { __typename: "User"; id: number; photoURL: string | null } | null;
};

export type Actual_compCycleData = {
  __typename: "CompCycle";
  id: number;
  allowBonus: boolean;
  allowActualRecurringBonus: boolean;
};

export type ActualRow_employee = {
  __typename: "Employee";
  id: number;
  displayName: string;
  activeEmployment: {
    __typename: "Employment";
    id: number;
    salary: GraphQL_Money | null;
    jobTitle: string | null;
  } | null;
  compCycleBudget: {
    __typename: "CompCycleBudget";
    compCycleId: number;
    employeeId: number | null;
    bonus: GraphQL_Money | null;
    actualRecurringBonus: GraphQL_Money | null;
  } | null;
  directReports: Array<{
    __typename: "Employee";
    id: number;
    compRecommendation: {
      __typename: "CompRecommendation";
      subjectId: number;
      compCycleId: number | null;
      reviewStatus: ReviewRequestStatus | null;
      latestSubmittedItems: Array<{
        __typename: "RecItem";
        id: number;
        submittedAt: GraphQL_DateTime | null;
        recommendationType: RecItemType;
        cashEquivalent: GraphQL_Money | null;
      }>;
    } | null;
  }>;
  indirectReports: Array<{
    __typename: "Employee";
    id: number;
    compRecommendation: {
      __typename: "CompRecommendation";
      subjectId: number;
      compCycleId: number | null;
      reviewStatus: ReviewRequestStatus | null;
      latestSubmittedItems: Array<{
        __typename: "RecItem";
        id: number;
        submittedAt: GraphQL_DateTime | null;
        recommendationType: RecItemType;
        cashEquivalent: GraphQL_Money | null;
      }>;
    } | null;
  }>;
  user: { __typename: "User"; id: number; photoURL: string | null } | null;
};

export type ActualRow_compCycleData = {
  __typename: "CompCycle";
  id: number;
  allowBonus: boolean;
  allowActualRecurringBonus: boolean;
};

export type ActualSummarizedRow_compCycleBudget = {
  __typename: "CompCycleBudget";
  compCycleId: number;
  employeeId: number | null;
  bonus: GraphQL_Money | null;
  actualRecurringBonus: GraphQL_Money | null;
};

export type ActualSummarizedRow_manager = {
  __typename: "Employee";
  id: number;
  displayName: string;
  compCycleBudget: {
    __typename: "CompCycleBudget";
    compCycleId: number;
    employeeId: number | null;
    bonus: GraphQL_Money | null;
    actualRecurringBonus: GraphQL_Money | null;
  } | null;
};

export type ActualSummarizedRow_reports = {
  __typename: "Employee";
  id: number;
  displayName: string;
  compRecommendation: {
    __typename: "CompRecommendation";
    subjectId: number;
    compCycleId: number | null;
    reviewStatus: ReviewRequestStatus | null;
    latestSubmittedItems: Array<{
      __typename: "RecItem";
      id: number;
      submittedAt: GraphQL_DateTime | null;
      recommendationType: RecItemType;
      cashEquivalent: GraphQL_Money | null;
    }>;
  } | null;
  compCycleBudget: {
    __typename: "CompCycleBudget";
    compCycleId: number;
    employeeId: number | null;
    bonus: GraphQL_Money | null;
    actualRecurringBonus: GraphQL_Money | null;
  } | null;
};

export type ActualSummarizedRow_compCycleData = {
  __typename: "CompCycle";
  id: number;
  allowBonus: boolean;
  allowActualRecurringBonus: boolean;
};

export type Equity_compCycleBudget = {
  __typename: "CompCycleBudget";
  compCycleId: number;
  employeeId: number | null;
  equity: GraphQL_Money | null;
};

export type Equity_manager = {
  __typename: "Employee";
  id: number;
  displayName: string;
  compCycleBudget: {
    __typename: "CompCycleBudget";
    compCycleId: number;
    employeeId: number | null;
    equity: GraphQL_Money | null;
  } | null;
};

export type Equity_reports = {
  __typename: "Employee";
  id: number;
  displayName: string;
  directReports: Array<{
    __typename: "Employee";
    id: number;
    compRecommendation: {
      __typename: "CompRecommendation";
      subjectId: number;
      compCycleId: number | null;
      reviewStatus: ReviewRequestStatus | null;
      latestSubmittedItems: Array<{
        __typename: "RecItem";
        id: number;
        submittedAt: GraphQL_DateTime | null;
        recommendationType: RecItemType;
        cashEquivalent: GraphQL_Money | null;
      }>;
    } | null;
  }>;
  indirectReports: Array<{
    __typename: "Employee";
    id: number;
    compRecommendation: {
      __typename: "CompRecommendation";
      subjectId: number;
      compCycleId: number | null;
      reviewStatus: ReviewRequestStatus | null;
      latestSubmittedItems: Array<{
        __typename: "RecItem";
        id: number;
        submittedAt: GraphQL_DateTime | null;
        recommendationType: RecItemType;
        cashEquivalent: GraphQL_Money | null;
      }>;
    } | null;
  }>;
  activeEmployment: {
    __typename: "Employment";
    id: number;
    salary: GraphQL_Money | null;
    jobTitle: string | null;
  } | null;
  compCycleBudget: {
    __typename: "CompCycleBudget";
    compCycleId: number;
    employeeId: number | null;
    equity: GraphQL_Money | null;
  } | null;
  compRecommendation: {
    __typename: "CompRecommendation";
    subjectId: number;
    compCycleId: number | null;
    reviewStatus: ReviewRequestStatus | null;
    latestSubmittedItems: Array<{
      __typename: "RecItem";
      id: number;
      submittedAt: GraphQL_DateTime | null;
      recommendationType: RecItemType;
      cashEquivalent: GraphQL_Money | null;
    }>;
  } | null;
  user: { __typename: "User"; id: number; photoURL: string | null } | null;
};

export type Equity_valuation = {
  __typename: "Valuation";
  id: number;
  valuation: GraphQL_Money;
  fdso: number;
};

export type Equity_compCycleData = { __typename: "CompCycle"; id: number };

export type EquityRow_employee = {
  __typename: "Employee";
  id: number;
  displayName: string;
  activeEmployment: {
    __typename: "Employment";
    id: number;
    salary: GraphQL_Money | null;
    jobTitle: string | null;
  } | null;
  compCycleBudget: {
    __typename: "CompCycleBudget";
    compCycleId: number;
    employeeId: number | null;
    equity: GraphQL_Money | null;
  } | null;
  directReports: Array<{
    __typename: "Employee";
    id: number;
    compRecommendation: {
      __typename: "CompRecommendation";
      subjectId: number;
      compCycleId: number | null;
      reviewStatus: ReviewRequestStatus | null;
      latestSubmittedItems: Array<{
        __typename: "RecItem";
        id: number;
        submittedAt: GraphQL_DateTime | null;
        recommendationType: RecItemType;
        cashEquivalent: GraphQL_Money | null;
      }>;
    } | null;
  }>;
  indirectReports: Array<{
    __typename: "Employee";
    id: number;
    compRecommendation: {
      __typename: "CompRecommendation";
      subjectId: number;
      compCycleId: number | null;
      reviewStatus: ReviewRequestStatus | null;
      latestSubmittedItems: Array<{
        __typename: "RecItem";
        id: number;
        submittedAt: GraphQL_DateTime | null;
        recommendationType: RecItemType;
        cashEquivalent: GraphQL_Money | null;
      }>;
    } | null;
  }>;
  user: { __typename: "User"; id: number; photoURL: string | null } | null;
};

export type EquityRow_valuation = {
  __typename: "Valuation";
  id: number;
  valuation: GraphQL_Money;
  fdso: number;
};

export type EquityRow_compCycleData = { __typename: "CompCycle"; id: number };

export type EquitySummarizedRow_compCycleBudget = {
  __typename: "CompCycleBudget";
  compCycleId: number;
  employeeId: number | null;
  equity: GraphQL_Money | null;
};

export type EquitySummarizedRow_manager = {
  __typename: "Employee";
  id: number;
  displayName: string;
  compCycleBudget: {
    __typename: "CompCycleBudget";
    compCycleId: number;
    employeeId: number | null;
    equity: GraphQL_Money | null;
  } | null;
};

export type EquitySummarizedRow_reports = {
  __typename: "Employee";
  id: number;
  displayName: string;
  compRecommendation: {
    __typename: "CompRecommendation";
    subjectId: number;
    compCycleId: number | null;
    reviewStatus: ReviewRequestStatus | null;
    latestSubmittedItems: Array<{
      __typename: "RecItem";
      id: number;
      submittedAt: GraphQL_DateTime | null;
      recommendationType: RecItemType;
      cashEquivalent: GraphQL_Money | null;
    }>;
  } | null;
  compCycleBudget: {
    __typename: "CompCycleBudget";
    compCycleId: number;
    employeeId: number | null;
    equity: GraphQL_Money | null;
  } | null;
};

export type EquitySummarizedRow_valuation = {
  __typename: "Valuation";
  id: number;
  valuation: GraphQL_Money;
  fdso: number;
};

export type GetLazyReportingActualTierDataVariables = Exact<{
  compCycleId: Scalars["Int"]["input"];
  employeeId: Scalars["Int"]["input"];
  currencyCode: CurrencyCode;
}>;

export type GetLazyReportingActualTierData = {
  compCycleBudget: {
    __typename: "CompCycleBudget";
    compCycleId: number;
    employeeId: number | null;
    bonus: GraphQL_Money | null;
    actualRecurringBonus: GraphQL_Money | null;
  } | null;
  employee: {
    __typename: "Employee";
    id: number;
    displayName: string;
    compCycleBudget: {
      __typename: "CompCycleBudget";
      compCycleId: number;
      employeeId: number | null;
      bonus: GraphQL_Money | null;
      actualRecurringBonus: GraphQL_Money | null;
    } | null;
  };
  employees: Array<{
    __typename: "Employee";
    id: number;
    displayName: string;
    directReports: Array<{
      __typename: "Employee";
      id: number;
      compRecommendation: {
        __typename: "CompRecommendation";
        subjectId: number;
        compCycleId: number | null;
        reviewStatus: ReviewRequestStatus | null;
        latestSubmittedItems: Array<{
          __typename: "RecItem";
          id: number;
          submittedAt: GraphQL_DateTime | null;
          recommendationType: RecItemType;
          cashEquivalent: GraphQL_Money | null;
        }>;
      } | null;
    }>;
    indirectReports: Array<{
      __typename: "Employee";
      id: number;
      compRecommendation: {
        __typename: "CompRecommendation";
        subjectId: number;
        compCycleId: number | null;
        reviewStatus: ReviewRequestStatus | null;
        latestSubmittedItems: Array<{
          __typename: "RecItem";
          id: number;
          submittedAt: GraphQL_DateTime | null;
          recommendationType: RecItemType;
          cashEquivalent: GraphQL_Money | null;
        }>;
      } | null;
    }>;
    activeEmployment: {
      __typename: "Employment";
      id: number;
      salary: GraphQL_Money | null;
      jobTitle: string | null;
    } | null;
    compCycleBudget: {
      __typename: "CompCycleBudget";
      compCycleId: number;
      employeeId: number | null;
      bonus: GraphQL_Money | null;
      actualRecurringBonus: GraphQL_Money | null;
    } | null;
    compRecommendation: {
      __typename: "CompRecommendation";
      subjectId: number;
      compCycleId: number | null;
      reviewStatus: ReviewRequestStatus | null;
      latestSubmittedItems: Array<{
        __typename: "RecItem";
        id: number;
        submittedAt: GraphQL_DateTime | null;
        recommendationType: RecItemType;
        cashEquivalent: GraphQL_Money | null;
      }>;
    } | null;
    user: { __typename: "User"; id: number; photoURL: string | null } | null;
  }>;
  compCycle: {
    __typename: "CompCycle";
    id: number;
    allowBonus: boolean;
    allowActualRecurringBonus: boolean;
  } | null;
};

export type GetLazyReportingEquityTierVariables = Exact<{
  compCycleId: Scalars["Int"]["input"];
  employeeId: Scalars["Int"]["input"];
  currencyCode: CurrencyCode;
}>;

export type GetLazyReportingEquityTier = {
  compCycleBudget: {
    __typename: "CompCycleBudget";
    compCycleId: number;
    employeeId: number | null;
    equity: GraphQL_Money | null;
  } | null;
  employee: {
    __typename: "Employee";
    id: number;
    displayName: string;
    compCycleBudget: {
      __typename: "CompCycleBudget";
      compCycleId: number;
      employeeId: number | null;
      equity: GraphQL_Money | null;
    } | null;
  };
  employees: Array<{
    __typename: "Employee";
    id: number;
    displayName: string;
    directReports: Array<{
      __typename: "Employee";
      id: number;
      compRecommendation: {
        __typename: "CompRecommendation";
        subjectId: number;
        compCycleId: number | null;
        reviewStatus: ReviewRequestStatus | null;
        latestSubmittedItems: Array<{
          __typename: "RecItem";
          id: number;
          submittedAt: GraphQL_DateTime | null;
          recommendationType: RecItemType;
          cashEquivalent: GraphQL_Money | null;
        }>;
      } | null;
    }>;
    indirectReports: Array<{
      __typename: "Employee";
      id: number;
      compRecommendation: {
        __typename: "CompRecommendation";
        subjectId: number;
        compCycleId: number | null;
        reviewStatus: ReviewRequestStatus | null;
        latestSubmittedItems: Array<{
          __typename: "RecItem";
          id: number;
          submittedAt: GraphQL_DateTime | null;
          recommendationType: RecItemType;
          cashEquivalent: GraphQL_Money | null;
        }>;
      } | null;
    }>;
    activeEmployment: {
      __typename: "Employment";
      id: number;
      salary: GraphQL_Money | null;
      jobTitle: string | null;
    } | null;
    compCycleBudget: {
      __typename: "CompCycleBudget";
      compCycleId: number;
      employeeId: number | null;
      equity: GraphQL_Money | null;
    } | null;
    compRecommendation: {
      __typename: "CompRecommendation";
      subjectId: number;
      compCycleId: number | null;
      reviewStatus: ReviewRequestStatus | null;
      latestSubmittedItems: Array<{
        __typename: "RecItem";
        id: number;
        submittedAt: GraphQL_DateTime | null;
        recommendationType: RecItemType;
        cashEquivalent: GraphQL_Money | null;
      }>;
    } | null;
    user: { __typename: "User"; id: number; photoURL: string | null } | null;
  }>;
  compCycle: { __typename: "CompCycle"; id: number } | null;
};

export type GetLazyReportingSalaryTierDataVariables = Exact<{
  compCycleId: Scalars["Int"]["input"];
  employeeId: Scalars["Int"]["input"];
  currencyCode: CurrencyCode;
}>;

export type GetLazyReportingSalaryTierData = {
  compCycleBudget: {
    __typename: "CompCycleBudget";
    compCycleId: number;
    employeeId: number | null;
    salary: GraphQL_Money | null;
    salaryMarket: GraphQL_Money | null;
    salaryMerit: GraphQL_Money | null;
    salaryPromotion: GraphQL_Money | null;
  } | null;
  employee: {
    __typename: "Employee";
    id: number;
    displayName: string;
    compCycleBudget: {
      __typename: "CompCycleBudget";
      compCycleId: number;
      employeeId: number | null;
      salary: GraphQL_Money | null;
      salaryMarket: GraphQL_Money | null;
      salaryMerit: GraphQL_Money | null;
      salaryPromotion: GraphQL_Money | null;
    } | null;
  };
  employees: Array<{
    __typename: "Employee";
    id: number;
    displayName: string;
    directReports: Array<{
      __typename: "Employee";
      id: number;
      compRecommendation: {
        __typename: "CompRecommendation";
        subjectId: number;
        compCycleId: number | null;
        reviewStatus: ReviewRequestStatus | null;
        latestSubmittedItems: Array<{
          __typename: "RecItem";
          id: number;
          submittedAt: GraphQL_DateTime | null;
          recommendationType: RecItemType;
          cashEquivalent: GraphQL_Money | null;
        }>;
      } | null;
    }>;
    indirectReports: Array<{
      __typename: "Employee";
      id: number;
      compRecommendation: {
        __typename: "CompRecommendation";
        subjectId: number;
        compCycleId: number | null;
        reviewStatus: ReviewRequestStatus | null;
        latestSubmittedItems: Array<{
          __typename: "RecItem";
          id: number;
          submittedAt: GraphQL_DateTime | null;
          recommendationType: RecItemType;
          cashEquivalent: GraphQL_Money | null;
        }>;
      } | null;
    }>;
    activeEmployment: {
      __typename: "Employment";
      id: number;
      salary: GraphQL_Money | null;
      jobTitle: string | null;
    } | null;
    compCycleBudget: {
      __typename: "CompCycleBudget";
      compCycleId: number;
      employeeId: number | null;
      salary: GraphQL_Money | null;
      salaryMarket: GraphQL_Money | null;
      salaryMerit: GraphQL_Money | null;
      salaryPromotion: GraphQL_Money | null;
    } | null;
    compRecommendation: {
      __typename: "CompRecommendation";
      subjectId: number;
      compCycleId: number | null;
      reviewStatus: ReviewRequestStatus | null;
      latestSubmittedItems: Array<{
        __typename: "RecItem";
        id: number;
        submittedAt: GraphQL_DateTime | null;
        recommendationType: RecItemType;
        cashEquivalent: GraphQL_Money | null;
      }>;
    } | null;
    user: { __typename: "User"; id: number; photoURL: string | null } | null;
  }>;
  compCycle: {
    __typename: "CompCycle";
    id: number;
    allowSalary: boolean;
    allowSalaryMarket: boolean;
    allowSalaryMerit: boolean;
    allowSalaryPromotion: boolean;
  } | null;
};

export type GetLazyReportingTargetTierDataVariables = Exact<{
  compCycleId: Scalars["Int"]["input"];
  employeeId: Scalars["Int"]["input"];
  currencyCode: CurrencyCode;
}>;

export type GetLazyReportingTargetTierData = {
  compCycleBudget: {
    __typename: "CompCycleBudget";
    compCycleId: number;
    employeeId: number | null;
    targetCommission: GraphQL_Money | null;
    targetRecurringBonus: GraphQL_Money | null;
  } | null;
  employee: {
    __typename: "Employee";
    id: number;
    displayName: string;
    compCycleBudget: {
      __typename: "CompCycleBudget";
      compCycleId: number;
      employeeId: number | null;
      targetCommission: GraphQL_Money | null;
      targetRecurringBonus: GraphQL_Money | null;
    } | null;
  };
  employees: Array<{
    __typename: "Employee";
    id: number;
    displayName: string;
    directReports: Array<{
      __typename: "Employee";
      id: number;
      compRecommendation: {
        __typename: "CompRecommendation";
        subjectId: number;
        compCycleId: number | null;
        reviewStatus: ReviewRequestStatus | null;
        latestSubmittedItems: Array<{
          __typename: "RecItem";
          id: number;
          submittedAt: GraphQL_DateTime | null;
          recommendationType: RecItemType;
          cashEquivalent: GraphQL_Money | null;
        }>;
      } | null;
    }>;
    indirectReports: Array<{
      __typename: "Employee";
      id: number;
      compRecommendation: {
        __typename: "CompRecommendation";
        subjectId: number;
        compCycleId: number | null;
        reviewStatus: ReviewRequestStatus | null;
        latestSubmittedItems: Array<{
          __typename: "RecItem";
          id: number;
          submittedAt: GraphQL_DateTime | null;
          recommendationType: RecItemType;
          cashEquivalent: GraphQL_Money | null;
        }>;
      } | null;
    }>;
    activeEmployment: {
      __typename: "Employment";
      id: number;
      salary: GraphQL_Money | null;
      jobTitle: string | null;
    } | null;
    compCycleBudget: {
      __typename: "CompCycleBudget";
      compCycleId: number;
      employeeId: number | null;
      targetCommission: GraphQL_Money | null;
      targetRecurringBonus: GraphQL_Money | null;
    } | null;
    compRecommendation: {
      __typename: "CompRecommendation";
      subjectId: number;
      compCycleId: number | null;
      reviewStatus: ReviewRequestStatus | null;
      latestSubmittedItems: Array<{
        __typename: "RecItem";
        id: number;
        submittedAt: GraphQL_DateTime | null;
        recommendationType: RecItemType;
        cashEquivalent: GraphQL_Money | null;
      }>;
    } | null;
    user: { __typename: "User"; id: number; photoURL: string | null } | null;
  }>;
  compCycle: {
    __typename: "CompCycle";
    id: number;
    allowTargetCommission: boolean;
    allowTargetRecurringBonus: boolean;
  } | null;
};

export type ReportingActualTier_compCycleBudget = {
  __typename: "CompCycleBudget";
  compCycleId: number;
  employeeId: number | null;
  bonus: GraphQL_Money | null;
  actualRecurringBonus: GraphQL_Money | null;
};

export type ReportingActualTier_manager = {
  __typename: "Employee";
  id: number;
  displayName: string;
  compCycleBudget: {
    __typename: "CompCycleBudget";
    compCycleId: number;
    employeeId: number | null;
    bonus: GraphQL_Money | null;
    actualRecurringBonus: GraphQL_Money | null;
  } | null;
};

export type ReportingActualTier_reports = {
  __typename: "Employee";
  id: number;
  displayName: string;
  directReports: Array<{
    __typename: "Employee";
    id: number;
    compRecommendation: {
      __typename: "CompRecommendation";
      subjectId: number;
      compCycleId: number | null;
      reviewStatus: ReviewRequestStatus | null;
      latestSubmittedItems: Array<{
        __typename: "RecItem";
        id: number;
        submittedAt: GraphQL_DateTime | null;
        recommendationType: RecItemType;
        cashEquivalent: GraphQL_Money | null;
      }>;
    } | null;
  }>;
  indirectReports: Array<{
    __typename: "Employee";
    id: number;
    compRecommendation: {
      __typename: "CompRecommendation";
      subjectId: number;
      compCycleId: number | null;
      reviewStatus: ReviewRequestStatus | null;
      latestSubmittedItems: Array<{
        __typename: "RecItem";
        id: number;
        submittedAt: GraphQL_DateTime | null;
        recommendationType: RecItemType;
        cashEquivalent: GraphQL_Money | null;
      }>;
    } | null;
  }>;
  activeEmployment: {
    __typename: "Employment";
    id: number;
    salary: GraphQL_Money | null;
    jobTitle: string | null;
  } | null;
  compCycleBudget: {
    __typename: "CompCycleBudget";
    compCycleId: number;
    employeeId: number | null;
    bonus: GraphQL_Money | null;
    actualRecurringBonus: GraphQL_Money | null;
  } | null;
  compRecommendation: {
    __typename: "CompRecommendation";
    subjectId: number;
    compCycleId: number | null;
    reviewStatus: ReviewRequestStatus | null;
    latestSubmittedItems: Array<{
      __typename: "RecItem";
      id: number;
      submittedAt: GraphQL_DateTime | null;
      recommendationType: RecItemType;
      cashEquivalent: GraphQL_Money | null;
    }>;
  } | null;
  user: { __typename: "User"; id: number; photoURL: string | null } | null;
};

export type ReportingActualTier_compCycleData = {
  __typename: "CompCycle";
  id: number;
  allowBonus: boolean;
  allowActualRecurringBonus: boolean;
};

export type ReportingEquityTier_compCycleBudget = {
  __typename: "CompCycleBudget";
  compCycleId: number;
  employeeId: number | null;
  equity: GraphQL_Money | null;
};

export type ReportingEquityTier_manager = {
  __typename: "Employee";
  id: number;
  displayName: string;
  compCycleBudget: {
    __typename: "CompCycleBudget";
    compCycleId: number;
    employeeId: number | null;
    equity: GraphQL_Money | null;
  } | null;
};

export type ReportingEquityTier_reports = {
  __typename: "Employee";
  id: number;
  displayName: string;
  directReports: Array<{
    __typename: "Employee";
    id: number;
    compRecommendation: {
      __typename: "CompRecommendation";
      subjectId: number;
      compCycleId: number | null;
      reviewStatus: ReviewRequestStatus | null;
      latestSubmittedItems: Array<{
        __typename: "RecItem";
        id: number;
        submittedAt: GraphQL_DateTime | null;
        recommendationType: RecItemType;
        cashEquivalent: GraphQL_Money | null;
      }>;
    } | null;
  }>;
  indirectReports: Array<{
    __typename: "Employee";
    id: number;
    compRecommendation: {
      __typename: "CompRecommendation";
      subjectId: number;
      compCycleId: number | null;
      reviewStatus: ReviewRequestStatus | null;
      latestSubmittedItems: Array<{
        __typename: "RecItem";
        id: number;
        submittedAt: GraphQL_DateTime | null;
        recommendationType: RecItemType;
        cashEquivalent: GraphQL_Money | null;
      }>;
    } | null;
  }>;
  activeEmployment: {
    __typename: "Employment";
    id: number;
    salary: GraphQL_Money | null;
    jobTitle: string | null;
  } | null;
  compCycleBudget: {
    __typename: "CompCycleBudget";
    compCycleId: number;
    employeeId: number | null;
    equity: GraphQL_Money | null;
  } | null;
  compRecommendation: {
    __typename: "CompRecommendation";
    subjectId: number;
    compCycleId: number | null;
    reviewStatus: ReviewRequestStatus | null;
    latestSubmittedItems: Array<{
      __typename: "RecItem";
      id: number;
      submittedAt: GraphQL_DateTime | null;
      recommendationType: RecItemType;
      cashEquivalent: GraphQL_Money | null;
    }>;
  } | null;
  user: { __typename: "User"; id: number; photoURL: string | null } | null;
};

export type ReportingEquityTier_valuation = {
  __typename: "Valuation";
  id: number;
  valuation: GraphQL_Money;
  fdso: number;
};

export type ReportingEquityTier_compCycleData = {
  __typename: "CompCycle";
  id: number;
};

export type ReportingSalaryTier_manager = {
  __typename: "Employee";
  id: number;
  displayName: string;
  compCycleBudget: {
    __typename: "CompCycleBudget";
    compCycleId: number;
    employeeId: number | null;
    salary: GraphQL_Money | null;
    salaryMarket: GraphQL_Money | null;
    salaryMerit: GraphQL_Money | null;
    salaryPromotion: GraphQL_Money | null;
  } | null;
};

export type ReportingSalaryTier_reports = {
  __typename: "Employee";
  id: number;
  displayName: string;
  directReports: Array<{
    __typename: "Employee";
    id: number;
    compRecommendation: {
      __typename: "CompRecommendation";
      subjectId: number;
      compCycleId: number | null;
      reviewStatus: ReviewRequestStatus | null;
      latestSubmittedItems: Array<{
        __typename: "RecItem";
        id: number;
        submittedAt: GraphQL_DateTime | null;
        recommendationType: RecItemType;
        cashEquivalent: GraphQL_Money | null;
      }>;
    } | null;
  }>;
  indirectReports: Array<{
    __typename: "Employee";
    id: number;
    compRecommendation: {
      __typename: "CompRecommendation";
      subjectId: number;
      compCycleId: number | null;
      reviewStatus: ReviewRequestStatus | null;
      latestSubmittedItems: Array<{
        __typename: "RecItem";
        id: number;
        submittedAt: GraphQL_DateTime | null;
        recommendationType: RecItemType;
        cashEquivalent: GraphQL_Money | null;
      }>;
    } | null;
  }>;
  activeEmployment: {
    __typename: "Employment";
    id: number;
    salary: GraphQL_Money | null;
    jobTitle: string | null;
  } | null;
  compCycleBudget: {
    __typename: "CompCycleBudget";
    compCycleId: number;
    employeeId: number | null;
    salary: GraphQL_Money | null;
    salaryMarket: GraphQL_Money | null;
    salaryMerit: GraphQL_Money | null;
    salaryPromotion: GraphQL_Money | null;
  } | null;
  compRecommendation: {
    __typename: "CompRecommendation";
    subjectId: number;
    compCycleId: number | null;
    reviewStatus: ReviewRequestStatus | null;
    latestSubmittedItems: Array<{
      __typename: "RecItem";
      id: number;
      submittedAt: GraphQL_DateTime | null;
      recommendationType: RecItemType;
      cashEquivalent: GraphQL_Money | null;
    }>;
  } | null;
  user: { __typename: "User"; id: number; photoURL: string | null } | null;
};

export type ReportingSalaryTier_compCycleBudget = {
  __typename: "CompCycleBudget";
  compCycleId: number;
  employeeId: number | null;
  salary: GraphQL_Money | null;
  salaryMarket: GraphQL_Money | null;
  salaryMerit: GraphQL_Money | null;
  salaryPromotion: GraphQL_Money | null;
};

export type ReportingSalaryTier_compCycleData = {
  __typename: "CompCycle";
  id: number;
  allowSalary: boolean;
  allowSalaryMarket: boolean;
  allowSalaryMerit: boolean;
  allowSalaryPromotion: boolean;
};

export type ReportingTargetTier_compCycleBudget = {
  __typename: "CompCycleBudget";
  compCycleId: number;
  employeeId: number | null;
  targetCommission: GraphQL_Money | null;
  targetRecurringBonus: GraphQL_Money | null;
};

export type ReportingTargetTier_manager = {
  __typename: "Employee";
  id: number;
  displayName: string;
  compCycleBudget: {
    __typename: "CompCycleBudget";
    compCycleId: number;
    employeeId: number | null;
    targetCommission: GraphQL_Money | null;
    targetRecurringBonus: GraphQL_Money | null;
  } | null;
};

export type ReportingTargetTier_reports = {
  __typename: "Employee";
  id: number;
  displayName: string;
  directReports: Array<{
    __typename: "Employee";
    id: number;
    compRecommendation: {
      __typename: "CompRecommendation";
      subjectId: number;
      compCycleId: number | null;
      reviewStatus: ReviewRequestStatus | null;
      latestSubmittedItems: Array<{
        __typename: "RecItem";
        id: number;
        submittedAt: GraphQL_DateTime | null;
        recommendationType: RecItemType;
        cashEquivalent: GraphQL_Money | null;
      }>;
    } | null;
  }>;
  indirectReports: Array<{
    __typename: "Employee";
    id: number;
    compRecommendation: {
      __typename: "CompRecommendation";
      subjectId: number;
      compCycleId: number | null;
      reviewStatus: ReviewRequestStatus | null;
      latestSubmittedItems: Array<{
        __typename: "RecItem";
        id: number;
        submittedAt: GraphQL_DateTime | null;
        recommendationType: RecItemType;
        cashEquivalent: GraphQL_Money | null;
      }>;
    } | null;
  }>;
  activeEmployment: {
    __typename: "Employment";
    id: number;
    salary: GraphQL_Money | null;
    jobTitle: string | null;
  } | null;
  compCycleBudget: {
    __typename: "CompCycleBudget";
    compCycleId: number;
    employeeId: number | null;
    targetCommission: GraphQL_Money | null;
    targetRecurringBonus: GraphQL_Money | null;
  } | null;
  compRecommendation: {
    __typename: "CompRecommendation";
    subjectId: number;
    compCycleId: number | null;
    reviewStatus: ReviewRequestStatus | null;
    latestSubmittedItems: Array<{
      __typename: "RecItem";
      id: number;
      submittedAt: GraphQL_DateTime | null;
      recommendationType: RecItemType;
      cashEquivalent: GraphQL_Money | null;
    }>;
  } | null;
  user: { __typename: "User"; id: number; photoURL: string | null } | null;
};

export type ReportingTargetTier_compCycleData = {
  __typename: "CompCycle";
  id: number;
  allowTargetCommission: boolean;
  allowTargetRecurringBonus: boolean;
};

export type Salary_manager = {
  __typename: "Employee";
  id: number;
  displayName: string;
  compCycleBudget: {
    __typename: "CompCycleBudget";
    compCycleId: number;
    employeeId: number | null;
    salary: GraphQL_Money | null;
    salaryMarket: GraphQL_Money | null;
    salaryMerit: GraphQL_Money | null;
    salaryPromotion: GraphQL_Money | null;
  } | null;
};

export type Salary_reports = {
  __typename: "Employee";
  id: number;
  displayName: string;
  directReports: Array<{
    __typename: "Employee";
    id: number;
    compRecommendation: {
      __typename: "CompRecommendation";
      subjectId: number;
      compCycleId: number | null;
      reviewStatus: ReviewRequestStatus | null;
      latestSubmittedItems: Array<{
        __typename: "RecItem";
        id: number;
        submittedAt: GraphQL_DateTime | null;
        recommendationType: RecItemType;
        cashEquivalent: GraphQL_Money | null;
      }>;
    } | null;
  }>;
  indirectReports: Array<{
    __typename: "Employee";
    id: number;
    compRecommendation: {
      __typename: "CompRecommendation";
      subjectId: number;
      compCycleId: number | null;
      reviewStatus: ReviewRequestStatus | null;
      latestSubmittedItems: Array<{
        __typename: "RecItem";
        id: number;
        submittedAt: GraphQL_DateTime | null;
        recommendationType: RecItemType;
        cashEquivalent: GraphQL_Money | null;
      }>;
    } | null;
  }>;
  activeEmployment: {
    __typename: "Employment";
    id: number;
    salary: GraphQL_Money | null;
    jobTitle: string | null;
  } | null;
  compCycleBudget: {
    __typename: "CompCycleBudget";
    compCycleId: number;
    employeeId: number | null;
    salary: GraphQL_Money | null;
    salaryMarket: GraphQL_Money | null;
    salaryMerit: GraphQL_Money | null;
    salaryPromotion: GraphQL_Money | null;
  } | null;
  compRecommendation: {
    __typename: "CompRecommendation";
    subjectId: number;
    compCycleId: number | null;
    reviewStatus: ReviewRequestStatus | null;
    latestSubmittedItems: Array<{
      __typename: "RecItem";
      id: number;
      submittedAt: GraphQL_DateTime | null;
      recommendationType: RecItemType;
      cashEquivalent: GraphQL_Money | null;
    }>;
  } | null;
  user: { __typename: "User"; id: number; photoURL: string | null } | null;
};

export type Salary_compCycleBudget = {
  __typename: "CompCycleBudget";
  compCycleId: number;
  employeeId: number | null;
  salary: GraphQL_Money | null;
  salaryMarket: GraphQL_Money | null;
  salaryMerit: GraphQL_Money | null;
  salaryPromotion: GraphQL_Money | null;
};

export type Salary_compCycleData = {
  __typename: "CompCycle";
  allowSalary: boolean;
  allowSalaryMarket: boolean;
  allowSalaryMerit: boolean;
  allowSalaryPromotion: boolean;
  id: number;
};

export type SalaryRow_report = {
  __typename: "Employee";
  id: number;
  compRecommendation: {
    __typename: "CompRecommendation";
    subjectId: number;
    compCycleId: number | null;
    reviewStatus: ReviewRequestStatus | null;
    latestSubmittedItems: Array<{
      __typename: "RecItem";
      id: number;
      submittedAt: GraphQL_DateTime | null;
      recommendationType: RecItemType;
      cashEquivalent: GraphQL_Money | null;
    }>;
  } | null;
};

export type SalaryRow_employee = {
  __typename: "Employee";
  id: number;
  displayName: string;
  activeEmployment: {
    __typename: "Employment";
    id: number;
    salary: GraphQL_Money | null;
    jobTitle: string | null;
  } | null;
  compCycleBudget: {
    __typename: "CompCycleBudget";
    compCycleId: number;
    employeeId: number | null;
    salary: GraphQL_Money | null;
    salaryMarket: GraphQL_Money | null;
    salaryMerit: GraphQL_Money | null;
    salaryPromotion: GraphQL_Money | null;
  } | null;
  directReports: Array<{
    __typename: "Employee";
    id: number;
    compRecommendation: {
      __typename: "CompRecommendation";
      subjectId: number;
      compCycleId: number | null;
      reviewStatus: ReviewRequestStatus | null;
      latestSubmittedItems: Array<{
        __typename: "RecItem";
        id: number;
        submittedAt: GraphQL_DateTime | null;
        recommendationType: RecItemType;
        cashEquivalent: GraphQL_Money | null;
      }>;
    } | null;
  }>;
  indirectReports: Array<{
    __typename: "Employee";
    id: number;
    compRecommendation: {
      __typename: "CompRecommendation";
      subjectId: number;
      compCycleId: number | null;
      reviewStatus: ReviewRequestStatus | null;
      latestSubmittedItems: Array<{
        __typename: "RecItem";
        id: number;
        submittedAt: GraphQL_DateTime | null;
        recommendationType: RecItemType;
        cashEquivalent: GraphQL_Money | null;
      }>;
    } | null;
  }>;
  user: { __typename: "User"; id: number; photoURL: string | null } | null;
};

export type SalaryRow_compCycleData = {
  __typename: "CompCycle";
  id: number;
  allowSalary: boolean;
  allowSalaryMarket: boolean;
  allowSalaryMerit: boolean;
  allowSalaryPromotion: boolean;
};

export type SalarySummarizedRow_compCycleBudget = {
  __typename: "CompCycleBudget";
  compCycleId: number;
  employeeId: number | null;
  salary: GraphQL_Money | null;
  salaryMarket: GraphQL_Money | null;
  salaryMerit: GraphQL_Money | null;
  salaryPromotion: GraphQL_Money | null;
};

export type SalarySummarizedRow_manager = {
  __typename: "Employee";
  id: number;
  displayName: string;
  compCycleBudget: {
    __typename: "CompCycleBudget";
    compCycleId: number;
    employeeId: number | null;
    salary: GraphQL_Money | null;
    salaryMarket: GraphQL_Money | null;
    salaryMerit: GraphQL_Money | null;
    salaryPromotion: GraphQL_Money | null;
  } | null;
};

export type SalarySummarizedRow_reports = {
  __typename: "Employee";
  id: number;
  displayName: string;
  compRecommendation: {
    __typename: "CompRecommendation";
    subjectId: number;
    compCycleId: number | null;
    reviewStatus: ReviewRequestStatus | null;
    latestSubmittedItems: Array<{
      __typename: "RecItem";
      id: number;
      submittedAt: GraphQL_DateTime | null;
      recommendationType: RecItemType;
      cashEquivalent: GraphQL_Money | null;
    }>;
  } | null;
  compCycleBudget: {
    __typename: "CompCycleBudget";
    compCycleId: number;
    employeeId: number | null;
    salary: GraphQL_Money | null;
    salaryMarket: GraphQL_Money | null;
    salaryMerit: GraphQL_Money | null;
    salaryPromotion: GraphQL_Money | null;
  } | null;
};

export type SalarySummarizedRow_compCycleData = {
  __typename: "CompCycle";
  id: number;
  allowSalary: boolean;
  allowSalaryMarket: boolean;
  allowSalaryMerit: boolean;
  allowSalaryPromotion: boolean;
};

export type TableHeader_compCycleData = {
  __typename: "CompCycle";
  allowSalary: boolean;
  allowEquity: boolean;
  allowBonus: boolean;
  allowTargetCommission: boolean;
  allowTargetRecurringBonus: boolean;
  allowActualRecurringBonus: boolean;
};

export type Target_compCycleBudget = {
  __typename: "CompCycleBudget";
  compCycleId: number;
  employeeId: number | null;
  targetCommission: GraphQL_Money | null;
  targetRecurringBonus: GraphQL_Money | null;
};

export type Target_manager = {
  __typename: "Employee";
  id: number;
  displayName: string;
  compCycleBudget: {
    __typename: "CompCycleBudget";
    compCycleId: number;
    employeeId: number | null;
    targetCommission: GraphQL_Money | null;
    targetRecurringBonus: GraphQL_Money | null;
  } | null;
};

export type Target_reports = {
  __typename: "Employee";
  id: number;
  displayName: string;
  directReports: Array<{
    __typename: "Employee";
    id: number;
    compRecommendation: {
      __typename: "CompRecommendation";
      subjectId: number;
      compCycleId: number | null;
      reviewStatus: ReviewRequestStatus | null;
      latestSubmittedItems: Array<{
        __typename: "RecItem";
        id: number;
        submittedAt: GraphQL_DateTime | null;
        recommendationType: RecItemType;
        cashEquivalent: GraphQL_Money | null;
      }>;
    } | null;
  }>;
  indirectReports: Array<{
    __typename: "Employee";
    id: number;
    compRecommendation: {
      __typename: "CompRecommendation";
      subjectId: number;
      compCycleId: number | null;
      reviewStatus: ReviewRequestStatus | null;
      latestSubmittedItems: Array<{
        __typename: "RecItem";
        id: number;
        submittedAt: GraphQL_DateTime | null;
        recommendationType: RecItemType;
        cashEquivalent: GraphQL_Money | null;
      }>;
    } | null;
  }>;
  activeEmployment: {
    __typename: "Employment";
    id: number;
    salary: GraphQL_Money | null;
    jobTitle: string | null;
  } | null;
  compCycleBudget: {
    __typename: "CompCycleBudget";
    compCycleId: number;
    employeeId: number | null;
    targetCommission: GraphQL_Money | null;
    targetRecurringBonus: GraphQL_Money | null;
  } | null;
  compRecommendation: {
    __typename: "CompRecommendation";
    subjectId: number;
    compCycleId: number | null;
    reviewStatus: ReviewRequestStatus | null;
    latestSubmittedItems: Array<{
      __typename: "RecItem";
      id: number;
      submittedAt: GraphQL_DateTime | null;
      recommendationType: RecItemType;
      cashEquivalent: GraphQL_Money | null;
    }>;
  } | null;
  user: { __typename: "User"; id: number; photoURL: string | null } | null;
};

export type Target_compCycleData = {
  __typename: "CompCycle";
  id: number;
  allowTargetCommission: boolean;
  allowTargetRecurringBonus: boolean;
};

export type TargetRow_employee = {
  __typename: "Employee";
  id: number;
  displayName: string;
  activeEmployment: {
    __typename: "Employment";
    id: number;
    salary: GraphQL_Money | null;
    jobTitle: string | null;
  } | null;
  compCycleBudget: {
    __typename: "CompCycleBudget";
    compCycleId: number;
    employeeId: number | null;
    targetCommission: GraphQL_Money | null;
    targetRecurringBonus: GraphQL_Money | null;
  } | null;
  directReports: Array<{
    __typename: "Employee";
    id: number;
    compRecommendation: {
      __typename: "CompRecommendation";
      subjectId: number;
      compCycleId: number | null;
      reviewStatus: ReviewRequestStatus | null;
      latestSubmittedItems: Array<{
        __typename: "RecItem";
        id: number;
        submittedAt: GraphQL_DateTime | null;
        recommendationType: RecItemType;
        cashEquivalent: GraphQL_Money | null;
      }>;
    } | null;
  }>;
  indirectReports: Array<{
    __typename: "Employee";
    id: number;
    compRecommendation: {
      __typename: "CompRecommendation";
      subjectId: number;
      compCycleId: number | null;
      reviewStatus: ReviewRequestStatus | null;
      latestSubmittedItems: Array<{
        __typename: "RecItem";
        id: number;
        submittedAt: GraphQL_DateTime | null;
        recommendationType: RecItemType;
        cashEquivalent: GraphQL_Money | null;
      }>;
    } | null;
  }>;
  user: { __typename: "User"; id: number; photoURL: string | null } | null;
};

export type TargetRow_compCycleData = {
  __typename: "CompCycle";
  id: number;
  allowTargetCommission: boolean;
  allowTargetRecurringBonus: boolean;
};

export type TargetSummarizedRow_compCycleBudget = {
  __typename: "CompCycleBudget";
  compCycleId: number;
  employeeId: number | null;
  targetCommission: GraphQL_Money | null;
  targetRecurringBonus: GraphQL_Money | null;
};

export type TargetSummarizedRow_manager = {
  __typename: "Employee";
  id: number;
  displayName: string;
  compCycleBudget: {
    __typename: "CompCycleBudget";
    compCycleId: number;
    employeeId: number | null;
    targetCommission: GraphQL_Money | null;
    targetRecurringBonus: GraphQL_Money | null;
  } | null;
};

export type TargetSummarizedRow_reports = {
  __typename: "Employee";
  id: number;
  displayName: string;
  compRecommendation: {
    __typename: "CompRecommendation";
    subjectId: number;
    compCycleId: number | null;
    reviewStatus: ReviewRequestStatus | null;
    latestSubmittedItems: Array<{
      __typename: "RecItem";
      id: number;
      submittedAt: GraphQL_DateTime | null;
      recommendationType: RecItemType;
      cashEquivalent: GraphQL_Money | null;
    }>;
  } | null;
  compCycleBudget: {
    __typename: "CompCycleBudget";
    compCycleId: number;
    employeeId: number | null;
    targetCommission: GraphQL_Money | null;
    targetRecurringBonus: GraphQL_Money | null;
  } | null;
};

export type TargetSummarizedRow_compCycleData = {
  __typename: "CompCycle";
  id: number;
  allowTargetCommission: boolean;
  allowTargetRecurringBonus: boolean;
};

export type CreateCompCycleQueryVariables = Exact<{ [key: string]: never }>;

export type CreateCompCycleQuery = {
  valuation: {
    __typename: "Valuation";
    id: number;
    valuation: GraphQL_Money;
    fdso: number;
  } | null;
};

export type EligibilityEditor_organization = {
  __typename: "Organization";
  id: number;
  compStructure: {
    __typename: "CompStructure";
    id: number;
    levels: Array<number>;
  } | null;
  departments: Array<{
    __typename: "Department";
    id: number;
    name: string;
    ladders: Array<{ __typename: "Ladder"; id: number; name: string }>;
  }>;
  locationGroups: Array<{
    __typename: "LocationGroup";
    id: number;
    name: string;
  }>;
};

export type EligibilityEditor_compCycle = {
  __typename: "CompCycle";
  perfRatings: Array<string>;
};

export type EligibilityEditor_employee = {
  __typename: "Employee";
  id: number;
  displayName: string;
  email: string;
};

export type GetEligibilityOptionsVariables = Exact<{
  compCycleId: Scalars["Int"]["input"];
}>;

export type GetEligibilityOptions = {
  organization: {
    __typename: "Organization";
    id: number;
    compStructure: {
      __typename: "CompStructure";
      id: number;
      levels: Array<number>;
    } | null;
    departments: Array<{
      __typename: "Department";
      id: number;
      name: string;
      ladders: Array<{ __typename: "Ladder"; id: number; name: string }>;
    }>;
    locationGroups: Array<{
      __typename: "LocationGroup";
      id: number;
      name: string;
    }>;
  };
  compCycle: {
    __typename: "CompCycle";
    id: number;
    eligibilityRules: Array<Array<GraphQL_Json>> | null;
    perfRatings: Array<string>;
  } | null;
  employees: Array<{
    __typename: "Employee";
    id: number;
    displayName: string;
    email: string;
  }>;
};

export type EligibleIneligibleTable_employee = {
  __typename: "Employee";
  id: number;
  lastCompChangeDate: GraphQL_Date | null;
  displayName: string;
  email: string;
  activeAt: GraphQL_DateTime | null;
  user: { __typename: "User"; id: number; photoURL: string | null } | null;
  manager: {
    __typename: "MinimalEmployee";
    id: number;
    displayName: string;
    user: { __typename: "User"; id: number; photoURL: string | null } | null;
  } | null;
  activeEmployment: {
    __typename: "Employment";
    id: number;
    activeAt: GraphQL_Date;
    position: {
      __typename: "Position";
      id: number;
      name: string;
      ladder: {
        __typename: "Ladder";
        id: number;
        name: string;
        department: { __typename: "Department"; id: number; name: string };
      };
    } | null;
  } | null;
};

export type EligibleIneligibleTable_perfRating = {
  __typename: "EmployeeCompCyclePerfRating";
  id: number;
  perfRating: string | null;
};

export type EmailRuleEditor_employee = {
  __typename: "Employee";
  id: number;
  displayName: string;
  email: string;
};

export type MultiRuleEditor_organization = {
  __typename: "Organization";
  id: number;
  compStructure: {
    __typename: "CompStructure";
    id: number;
    levels: Array<number>;
  } | null;
  departments: Array<{
    __typename: "Department";
    id: number;
    name: string;
    ladders: Array<{ __typename: "Ladder"; id: number; name: string }>;
  }>;
  locationGroups: Array<{
    __typename: "LocationGroup";
    id: number;
    name: string;
  }>;
};

export type MultiRuleEditor_compCycle = {
  __typename: "CompCycle";
  perfRatings: Array<string>;
};

export type MultiRuleEditor_employee = {
  __typename: "Employee";
  id: number;
  displayName: string;
  email: string;
};

export type GetPotentialParticipantsVariables = Exact<{
  compCycleId: Scalars["Int"]["input"];
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  eligibleOrIneligible?: InputMaybe<Scalars["String"]["input"]>;
  displayName?: InputMaybe<Scalars["String"]["input"]>;
  order?: InputMaybe<Scalars["String"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetPotentialParticipants = {
  compCycle: {
    __typename: "CompCycle";
    id: number;
    potentialParticipants: {
      __typename: "PotentialParticipantsResult";
      eligible: Array<{
        __typename: "Employee";
        id: number;
        lastCompChangeDate: GraphQL_Date | null;
        displayName: string;
        email: string;
        activeAt: GraphQL_DateTime | null;
        perfRating: {
          __typename: "EmployeeCompCyclePerfRating";
          id: number;
          perfRating: string | null;
        } | null;
        user: {
          __typename: "User";
          id: number;
          photoURL: string | null;
        } | null;
        manager: {
          __typename: "MinimalEmployee";
          id: number;
          displayName: string;
          user: {
            __typename: "User";
            id: number;
            photoURL: string | null;
          } | null;
        } | null;
        activeEmployment: {
          __typename: "Employment";
          id: number;
          activeAt: GraphQL_Date;
          position: {
            __typename: "Position";
            id: number;
            name: string;
            ladder: {
              __typename: "Ladder";
              id: number;
              name: string;
              department: {
                __typename: "Department";
                id: number;
                name: string;
              };
            };
          } | null;
        } | null;
      }>;
      ineligible: Array<{
        __typename: "Employee";
        id: number;
        lastCompChangeDate: GraphQL_Date | null;
        displayName: string;
        email: string;
        activeAt: GraphQL_DateTime | null;
        perfRating: {
          __typename: "EmployeeCompCyclePerfRating";
          id: number;
          perfRating: string | null;
        } | null;
        user: {
          __typename: "User";
          id: number;
          photoURL: string | null;
        } | null;
        manager: {
          __typename: "MinimalEmployee";
          id: number;
          displayName: string;
          user: {
            __typename: "User";
            id: number;
            photoURL: string | null;
          } | null;
        } | null;
        activeEmployment: {
          __typename: "Employment";
          id: number;
          activeAt: GraphQL_Date;
          position: {
            __typename: "Position";
            id: number;
            name: string;
            ladder: {
              __typename: "Ladder";
              id: number;
              name: string;
              department: {
                __typename: "Department";
                id: number;
                name: string;
              };
            };
          } | null;
        } | null;
      }>;
      counts: {
        __typename: "PotentialParticipantsCount";
        eligible: number;
        ineligible: number;
      };
    };
  } | null;
};

export type ParticipantsTable_perfRating = {
  __typename: "EmployeeCompCyclePerfRating";
  id: number;
  perfRating: string | null;
};

export type ParticipantsTable_employee = {
  __typename: "Employee";
  id: number;
  lastCompChangeDate: GraphQL_Date | null;
  displayName: string;
  email: string;
  activeAt: GraphQL_DateTime | null;
  user: { __typename: "User"; id: number; photoURL: string | null } | null;
  manager: {
    __typename: "MinimalEmployee";
    id: number;
    displayName: string;
    user: { __typename: "User"; id: number; photoURL: string | null } | null;
  } | null;
  activeEmployment: {
    __typename: "Employment";
    id: number;
    activeAt: GraphQL_Date;
    position: {
      __typename: "Position";
      id: number;
      name: string;
      ladder: {
        __typename: "Ladder";
        id: number;
        name: string;
        department: { __typename: "Department"; id: number; name: string };
      };
    } | null;
  } | null;
};

export type ApprovalsAndPhases_employee = {
  __typename: "Employee2";
  id: number;
  displayName: string;
  parentId: number | null;
  _directSubordinates: number;
  _totalSubordinates: number;
  activeEmployment: {
    __typename: "Employment2";
    id: number;
    jobTitle: string | null;
  } | null;
};

export type ApproverCard_employee = {
  __typename: "Employee2";
  id: number;
  displayName: string;
  activeEmployment: {
    __typename: "Employment2";
    id: number;
    jobTitle: string | null;
  } | null;
  location: { __typename: "LocationGroup2"; id: number; name: string } | null;
  user: { __typename: "User2"; id: number; photoURL: string | null } | null;
};

export type CompCycleHomeBoundaryQueryVariables = Exact<{
  compCycleId: Scalars["Int"]["input"];
}>;

export type CompCycleHomeBoundaryQuery = {
  compCycle2: {
    __typename: "CompCycle2";
    id: number;
    phases: Array<{ __typename: "CompCyclePhase2"; id: number }>;
  } | null;
};

export type CompCycleReviewPhases_compCycle = {
  __typename: "CompCycle2";
  id: number;
  name: string;
  closeDate: GraphQL_Date | null;
  phases: Array<{
    __typename: "CompCyclePhase2";
    id: number;
    phaseOrder: number;
    startDate: GraphQL_Date;
    isMyPhase: boolean;
    compCyclePhaseAssignments: Array<{
      __typename: "CompCyclePhaseAssignment2";
      id: number;
      phaseId: number;
      status: PhaseTimelineStatus | null;
      assigneeId: number;
    } | null>;
  }>;
  currentPhase: {
    __typename: "CompCyclePhase2";
    id: number;
    phaseOrder: number;
  } | null;
};

export type CompCycleReviewPhases_employee = {
  __typename: "Employee2";
  id: number;
  displayName: string;
  managerIds: Array<number> | null;
  managerId: number | null;
  location: { __typename: "LocationGroup2"; id: number; name: string } | null;
  activeEmployment: {
    __typename: "Employment2";
    id: number;
    jobTitle: string | null;
  } | null;
  user: { __typename: "User2"; id: number; photoURL: string | null } | null;
};

export type CompCycleReviewPhasesBoundary_participant = {
  __typename: "CompCycleParticipant";
  subjectId: number;
  compCycleId: number;
  subject: {
    __typename: "Employee2";
    id: number;
    managerId: number | null;
    managerIds: Array<number> | null;
  };
  compRecommendation: {
    __typename: "CompRecommendation2";
    subjectId: number;
    compCycleId: number;
    reviewStatus: CompRecommendationStatus;
    latestSubmittedItems: Array<{
      __typename: "RecItem2";
      id: number;
      submittedAt: GraphQL_DateTime | null;
      author: { __typename: "User2"; id: number; name: string | null };
    }>;
    latestSubmittedReviews: Array<{
      __typename: "RecReview2";
      id: number;
      status: RecReviewStatus;
      author: { __typename: "User2"; id: number };
    }>;
  } | null;
};

export type CompCycleReviewPhasesBoundary_phase = {
  __typename: "CompCyclePhase2";
  id: number;
  compCyclePhaseAssignments: Array<{
    __typename: "CompCyclePhaseAssignment2";
    id: number;
    status: PhaseTimelineStatus | null;
    assigneeId: number;
    employee: {
      __typename: "Employee2";
      id: number;
      displayName: string;
      managerIds: Array<number> | null;
      managerId: number | null;
      location: {
        __typename: "LocationGroup2";
        id: number;
        name: string;
      } | null;
      activeEmployment: {
        __typename: "Employment2";
        id: number;
        jobTitle: string | null;
      } | null;
      user: { __typename: "User2"; id: number; photoURL: string | null } | null;
    };
    recommendees: Array<{
      __typename: "CompCyclePhaseAssignmentSubject2";
      id: number;
      participant: {
        __typename: "CompCyclePhaseAssignmentSubjectParticipant";
        subjectId: number;
        compCycleId: number;
        managerId: number | null;
      };
    }>;
    reviewees: Array<{
      __typename: "CompCyclePhaseAssignmentSubject2";
      id: number;
      participant: {
        __typename: "CompCyclePhaseAssignmentSubjectParticipant";
        subjectId: number;
        compCycleId: number;
        managerId: number | null;
      };
    }>;
  } | null>;
};

export type CompCycleReviewPhasesBoundaryQueryVariables = Exact<{
  compCycleId: Scalars["Int"]["input"];
}>;

export type CompCycleReviewPhasesBoundaryQuery = {
  compCycle2: {
    __typename: "CompCycle2";
    id: number;
    name: string;
    closeDate: GraphQL_Date | null;
    participants: {
      __typename: "ParticipantsResult2";
      participants: Array<{
        __typename: "CompCycleParticipant";
        subjectId: number;
        compCycleId: number;
        subject: {
          __typename: "Employee2";
          id: number;
          managerId: number | null;
          managerIds: Array<number> | null;
        };
        compRecommendation: {
          __typename: "CompRecommendation2";
          subjectId: number;
          compCycleId: number;
          reviewStatus: CompRecommendationStatus;
          latestSubmittedItems: Array<{
            __typename: "RecItem2";
            id: number;
            submittedAt: GraphQL_DateTime | null;
            author: { __typename: "User2"; id: number; name: string | null };
          }>;
          latestSubmittedReviews: Array<{
            __typename: "RecReview2";
            id: number;
            status: RecReviewStatus;
            author: { __typename: "User2"; id: number };
          }>;
        } | null;
      }>;
    };
    phases: Array<{
      __typename: "CompCyclePhase2";
      id: number;
      phaseOrder: number;
      startDate: GraphQL_Date;
      isMyPhase: boolean;
      compCyclePhaseAssignments: Array<{
        __typename: "CompCyclePhaseAssignment2";
        id: number;
        status: PhaseTimelineStatus | null;
        assigneeId: number;
        phaseId: number;
        employee: {
          __typename: "Employee2";
          id: number;
          displayName: string;
          managerIds: Array<number> | null;
          managerId: number | null;
          location: {
            __typename: "LocationGroup2";
            id: number;
            name: string;
          } | null;
          activeEmployment: {
            __typename: "Employment2";
            id: number;
            jobTitle: string | null;
          } | null;
          user: {
            __typename: "User2";
            id: number;
            photoURL: string | null;
          } | null;
        };
        recommendees: Array<{
          __typename: "CompCyclePhaseAssignmentSubject2";
          id: number;
          participant: {
            __typename: "CompCyclePhaseAssignmentSubjectParticipant";
            subjectId: number;
            compCycleId: number;
            managerId: number | null;
          };
        }>;
        reviewees: Array<{
          __typename: "CompCyclePhaseAssignmentSubject2";
          id: number;
          participant: {
            __typename: "CompCyclePhaseAssignmentSubjectParticipant";
            subjectId: number;
            compCycleId: number;
            managerId: number | null;
          };
        }>;
      } | null>;
    }>;
    currentPhase: {
      __typename: "CompCyclePhase2";
      id: number;
      phaseOrder: number;
      compCyclePhaseAssignments: Array<{
        __typename: "CompCyclePhaseAssignment2";
        id: number;
        phaseId: number;
        status: PhaseTimelineStatus | null;
        assigneeId: number;
      } | null>;
    } | null;
  } | null;
};

export type CompCycleReviewRequests_employee = {
  __typename: "Employee2";
  id: number;
  displayName: string;
  location: { __typename: "LocationGroup2"; id: number; name: string } | null;
  activeEmployment: {
    __typename: "Employment2";
    id: number;
    jobTitle: string | null;
  } | null;
  user: { __typename: "User2"; id: number; photoURL: string | null } | null;
};

export type CompCycleReviewRequests_compCycle = {
  __typename: "CompCycle2";
  id: number;
  closeDate: GraphQL_Date | null;
  name: string;
};

export type CompCycleReviewRequestsBoundary_participant = {
  __typename: "CompCycleParticipant";
  subjectId: number;
  compCycleId: number;
  subject: {
    __typename: "Employee2";
    id: number;
    managerIds: Array<number> | null;
    managerId: number | null;
  };
  compRecommendation: {
    __typename: "CompRecommendation2";
    subjectId: number;
    compCycleId: number;
    reviewStatus: CompRecommendationStatus;
    latestSubmittedItems: Array<{
      __typename: "RecItem2";
      id: number;
      submittedAt: GraphQL_DateTime | null;
      author: { __typename: "User2"; id: number; name: string | null };
    }>;
    latestSubmittedReviews: Array<{
      __typename: "RecReview2";
      id: number;
      status: RecReviewStatus;
      author: { __typename: "User2"; id: number };
    }>;
  } | null;
};

export type CompCycleReviewRequestsBoundaryQueryVariables = Exact<{
  compCycleId: Scalars["Int"]["input"];
}>;

export type CompCycleReviewRequestsBoundaryQuery = {
  compCycle2: {
    __typename: "CompCycle2";
    id: number;
    closeDate: GraphQL_Date | null;
    name: string;
    participants: {
      __typename: "ParticipantsResult2";
      participants: Array<{
        __typename: "CompCycleParticipant";
        subjectId: number;
        compCycleId: number;
        subject: {
          __typename: "Employee2";
          id: number;
          managerIds: Array<number> | null;
          managerId: number | null;
        };
        compRecommendation: {
          __typename: "CompRecommendation2";
          subjectId: number;
          compCycleId: number;
          reviewStatus: CompRecommendationStatus;
          latestSubmittedItems: Array<{
            __typename: "RecItem2";
            id: number;
            submittedAt: GraphQL_DateTime | null;
            author: { __typename: "User2"; id: number; name: string | null };
          }>;
          latestSubmittedReviews: Array<{
            __typename: "RecReview2";
            id: number;
            status: RecReviewStatus;
            author: { __typename: "User2"; id: number };
          }>;
        } | null;
      }>;
    };
    reviewers: Array<{
      __typename: "Employee2";
      id: number;
      displayName: string;
      location: {
        __typename: "LocationGroup2";
        id: number;
        name: string;
      } | null;
      activeEmployment: {
        __typename: "Employment2";
        id: number;
        jobTitle: string | null;
      } | null;
      user: { __typename: "User2"; id: number; photoURL: string | null } | null;
    }>;
  } | null;
};

export type PhaseApproverCard_employee = {
  __typename: "Employee2";
  id: number;
  displayName: string;
  activeEmployment: {
    __typename: "Employment2";
    id: number;
    jobTitle: string | null;
  } | null;
  location: { __typename: "LocationGroup2"; id: number; name: string } | null;
  user: { __typename: "User2"; id: number; photoURL: string | null } | null;
};

export type CompCycleHeaderQueryVariables = Exact<{
  cycleId: Scalars["Int"]["input"];
}>;

export type CompCycleHeaderQuery = {
  compCycle: {
    __typename: "CompCycle";
    id: number;
    name: string;
    endedAt: GraphQL_DateTime | null;
  } | null;
  activeCompCycles: Array<{
    __typename: "CompCycle";
    id: number;
    endedAt: GraphQL_DateTime | null;
  }>;
};

export type ManagerSelfRedirect_actableManagers = {
  __typename: "MinimalEmployee2";
  id: number;
  displayName: string | null;
};

export type ManagerSelfRedirectLoadingBoundaryQueryVariables = Exact<{
  compCycleId: Scalars["Int"]["input"];
}>;

export type ManagerSelfRedirectLoadingBoundaryQuery = {
  actableManagers: Array<{
    __typename: "MinimalEmployee2";
    id: number;
    displayName: string | null;
  }> | null;
};

export type ApproveButton2_participant = {
  __typename: "CompCycleParticipant";
  subjectId: number;
  compRecommendation: {
    __typename: "CompRecommendation2";
    subjectId: number;
    compCycleId: number;
    reviewStatus: CompRecommendationStatus;
  } | null;
};

export type DenyButton2_participant = {
  __typename: "CompCycleParticipant";
  subjectId: number;
  compCycleId: number;
  subject: {
    __typename: "Employee2";
    id: number;
    displayName: string;
    user: { __typename: "User2"; id: number; photoURL: string | null } | null;
    activeEmployment: {
      __typename: "Employment2";
      id: number;
      jobTitle: string | null;
    } | null;
  };
  compRecommendation: {
    __typename: "CompRecommendation2";
    subjectId: number;
    compCycleId: number;
  } | null;
};

export type EditRequestButton2_participant = {
  __typename: "CompCycleParticipant";
  subjectId: number;
  compCycleId: number;
  subject: {
    __typename: "Employee2";
    activeEmployment: {
      __typename: "Employment2";
      id: number;
      payCurrencyCode: string | null;
    } | null;
  };
};

export type CondensedTableActionsCell2_participant = {
  __typename: "CompCycleParticipant";
  subjectId: number;
  compCycleId: number;
  compRecommendation: {
    __typename: "CompRecommendation2";
    subjectId: number;
    compCycleId: number;
    canICurrentlyReview: boolean;
    canICurrentlyEdit: boolean;
    reviewStatus: CompRecommendationStatus;
    latestSubmittedReviews: Array<{
      __typename: "RecReview2";
      id: number;
      status: RecReviewStatus;
      submittedAt: GraphQL_DateTime | null;
      author: {
        __typename: "User2";
        id: number;
        employee: { __typename: "Employee2"; id: number } | null;
      };
    }>;
    latestSubmittedItems: Array<{
      __typename: "RecItem2";
      id: number;
      submittedAt: GraphQL_DateTime | null;
    }>;
  } | null;
  phaseTimeline: Array<{
    __typename: "CompCyclePhaseAssignment2";
    id: number;
    phaseId: number;
    status: PhaseTimelineStatus | null;
    assigneeId: number;
    employee?: { __typename: "Employee2"; id: number; displayName: string };
    phase: {
      __typename: "CompCyclePhase2";
      id: number;
      phaseOrder: number;
    } | null;
  }>;
  subject: {
    __typename: "Employee2";
    id: number;
    displayName: string;
    activeEmployment: {
      __typename: "Employment2";
      id: number;
      payCurrencyCode: string | null;
      jobTitle: string | null;
    } | null;
    user: { __typename: "User2"; id: number; photoURL: string | null } | null;
  };
};

export type CondensedTableActivityCell2_participant = {
  __typename: "CompCycleParticipant";
  lastActivityAt: GraphQL_Date | null;
  subject: { __typename: "Employee2"; id: number };
  compRecommendation: {
    __typename: "CompRecommendation2";
    subjectId: number;
    compCycleId: number;
    reviewStatus: CompRecommendationStatus;
  } | null;
  phaseTimeline: Array<{
    __typename: "CompCyclePhaseAssignment2";
    id: number;
    phaseId: number;
    status: PhaseTimelineStatus | null;
    phase: {
      __typename: "CompCyclePhase2";
      id: number;
      phaseOrder: number;
    } | null;
  }>;
};

export type CondensedTableActivityCell2_compCycle2 = {
  __typename: "CompCycle2";
  id: number;
  currentPhase: {
    __typename: "CompCyclePhase2";
    id: number;
    phaseOrder: number;
    startDate: GraphQL_Date;
  } | null;
};

export type CondensedTableActualRecurringBonusCell2_participant = {
  __typename: "CompCycleParticipant";
  subject: {
    __typename: "Employee2";
    activeEmployment: {
      __typename: "Employment2";
      id: number;
      salary: GraphQL_Money | null;
    } | null;
  };
  compRecommendation: {
    __typename: "CompRecommendation2";
    subjectId: number;
    compCycleId: number;
    latestSubmittedItems: Array<{
      __typename: "RecItem2";
      id: number;
      recommendationType: RecItemType;
      recommendedCashValue: GraphQL_Money | null;
    }>;
  } | null;
};

export type CondensedTableActualRecurringBonusPercentCell2_participant = {
  __typename: "CompCycleParticipant";
  compRecommendation: {
    __typename: "CompRecommendation2";
    subjectId: number;
    compCycleId: number;
    latestSubmittedItems: Array<{
      __typename: "RecItem2";
      id: number;
      recommendationType: RecItemType;
      recommendedCashValue: GraphQL_Money | null;
    }>;
  } | null;
  subject: {
    __typename: "Employee2";
    activeCashCompensation: Array<{
      __typename: "CashCompensation2";
      employeeId: number;
      type: CashCompType;
      activeAt: GraphQL_Date;
      unit: CompUnit;
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      percentOfSalary: number;
    }> | null;
  };
};

export type CondensedTableBonusCell2_participant = {
  __typename: "CompCycleParticipant";
  subject: {
    __typename: "Employee2";
    activeEmployment: {
      __typename: "Employment2";
      id: number;
      salary: GraphQL_Money | null;
    } | null;
  };
  compRecommendation: {
    __typename: "CompRecommendation2";
    subjectId: number;
    compCycleId: number;
    latestSubmittedItems: Array<{
      __typename: "RecItem2";
      id: number;
      recommendationType: RecItemType;
      recommendedCashValue: GraphQL_Money | null;
    }>;
  } | null;
};

export type CondensedTableBonusPercentCell2_participant = {
  __typename: "CompCycleParticipant";
  subject: {
    __typename: "Employee2";
    activeEmployment: {
      __typename: "Employment2";
      id: number;
      salary: GraphQL_Money | null;
    } | null;
  };
  compRecommendation: {
    __typename: "CompRecommendation2";
    subjectId: number;
    compCycleId: number;
    latestSubmittedItems: Array<{
      __typename: "RecItem2";
      id: number;
      recommendationType: RecItemType;
      recommendedCashValue: GraphQL_Money | null;
    }>;
  } | null;
};

export type CondensedTableCurrentDepartmentCell2_participant = {
  __typename: "CompCycleParticipant";
  subject: {
    __typename: "Employee2";
    activeEmployment: {
      __typename: "Employment2";
      id: number;
      position: {
        __typename: "Position";
        id: number;
        ladder: {
          __typename: "Ladder";
          id: number;
          department: { __typename: "Department"; id: number; name: string };
        };
      } | null;
    } | null;
  };
};

export type CondensedTableCurrentDepartmentCell_position = {
  __typename: "Position";
  id: number;
  ladder: {
    __typename: "Ladder";
    id: number;
    department: { __typename: "Department"; id: number; name: string };
  };
};

export type CondensedTableCurrentEquityUnitsCell2_participant = {
  __typename: "CompCycleParticipant";
  subject: {
    __typename: "Employee2";
    id: number;
    equityHoldings: {
      __typename: "EquityHoldings2";
      id: number;
      totalUnitCount: number;
    };
  };
};

export type CondensedTableCurrentEquityValueCell2_participant = {
  __typename: "CompCycleParticipant";
  subject: {
    __typename: "Employee2";
    id: number;
    equityHoldings: {
      __typename: "EquityHoldings2";
      id: number;
      totalGrossHoldingValue: GraphQL_Money | null;
    };
  };
};

export type CondensedTableCurrentLadderCell2_participant = {
  __typename: "CompCycleParticipant";
  subject: {
    __typename: "Employee2";
    activeEmployment: {
      __typename: "Employment2";
      id: number;
      position: {
        __typename: "Position";
        id: number;
        ladder: { __typename: "Ladder"; id: number; name: string };
      } | null;
    } | null;
  };
};

export type CondensedTableCurrentLadderCell_position = {
  __typename: "Position";
  id: number;
  ladder: { __typename: "Ladder"; id: number; name: string };
};

export type CondensedTableCurrentLevelCell2_participant = {
  __typename: "CompCycleParticipant";
  subject: {
    __typename: "Employee2";
    activeEmployment: {
      __typename: "Employment2";
      id: number;
      position: { __typename: "Position"; id: number; level: number } | null;
    } | null;
  };
  compRecommendation: {
    __typename: "CompRecommendation2";
    subjectId: number;
    compCycleId: number;
    latestSubmittedItems: Array<{
      __typename: "RecItem2";
      id: number;
      recommendationType: RecItemType;
      recommendedPosition: { __typename: "Position"; id: number } | null;
    }>;
  } | null;
};

export type CondensedTableCurrentLevelCell_position = {
  __typename: "Position";
  id: number;
  level: number;
};

export type CondensedTableCurrentPositionCell2_participant = {
  __typename: "CompCycleParticipant";
  subject: {
    __typename: "Employee2";
    activeEmployment: {
      __typename: "Employment2";
      id: number;
      payPeriod: PayPeriodType;
      position: {
        __typename: "Position";
        id: number;
        name: string;
        type: PositionType;
      } | null;
    } | null;
  };
  compRecommendation: {
    __typename: "CompRecommendation2";
    reviewStatus: CompRecommendationStatus;
  } | null;
};

export type CondensedTableCurrentSalaryCompaRatioCell2_participant = {
  __typename: "CompCycleParticipant";
  subject: {
    __typename: "Employee2";
    activeCashCompensation: Array<{
      __typename: "CashCompensation2";
      employeeId: number;
      type: CashCompType;
      activeAt: GraphQL_Date;
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      unit: CompUnit;
    }> | null;
    adjustedCashBands: Array<{
      __typename: "AdjustedCashBand";
      id: string;
      name: string;
      bandPoints: Array<{
        __typename: "AdjustedCashBandPoint";
        name: string;
        value: {
          __typename: "CashValue";
          annualRate: GraphQL_Money | null;
          hourlyRate: GraphQL_Money | null;
          currencyCode: CurrencyCode;
        };
      }>;
    }> | null;
  };
};

export type CondensedTableCurrentTCCCompaRatioCell_compStructure = {
  __typename: "CompStructure";
  id: number;
  bandPointTypes: Array<string>;
};

export type CondensedTableCurrentTCCCompaRatioCell_participant = {
  __typename: "CompCycleParticipant";
  subject: {
    __typename: "Employee2";
    activeCashCompensation: Array<{
      __typename: "CashCompensation2";
      employeeId: number;
      type: CashCompType;
      activeAt: GraphQL_Date;
      annualCashEquivalent: GraphQL_Money;
    }> | null;
    adjustedCashBands: Array<{
      __typename: "AdjustedCashBand";
      id: string;
      name: string;
      bandPoints: Array<{
        __typename: "AdjustedCashBandPoint";
        name: string;
        annualCashEquivalent: GraphQL_Money;
      }>;
    }> | null;
  };
};

export type CondensedTableCurrentTargetCashCell2_participant = {
  __typename: "CompCycleParticipant";
  subject: {
    __typename: "Employee2";
    activeCashCompensation: Array<{
      __typename: "CashCompensation2";
      employeeId: number;
      type: CashCompType;
      activeAt: GraphQL_Date;
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      unit: CompUnit;
      percentOfSalary: number;
    }> | null;
  };
};

export type CondensedTableCurrentTargetCommissionCell2_participant = {
  __typename: "CompCycleParticipant";
  subject: {
    __typename: "Employee2";
    activeEmployment: {
      __typename: "Employment2";
      id: number;
      salary: GraphQL_Money | null;
    } | null;
    activeCashCompensation: Array<{
      __typename: "CashCompensation2";
      employeeId: number;
      type: CashCompType;
      activeAt: GraphQL_Date;
      unit: CompUnit;
      annualCashEquivalent: GraphQL_Money;
      percentOfSalary: number;
    }> | null;
  };
};

export type CondensedTableCurrentTargetCommissionPercentCell2_participant = {
  __typename: "CompCycleParticipant";
  subject: {
    __typename: "Employee2";
    activeCashCompensation: Array<{
      __typename: "CashCompensation2";
      employeeId: number;
      type: CashCompType;
      activeAt: GraphQL_Date;
      unit: CompUnit;
      annualCashEquivalent: GraphQL_Money;
      percentOfSalary: number;
    }> | null;
  };
};

export type CondensedTableCurrentTargetRecurringBonusCell2_participant = {
  __typename: "CompCycleParticipant";
  subject: {
    __typename: "Employee2";
    activeEmployment: {
      __typename: "Employment2";
      id: number;
      salary: GraphQL_Money | null;
    } | null;
    activeCashCompensation: Array<{
      __typename: "CashCompensation2";
      employeeId: number;
      type: CashCompType;
      activeAt: GraphQL_Date;
      unit: CompUnit;
      annualCashEquivalent: GraphQL_Money;
      percentOfSalary: number;
    }> | null;
  };
};

export type CondensedTableCurrentTargetRecurringBonusPercentCell2_participant =
  {
    __typename: "CompCycleParticipant";
    subject: {
      __typename: "Employee2";
      activeCashCompensation: Array<{
        __typename: "CashCompensation2";
        employeeId: number;
        type: CashCompType;
        activeAt: GraphQL_Date;
        unit: CompUnit;
        annualCashEquivalent: GraphQL_Money;
        percentOfSalary: number;
      }> | null;
    };
  };

export type CondensedTableEquityBandCell2_compCycleParticipant = {
  __typename: "CompCycleParticipant";
  subjectId: number;
  compCycleId: number;
  subject: {
    __typename: "Employee2";
    id: number;
    adjustedEquityBands: Array<{
      __typename: "AdjustedEquityBand";
      id: string;
      name: string;
      bandPoints: Array<{
        __typename: "AdjustedEquityBandPoint";
        bandName: string;
        annualCashEquivalent: GraphQL_Money;
      }>;
    }> | null;
    activeEmployment: {
      __typename: "Employment2";
      id: number;
      salary: GraphQL_Money | null;
      position: {
        __typename: "Position";
        id: number;
        name: string;
        level: number;
        type: PositionType;
      } | null;
    } | null;
  };
  compRecommendation: {
    __typename: "CompRecommendation2";
    subjectId: number;
    compCycleId: number;
    latestSubmittedItems: Array<{
      __typename: "RecItem2";
      id: number;
      recommendationType: RecItemType;
      recommendedCashValue: GraphQL_Money | null;
    }>;
  } | null;
};

export type CondensedTableEquityUnitsCell2_participant = {
  __typename: "CompCycleParticipant";
  subject: {
    __typename: "Employee2";
    activeEmployment: {
      __typename: "Employment2";
      id: number;
      salary: GraphQL_Money | null;
    } | null;
  };
  compRecommendation: {
    __typename: "CompRecommendation2";
    subjectId: number;
    compCycleId: number;
    latestSubmittedItems: Array<{
      __typename: "RecItem2";
      id: number;
      recommendationType: RecItemType;
      recommendedCashValue: GraphQL_Money | null;
      recommendedEquityUnitCount: number | null;
    }>;
  } | null;
};

export type CondensedTableEquityValueCell2_participant = {
  __typename: "CompCycleParticipant";
  subject: {
    __typename: "Employee2";
    activeEmployment: {
      __typename: "Employment2";
      id: number;
      salary: GraphQL_Money | null;
    } | null;
  };
  compRecommendation: {
    __typename: "CompRecommendation2";
    subjectId: number;
    compCycleId: number;
    latestSubmittedItems: Array<{
      __typename: "RecItem2";
      id: number;
      recommendationType: RecItemType;
      recommendedCashValue: GraphQL_Money | null;
    }>;
  } | null;
};

export type CondensedTableLastSalaryChangeAmountCell2_participant = {
  __typename: "CompCycleParticipant";
  subject: {
    __typename: "Employee2";
    lastCompChangeAmount: {
      __typename: "CompValue";
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      unitType: CompUnit | null;
    } | null;
  };
};

export type CondensedTableLastSalaryChangeDateCell2_participant = {
  __typename: "CompCycleParticipant";
  subject: {
    __typename: "Employee2";
    id: number;
    lastCompChangeDate: GraphQL_Date | null;
  };
};

export type CondensedTableLocationGroupCell2_participant = {
  __typename: "CompCycleParticipant";
  subject: {
    __typename: "Employee2";
    location: { __typename: "LocationGroup2"; id: number; name: string } | null;
  };
};

export type CondensedTableManagerCell2_participant = {
  __typename: "CompCycleParticipant";
  subject: {
    __typename: "Employee2";
    manager: {
      __typename: "MinimalEmployee2";
      id: number;
      displayName: string | null;
      user: { __typename: "User2"; id: number; photoURL: string | null } | null;
    } | null;
  };
};

export type CondensedTableNameCell2_participant = {
  __typename: "CompCycleParticipant";
  subject: {
    __typename: "Employee2";
    id: number;
    displayName: string;
    userId: number | null;
    photoURL: string | null;
  };
};

export type CondensedTableNewDepartmentCell2_participant = {
  __typename: "CompCycleParticipant";
  compRecommendation: {
    __typename: "CompRecommendation2";
    subjectId: number;
    compCycleId: number;
    latestSubmittedItems: Array<{
      __typename: "RecItem2";
      id: number;
      recommendationType: RecItemType;
    }>;
  } | null;
  subject: {
    __typename: "Employee2";
    activeEmployment: {
      __typename: "Employment2";
      id: number;
      position: {
        __typename: "Position";
        id: number;
        ladder: {
          __typename: "Ladder";
          id: number;
          department: { __typename: "Department"; id: number; name: string };
        };
      } | null;
    } | null;
  };
};

export type CondensedTableNewDepartmentCell_position = {
  __typename: "Position";
  id: number;
  ladder: {
    __typename: "Ladder";
    id: number;
    department: { __typename: "Department"; id: number; name: string };
  };
};

export type CondensedTableNewLadderCell2_participant = {
  __typename: "CompCycleParticipant";
  subject: {
    __typename: "Employee2";
    activeEmployment: {
      __typename: "Employment2";
      id: number;
      position: {
        __typename: "Position";
        id: number;
        ladder: { __typename: "Ladder"; id: number; name: string };
      } | null;
    } | null;
  };
  compRecommendation: {
    __typename: "CompRecommendation2";
    subjectId: number;
    compCycleId: number;
    latestSubmittedItems: Array<{
      __typename: "RecItem2";
      id: number;
      recommendationType: RecItemType;
      recommendedPosition: { __typename: "Position"; id: number } | null;
    }>;
  } | null;
};

export type CondensedTableNewLadderCell_position = {
  __typename: "Position";
  id: number;
  ladder: { __typename: "Ladder"; id: number; name: string };
};

export type CondensedTableNewLevelCell2_participant = {
  __typename: "CompCycleParticipant";
  subject: {
    __typename: "Employee2";
    activeEmployment: {
      __typename: "Employment2";
      id: number;
      position: { __typename: "Position"; id: number; level: number } | null;
    } | null;
  };
  compRecommendation: {
    __typename: "CompRecommendation2";
    subjectId: number;
    compCycleId: number;
    latestSubmittedItems: Array<{
      __typename: "RecItem2";
      id: number;
      recommendationType: RecItemType;
      recommendedPosition: { __typename: "Position"; id: number } | null;
    }>;
  } | null;
};

export type CondensedTableNewLevelCell_position = {
  __typename: "Position";
  id: number;
  level: number;
};

export type CondensedTableNewSalaryCell2_participant = {
  __typename: "CompCycleParticipant";
  subject: {
    __typename: "Employee2";
    adjustedCashBands: Array<{
      __typename: "AdjustedCashBand";
      id: string;
      name: string;
      bandPoints: Array<{
        __typename: "AdjustedCashBandPoint";
        value: {
          __typename: "CashValue";
          annualRate: GraphQL_Money | null;
          currencyCode: CurrencyCode;
        };
      }>;
    }> | null;
    activeCashCompensation: Array<{
      __typename: "CashCompensation2";
      employeeId: number;
      type: CashCompType;
      activeAt: GraphQL_Date;
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      unit: CompUnit;
    }> | null;
    activeEmployment: {
      __typename: "Employment2";
      id: number;
      salary: GraphQL_Money | null;
    } | null;
  };
  compRecommendation: {
    __typename: "CompRecommendation2";
    subjectId: number;
    compCycleId: number;
    latestSubmittedPayIncrease: {
      __typename: "CompValue";
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      unitType: CompUnit | null;
    };
  } | null;
};

export type CondensedTableNewPositionCell2_participant = {
  __typename: "CompCycleParticipant";
  subject: {
    __typename: "Employee2";
    activeEmployment: {
      __typename: "Employment2";
      id: number;
      payPeriod: PayPeriodType;
      position: {
        __typename: "Position";
        id: number;
        name: string;
        type: PositionType;
      } | null;
    } | null;
  };
  compRecommendation: {
    __typename: "CompRecommendation2";
    subjectId: number;
    compCycleId: number;
    reviewStatus: CompRecommendationStatus;
    latestSubmittedItems: Array<{
      __typename: "RecItem2";
      id: number;
      recommendationType: RecItemType;
      recommendedPosition: { __typename: "Position"; id: number } | null;
    }>;
  } | null;
};

export type CondensedTableNewPositionCell2_position = {
  __typename: "Position";
  id: number;
  name: string;
  type: PositionType;
};

export type CondensedTableNewPositionIfChangedCell2_participant = {
  __typename: "CompCycleParticipant";
  subject: {
    __typename: "Employee2";
    activeEmployment: {
      __typename: "Employment2";
      id: number;
      payPeriod: PayPeriodType;
      position: {
        __typename: "Position";
        id: number;
        name: string;
        type: PositionType;
      } | null;
    } | null;
  };
  compRecommendation: {
    __typename: "CompRecommendation2";
    subjectId: number;
    compCycleId: number;
    reviewStatus: CompRecommendationStatus;
    latestSubmittedItems: Array<{
      __typename: "RecItem2";
      id: number;
      recommendationType: RecItemType;
      recommendedPosition: { __typename: "Position"; id: number } | null;
    }>;
  } | null;
};

export type CondensedTableNewPositionIfChangedCell_position = {
  __typename: "Position";
  id: number;
  name: string;
};

export type CondensedTableNewSalaryBandCell2_position = {
  __typename: "Position";
  id: number;
  name: string;
  type: PositionType;
  level: number;
  ladder: {
    __typename: "Ladder";
    id: number;
    name: string;
    department: { __typename: "Department"; id: number; name: string };
  };
};

export type CondensedTableNewSalaryBandCell2_participant = {
  __typename: "CompCycleParticipant";
  subject: {
    __typename: "Employee2";
    adjustedCashBands: Array<{
      __typename: "AdjustedCashBand";
      id: string;
      name: string;
      bandPoints: Array<{
        __typename: "AdjustedCashBandPoint";
        name: string;
        value: {
          __typename: "CashValue";
          annualRate: GraphQL_Money | null;
          hourlyRate: GraphQL_Money | null;
          currencyCode: CurrencyCode;
        };
      }>;
    }> | null;
    activeCashCompensation: Array<{
      __typename: "CashCompensation2";
      employeeId: number;
      type: CashCompType;
      activeAt: GraphQL_Date;
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      unit: CompUnit;
    }> | null;
    activeEmployment: {
      __typename: "Employment2";
      id: number;
      salary: GraphQL_Money | null;
      position: {
        __typename: "Position";
        id: number;
        name: string;
        type: PositionType;
        level: number;
        ladder: {
          __typename: "Ladder";
          id: number;
          name: string;
          department: { __typename: "Department"; id: number; name: string };
        };
      } | null;
    } | null;
  };
  compRecommendation: {
    __typename: "CompRecommendation2";
    subjectId: number;
    compCycleId: number;
    latestSubmittedPayIncrease: {
      __typename: "CompValue";
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      unitType: CompUnit | null;
    };
    latestSubmittedItems: Array<{
      __typename: "RecItem2";
      id: number;
      recommendationType: RecItemType;
      recommendedCashValue: GraphQL_Money | null;
      recommendedPosition: {
        __typename: "Position";
        id: number;
        name: string;
        type: PositionType;
        level: number;
        ladder: {
          __typename: "Ladder";
          id: number;
          name: string;
          department: { __typename: "Department"; id: number; name: string };
        };
      } | null;
      adjustedCashBands: Array<{
        __typename: "AdjustedCashBand";
        id: string;
        name: string;
        bandPoints: Array<{
          __typename: "AdjustedCashBandPoint";
          name: string;
          value: {
            __typename: "CashValue";
            annualRate: GraphQL_Money | null;
            hourlyRate: GraphQL_Money | null;
            currencyCode: CurrencyCode;
          };
        }>;
      }> | null;
    }>;
  } | null;
};

export type CondensedTableNewSalaryCompaRatioCell2_participant = {
  __typename: "CompCycleParticipant";
  subject: {
    __typename: "Employee2";
    activeEmployment: {
      __typename: "Employment2";
      id: number;
      salary: GraphQL_Money | null;
    } | null;
    adjustedCashBands: Array<{
      __typename: "AdjustedCashBand";
      id: string;
      name: string;
      bandPoints: Array<{
        __typename: "AdjustedCashBandPoint";
        value: {
          __typename: "CashValue";
          annualRate: GraphQL_Money | null;
          hourlyRate: GraphQL_Money | null;
          currencyCode: CurrencyCode;
        };
      }>;
    }> | null;
    activeCashCompensation: Array<{
      __typename: "CashCompensation2";
      employeeId: number;
      type: CashCompType;
      activeAt: GraphQL_Date;
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      unit: CompUnit;
    }> | null;
  };
  compRecommendation: {
    __typename: "CompRecommendation2";
    subjectId: number;
    compCycleId: number;
    latestSubmittedPayIncrease: {
      __typename: "CompValue";
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      unitType: CompUnit | null;
    };
    latestSubmittedItems: Array<{
      __typename: "RecItem2";
      id: number;
      adjustedCashBands: Array<{
        __typename: "AdjustedCashBand";
        id: string;
        name: string;
        bandPoints: Array<{
          __typename: "AdjustedCashBandPoint";
          name: string;
          value: {
            __typename: "CashValue";
            annualRate: GraphQL_Money | null;
            hourlyRate: GraphQL_Money | null;
            currencyCode: CurrencyCode;
          };
        }>;
      }> | null;
    }>;
  } | null;
};

export type CondensedTableNewTCCCompaRatioCell_participant = {
  __typename: "CompCycleParticipant";
  subject: {
    __typename: "Employee2";
    id: number;
    activeEmployment: {
      __typename: "Employment2";
      id: number;
      salary: GraphQL_Money | null;
    } | null;
    adjustedCashBands: Array<{
      __typename: "AdjustedCashBand";
      id: string;
      name: string;
      bandPoints: Array<{
        __typename: "AdjustedCashBandPoint";
        name: string;
        annualCashEquivalent: GraphQL_Money;
      }>;
    }> | null;
    activeCashCompensation: Array<{
      __typename: "CashCompensation2";
      employeeId: number;
      type: CashCompType;
      activeAt: GraphQL_Date;
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      unit: CompUnit;
      percentOfSalary: number;
    }> | null;
  };
  compRecommendation: {
    __typename: "CompRecommendation2";
    subjectId: number;
    compCycleId: number;
    latestSubmittedPayIncrease: {
      __typename: "CompValue";
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      unitType: CompUnit | null;
    };
    latestSubmittedItems: Array<{
      __typename: "RecItem2";
      id: number;
      recommendationType: RecItemType;
      recommendedCashValue: GraphQL_Money | null;
      adjustedCashBands: Array<{
        __typename: "AdjustedCashBand";
        id: string;
        name: string;
        bandPoints: Array<{
          __typename: "AdjustedCashBandPoint";
          name: string;
          annualCashEquivalent: GraphQL_Money;
        }>;
      }> | null;
    }>;
  } | null;
};

export type CondensedTableNewTargetCashCell2_participant = {
  __typename: "CompCycleParticipant";
  subject: {
    __typename: "Employee2";
    activeCashCompensation: Array<{
      __typename: "CashCompensation2";
      employeeId: number;
      type: CashCompType;
      activeAt: GraphQL_Date;
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      unit: CompUnit;
      percentOfSalary: number;
    }> | null;
  };
  compRecommendation: {
    __typename: "CompRecommendation2";
    subjectId: number;
    compCycleId: number;
    reviewStatus: CompRecommendationStatus;
    latestSubmittedItems: Array<{
      __typename: "RecItem2";
      id: number;
      recommendationType: RecItemType;
      recommendedCashValue: GraphQL_Money | null;
    }>;
  } | null;
};

export type CondensedTableNewTargetCommissionCell2_participant = {
  __typename: "CompCycleParticipant";
  subject: {
    __typename: "Employee2";
    activeEmployment: {
      __typename: "Employment2";
      id: number;
      salary: GraphQL_Money | null;
    } | null;
    activeCashCompensation: Array<{
      __typename: "CashCompensation2";
      employeeId: number;
      type: CashCompType;
      activeAt: GraphQL_Date;
      unit: CompUnit;
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      percentOfSalary: number;
    }> | null;
  };
  compRecommendation: {
    __typename: "CompRecommendation2";
    subjectId: number;
    compCycleId: number;
    latestSubmittedItems: Array<{
      __typename: "RecItem2";
      id: number;
      recommendationType: RecItemType;
      recommendedCashValue: GraphQL_Money | null;
      recommendedPercentValue: number | null;
      unitType: CompUnit | null;
    }>;
  } | null;
};

export type CondensedTableNewTargetCommissionPercentCell2_participant = {
  __typename: "CompCycleParticipant";
  subject: {
    __typename: "Employee2";
    activeCashCompensation: Array<{
      __typename: "CashCompensation2";
      employeeId: number;
      type: CashCompType;
      activeAt: GraphQL_Date;
      unit: CompUnit;
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      percentOfSalary: number;
    }> | null;
  };
  compRecommendation: {
    __typename: "CompRecommendation2";
    subjectId: number;
    compCycleId: number;
    latestSubmittedItems: Array<{
      __typename: "RecItem2";
      id: number;
      recommendationType: RecItemType;
      recommendedCashValue: GraphQL_Money | null;
      recommendedPercentValue: number | null;
      unitType: CompUnit | null;
    }>;
  } | null;
};

export type CondensedTableNewTargetRecurringBonusCell2_participant = {
  __typename: "CompCycleParticipant";
  subject: {
    __typename: "Employee2";
    activeEmployment: {
      __typename: "Employment2";
      id: number;
      salary: GraphQL_Money | null;
    } | null;
    activeCashCompensation: Array<{
      __typename: "CashCompensation2";
      employeeId: number;
      type: CashCompType;
      activeAt: GraphQL_Date;
      unit: CompUnit;
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      percentOfSalary: number;
    }> | null;
  };
  compRecommendation: {
    __typename: "CompRecommendation2";
    subjectId: number;
    compCycleId: number;
    latestSubmittedItems: Array<{
      __typename: "RecItem2";
      id: number;
      recommendationType: RecItemType;
      recommendedCashValue: GraphQL_Money | null;
      recommendedPercentValue: number | null;
    }>;
  } | null;
};

export type CondensedTableNewTargetRecurringBonusPercentCell2_participant = {
  __typename: "CompCycleParticipant";
  subject: {
    __typename: "Employee2";
    activeCashCompensation: Array<{
      __typename: "CashCompensation2";
      employeeId: number;
      type: CashCompType;
      activeAt: GraphQL_Date;
      unit: CompUnit;
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      percentOfSalary: number;
    }> | null;
  };
  compRecommendation: {
    __typename: "CompRecommendation2";
    subjectId: number;
    compCycleId: number;
    latestSubmittedItems: Array<{
      __typename: "RecItem2";
      id: number;
      recommendationType: RecItemType;
      recommendedCashValue: GraphQL_Money | null;
      recommendedPercentValue: number | null;
    }>;
  } | null;
};

export type CondensedTablePerfRatingCell2_participant = {
  __typename: "CompCycleParticipant";
  perfRating: string | null;
};

export type CondensedTablePrevSalaryCell2_participant = {
  __typename: "CompCycleParticipant";
  subject: {
    __typename: "Employee2";
    id: number;
    activeCashCompensation: Array<{
      __typename: "CashCompensation2";
      type: CashCompType;
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      unit: CompUnit;
      employeeId: number;
    }> | null;
  };
};

export type PreviousEmployment_employment2 = {
  __typename: "Employment2";
  position: {
    __typename: "Position";
    id: number;
    name: string;
    level: number;
    type: PositionType;
    ladder: {
      __typename: "Ladder";
      id: number;
      name: string;
      department: { __typename: "Department"; id: number; name: string };
    };
  } | null;
};

export type CondensedTablePromotionCell2_participant = {
  __typename: "CompCycleParticipant";
  subject: {
    __typename: "Employee2";
    activeEmployment: {
      __typename: "Employment2";
      id: number;
      position: {
        __typename: "Position";
        id: number;
        name: string;
        level: number;
        type: PositionType;
        ladder: {
          __typename: "Ladder";
          id: number;
          name: string;
          department: { __typename: "Department"; id: number; name: string };
        };
      } | null;
    } | null;
  };
  compRecommendation: {
    __typename: "CompRecommendation2";
    subjectId: number;
    compCycleId: number;
    latestSubmittedItems: Array<{
      __typename: "RecItem2";
      id: number;
      recommendationType: RecItemType;
      recommendedCashValue: GraphQL_Money | null;
      recommendedPosition: {
        __typename: "Position";
        id: number;
        name: string;
        level: number;
        type: PositionType;
        ladder: {
          __typename: "Ladder";
          id: number;
          name: string;
          department: { __typename: "Department"; id: number; name: string };
        };
      } | null;
    }>;
  } | null;
};

export type CondensedTableSalaryBandCell2_participant = {
  __typename: "CompCycleParticipant";
  subject: {
    __typename: "Employee2";
    activeCashCompensation: Array<{
      __typename: "CashCompensation2";
      employeeId: number;
      type: CashCompType;
      activeAt: GraphQL_Date;
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      unit: CompUnit;
    }> | null;
    adjustedCashBands: Array<{
      __typename: "AdjustedCashBand";
      id: string;
      name: string;
      bandPoints: Array<{
        __typename: "AdjustedCashBandPoint";
        name: string;
        value: {
          __typename: "CashValue";
          annualRate: GraphQL_Money | null;
          hourlyRate: GraphQL_Money | null;
          currencyCode: CurrencyCode;
        };
      }>;
    }> | null;
    activeEmployment: {
      __typename: "Employment2";
      id: number;
      position: {
        __typename: "Position";
        id: number;
        name: string;
        type: PositionType;
        level: number;
        ladder: {
          __typename: "Ladder";
          id: number;
          name: string;
          department: { __typename: "Department"; id: number; name: string };
        };
      } | null;
    } | null;
  };
};

export type CondensedTableSalaryIncreaseCell2_participant = {
  __typename: "CompCycleParticipant";
  subject: {
    __typename: "Employee2";
    id: number;
    activeCashCompensation: Array<{
      __typename: "CashCompensation2";
      type: CashCompType;
      annualCashEquivalent: GraphQL_Money;
      employeeId: number;
    }> | null;
  };
  compRecommendation: {
    __typename: "CompRecommendation2";
    subjectId: number;
    compCycleId: number;
    latestSubmittedPayIncrease: {
      __typename: "CompValue";
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      unitType: CompUnit | null;
    };
  } | null;
};

export type CondensedTableSalaryMarketCell2_participant = {
  __typename: "CompCycleParticipant";
  subject: {
    __typename: "Employee2";
    id: number;
    activeCashCompensation: Array<{
      __typename: "CashCompensation2";
      type: CashCompType;
      employeeId: number;
      unit: CompUnit;
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
    }> | null;
  };
  compRecommendation: {
    __typename: "CompRecommendation2";
    subjectId: number;
    compCycleId: number;
    latestSubmittedItems: Array<{
      __typename: "RecItem2";
      id: number;
      recommendationType: RecItemType;
      recommendedCashValue: GraphQL_Money | null;
      unitType: CompUnit | null;
    }>;
  } | null;
};

export type CondensedTableSalaryMeritCell2_participant = {
  __typename: "CompCycleParticipant";
  subject: {
    __typename: "Employee2";
    id: number;
    activeCashCompensation: Array<{
      __typename: "CashCompensation2";
      type: CashCompType;
      employeeId: number;
      unit: CompUnit;
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
    }> | null;
  };
  compRecommendation: {
    __typename: "CompRecommendation2";
    subjectId: number;
    compCycleId: number;
    latestSubmittedItems: Array<{
      __typename: "RecItem2";
      id: number;
      recommendationType: RecItemType;
      recommendedCashValue: GraphQL_Money | null;
      unitType: CompUnit | null;
    }>;
  } | null;
};

export type CondensedTableSalaryMeritDifferenceCell2_participant = {
  __typename: "CompCycleParticipant";
  perfRating: string | null;
  subject: {
    __typename: "Employee2";
    id: number;
    activeCashCompensation: Array<{
      __typename: "CashCompensation2";
      type: CashCompType;
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      unit: CompUnit;
      employeeId: number;
    }> | null;
    adjustedCashBands: Array<{
      __typename: "AdjustedCashBand";
      id: string;
      name: string;
      bandPoints: Array<{
        __typename: "AdjustedCashBandPoint";
        id: string;
        bandName: string;
        name: string;
        value: {
          __typename: "CashValue";
          annualRate: GraphQL_Money | null;
          currencyCode: CurrencyCode;
        };
      }>;
    }> | null;
  };
  compRecommendation: {
    __typename: "CompRecommendation2";
    subjectId: number;
    compCycleId: number;
    latestSubmittedItems: Array<{
      __typename: "RecItem2";
      id: number;
      recommendationType: RecItemType;
      recommendedCashValue: GraphQL_Money | null;
      unitType: CompUnit | null;
    }>;
  } | null;
};

export type CondensedTableSalaryMeritGuidanceCell2_participant = {
  __typename: "CompCycleParticipant";
  perfRating: string | null;
  subject: {
    __typename: "Employee2";
    activeCashCompensation: Array<{
      __typename: "CashCompensation2";
      type: CashCompType;
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      unit: CompUnit;
      employeeId: number;
    }> | null;
    adjustedCashBands: Array<{
      __typename: "AdjustedCashBand";
      id: string;
      name: string;
      bandPoints: Array<{
        __typename: "AdjustedCashBandPoint";
        id: string;
        bandName: string;
        name: string;
        value: {
          __typename: "CashValue";
          annualRate: GraphQL_Money | null;
          currencyCode: CurrencyCode;
        };
      }>;
    }> | null;
  };
  compRecommendation: {
    __typename: "CompRecommendation2";
    subjectId: number;
    compCycleId: number;
    allSubmittedItems: Array<{ __typename: "RecItem2"; id: number }>;
    latestSubmittedItems: Array<{
      __typename: "RecItem2";
      id: number;
      recommendationType: RecItemType;
      recommendedCashValue: GraphQL_Money | null;
      unitType: CompUnit | null;
    }>;
  } | null;
};

export type CondensedTableSalaryMeritPercentCell2_participant = {
  __typename: "CompCycleParticipant";
  subject: {
    __typename: "Employee2";
    id: number;
    activeCashCompensation: Array<{
      __typename: "CashCompensation2";
      type: CashCompType;
      employeeId: number;
      unit: CompUnit;
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
    }> | null;
  };
  compRecommendation: {
    __typename: "CompRecommendation2";
    subjectId: number;
    compCycleId: number;
    latestSubmittedItems: Array<{
      __typename: "RecItem2";
      id: number;
      recommendationType: RecItemType;
      recommendedCashValue: GraphQL_Money | null;
      unitType: CompUnit | null;
    }>;
  } | null;
};

export type CondensedTableSalaryPercentChangeCell2_participant = {
  __typename: "CompCycleParticipant";
  subject: {
    __typename: "Employee2";
    id: number;
    activeCashCompensation: Array<{
      __typename: "CashCompensation2";
      type: CashCompType;
      annualCashEquivalent: GraphQL_Money;
      employeeId: number;
    }> | null;
  };
  compRecommendation: {
    __typename: "CompRecommendation2";
    subjectId: number;
    compCycleId: number;
    latestSubmittedPayIncrease: {
      __typename: "CompValue";
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      unitType: CompUnit | null;
    };
  } | null;
};

export type CondensedTableSalaryPromoCell2_participant = {
  __typename: "CompCycleParticipant";
  subjectId: number;
  compCycleId: number;
  subject: {
    __typename: "Employee2";
    id: number;
    activeCashCompensation: Array<{
      __typename: "CashCompensation2";
      type: CashCompType;
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      unit: CompUnit;
      employeeId: number;
    }> | null;
  };
  compRecommendation: {
    __typename: "CompRecommendation2";
    subjectId: number;
    compCycleId: number;
    latestSubmittedItems: Array<{
      __typename: "RecItem2";
      id: number;
      recommendationType: RecItemType;
      recommendedCashValue: GraphQL_Money | null;
      unitType: CompUnit | null;
    }>;
  } | null;
};

export type CondensedTableStatusCell2_participant = {
  __typename: "CompCycleParticipant";
  lastActivityAt: GraphQL_Date | null;
  subjectId: number;
  compCycleId: number;
  subject: { __typename: "Employee2"; id: number };
  compRecommendation: {
    __typename: "CompRecommendation2";
    subjectId: number;
    compCycleId: number;
    reviewStatus: CompRecommendationStatus;
    allSubmittedItems: Array<{
      __typename: "RecItem2";
      id: number;
      authorId: number;
    }>;
    allSubmittedReviews: Array<{
      __typename: "RecReview2";
      id: number;
      authorId: number;
    }>;
  } | null;
  phaseTimeline: Array<{
    __typename: "CompCyclePhaseAssignment2";
    id: number;
    phaseId: number;
    status: PhaseTimelineStatus | null;
    phase: {
      __typename: "CompCyclePhase2";
      id: number;
      phaseOrder: number;
      startDate: GraphQL_Date;
    } | null;
    employee?: {
      __typename: "Employee2";
      id: number;
      displayName: string;
      activeEmployment: {
        __typename: "Employment2";
        id: number;
        jobTitle: string | null;
      } | null;
      user: { __typename: "User2"; id: number } | null;
    };
  }>;
};

export type CondensedTableStatusCell2_compCycle = {
  __typename: "CompCycle2";
  id: number;
  currentPhase: {
    __typename: "CompCyclePhase2";
    id: number;
    phaseOrder: number;
    startDate: GraphQL_Date;
  } | null;
};

export type CondensedTableStatusCellTooltip2_compCycle2 = {
  __typename: "CompCycle2";
  id: number;
  currentPhase: {
    __typename: "CompCyclePhase2";
    id: number;
    phaseOrder: number;
  } | null;
};

export type CondensedTableStatusCellTooltip2_participant = {
  __typename: "CompCycleParticipant";
  subjectId: number;
  compCycleId: number;
  compRecommendation: {
    __typename: "CompRecommendation2";
    subjectId: number;
    compCycleId: number;
    reviewStatus: CompRecommendationStatus;
    allSubmittedItems: Array<{
      __typename: "RecItem2";
      id: number;
      authorId: number;
    }>;
    allSubmittedReviews: Array<{
      __typename: "RecReview2";
      id: number;
      authorId: number;
    }>;
  } | null;
  phaseTimeline: Array<{
    __typename: "CompCyclePhaseAssignment2";
    id: number;
    status: PhaseTimelineStatus | null;
    phaseId: number;
    employee?: {
      __typename: "Employee2";
      id: number;
      displayName: string;
      activeEmployment: {
        __typename: "Employment2";
        id: number;
        jobTitle: string | null;
      } | null;
      user: { __typename: "User2"; id: number } | null;
    };
    phase: {
      __typename: "CompCyclePhase2";
      id: number;
      phaseOrder: number;
      startDate: GraphQL_Date;
    } | null;
  }>;
};

export type CondensedTableTenureCell2_participant = {
  __typename: "CompCycleParticipant";
  subject: { __typename: "Employee2"; activeAt: GraphQL_Date | null };
};

export type CompCycleFilterBarQueryVariables = Exact<{
  compCycleId: Scalars["Int"]["input"];
}>;

export type CompCycleFilterBarQuery = {
  organization: {
    __typename: "Organization";
    id: number;
    compStructure: {
      __typename: "CompStructure";
      id: number;
      levels: Array<number>;
    } | null;
    departments: Array<{
      __typename: "Department";
      id: number;
      name: string;
      ladders: Array<{ __typename: "Ladder"; id: number; name: string }>;
    }>;
  };
  compCycle: {
    __typename: "CompCycle";
    id: number;
    perfRatings: Array<string>;
  } | null;
  managers: Array<{ __typename: "Employee"; id: number; displayName: string }>;
  actableManagers: Array<{
    __typename: "MinimalEmployee2";
    id: number;
    displayName: string | null;
  }> | null;
  markets: Array<{
    __typename: "Market";
    id: number;
    name: string;
    locationGroups: Array<{
      __typename: "LocationGroup";
      id: number;
      name: string;
    }>;
  }>;
};

export type CompCycleSubmitChangesButton_currentEmployee = {
  __typename: "Employee";
  id: number;
  indirectReportsCount: number;
};

export type CompCycleSubmitChangesButton_participant = {
  __typename: "CompCycleParticipant";
  subjectId: number;
  compCycleId: number;
  compRecommendation: {
    __typename: "CompRecommendation2";
    subjectId: number;
    compCycleId: number;
    canICurrentlyReview: boolean;
    latestSubmittedReviews: Array<{
      __typename: "RecReview2";
      id: number;
      submittedAt: GraphQL_DateTime | null;
      authorId: number;
    }>;
    latestSubmittedItems: Array<{
      __typename: "RecItem2";
      id: number;
      submittedAt: GraphQL_DateTime | null;
    }>;
  } | null;
};

export type CompCycleSubmitChangesButtonLoadingBoundaryQueryVariables = Exact<{
  compCycleId: Scalars["Int"]["input"];
  filter?: InputMaybe<GetParticipantsInput>;
  skipEligibility?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type CompCycleSubmitChangesButtonLoadingBoundaryQuery = {
  whoami: {
    __typename: "AuthSubject";
    user: {
      __typename: "User";
      employee: {
        __typename: "Employee";
        id: number;
        indirectReportsCount: number;
      } | null;
    };
  } | null;
  compCycle2: {
    __typename: "CompCycle2";
    id: number;
    participants: {
      __typename: "ParticipantsResult2";
      participants: Array<{
        __typename: "CompCycleParticipant";
        subjectId: number;
        compCycleId: number;
        compRecommendation: {
          __typename: "CompRecommendation2";
          subjectId: number;
          compCycleId: number;
          canICurrentlyReview: boolean;
          latestSubmittedReviews: Array<{
            __typename: "RecReview2";
            id: number;
            submittedAt: GraphQL_DateTime | null;
            authorId: number;
          }>;
          latestSubmittedItems: Array<{
            __typename: "RecItem2";
            id: number;
            submittedAt: GraphQL_DateTime | null;
          }>;
        } | null;
      }>;
    };
  } | null;
};

export type CondensedTable2_participant = {
  __typename: "CompCycleParticipant";
  subjectId: number;
  perfRating: string | null;
  compCycleId: number;
  lastActivityAt: GraphQL_Date | null;
  compRecommendation: {
    __typename: "CompRecommendation2";
    subjectId: number;
    compCycleId: number;
    reviewStatus: CompRecommendationStatus;
    canICurrentlyReview: boolean;
    canICurrentlyEdit: boolean;
    latestSubmittedItems: Array<{
      __typename: "RecItem2";
      id: number;
      recommendationType: RecItemType;
      recommendedCashValue: GraphQL_Money | null;
      unitType: CompUnit | null;
      recommendedPercentValue: number | null;
      recommendedEquityUnitCount: number | null;
      submittedAt: GraphQL_DateTime | null;
      recommendedPosition: {
        __typename: "Position";
        id: number;
        name: string;
        level: number;
        type: PositionType;
        ladder: {
          __typename: "Ladder";
          id: number;
          name: string;
          department: { __typename: "Department"; id: number; name: string };
        };
      } | null;
      adjustedCashBands: Array<{
        __typename: "AdjustedCashBand";
        id: string;
        name: string;
        bandPoints: Array<{
          __typename: "AdjustedCashBandPoint";
          name: string;
          annualCashEquivalent: GraphQL_Money;
          value: {
            __typename: "CashValue";
            annualRate: GraphQL_Money | null;
            hourlyRate: GraphQL_Money | null;
            currencyCode: CurrencyCode;
          };
        }>;
      }> | null;
    }>;
    latestSubmittedPayIncrease: {
      __typename: "CompValue";
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      unitType: CompUnit | null;
    };
    allSubmittedItems: Array<{
      __typename: "RecItem2";
      id: number;
      authorId: number;
    }>;
    latestSubmittedReviews: Array<{
      __typename: "RecReview2";
      id: number;
      status: RecReviewStatus;
      submittedAt: GraphQL_DateTime | null;
      author: {
        __typename: "User2";
        id: number;
        employee: { __typename: "Employee2"; id: number } | null;
      };
    }>;
    allSubmittedReviews: Array<{
      __typename: "RecReview2";
      id: number;
      authorId: number;
    }>;
  } | null;
  subject: {
    __typename: "Employee2";
    id: number;
    displayName: string;
    userId: number | null;
    photoURL: string | null;
    activeAt: GraphQL_Date | null;
    lastCompChangeDate: GraphQL_Date | null;
    activeEmployment: {
      __typename: "Employment2";
      id: number;
      payPeriod: PayPeriodType;
      salary: GraphQL_Money | null;
      payCurrencyCode: string | null;
      jobTitle: string | null;
      position: {
        __typename: "Position";
        id: number;
        name: string;
        type: PositionType;
        level: number;
        ladder: {
          __typename: "Ladder";
          id: number;
          name: string;
          department: { __typename: "Department"; id: number; name: string };
        };
      } | null;
    } | null;
    manager: {
      __typename: "MinimalEmployee2";
      id: number;
      displayName: string | null;
      user: { __typename: "User2"; id: number; photoURL: string | null } | null;
    } | null;
    location: { __typename: "LocationGroup2"; id: number; name: string } | null;
    activeCashCompensation: Array<{
      __typename: "CashCompensation2";
      employeeId: number;
      type: CashCompType;
      activeAt: GraphQL_Date;
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      unit: CompUnit;
      percentOfSalary: number;
    }> | null;
    adjustedCashBands: Array<{
      __typename: "AdjustedCashBand";
      id: string;
      name: string;
      bandPoints: Array<{
        __typename: "AdjustedCashBandPoint";
        name: string;
        annualCashEquivalent: GraphQL_Money;
        id: string;
        bandName: string;
        value: {
          __typename: "CashValue";
          annualRate: GraphQL_Money | null;
          hourlyRate: GraphQL_Money | null;
          currencyCode: CurrencyCode;
        };
      }>;
    }> | null;
    adjustedEquityBands: Array<{
      __typename: "AdjustedEquityBand";
      id: string;
      name: string;
      bandPoints: Array<{
        __typename: "AdjustedEquityBandPoint";
        bandName: string;
        annualCashEquivalent: GraphQL_Money;
      }>;
    }> | null;
    lastCompChangeAmount: {
      __typename: "CompValue";
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      unitType: CompUnit | null;
    } | null;
    equityHoldings: {
      __typename: "EquityHoldings2";
      id: number;
      totalGrossHoldingValue: GraphQL_Money | null;
      totalUnitCount: number;
    };
    user: { __typename: "User2"; id: number; photoURL: string | null } | null;
  };
  phaseTimeline: Array<{
    __typename: "CompCyclePhaseAssignment2";
    id: number;
    phaseId: number;
    status: PhaseTimelineStatus | null;
    assigneeId: number;
    employee?: {
      __typename: "Employee2";
      id: number;
      displayName: string;
      activeEmployment: {
        __typename: "Employment2";
        id: number;
        jobTitle: string | null;
      } | null;
      user: { __typename: "User2"; id: number } | null;
    };
    phase: {
      __typename: "CompCyclePhase2";
      id: number;
      phaseOrder: number;
      startDate: GraphQL_Date;
    } | null;
  }>;
};

export type CondensedTable2_position = {
  __typename: "Position";
  id: number;
  level: number;
  name: string;
  type: PositionType;
  ladder: {
    __typename: "Ladder";
    id: number;
    name: string;
    department: { __typename: "Department"; id: number; name: string };
  };
};

export type CondensedTable2_valuation = {
  __typename: "Valuation";
  id: number;
  fdso: number;
  valuation: GraphQL_Money;
};

export type CondensedTable2_compCycleBudget = {
  __typename: "CompCycleBudget";
  compCycleId: number;
  employeeId: number | null;
  salary: GraphQL_Money | null;
  salaryMarket: GraphQL_Money | null;
  salaryMerit: GraphQL_Money | null;
  salaryPromotion: GraphQL_Money | null;
  bonus: GraphQL_Money | null;
  equity: GraphQL_Money | null;
  targetRecurringBonus: GraphQL_Money | null;
  targetCommission: GraphQL_Money | null;
  actualRecurringBonus: GraphQL_Money | null;
};

export type CondensedTable2_user = {
  __typename: "User";
  id: number;
  employee: {
    __typename: "Employee";
    id: number;
    directReports: Array<{ __typename: "Employee"; id: number }>;
    indirectReports: Array<{ __typename: "Employee"; id: number }>;
  } | null;
};

export type CondensedTable2_compCycle = {
  __typename: "CompCycle2";
  id: number;
  finalApproversIds: Array<number>;
  allowSalary: boolean;
  allowSalaryMarket: boolean;
  allowSalaryMerit: boolean;
  allowSalaryPromotion: boolean;
  allowBonus: boolean;
  allowEquity: boolean;
  allowActualRecurringBonus: boolean;
  allowTargetCommission: boolean;
  allowTargetRecurringBonus: boolean;
  recommendationsPreFill: boolean;
  phases: Array<{
    __typename: "CompCyclePhase2";
    id: number;
    phaseOrder: number;
    startDate: GraphQL_Date;
  }>;
  currentPhase: {
    __typename: "CompCyclePhase2";
    id: number;
    phaseOrder: number;
    startDate: GraphQL_Date;
  } | null;
};

export type CondensedTableHeader_compCycle2 = {
  __typename: "CompCycle2";
  id: number;
  name: string;
  phases: Array<{
    __typename: "CompCyclePhase2";
    id: number;
    startDate: GraphQL_Date;
  }>;
};

export type CondensedTableLoadingBoundaryVariables = Exact<{
  compCycleId: Scalars["Int"]["input"];
  isAdmin: Scalars["Boolean"]["input"];
}>;

export type CondensedTableLoadingBoundary = {
  compCycle2: {
    __typename: "CompCycle2";
    id: number;
    currentPhase: {
      __typename: "CompCyclePhase2";
      id: number;
      phaseOrder: number;
      startDate: GraphQL_Date;
    } | null;
    phases: Array<{
      __typename: "CompCyclePhase2";
      id: number;
      phaseOrder: number;
      startDate: GraphQL_Date;
      isMyPhase: boolean;
      compCyclePhaseAssignments: Array<{
        __typename: "CompCyclePhaseAssignment2";
        id: number;
        phaseId: number;
        status: PhaseTimelineStatus | null;
        assigneeId: number;
      } | null>;
    }>;
  } | null;
};

export type CondensedTablePeople2_compCycle = {
  __typename: "CompCycle2";
  id: number;
  name: string;
  closeDate: GraphQL_Date | null;
  finalApproversIds: Array<number>;
  allowSalary: boolean;
  allowSalaryMarket: boolean;
  allowSalaryMerit: boolean;
  allowSalaryPromotion: boolean;
  allowBonus: boolean;
  allowEquity: boolean;
  allowActualRecurringBonus: boolean;
  allowTargetCommission: boolean;
  allowTargetRecurringBonus: boolean;
  recommendationsPreFill: boolean;
  participants: {
    __typename: "ParticipantsResult2";
    count: number;
    participants: Array<{
      __typename: "CompCycleParticipant";
      compCycleId: number;
      subjectId: number;
      perfRating: string | null;
      lastActivityAt: GraphQL_Date | null;
      compRecommendation: {
        __typename: "CompRecommendation2";
        reviewStatus: CompRecommendationStatus;
        subjectId: number;
        compCycleId: number;
        canICurrentlyReview: boolean;
        canICurrentlyEdit: boolean;
        latestSubmittedReviews: Array<{
          __typename: "RecReview2";
          id: number;
          status: RecReviewStatus;
          submittedAt: GraphQL_DateTime | null;
          author: {
            __typename: "User2";
            id: number;
            employee: { __typename: "Employee2"; id: number } | null;
          };
        }>;
        latestSubmittedItems: Array<{
          __typename: "RecItem2";
          id: number;
          recommendationType: RecItemType;
          recommendedCashValue: GraphQL_Money | null;
          unitType: CompUnit | null;
          recommendedPercentValue: number | null;
          recommendedEquityUnitCount: number | null;
          submittedAt: GraphQL_DateTime | null;
          recommendedPosition: {
            __typename: "Position";
            id: number;
            name: string;
            level: number;
            type: PositionType;
            ladder: {
              __typename: "Ladder";
              id: number;
              name: string;
              department: {
                __typename: "Department";
                id: number;
                name: string;
              };
            };
          } | null;
          adjustedCashBands: Array<{
            __typename: "AdjustedCashBand";
            id: string;
            name: string;
            bandPoints: Array<{
              __typename: "AdjustedCashBandPoint";
              name: string;
              annualCashEquivalent: GraphQL_Money;
              value: {
                __typename: "CashValue";
                annualRate: GraphQL_Money | null;
                hourlyRate: GraphQL_Money | null;
                currencyCode: CurrencyCode;
              };
            }>;
          }> | null;
        }>;
        latestSubmittedPayIncrease: {
          __typename: "CompValue";
          annualCashEquivalent: GraphQL_Money;
          hourlyCashEquivalent: GraphQL_Money;
          unitType: CompUnit | null;
        };
        allSubmittedItems: Array<{
          __typename: "RecItem2";
          id: number;
          authorId: number;
        }>;
        allSubmittedReviews: Array<{
          __typename: "RecReview2";
          id: number;
          authorId: number;
        }>;
      } | null;
      subject: {
        __typename: "Employee2";
        id: number;
        displayName: string;
        userId: number | null;
        photoURL: string | null;
        activeAt: GraphQL_Date | null;
        lastCompChangeDate: GraphQL_Date | null;
        activeEmployment: {
          __typename: "Employment2";
          id: number;
          payPeriod: PayPeriodType;
          salary: GraphQL_Money | null;
          payCurrencyCode: string | null;
          jobTitle: string | null;
          position: {
            __typename: "Position";
            id: number;
            name: string;
            type: PositionType;
            level: number;
            ladder: {
              __typename: "Ladder";
              id: number;
              name: string;
              department: {
                __typename: "Department";
                id: number;
                name: string;
              };
            };
          } | null;
        } | null;
        manager: {
          __typename: "MinimalEmployee2";
          id: number;
          displayName: string | null;
          user: {
            __typename: "User2";
            id: number;
            photoURL: string | null;
          } | null;
        } | null;
        location: {
          __typename: "LocationGroup2";
          id: number;
          name: string;
        } | null;
        activeCashCompensation: Array<{
          __typename: "CashCompensation2";
          employeeId: number;
          type: CashCompType;
          activeAt: GraphQL_Date;
          annualCashEquivalent: GraphQL_Money;
          hourlyCashEquivalent: GraphQL_Money;
          unit: CompUnit;
          percentOfSalary: number;
        }> | null;
        adjustedCashBands: Array<{
          __typename: "AdjustedCashBand";
          id: string;
          name: string;
          bandPoints: Array<{
            __typename: "AdjustedCashBandPoint";
            name: string;
            annualCashEquivalent: GraphQL_Money;
            id: string;
            bandName: string;
            value: {
              __typename: "CashValue";
              annualRate: GraphQL_Money | null;
              hourlyRate: GraphQL_Money | null;
              currencyCode: CurrencyCode;
            };
          }>;
        }> | null;
        adjustedEquityBands: Array<{
          __typename: "AdjustedEquityBand";
          id: string;
          name: string;
          bandPoints: Array<{
            __typename: "AdjustedEquityBandPoint";
            bandName: string;
            annualCashEquivalent: GraphQL_Money;
          }>;
        }> | null;
        lastCompChangeAmount: {
          __typename: "CompValue";
          annualCashEquivalent: GraphQL_Money;
          hourlyCashEquivalent: GraphQL_Money;
          unitType: CompUnit | null;
        } | null;
        equityHoldings: {
          __typename: "EquityHoldings2";
          id: number;
          totalGrossHoldingValue: GraphQL_Money | null;
          totalUnitCount: number;
        };
        user: {
          __typename: "User2";
          id: number;
          photoURL: string | null;
        } | null;
      };
      phaseTimeline: Array<{
        __typename: "CompCyclePhaseAssignment2";
        id: number;
        phaseId: number;
        status: PhaseTimelineStatus | null;
        assigneeId: number;
        employee?: {
          __typename: "Employee2";
          id: number;
          displayName: string;
          activeEmployment: {
            __typename: "Employment2";
            id: number;
            jobTitle: string | null;
          } | null;
          user: { __typename: "User2"; id: number } | null;
        };
        phase: {
          __typename: "CompCyclePhase2";
          id: number;
          phaseOrder: number;
          startDate: GraphQL_Date;
        } | null;
      }>;
    }>;
  };
  phases: Array<{
    __typename: "CompCyclePhase2";
    id: number;
    phaseOrder: number;
    startDate: GraphQL_Date;
  }>;
  currentPhase: {
    __typename: "CompCyclePhase2";
    id: number;
    phaseOrder: number;
    startDate: GraphQL_Date;
  } | null;
};

export type CondensedTablePeople2_participant = {
  __typename: "CompCycleParticipant";
  subjectId: number;
  compCycleId: number;
  perfRating: string | null;
  lastActivityAt: GraphQL_Date | null;
  compRecommendation: {
    __typename: "CompRecommendation2";
    subjectId: number;
    compCycleId: number;
    reviewStatus: CompRecommendationStatus;
    canICurrentlyReview: boolean;
    canICurrentlyEdit: boolean;
    latestSubmittedReviews: Array<{
      __typename: "RecReview2";
      id: number;
      status: RecReviewStatus;
      submittedAt: GraphQL_DateTime | null;
      author: {
        __typename: "User2";
        id: number;
        employee: { __typename: "Employee2"; id: number } | null;
      };
    }>;
    latestSubmittedItems: Array<{
      __typename: "RecItem2";
      id: number;
      recommendationType: RecItemType;
      recommendedCashValue: GraphQL_Money | null;
      unitType: CompUnit | null;
      recommendedPercentValue: number | null;
      recommendedEquityUnitCount: number | null;
      submittedAt: GraphQL_DateTime | null;
      recommendedPosition: {
        __typename: "Position";
        id: number;
        name: string;
        level: number;
        type: PositionType;
        ladder: {
          __typename: "Ladder";
          id: number;
          name: string;
          department: { __typename: "Department"; id: number; name: string };
        };
      } | null;
      adjustedCashBands: Array<{
        __typename: "AdjustedCashBand";
        id: string;
        name: string;
        bandPoints: Array<{
          __typename: "AdjustedCashBandPoint";
          name: string;
          annualCashEquivalent: GraphQL_Money;
          value: {
            __typename: "CashValue";
            annualRate: GraphQL_Money | null;
            hourlyRate: GraphQL_Money | null;
            currencyCode: CurrencyCode;
          };
        }>;
      }> | null;
    }>;
    latestSubmittedPayIncrease: {
      __typename: "CompValue";
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      unitType: CompUnit | null;
    };
    allSubmittedItems: Array<{
      __typename: "RecItem2";
      id: number;
      authorId: number;
    }>;
    allSubmittedReviews: Array<{
      __typename: "RecReview2";
      id: number;
      authorId: number;
    }>;
  } | null;
  subject: {
    __typename: "Employee2";
    id: number;
    displayName: string;
    userId: number | null;
    photoURL: string | null;
    activeAt: GraphQL_Date | null;
    lastCompChangeDate: GraphQL_Date | null;
    activeEmployment: {
      __typename: "Employment2";
      id: number;
      payPeriod: PayPeriodType;
      salary: GraphQL_Money | null;
      payCurrencyCode: string | null;
      jobTitle: string | null;
      position: {
        __typename: "Position";
        id: number;
        name: string;
        type: PositionType;
        level: number;
        ladder: {
          __typename: "Ladder";
          id: number;
          name: string;
          department: { __typename: "Department"; id: number; name: string };
        };
      } | null;
    } | null;
    manager: {
      __typename: "MinimalEmployee2";
      id: number;
      displayName: string | null;
      user: { __typename: "User2"; id: number; photoURL: string | null } | null;
    } | null;
    location: { __typename: "LocationGroup2"; id: number; name: string } | null;
    activeCashCompensation: Array<{
      __typename: "CashCompensation2";
      employeeId: number;
      type: CashCompType;
      activeAt: GraphQL_Date;
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      unit: CompUnit;
      percentOfSalary: number;
    }> | null;
    adjustedCashBands: Array<{
      __typename: "AdjustedCashBand";
      id: string;
      name: string;
      bandPoints: Array<{
        __typename: "AdjustedCashBandPoint";
        name: string;
        annualCashEquivalent: GraphQL_Money;
        id: string;
        bandName: string;
        value: {
          __typename: "CashValue";
          annualRate: GraphQL_Money | null;
          hourlyRate: GraphQL_Money | null;
          currencyCode: CurrencyCode;
        };
      }>;
    }> | null;
    adjustedEquityBands: Array<{
      __typename: "AdjustedEquityBand";
      id: string;
      name: string;
      bandPoints: Array<{
        __typename: "AdjustedEquityBandPoint";
        bandName: string;
        annualCashEquivalent: GraphQL_Money;
      }>;
    }> | null;
    lastCompChangeAmount: {
      __typename: "CompValue";
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      unitType: CompUnit | null;
    } | null;
    equityHoldings: {
      __typename: "EquityHoldings2";
      id: number;
      totalGrossHoldingValue: GraphQL_Money | null;
      totalUnitCount: number;
    };
    user: { __typename: "User2"; id: number; photoURL: string | null } | null;
  };
  phaseTimeline: Array<{
    __typename: "CompCyclePhaseAssignment2";
    id: number;
    phaseId: number;
    status: PhaseTimelineStatus | null;
    assigneeId: number;
    employee?: {
      __typename: "Employee2";
      id: number;
      displayName: string;
      activeEmployment: {
        __typename: "Employment2";
        id: number;
        jobTitle: string | null;
      } | null;
      user: { __typename: "User2"; id: number } | null;
    };
    phase: {
      __typename: "CompCyclePhase2";
      id: number;
      phaseOrder: number;
      startDate: GraphQL_Date;
    } | null;
  }>;
};

export type CondensedTablePeople2_position = {
  __typename: "Position";
  id: number;
  level: number;
  name: string;
  type: PositionType;
  ladder: {
    __typename: "Ladder";
    id: number;
    name: string;
    department: { __typename: "Department"; id: number; name: string };
  };
};

export type CondensedTablePeople2_valuation = {
  __typename: "Valuation";
  id: number;
  fdso: number;
  valuation: GraphQL_Money;
};

export type CondensedTablePeople2_compCycleBudget = {
  __typename: "CompCycleBudget";
  compCycleId: number;
  employeeId: number | null;
  salary: GraphQL_Money | null;
  salaryMarket: GraphQL_Money | null;
  salaryMerit: GraphQL_Money | null;
  salaryPromotion: GraphQL_Money | null;
  bonus: GraphQL_Money | null;
  equity: GraphQL_Money | null;
  targetRecurringBonus: GraphQL_Money | null;
  targetCommission: GraphQL_Money | null;
  actualRecurringBonus: GraphQL_Money | null;
};

export type CondensedTablePeople2_user = {
  __typename: "User";
  id: number;
  employee: {
    __typename: "Employee";
    id: number;
    directReports: Array<{ __typename: "Employee"; id: number }>;
    indirectReports: Array<{ __typename: "Employee"; id: number }>;
  } | null;
};

export type CondensedTable_meritAdjustmentGuide = {
  __typename: "MeritAdjustmentGuide";
  id: number;
  perfRatingOptionId: number;
  meritAdjustmentRangeId: number;
  percent: number;
  compCycleId: number;
  organizationId: number;
};

export type CondensedTable_perfRatingOption = {
  __typename: "PerfRatingOption";
  id: number;
  name: string;
  compCycleId: number;
  organizationId: number;
  rank: number;
};

export type CondensedTable_meritAdjustmentRange = {
  __typename: "MeritAdjustmentRange";
  id: number;
  rangeStart: number;
  compCycleId: number;
  organizationId: number;
};

export type CondensedTablePeopleLoadingBoundaryQueryVariables = Exact<{
  compCycleId: Scalars["Int"]["input"];
  filter?: InputMaybe<GetParticipantsInput>;
  currencyCode?: InputMaybe<CurrencyCode>;
  employeeIds: Array<Scalars["Int"]["input"]> | Scalars["Int"]["input"];
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  sortBy?: InputMaybe<Scalars["String"]["input"]>;
  sortDir?: InputMaybe<Scalars["String"]["input"]>;
  isAdmin: Scalars["Boolean"]["input"];
  skipEligibility?: InputMaybe<Scalars["Boolean"]["input"]>;
  actingManagerEmployeeId?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type CondensedTablePeopleLoadingBoundaryQuery = {
  compCycle2: {
    __typename: "CompCycle2";
    id: number;
    name: string;
    closeDate: GraphQL_Date | null;
    finalApproversIds: Array<number>;
    allowSalary: boolean;
    allowSalaryMarket: boolean;
    allowSalaryMerit: boolean;
    allowSalaryPromotion: boolean;
    allowBonus: boolean;
    allowEquity: boolean;
    allowActualRecurringBonus: boolean;
    allowTargetCommission: boolean;
    allowTargetRecurringBonus: boolean;
    recommendationsPreFill: boolean;
    participants: {
      __typename: "ParticipantsResult2";
      count: number;
      participants: Array<{
        __typename: "CompCycleParticipant";
        compCycleId: number;
        subjectId: number;
        perfRating: string | null;
        lastActivityAt: GraphQL_Date | null;
        compRecommendation: {
          __typename: "CompRecommendation2";
          reviewStatus: CompRecommendationStatus;
          subjectId: number;
          compCycleId: number;
          canICurrentlyReview: boolean;
          canICurrentlyEdit: boolean;
          latestSubmittedReviews: Array<{
            __typename: "RecReview2";
            id: number;
            status: RecReviewStatus;
            submittedAt: GraphQL_DateTime | null;
            author: {
              __typename: "User2";
              id: number;
              employee: { __typename: "Employee2"; id: number } | null;
            };
          }>;
          latestSubmittedItems: Array<{
            __typename: "RecItem2";
            id: number;
            recommendationType: RecItemType;
            recommendedCashValue: GraphQL_Money | null;
            unitType: CompUnit | null;
            recommendedPercentValue: number | null;
            recommendedEquityUnitCount: number | null;
            submittedAt: GraphQL_DateTime | null;
            recommendedPosition: {
              __typename: "Position";
              id: number;
              name: string;
              level: number;
              type: PositionType;
              ladder: {
                __typename: "Ladder";
                id: number;
                name: string;
                department: {
                  __typename: "Department";
                  id: number;
                  name: string;
                };
              };
            } | null;
            adjustedCashBands: Array<{
              __typename: "AdjustedCashBand";
              id: string;
              name: string;
              bandPoints: Array<{
                __typename: "AdjustedCashBandPoint";
                name: string;
                annualCashEquivalent: GraphQL_Money;
                value: {
                  __typename: "CashValue";
                  annualRate: GraphQL_Money | null;
                  hourlyRate: GraphQL_Money | null;
                  currencyCode: CurrencyCode;
                };
              }>;
            }> | null;
          }>;
          latestSubmittedPayIncrease: {
            __typename: "CompValue";
            annualCashEquivalent: GraphQL_Money;
            hourlyCashEquivalent: GraphQL_Money;
            unitType: CompUnit | null;
          };
          allSubmittedItems: Array<{
            __typename: "RecItem2";
            id: number;
            authorId: number;
          }>;
          allSubmittedReviews: Array<{
            __typename: "RecReview2";
            id: number;
            authorId: number;
          }>;
        } | null;
        subject: {
          __typename: "Employee2";
          id: number;
          displayName: string;
          userId: number | null;
          photoURL: string | null;
          activeAt: GraphQL_Date | null;
          lastCompChangeDate: GraphQL_Date | null;
          activeEmployment: {
            __typename: "Employment2";
            id: number;
            payPeriod: PayPeriodType;
            salary: GraphQL_Money | null;
            payCurrencyCode: string | null;
            jobTitle: string | null;
            position: {
              __typename: "Position";
              id: number;
              name: string;
              type: PositionType;
              level: number;
              ladder: {
                __typename: "Ladder";
                id: number;
                name: string;
                department: {
                  __typename: "Department";
                  id: number;
                  name: string;
                };
              };
            } | null;
          } | null;
          manager: {
            __typename: "MinimalEmployee2";
            id: number;
            displayName: string | null;
            user: {
              __typename: "User2";
              id: number;
              photoURL: string | null;
            } | null;
          } | null;
          location: {
            __typename: "LocationGroup2";
            id: number;
            name: string;
          } | null;
          activeCashCompensation: Array<{
            __typename: "CashCompensation2";
            employeeId: number;
            type: CashCompType;
            activeAt: GraphQL_Date;
            annualCashEquivalent: GraphQL_Money;
            hourlyCashEquivalent: GraphQL_Money;
            unit: CompUnit;
            percentOfSalary: number;
          }> | null;
          adjustedCashBands: Array<{
            __typename: "AdjustedCashBand";
            id: string;
            name: string;
            bandPoints: Array<{
              __typename: "AdjustedCashBandPoint";
              name: string;
              annualCashEquivalent: GraphQL_Money;
              id: string;
              bandName: string;
              value: {
                __typename: "CashValue";
                annualRate: GraphQL_Money | null;
                hourlyRate: GraphQL_Money | null;
                currencyCode: CurrencyCode;
              };
            }>;
          }> | null;
          adjustedEquityBands: Array<{
            __typename: "AdjustedEquityBand";
            id: string;
            name: string;
            bandPoints: Array<{
              __typename: "AdjustedEquityBandPoint";
              bandName: string;
              annualCashEquivalent: GraphQL_Money;
            }>;
          }> | null;
          lastCompChangeAmount: {
            __typename: "CompValue";
            annualCashEquivalent: GraphQL_Money;
            hourlyCashEquivalent: GraphQL_Money;
            unitType: CompUnit | null;
          } | null;
          equityHoldings: {
            __typename: "EquityHoldings2";
            id: number;
            totalGrossHoldingValue: GraphQL_Money | null;
            totalUnitCount: number;
          };
          user: {
            __typename: "User2";
            id: number;
            photoURL: string | null;
          } | null;
        };
        phaseTimeline: Array<{
          __typename: "CompCyclePhaseAssignment2";
          id: number;
          phaseId: number;
          status: PhaseTimelineStatus | null;
          assigneeId: number;
          employee?: {
            __typename: "Employee2";
            id: number;
            displayName: string;
            activeEmployment: {
              __typename: "Employment2";
              id: number;
              jobTitle: string | null;
            } | null;
            user: { __typename: "User2"; id: number } | null;
          };
          phase: {
            __typename: "CompCyclePhase2";
            id: number;
            phaseOrder: number;
            startDate: GraphQL_Date;
          } | null;
        }>;
      }>;
    };
    meritAdjustmentGuides: Array<{
      __typename: "MeritAdjustmentGuide";
      id: number;
      perfRatingOptionId: number;
      meritAdjustmentRangeId: number;
      percent: number;
      compCycleId: number;
      organizationId: number;
    }>;
    phases: Array<{
      __typename: "CompCyclePhase2";
      id: number;
      phaseOrder: number;
      startDate: GraphQL_Date;
    }>;
    currentPhase: {
      __typename: "CompCyclePhase2";
      id: number;
      phaseOrder: number;
      startDate: GraphQL_Date;
    } | null;
  } | null;
  positions: Array<{
    __typename: "Position";
    id: number;
    level: number;
    name: string;
    type: PositionType;
    ladder: {
      __typename: "Ladder";
      id: number;
      name: string;
      department: { __typename: "Department"; id: number; name: string };
    };
  }>;
  valuation: {
    __typename: "Valuation";
    id: number;
    fdso: number;
    valuation: GraphQL_Money;
  } | null;
  compCycleBudgets: {
    __typename: "CompCycleBudget";
    compCycleId: number;
    employeeId: number | null;
    salary: GraphQL_Money | null;
    salaryMarket: GraphQL_Money | null;
    salaryMerit: GraphQL_Money | null;
    salaryPromotion: GraphQL_Money | null;
    bonus: GraphQL_Money | null;
    equity: GraphQL_Money | null;
    targetRecurringBonus: GraphQL_Money | null;
    targetCommission: GraphQL_Money | null;
    actualRecurringBonus: GraphQL_Money | null;
  } | null;
  whoami: {
    __typename: "AuthSubject";
    user: {
      __typename: "User";
      id: number;
      employee: {
        __typename: "Employee";
        id: number;
        directReports: Array<{ __typename: "Employee"; id: number }>;
        indirectReports: Array<{ __typename: "Employee"; id: number }>;
      } | null;
    };
  } | null;
  perfRatingOptions: Array<{
    __typename: "PerfRatingOption";
    id: number;
    name: string;
    compCycleId: number;
    organizationId: number;
    rank: number;
  }>;
  meritAdjustmentRanges: Array<{
    __typename: "MeritAdjustmentRange";
    id: number;
    rangeStart: number;
    compCycleId: number;
    organizationId: number;
  }>;
};

export type CondensedTableStatusSelect_compCycle = {
  __typename: "CompCycle2";
  id: number;
  phases: Array<{
    __typename: "CompCyclePhase2";
    id: number;
    startDate: GraphQL_Date;
  }>;
};

export type CondensedTableView_compCycle = {
  __typename: "CompCycle2";
  id: number;
  phases: Array<{
    __typename: "CompCyclePhase2";
    id: number;
    phaseOrder: number;
    startDate: GraphQL_Date;
    isMyPhase: boolean;
    compCyclePhaseAssignments: Array<{
      __typename: "CompCyclePhaseAssignment2";
      id: number;
      phaseId: number;
      status: PhaseTimelineStatus | null;
      assigneeId: number;
    } | null>;
  }>;
  currentPhase: {
    __typename: "CompCyclePhase2";
    id: number;
    phaseOrder: number;
  } | null;
};

export type tableOrder = {
  __typename: "TableOrder";
  id: number;
  columnOrder: Array<string>;
  hiddenColumns: Array<string>;
};

export type GetTableOrderVariables = Exact<{
  tableName: NexusTableNameType;
}>;

export type GetTableOrder = {
  tableOrder: {
    __typename: "TableOrder";
    id: number;
    columnOrder: Array<string>;
    hiddenColumns: Array<string>;
  } | null;
};

export type UpsertTableOrderVariables = Exact<{
  data: TableOrderInput;
}>;

export type UpsertTableOrder = {
  upsertTableOrder: {
    __typename: "TableOrder";
    id: number;
    columnOrder: Array<string>;
    hiddenColumns: Array<string>;
  };
};

export type TableDataContext_position = {
  __typename: "Position";
  id: number;
  level: number;
  name: string;
  type: PositionType;
  ladder: {
    __typename: "Ladder";
    id: number;
    name: string;
    department: { __typename: "Department"; id: number; name: string };
  };
};

export type TableDataContext_user = {
  __typename: "User";
  id: number;
  employee: {
    __typename: "Employee";
    id: number;
    directReports: Array<{ __typename: "Employee"; id: number }>;
    indirectReports: Array<{ __typename: "Employee"; id: number }>;
  } | null;
};

export type TableDataContext2_position = {
  __typename: "Position";
  id: number;
  level: number;
  name: string;
  type: PositionType;
  ladder: {
    __typename: "Ladder";
    id: number;
    name: string;
    department: { __typename: "Department"; id: number; name: string };
  };
};

export type PhaseProgressBar_compCyclePhase = {
  __typename: "CompCyclePhase2";
  id: number;
  phaseOrder: number;
  startDate: GraphQL_Date;
  isMyPhase: boolean;
  compCyclePhaseAssignments: Array<{
    __typename: "CompCyclePhaseAssignment2";
    id: number;
    phaseId: number;
    status: PhaseTimelineStatus | null;
    assigneeId: number;
  } | null>;
};

export type PhaseProgressBar_compCycle = {
  __typename: "CompCycle2";
  id: number;
  phases: Array<{
    __typename: "CompCyclePhase2";
    id: number;
    phaseOrder: number;
    startDate: GraphQL_Date;
    isMyPhase: boolean;
    compCyclePhaseAssignments: Array<{
      __typename: "CompCyclePhaseAssignment2";
      id: number;
      phaseId: number;
      status: PhaseTimelineStatus | null;
      assigneeId: number;
    } | null>;
  }>;
  currentPhase: {
    __typename: "CompCyclePhase2";
    id: number;
    phaseOrder: number;
  } | null;
};

export type GetExploTokenVariables = Exact<{
  compCycleId: Scalars["Int"]["input"];
}>;

export type GetExploToken = {
  exploToken: { __typename: "ExploTokenResponse"; token: string };
};

export type CompCycleSettings_budget = {
  __typename: "CompCycleBudgetDraft";
  compCycleId: number;
  employeeId: number | null;
  salary: GraphQL_Money | null;
  equity: GraphQL_Money | null;
  bonus: GraphQL_Money | null;
  targetRecurringBonus: GraphQL_Money | null;
  targetCommission: GraphQL_Money | null;
  actualRecurringBonus: GraphQL_Money | null;
};

export type CompCycleSettings_compCycle = {
  __typename: "CompCycle";
  id: number;
  name: string;
  allowSalary: boolean;
  allowSalaryMarket: boolean;
  allowSalaryMerit: boolean;
  allowSalaryPromotion: boolean;
  allowEquity: boolean;
  allowBonus: boolean;
  allowTargetCommission: boolean;
  allowTargetRecurringBonus: boolean;
  allowActualRecurringBonus: boolean;
};

export type CompCycleSettings_valuation = {
  __typename: "Valuation";
  id: number;
  valuation: GraphQL_Money;
  fdso: number;
};

export type CompCycleSettingsQueryVariables = Exact<{
  compCycleId: Scalars["Int"]["input"];
}>;

export type CompCycleSettingsQuery = {
  compCycleBudgetDraft: {
    __typename: "CompCycleBudgetDraft";
    compCycleId: number;
    employeeId: number | null;
    salary: GraphQL_Money | null;
    equity: GraphQL_Money | null;
    bonus: GraphQL_Money | null;
    targetRecurringBonus: GraphQL_Money | null;
    targetCommission: GraphQL_Money | null;
    actualRecurringBonus: GraphQL_Money | null;
  };
  compCycle: {
    __typename: "CompCycle";
    id: number;
    name: string;
    endedAt: GraphQL_DateTime | null;
    awaitingReviewCount: number | null;
    allowSalary: boolean;
    allowSalaryMarket: boolean;
    allowSalaryMerit: boolean;
    allowSalaryPromotion: boolean;
    allowEquity: boolean;
    allowBonus: boolean;
    allowTargetCommission: boolean;
    allowTargetRecurringBonus: boolean;
    allowActualRecurringBonus: boolean;
    phases: Array<{ __typename: "CompCyclePhase"; id: number }>;
  } | null;
  valuation: {
    __typename: "Valuation";
    id: number;
    valuation: GraphQL_Money;
    fdso: number;
  } | null;
};

export type CompareTable_position = {
  __typename: "Position";
  id: number;
  name: string;
  level: number;
  jobCodes: Array<string>;
  ladder: { __typename: "Ladder"; id: number; name: string };
  adjustedCashBands: Array<{
    __typename: "AdjustedCashBand";
    id: string;
    name: string;
    bandPoints: Array<{
      __typename: "AdjustedCashBandPoint";
      id: string;
      name: string;
      bandName: string;
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      value: {
        __typename: "CashValue";
        annualRate: GraphQL_Money | null;
        hourlyRate: GraphQL_Money | null;
        currencyCode: CurrencyCode;
      };
    }>;
  }> | null;
  adjustedEquityBands: Array<{
    __typename: "AdjustedEquityBand";
    id: string;
    name: string;
    bandPoints: Array<{
      __typename: "AdjustedEquityBandPoint";
      id: string;
      name: string;
      bandName: string;
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      totalGrossValue: GraphQL_Money;
      totalUnits: number | null;
      vestingMonths: number;
      value:
        | {
            __typename: "CashValue";
            annualRate: GraphQL_Money | null;
            currencyCode: CurrencyCode;
          }
        | { __typename: "PercentValue"; decimalValue: number | null }
        | { __typename: "UnitValue"; unitValue: number | null };
      totalPercentOwnership: {
        __typename: "PercentValue";
        decimalValue: number | null;
      } | null;
    }>;
  }> | null;
};

export type GetLaddersVariables = Exact<{
  ladderIds: Array<Scalars["Int"]["input"]> | Scalars["Int"]["input"];
  currencyCode: CurrencyCode;
  marketId: Scalars["Int"]["input"];
  locationGroupId?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type GetLadders = {
  ladders: Array<{
    __typename: "Ladder";
    id: number;
    name: string;
    department: { __typename: "Department"; id: number; name: string };
    positions: Array<{
      __typename: "Position";
      id: number;
      name: string;
      level: number;
      jobCodes: Array<string>;
      ladder: { __typename: "Ladder"; id: number; name: string };
      adjustedCashBands: Array<{
        __typename: "AdjustedCashBand";
        id: string;
        name: string;
        bandPoints: Array<{
          __typename: "AdjustedCashBandPoint";
          id: string;
          name: string;
          bandName: string;
          annualCashEquivalent: GraphQL_Money;
          hourlyCashEquivalent: GraphQL_Money;
          value: {
            __typename: "CashValue";
            annualRate: GraphQL_Money | null;
            hourlyRate: GraphQL_Money | null;
            currencyCode: CurrencyCode;
          };
        }>;
      }> | null;
      adjustedEquityBands: Array<{
        __typename: "AdjustedEquityBand";
        id: string;
        name: string;
        bandPoints: Array<{
          __typename: "AdjustedEquityBandPoint";
          id: string;
          name: string;
          bandName: string;
          annualCashEquivalent: GraphQL_Money;
          hourlyCashEquivalent: GraphQL_Money;
          totalGrossValue: GraphQL_Money;
          totalUnits: number | null;
          vestingMonths: number;
          value:
            | {
                __typename: "CashValue";
                annualRate: GraphQL_Money | null;
                currencyCode: CurrencyCode;
              }
            | { __typename: "PercentValue"; decimalValue: number | null }
            | { __typename: "UnitValue"; unitValue: number | null };
          totalPercentOwnership: {
            __typename: "PercentValue";
            decimalValue: number | null;
          } | null;
        }>;
      }> | null;
    }>;
  }>;
  marketDataSets: Array<{
    __typename: "MarketDataSet";
    id: number;
    label: string;
    provider: string;
    createdAt: GraphQL_DateTime;
    market: { __typename: "Market"; id: number } | null;
    marketDataSamples: Array<{
      __typename: "MarketDataSample";
      id: number;
      jobCode: string;
      compType: string;
      sampleSize: string | null;
      currencyCode: string;
      marketDataPoints: Array<{
        __typename: "MarketDataPoint";
        percentile: number;
        value: number | null;
      }>;
    }>;
  }>;
};

export type CostToMove_costToMoveAnalysis = {
  __typename: "CostToMoveAnalysis";
  totalSpend: GraphQL_Money;
  costToMove: GraphQL_Money;
  averageTotalComparatio: number | null;
  averageAffectedComparatio: number | null;
  affectedEmployeeCount: number;
  totalEmployeeCount: number;
  employees: Array<{
    __typename: "EmployeeCostToMove";
    spend: GraphQL_Money;
    targetBandPoint: GraphQL_Money | null;
    costToMove: GraphQL_Money | null;
    convertedCostToMove: GraphQL_Money | null;
    comparatio: number | null;
    employee: {
      __typename: "Employee";
      id: number;
      displayName: string;
      email: string;
    };
  }>;
  byDepartment: Array<{
    __typename: "DepartmentCostToMove";
    department: { __typename: "Department"; id: number; name: string };
    costToMoveAnalysis: {
      __typename: "CostToMoveAnalysis";
      totalSpend: GraphQL_Money;
      costToMove: GraphQL_Money;
      affectedEmployeeCount: number;
      totalEmployeeCount: number;
    };
  }>;
};

export type CostToMoveDepartmentsTable_departmentCostToMove = {
  __typename: "DepartmentCostToMove";
  department: { __typename: "Department"; id: number; name: string };
  costToMoveAnalysis: {
    __typename: "CostToMoveAnalysis";
    totalSpend: GraphQL_Money;
    costToMove: GraphQL_Money;
    affectedEmployeeCount: number;
    totalEmployeeCount: number;
  };
};

export type CostToMoveEmployeesTable_employeeCostToMove = {
  __typename: "EmployeeCostToMove";
  spend: GraphQL_Money;
  targetBandPoint: GraphQL_Money | null;
  costToMove: GraphQL_Money | null;
  convertedCostToMove: GraphQL_Money | null;
  comparatio: number | null;
  employee: {
    __typename: "Employee";
    id: number;
    displayName: string;
    email: string;
  };
};

export type CostToMoveExportQueryVariables = Exact<{
  targetBandPoint: Scalars["String"]["input"];
  filter?: InputMaybe<GetEmployeesInput>;
}>;

export type CostToMoveExportQuery = {
  costToMoveAnalysis: {
    __typename: "CostToMoveAnalysis";
    employeeExport: string;
  };
};

export type CostToMoveAnalysisQueryVariables = Exact<{
  targetBandPoint: Scalars["String"]["input"];
  filter?: InputMaybe<GetEmployeesInput>;
}>;

export type CostToMoveAnalysisQuery = {
  costToMoveAnalysis: {
    __typename: "CostToMoveAnalysis";
    totalSpend: GraphQL_Money;
    costToMove: GraphQL_Money;
    averageTotalComparatio: number | null;
    averageAffectedComparatio: number | null;
    affectedEmployeeCount: number;
    totalEmployeeCount: number;
    employees: Array<{
      __typename: "EmployeeCostToMove";
      spend: GraphQL_Money;
      targetBandPoint: GraphQL_Money | null;
      costToMove: GraphQL_Money | null;
      convertedCostToMove: GraphQL_Money | null;
      comparatio: number | null;
      employee: {
        __typename: "Employee";
        id: number;
        displayName: string;
        email: string;
      };
    }>;
    byDepartment: Array<{
      __typename: "DepartmentCostToMove";
      department: { __typename: "Department"; id: number; name: string };
      costToMoveAnalysis: {
        __typename: "CostToMoveAnalysis";
        totalSpend: GraphQL_Money;
        costToMove: GraphQL_Money;
        affectedEmployeeCount: number;
        totalEmployeeCount: number;
      };
    }>;
  };
};

export type CostToMovePage_compStructure = {
  __typename: "CompStructure";
  id: number;
  levels: Array<number>;
  bandPointTypes: Array<string>;
};

export type CostToMovePage_employee = {
  __typename: "Employee";
  id: number;
  displayName: string;
};

export type CostToMovePage_department = {
  __typename: "Department";
  id: number;
  name: string;
  ladders: Array<{ __typename: "Ladder"; id: number; name: string }>;
};

export type CostToMovePage_market = {
  __typename: "Market";
  id: number;
  name: string;
  locationGroups: Array<{
    __typename: "LocationGroup";
    id: number;
    name: string;
  }>;
};

export type CostToMoveOuterPageQueryVariables = Exact<{ [key: string]: never }>;

export type CostToMoveOuterPageQuery = {
  compStructure: {
    __typename: "CompStructure";
    id: number;
    levels: Array<number>;
    bandPointTypes: Array<string>;
  } | null;
  managers: Array<{ __typename: "Employee"; id: number; displayName: string }>;
  departments: Array<{
    __typename: "Department";
    id: number;
    name: string;
    ladders: Array<{ __typename: "Ladder"; id: number; name: string }>;
  }>;
  markets: Array<{
    __typename: "Market";
    id: number;
    name: string;
    locationGroups: Array<{
      __typename: "LocationGroup";
      id: number;
      name: string;
    }>;
  }>;
};

export type CostToMoveTargetSelector_compStructure = {
  __typename: "CompStructure";
  id: number;
  bandPointTypes: Array<string>;
};

export type CostToMoveTopBar_compStructure = {
  __typename: "CompStructure";
  id: number;
  levels: Array<number>;
  bandPointTypes: Array<string>;
};

export type CostToMoveTopBar_employee = {
  __typename: "Employee";
  id: number;
  displayName: string;
};

export type CostToMoveTopBar_department = {
  __typename: "Department";
  id: number;
  name: string;
  ladders: Array<{ __typename: "Ladder"; id: number; name: string }>;
};

export type CostToMoveTopBar_market = {
  __typename: "Market";
  id: number;
  name: string;
  locationGroups: Array<{
    __typename: "LocationGroup";
    id: number;
    name: string;
  }>;
};

export type AnonymizedCompensationCells_position = {
  __typename: "Position";
  id: number;
  name: string;
  level: number;
  ladder: {
    __typename: "Ladder";
    id: number;
    name: string;
    department: { __typename: "Department"; id: number };
  };
  adjustedCashBands: Array<{
    __typename: "AdjustedCashBand";
    id: string;
    name: string;
    bandPoints: Array<{
      __typename: "AdjustedCashBandPoint";
      id: string;
      name: string;
      bandName: string;
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      value: {
        __typename: "CashValue";
        annualRate: GraphQL_Money | null;
        hourlyRate: GraphQL_Money | null;
        currencyCode: CurrencyCode;
      };
    }>;
  }> | null;
  adjustedEquityBands: Array<{
    __typename: "AdjustedEquityBand";
    id: string;
    name: string;
    bandPoints: Array<{
      __typename: "AdjustedEquityBandPoint";
      id: string;
      name: string;
      bandName: string;
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      totalGrossValue: GraphQL_Money;
      totalUnits: number | null;
      vestingMonths: number;
      value:
        | {
            __typename: "CashValue";
            annualRate: GraphQL_Money | null;
            currencyCode: CurrencyCode;
          }
        | { __typename: "PercentValue"; decimalValue: number | null }
        | { __typename: "UnitValue"; unitValue: number | null };
      totalPercentOwnership: {
        __typename: "PercentValue";
        decimalValue: number | null;
      } | null;
    }>;
  }> | null;
};

export type AnonymizedCompensationCells_anonymizedCompensation = {
  __typename: "AnonymizedCompensation";
  positionAndLocationGroup: {
    __typename: "PositionAndLocationGroupStatistics";
    employeeStatistics: { __typename: "EmployeeStatistics"; count: number };
    cashStatistics: {
      __typename: "CashStatistics";
      anonymizedDataPoints: Array<{
        __typename: "AnonymizedDataPoint";
        salary: GraphQL_Money | null;
        commission: GraphQL_Money | null;
        bonus: GraphQL_Money | null;
      }>;
    } | null;
  };
};

export type AnonymizedCompensationCells_permissionSettings = {
  __typename: "PermissionSettings";
  id: number;
  anonymizedCompensationEmployeeThreshold: number;
};

export type GetAnonymizedCompensationDataForVisualizationVariables = Exact<{
  currencyCode: Scalars["String"]["input"];
  positionId: Scalars["Int"]["input"];
  locationGroupId: Scalars["Int"]["input"];
}>;

export type GetAnonymizedCompensationDataForVisualization = {
  anonymizedCompensation: {
    __typename: "AnonymizedCompensation";
    positionAndLocationGroup: {
      __typename: "PositionAndLocationGroupStatistics";
      employeeStatistics: { __typename: "EmployeeStatistics"; count: number };
      cashStatistics: {
        __typename: "CashStatistics";
        anonymizedDataPoints: Array<{
          __typename: "AnonymizedDataPoint";
          salary: GraphQL_Money | null;
          commission: GraphQL_Money | null;
          bonus: GraphQL_Money | null;
        }>;
      } | null;
    };
  };
};

export type AnonymizedCompensationCellsLoadingBoundary_position = {
  __typename: "Position";
  id: number;
  name: string;
  level: number;
  ladder: {
    __typename: "Ladder";
    id: number;
    name: string;
    department: { __typename: "Department"; id: number };
  };
  adjustedCashBands: Array<{
    __typename: "AdjustedCashBand";
    id: string;
    name: string;
    bandPoints: Array<{
      __typename: "AdjustedCashBandPoint";
      id: string;
      name: string;
      bandName: string;
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      value: {
        __typename: "CashValue";
        annualRate: GraphQL_Money | null;
        hourlyRate: GraphQL_Money | null;
        currencyCode: CurrencyCode;
      };
    }>;
  }> | null;
  adjustedEquityBands: Array<{
    __typename: "AdjustedEquityBand";
    id: string;
    name: string;
    bandPoints: Array<{
      __typename: "AdjustedEquityBandPoint";
      id: string;
      name: string;
      bandName: string;
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      totalGrossValue: GraphQL_Money;
      totalUnits: number | null;
      vestingMonths: number;
      value:
        | {
            __typename: "CashValue";
            annualRate: GraphQL_Money | null;
            currencyCode: CurrencyCode;
          }
        | { __typename: "PercentValue"; decimalValue: number | null }
        | { __typename: "UnitValue"; unitValue: number | null };
      totalPercentOwnership: {
        __typename: "PercentValue";
        decimalValue: number | null;
      } | null;
    }>;
  }> | null;
};

export type AnonymizedCompensationCellsLoadingBoundary_permissionSettings = {
  __typename: "PermissionSettings";
  id: number;
  anonymizedCompensationEmployeeThreshold: number;
};

export type AnonymizedCompensationInformationCell_position = {
  __typename: "Position";
  id: number;
  level: number;
  ladder: {
    __typename: "Ladder";
    id: number;
    department: { __typename: "Department"; id: number };
  };
};

export type AnonymizedCompensationInformationCell_anonymizedCompensation = {
  __typename: "AnonymizedCompensation";
  positionAndLocationGroup: {
    __typename: "PositionAndLocationGroupStatistics";
    cashStatistics: {
      __typename: "CashStatistics";
      anonymizedDataPoints: Array<{
        __typename: "AnonymizedDataPoint";
        salary: GraphQL_Money | null;
        commission: GraphQL_Money | null;
        bonus: GraphQL_Money | null;
      }>;
    } | null;
    employeeStatistics: { __typename: "EmployeeStatistics"; count: number };
  };
};

export type AnonymizedCompensationInformationCell_permissionSettings = {
  __typename: "PermissionSettings";
  id: number;
  anonymizedCompensationEmployeeThreshold: number;
};

export type AnonymizedCompensationSwitch_permissionSettings = {
  __typename: "PermissionSettings";
  id: number;
  anonymizedCompensationStatisticsAccess: AnonymizedCompensationStatisticsAccessType;
};

export type LadderDetailBandCells_position = {
  __typename: "Position";
  id: number;
  jobCodes: Array<string>;
  adjustedCashBands: Array<{
    __typename: "AdjustedCashBand";
    id: string;
    name: string;
    bandPoints: Array<{
      __typename: "AdjustedCashBandPoint";
      id: string;
      name: string;
      bandName: string;
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      value: {
        __typename: "CashValue";
        annualRate: GraphQL_Money | null;
        hourlyRate: GraphQL_Money | null;
        currencyCode: CurrencyCode;
      };
    }>;
  }> | null;
  adjustedEquityBands: Array<{
    __typename: "AdjustedEquityBand";
    id: string;
    name: string;
    bandPoints: Array<{
      __typename: "AdjustedEquityBandPoint";
      id: string;
      name: string;
      bandName: string;
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      totalGrossValue: GraphQL_Money;
      totalUnits: number | null;
      vestingMonths: number;
      value:
        | {
            __typename: "CashValue";
            annualRate: GraphQL_Money | null;
            currencyCode: CurrencyCode;
          }
        | { __typename: "PercentValue"; decimalValue: number | null }
        | { __typename: "UnitValue"; unitValue: number | null };
      totalPercentOwnership: {
        __typename: "PercentValue";
        decimalValue: number | null;
      } | null;
    }>;
  }> | null;
};

export type LadderDetailBandVisualizationInformationCell_position = {
  __typename: "Position";
  id: number;
  adjustedCashBands: Array<{
    __typename: "AdjustedCashBand";
    id: string;
    name: string;
    bandPoints: Array<{
      __typename: "AdjustedCashBandPoint";
      id: string;
      name: string;
      bandName: string;
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      value: {
        __typename: "CashValue";
        annualRate: GraphQL_Money | null;
        hourlyRate: GraphQL_Money | null;
        currencyCode: CurrencyCode;
      };
    }>;
  }> | null;
  adjustedEquityBands: Array<{
    __typename: "AdjustedEquityBand";
    id: string;
    name: string;
    bandPoints: Array<{
      __typename: "AdjustedEquityBandPoint";
      id: string;
      name: string;
      bandName: string;
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      totalGrossValue: GraphQL_Money;
      totalUnits: number | null;
      vestingMonths: number;
      value:
        | {
            __typename: "CashValue";
            annualRate: GraphQL_Money | null;
            currencyCode: CurrencyCode;
          }
        | { __typename: "PercentValue"; decimalValue: number | null }
        | { __typename: "UnitValue"; unitValue: number | null };
      totalPercentOwnership: {
        __typename: "PercentValue";
        decimalValue: number | null;
      } | null;
    }>;
  }> | null;
};

export type LadderDetail_position = {
  __typename: "Position";
  id: number;
  level: number;
  name: string;
  description: string | null;
  type: PositionType;
  jobCodes: Array<string>;
  adjustedCashBands: Array<{
    __typename: "AdjustedCashBand";
    id: string;
    name: string;
    bandPoints: Array<{
      __typename: "AdjustedCashBandPoint";
      id: string;
      name: string;
      bandName: string;
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      value: {
        __typename: "CashValue";
        annualRate: GraphQL_Money | null;
        hourlyRate: GraphQL_Money | null;
        currencyCode: CurrencyCode;
      };
    }>;
  }> | null;
  adjustedEquityBands: Array<{
    __typename: "AdjustedEquityBand";
    id: string;
    name: string;
    bandPoints: Array<{
      __typename: "AdjustedEquityBandPoint";
      id: string;
      name: string;
      bandName: string;
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      totalGrossValue: GraphQL_Money;
      totalUnits: number | null;
      vestingMonths: number;
      value:
        | {
            __typename: "CashValue";
            annualRate: GraphQL_Money | null;
            currencyCode: CurrencyCode;
          }
        | { __typename: "PercentValue"; decimalValue: number | null }
        | { __typename: "UnitValue"; unitValue: number | null };
      totalPercentOwnership: {
        __typename: "PercentValue";
        decimalValue: number | null;
      } | null;
    }>;
  }> | null;
  ladder: {
    __typename: "Ladder";
    id: number;
    name: string;
    department: { __typename: "Department"; id: number };
  };
};

export type LadderDetail_permissionSettings = {
  __typename: "PermissionSettings";
  id: number;
  anonymizedCompensationStatisticsAccess: AnonymizedCompensationStatisticsAccessType;
  anonymizedCompensationEmployeeThreshold: number;
};

export type GetLadderVariables = Exact<{
  ladderId: Scalars["Int"]["input"];
  currencyCode: CurrencyCode;
  marketId: Scalars["Int"]["input"];
  locationGroupId?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type GetLadder = {
  ladder: {
    __typename: "Ladder";
    id: number;
    name: string;
    description: string | null;
    organization: { __typename: "Organization"; id: number; name: string };
    department: {
      __typename: "Department";
      id: number;
      name: string;
      ladders: Array<{ __typename: "Ladder"; id: number; name: string }>;
    };
    positions: Array<{
      __typename: "Position";
      id: number;
      level: number;
      name: string;
      description: string | null;
      type: PositionType;
      jobCodes: Array<string>;
      adjustedCashBands: Array<{
        __typename: "AdjustedCashBand";
        id: string;
        name: string;
        bandPoints: Array<{
          __typename: "AdjustedCashBandPoint";
          id: string;
          name: string;
          bandName: string;
          annualCashEquivalent: GraphQL_Money;
          hourlyCashEquivalent: GraphQL_Money;
          value: {
            __typename: "CashValue";
            annualRate: GraphQL_Money | null;
            hourlyRate: GraphQL_Money | null;
            currencyCode: CurrencyCode;
          };
        }>;
      }> | null;
      adjustedEquityBands: Array<{
        __typename: "AdjustedEquityBand";
        id: string;
        name: string;
        bandPoints: Array<{
          __typename: "AdjustedEquityBandPoint";
          id: string;
          name: string;
          bandName: string;
          annualCashEquivalent: GraphQL_Money;
          hourlyCashEquivalent: GraphQL_Money;
          totalGrossValue: GraphQL_Money;
          totalUnits: number | null;
          vestingMonths: number;
          value:
            | {
                __typename: "CashValue";
                annualRate: GraphQL_Money | null;
                currencyCode: CurrencyCode;
              }
            | { __typename: "PercentValue"; decimalValue: number | null }
            | { __typename: "UnitValue"; unitValue: number | null };
          totalPercentOwnership: {
            __typename: "PercentValue";
            decimalValue: number | null;
          } | null;
        }>;
      }> | null;
      ladder: {
        __typename: "Ladder";
        id: number;
        name: string;
        department: { __typename: "Department"; id: number };
      };
    }>;
  } | null;
  compStructure: {
    __typename: "CompStructure";
    id: number;
    bandPointTypes: Array<string>;
    cashBandTypes: Array<string>;
    equityBandTypes: Array<string>;
    allBandTypes: Array<string>;
    vestingMonths: number;
  } | null;
};

export type GetLadderDetailsVariables = Exact<{ [key: string]: never }>;

export type GetLadderDetails = {
  organization: {
    __typename: "Organization";
    id: number;
    permissionSettings: {
      __typename: "PermissionSettings";
      id: number;
      anonymizedCompensationStatisticsAccess: AnonymizedCompensationStatisticsAccessType;
      anonymizedCompensationEmployeeThreshold: number;
    };
  };
};

export type LadderDetailTable_position = {
  __typename: "Position";
  id: number;
  level: number;
  name: string;
  description: string | null;
  type: PositionType;
  jobCodes: Array<string>;
  adjustedCashBands: Array<{
    __typename: "AdjustedCashBand";
    id: string;
    name: string;
    bandPoints: Array<{
      __typename: "AdjustedCashBandPoint";
      id: string;
      name: string;
      bandName: string;
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      value: {
        __typename: "CashValue";
        annualRate: GraphQL_Money | null;
        hourlyRate: GraphQL_Money | null;
        currencyCode: CurrencyCode;
      };
    }>;
  }> | null;
  adjustedEquityBands: Array<{
    __typename: "AdjustedEquityBand";
    id: string;
    name: string;
    bandPoints: Array<{
      __typename: "AdjustedEquityBandPoint";
      id: string;
      name: string;
      bandName: string;
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      totalGrossValue: GraphQL_Money;
      totalUnits: number | null;
      vestingMonths: number;
      value:
        | {
            __typename: "CashValue";
            annualRate: GraphQL_Money | null;
            currencyCode: CurrencyCode;
          }
        | { __typename: "PercentValue"; decimalValue: number | null }
        | { __typename: "UnitValue"; unitValue: number | null };
      totalPercentOwnership: {
        __typename: "PercentValue";
        decimalValue: number | null;
      } | null;
    }>;
  }> | null;
  ladder: {
    __typename: "Ladder";
    id: number;
    name: string;
    department: { __typename: "Department"; id: number };
  };
};

export type LadderDetailTable_permissionSettings = {
  __typename: "PermissionSettings";
  id: number;
  anonymizedCompensationStatisticsAccess: AnonymizedCompensationStatisticsAccessType;
  anonymizedCompensationEmployeeThreshold: number;
};

export type LadderDetailTableHeader_permissionSettings = {
  __typename: "PermissionSettings";
  id: number;
  anonymizedCompensationStatisticsAccess: AnonymizedCompensationStatisticsAccessType;
};

export type LadderDetailTableRow_position = {
  __typename: "Position";
  id: number;
  name: string;
  description: string | null;
  level: number;
  type: PositionType;
  jobCodes: Array<string>;
  adjustedCashBands: Array<{
    __typename: "AdjustedCashBand";
    id: string;
    name: string;
    bandPoints: Array<{
      __typename: "AdjustedCashBandPoint";
      id: string;
      name: string;
      bandName: string;
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      value: {
        __typename: "CashValue";
        annualRate: GraphQL_Money | null;
        hourlyRate: GraphQL_Money | null;
        currencyCode: CurrencyCode;
      };
    }>;
  }> | null;
  adjustedEquityBands: Array<{
    __typename: "AdjustedEquityBand";
    id: string;
    name: string;
    bandPoints: Array<{
      __typename: "AdjustedEquityBandPoint";
      id: string;
      name: string;
      bandName: string;
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      totalGrossValue: GraphQL_Money;
      totalUnits: number | null;
      vestingMonths: number;
      value:
        | {
            __typename: "CashValue";
            annualRate: GraphQL_Money | null;
            currencyCode: CurrencyCode;
          }
        | { __typename: "PercentValue"; decimalValue: number | null }
        | { __typename: "UnitValue"; unitValue: number | null };
      totalPercentOwnership: {
        __typename: "PercentValue";
        decimalValue: number | null;
      } | null;
    }>;
  }> | null;
  ladder: {
    __typename: "Ladder";
    id: number;
    name: string;
    department: { __typename: "Department"; id: number };
  };
};

export type LadderDetailTableRow_permissionSettings = {
  __typename: "PermissionSettings";
  id: number;
  anonymizedCompensationEmployeeThreshold: number;
};

export type LadderDetailNoAccessCells_position = {
  __typename: "Position";
  id: number;
  adjustedCashBands: Array<{
    __typename: "AdjustedCashBand";
    id: string;
    name: string;
    bandPoints: Array<{
      __typename: "AdjustedCashBandPoint";
      id: string;
      name: string;
      bandName: string;
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      value: {
        __typename: "CashValue";
        annualRate: GraphQL_Money | null;
        hourlyRate: GraphQL_Money | null;
        currencyCode: CurrencyCode;
      };
    }>;
  }> | null;
  adjustedEquityBands: Array<{
    __typename: "AdjustedEquityBand";
    id: string;
    name: string;
    bandPoints: Array<{
      __typename: "AdjustedEquityBandPoint";
      id: string;
      name: string;
      bandName: string;
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      totalGrossValue: GraphQL_Money;
      totalUnits: number | null;
      vestingMonths: number;
      value:
        | {
            __typename: "CashValue";
            annualRate: GraphQL_Money | null;
            currencyCode: CurrencyCode;
          }
        | { __typename: "PercentValue"; decimalValue: number | null }
        | { __typename: "UnitValue"; unitValue: number | null };
      totalPercentOwnership: {
        __typename: "PercentValue";
        decimalValue: number | null;
      } | null;
    }>;
  }> | null;
};

export type EmployeeBanners_employee = {
  __typename: "Employee";
  id: number;
  displayName: string;
  employmentStatus: EmploymentStatus;
  activeCashCompensation: Array<{
    __typename: "CashCompensation";
    employeeId: number;
    type: CashCompType;
    activeAt: GraphQL_Date;
    annualCashEquivalent: GraphQL_Money;
    adjustedCashBand: {
      __typename: "AdjustedCashBand";
      id: string;
      currencyCode: CurrencyCode;
      bandPoints: Array<{
        __typename: "AdjustedCashBandPoint";
        id: string;
        annualCashEquivalent: GraphQL_Money;
      }>;
    } | null;
  }> | null;
  activeEmployment: {
    __typename: "Employment";
    id: number;
    positionId: number | null;
  } | null;
};

export type ActualVariableCashComp_table = {
  __typename: "ActualVariableCashCompensation";
  id: number;
  paymentDate: GraphQL_Date;
  value: GraphQL_Money;
  type: ActualVariableCashCompType;
  subType: string | null;
};

export type ActualVariableCashComp_employee = {
  __typename: "Employee";
  actualVariableCashCompensations: Array<{
    __typename: "ActualVariableCashCompensation";
    id: number;
    paymentDate: GraphQL_Date;
    value: GraphQL_Money;
    type: ActualVariableCashCompType;
    subType: string | null;
  }> | null;
};

export type CompensationTimeline_cash = {
  __typename: "CashCompensation";
  activeAt: GraphQL_Date;
  employeeId: number;
  type: CashCompType;
  annualCashEquivalent: GraphQL_Money;
  hourlyCashEquivalent: GraphQL_Money;
  percentOfSalary: number;
  jobTitle: string | null;
  unit: CompUnit;
};

export type CompensationTimeline_valuationCurrency = {
  __typename: "EquityHoldings";
  id: number;
  valuationCurrency: { __typename: "Currency"; id: number; code: CurrencyCode };
};

export type CurrentCompensation_employee = {
  __typename: "Employee";
  id: number;
  adjustedCashBands: Array<{
    __typename: "AdjustedCashBand";
    id: string;
    name: string;
    bandPoints: Array<{
      __typename: "AdjustedCashBandPoint";
      id: string;
      name: string;
      bandName: string;
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      value: {
        __typename: "CashValue";
        annualRate: GraphQL_Money | null;
        hourlyRate: GraphQL_Money | null;
        currencyCode: CurrencyCode;
      };
    }>;
  }> | null;
  activeCashCompensation: Array<{
    __typename: "CashCompensation";
    employeeId: number;
    activeAt: GraphQL_Date;
    type: CashCompType;
    annualCashEquivalent: GraphQL_Money;
    hourlyCashEquivalent: GraphQL_Money;
    percentOfSalary: number;
    unit: CompUnit;
  }> | null;
};

export type EmployeeCompensation_employee = {
  __typename: "Employee";
  id: number;
  displayName: string;
  cashCompensation: Array<{
    __typename: "CashCompensation";
    employeeId: number;
    type: CashCompType;
    activeAt: GraphQL_Date;
    annualCashEquivalent: GraphQL_Money;
    hourlyCashEquivalent: GraphQL_Money;
    percentOfSalary: number;
    jobTitle: string | null;
    unit: CompUnit;
  }> | null;
  equityHoldings: {
    __typename: "EquityHoldings";
    id: number;
    totalUnitCount: number | null;
    grants: Array<{
      __typename: "EquityGrant";
      id: number;
      sharePrice: GraphQL_Money | null;
      fromCarta: boolean;
      unitCount: number;
      name: string;
      issueDate: GraphQL_DateTime;
      vestingStartDate: GraphQL_DateTime;
      vestingScheduleDescription: string;
      price: GraphQL_Money;
      awardType: string | null;
      grossValue: GraphQL_Money | null;
      vestingInformation: {
        __typename: "VestingInformation";
        vestedUnits: number | null;
        unvestedUnits: number | null;
        percentVested: number | null;
        vestEvents: Array<{
          __typename: "EquityGrantVestEventSource";
          id: bigint;
          grantName: string;
          unitCount: number;
          vestingDate: GraphQL_DateTime;
          grant: {
            __typename: "EquityGrant";
            id: number;
            vestingStartDate: GraphQL_DateTime;
          };
        }>;
      } | null;
    }> | null;
    valuationCurrency: {
      __typename: "Currency";
      id: number;
      code: CurrencyCode;
      exchangeRate: number;
    };
    vestingInformation: {
      __typename: "VestingInformation";
      id: number;
      vestedUnits: number | null;
      unvestedUnits: number | null;
      vestEvents: Array<{
        __typename: "EquityGrantVestEventSource";
        id: bigint;
        grantName: string;
        unitCount: number;
        vestingDate: GraphQL_DateTime;
        grant: {
          __typename: "EquityGrant";
          id: number;
          vestingStartDate: GraphQL_DateTime;
          sharePrice: GraphQL_Money | null;
          unitCount: number;
        };
      }>;
    } | null;
  };
  adjustedCashBands: Array<{
    __typename: "AdjustedCashBand";
    id: string;
    name: string;
    bandPoints: Array<{
      __typename: "AdjustedCashBandPoint";
      id: string;
      name: string;
      bandName: string;
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      value: {
        __typename: "CashValue";
        annualRate: GraphQL_Money | null;
        hourlyRate: GraphQL_Money | null;
        currencyCode: CurrencyCode;
      };
    }>;
  }> | null;
  activeCashCompensation: Array<{
    __typename: "CashCompensation";
    employeeId: number;
    activeAt: GraphQL_Date;
    type: CashCompType;
    annualCashEquivalent: GraphQL_Money;
    hourlyCashEquivalent: GraphQL_Money;
    percentOfSalary: number;
    unit: CompUnit;
  }> | null;
  actualVariableCashCompensations: Array<{
    __typename: "ActualVariableCashCompensation";
    id: number;
    paymentDate: GraphQL_Date;
    value: GraphQL_Money;
    type: ActualVariableCashCompType;
    subType: string | null;
  }> | null;
};

export type EmployeeDetailLoadingBoundaryQueryVariables = Exact<{
  id: Scalars["Int"]["input"];
  compCycleId?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type EmployeeDetailLoadingBoundaryQuery = {
  employee: {
    __typename: "Employee";
    id: number;
    displayName: string;
    email: string;
    employmentStatus: EmploymentStatus;
    activeAt: GraphQL_DateTime | null;
    cashCompensation: Array<{
      __typename: "CashCompensation";
      employeeId: number;
      type: CashCompType;
      activeAt: GraphQL_Date;
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      percentOfSalary: number;
      jobTitle: string | null;
      unit: CompUnit;
    }> | null;
    equityHoldings: {
      __typename: "EquityHoldings";
      id: number;
      totalUnitCount: number | null;
      grants: Array<{
        __typename: "EquityGrant";
        id: number;
        sharePrice: GraphQL_Money | null;
        fromCarta: boolean;
        unitCount: number;
        name: string;
        issueDate: GraphQL_DateTime;
        vestingStartDate: GraphQL_DateTime;
        vestingScheduleDescription: string;
        price: GraphQL_Money;
        awardType: string | null;
        grossValue: GraphQL_Money | null;
        vestingInformation: {
          __typename: "VestingInformation";
          vestedUnits: number | null;
          unvestedUnits: number | null;
          percentVested: number | null;
          vestEvents: Array<{
            __typename: "EquityGrantVestEventSource";
            id: bigint;
            grantName: string;
            unitCount: number;
            vestingDate: GraphQL_DateTime;
            grant: {
              __typename: "EquityGrant";
              id: number;
              vestingStartDate: GraphQL_DateTime;
            };
          }>;
        } | null;
      }> | null;
      valuationCurrency: {
        __typename: "Currency";
        id: number;
        code: CurrencyCode;
        exchangeRate: number;
      };
      vestingInformation: {
        __typename: "VestingInformation";
        id: number;
        vestedUnits: number | null;
        unvestedUnits: number | null;
        vestEvents: Array<{
          __typename: "EquityGrantVestEventSource";
          id: bigint;
          grantName: string;
          unitCount: number;
          vestingDate: GraphQL_DateTime;
          grant: {
            __typename: "EquityGrant";
            id: number;
            vestingStartDate: GraphQL_DateTime;
            sharePrice: GraphQL_Money | null;
            unitCount: number;
          };
        }>;
      } | null;
    };
    perfRating: {
      __typename: "EmployeeCompCyclePerfRating";
      perfRating: string | null;
      note: string | null;
    } | null;
    employments: Array<{
      __typename: "Employment";
      id: number;
      jobTitle: string | null;
      activeAt: GraphQL_Date;
      inactiveAt: GraphQL_Date | null;
      salary: GraphQL_Money | null;
      payPeriod: PayPeriodType;
      payRate: GraphQL_Money | null;
      position: {
        __typename: "Position";
        id: number;
        level: number;
        name: string;
        ladder: {
          __typename: "Ladder";
          id: number;
          name: string;
          department: { __typename: "Department"; id: number; name: string };
        };
      } | null;
    }>;
    adjustedCashBands: Array<{
      __typename: "AdjustedCashBand";
      id: string;
      name: string;
      bandPoints: Array<{
        __typename: "AdjustedCashBandPoint";
        id: string;
        name: string;
        bandName: string;
        annualCashEquivalent: GraphQL_Money;
        hourlyCashEquivalent: GraphQL_Money;
        value: {
          __typename: "CashValue";
          annualRate: GraphQL_Money | null;
          hourlyRate: GraphQL_Money | null;
          currencyCode: CurrencyCode;
        };
      }>;
    }> | null;
    activeCashCompensation: Array<{
      __typename: "CashCompensation";
      employeeId: number;
      activeAt: GraphQL_Date;
      type: CashCompType;
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      percentOfSalary: number;
      unit: CompUnit;
      adjustedCashBand: {
        __typename: "AdjustedCashBand";
        id: string;
        currencyCode: CurrencyCode;
        bandPoints: Array<{
          __typename: "AdjustedCashBandPoint";
          id: string;
          annualCashEquivalent: GraphQL_Money;
        }>;
      } | null;
    }> | null;
    actualVariableCashCompensations: Array<{
      __typename: "ActualVariableCashCompensation";
      id: number;
      paymentDate: GraphQL_Date;
      value: GraphQL_Money;
      type: ActualVariableCashCompType;
      subType: string | null;
    }> | null;
    user: { __typename: "User"; id: number; photoURL: string | null } | null;
    activeEmployment: {
      __typename: "Employment";
      id: number;
      jobTitle: string | null;
      positionId: number | null;
    } | null;
    manager: {
      __typename: "MinimalEmployee";
      id: number;
      displayName: string;
    } | null;
    homeLocation: {
      __typename: "Location";
      id: number;
      city: string;
      state: string;
      country: string;
    } | null;
  };
};

export type EmployeeDetailView_employee = {
  __typename: "Employee";
  id: number;
  displayName: string;
  email: string;
  employmentStatus: EmploymentStatus;
  activeAt: GraphQL_DateTime | null;
  cashCompensation: Array<{
    __typename: "CashCompensation";
    employeeId: number;
    type: CashCompType;
    activeAt: GraphQL_Date;
    annualCashEquivalent: GraphQL_Money;
    hourlyCashEquivalent: GraphQL_Money;
    percentOfSalary: number;
    jobTitle: string | null;
    unit: CompUnit;
  }> | null;
  equityHoldings: {
    __typename: "EquityHoldings";
    id: number;
    totalUnitCount: number | null;
    grants: Array<{
      __typename: "EquityGrant";
      id: number;
      sharePrice: GraphQL_Money | null;
      fromCarta: boolean;
      unitCount: number;
      name: string;
      issueDate: GraphQL_DateTime;
      vestingStartDate: GraphQL_DateTime;
      vestingScheduleDescription: string;
      price: GraphQL_Money;
      awardType: string | null;
      grossValue: GraphQL_Money | null;
      vestingInformation: {
        __typename: "VestingInformation";
        vestedUnits: number | null;
        unvestedUnits: number | null;
        percentVested: number | null;
        vestEvents: Array<{
          __typename: "EquityGrantVestEventSource";
          id: bigint;
          grantName: string;
          unitCount: number;
          vestingDate: GraphQL_DateTime;
          grant: {
            __typename: "EquityGrant";
            id: number;
            vestingStartDate: GraphQL_DateTime;
          };
        }>;
      } | null;
    }> | null;
    valuationCurrency: {
      __typename: "Currency";
      id: number;
      code: CurrencyCode;
      exchangeRate: number;
    };
    vestingInformation: {
      __typename: "VestingInformation";
      id: number;
      vestedUnits: number | null;
      unvestedUnits: number | null;
      vestEvents: Array<{
        __typename: "EquityGrantVestEventSource";
        id: bigint;
        grantName: string;
        unitCount: number;
        vestingDate: GraphQL_DateTime;
        grant: {
          __typename: "EquityGrant";
          id: number;
          vestingStartDate: GraphQL_DateTime;
          sharePrice: GraphQL_Money | null;
          unitCount: number;
        };
      }>;
    } | null;
  };
  perfRating: {
    __typename: "EmployeeCompCyclePerfRating";
    perfRating: string | null;
    note: string | null;
  } | null;
  employments: Array<{
    __typename: "Employment";
    id: number;
    jobTitle: string | null;
    activeAt: GraphQL_Date;
    inactiveAt: GraphQL_Date | null;
    salary: GraphQL_Money | null;
    payPeriod: PayPeriodType;
    payRate: GraphQL_Money | null;
    position: {
      __typename: "Position";
      id: number;
      level: number;
      name: string;
      ladder: {
        __typename: "Ladder";
        id: number;
        name: string;
        department: { __typename: "Department"; id: number; name: string };
      };
    } | null;
  }>;
  adjustedCashBands: Array<{
    __typename: "AdjustedCashBand";
    id: string;
    name: string;
    bandPoints: Array<{
      __typename: "AdjustedCashBandPoint";
      id: string;
      name: string;
      bandName: string;
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      value: {
        __typename: "CashValue";
        annualRate: GraphQL_Money | null;
        hourlyRate: GraphQL_Money | null;
        currencyCode: CurrencyCode;
      };
    }>;
  }> | null;
  activeCashCompensation: Array<{
    __typename: "CashCompensation";
    employeeId: number;
    activeAt: GraphQL_Date;
    type: CashCompType;
    annualCashEquivalent: GraphQL_Money;
    hourlyCashEquivalent: GraphQL_Money;
    percentOfSalary: number;
    unit: CompUnit;
    adjustedCashBand: {
      __typename: "AdjustedCashBand";
      id: string;
      currencyCode: CurrencyCode;
      bandPoints: Array<{
        __typename: "AdjustedCashBandPoint";
        id: string;
        annualCashEquivalent: GraphQL_Money;
      }>;
    } | null;
  }> | null;
  actualVariableCashCompensations: Array<{
    __typename: "ActualVariableCashCompensation";
    id: number;
    paymentDate: GraphQL_Date;
    value: GraphQL_Money;
    type: ActualVariableCashCompType;
    subType: string | null;
  }> | null;
  user: { __typename: "User"; id: number; photoURL: string | null } | null;
  activeEmployment: {
    __typename: "Employment";
    id: number;
    jobTitle: string | null;
    positionId: number | null;
  } | null;
  manager: {
    __typename: "MinimalEmployee";
    id: number;
    displayName: string;
  } | null;
  homeLocation: {
    __typename: "Location";
    id: number;
    city: string;
    state: string;
    country: string;
  } | null;
};

export type EmployeeEmployment_employee = {
  __typename: "Employee";
  id: number;
  employments: Array<{
    __typename: "Employment";
    id: number;
    jobTitle: string | null;
    activeAt: GraphQL_Date;
    inactiveAt: GraphQL_Date | null;
    salary: GraphQL_Money | null;
    payPeriod: PayPeriodType;
    payRate: GraphQL_Money | null;
    position: {
      __typename: "Position";
      id: number;
      level: number;
      name: string;
      ladder: {
        __typename: "Ladder";
        id: number;
        name: string;
        department: { __typename: "Department"; id: number; name: string };
      };
    } | null;
  }>;
};

export type EmploymentAccordion_employment = {
  __typename: "Employment";
  id: number;
  jobTitle: string | null;
  activeAt: GraphQL_Date;
  inactiveAt: GraphQL_Date | null;
  salary: GraphQL_Money | null;
  payPeriod: PayPeriodType;
  payRate: GraphQL_Money | null;
  position: {
    __typename: "Position";
    id: number;
    level: number;
    name: string;
    ladder: {
      __typename: "Ladder";
      id: number;
      name: string;
      department: { __typename: "Department"; id: number; name: string };
    };
  } | null;
};

export type EmploymentCarousel_employment = {
  __typename: "Employment";
  id: number;
  jobTitle: string | null;
  activeAt: GraphQL_Date;
  inactiveAt: GraphQL_Date | null;
  salary: GraphQL_Money | null;
  payPeriod: PayPeriodType;
  payRate: GraphQL_Money | null;
  position: { __typename: "Position"; id: number; level: number } | null;
};

export type EmploymentCarouselCard_employment = {
  __typename: "Employment";
  id: number;
  jobTitle: string | null;
  activeAt: GraphQL_Date;
  inactiveAt: GraphQL_Date | null;
  salary: GraphQL_Money | null;
  payPeriod: PayPeriodType;
  payRate: GraphQL_Money | null;
  position: { __typename: "Position"; id: number; level: number } | null;
};

export type EmploymentCarouselPanel_employment = {
  __typename: "Employment";
  id: number;
  jobTitle: string | null;
  activeAt: GraphQL_Date;
  inactiveAt: GraphQL_Date | null;
  salary: GraphQL_Money | null;
  payPeriod: PayPeriodType;
  payRate: GraphQL_Money | null;
  position: { __typename: "Position"; id: number; level: number } | null;
};

export type EmployeeEquity_employee = {
  __typename: "Employee";
  id: number;
  displayName: string;
};

export type EmployeeEquity_equityHoldings = {
  __typename: "EquityHoldings";
  id: number;
  totalUnitCount: number | null;
  valuationCurrency: {
    __typename: "Currency";
    id: number;
    code: CurrencyCode;
    exchangeRate: number;
  };
  grants: Array<{
    __typename: "EquityGrant";
    id: number;
    fromCarta: boolean;
    sharePrice: GraphQL_Money | null;
    unitCount: number;
    name: string;
    issueDate: GraphQL_DateTime;
    vestingStartDate: GraphQL_DateTime;
    vestingScheduleDescription: string;
    price: GraphQL_Money;
    awardType: string | null;
    grossValue: GraphQL_Money | null;
    vestingInformation: {
      __typename: "VestingInformation";
      vestedUnits: number | null;
      unvestedUnits: number | null;
      percentVested: number | null;
      vestEvents: Array<{
        __typename: "EquityGrantVestEventSource";
        id: bigint;
        grantName: string;
        unitCount: number;
        vestingDate: GraphQL_DateTime;
        grant: {
          __typename: "EquityGrant";
          id: number;
          vestingStartDate: GraphQL_DateTime;
        };
      }>;
    } | null;
  }> | null;
  vestingInformation: {
    __typename: "VestingInformation";
    id: number;
    vestedUnits: number | null;
    unvestedUnits: number | null;
    vestEvents: Array<{
      __typename: "EquityGrantVestEventSource";
      id: bigint;
      grantName: string;
      unitCount: number;
      vestingDate: GraphQL_DateTime;
      grant: {
        __typename: "EquityGrant";
        id: number;
        vestingStartDate: GraphQL_DateTime;
        sharePrice: GraphQL_Money | null;
        unitCount: number;
      };
    }>;
  } | null;
};

export type EmployeeHeaderSection_employee = {
  __typename: "Employee";
  id: number;
  displayName: string;
  email: string;
  employmentStatus: EmploymentStatus;
  activeAt: GraphQL_DateTime | null;
  perfRating: {
    __typename: "EmployeeCompCyclePerfRating";
    perfRating: string | null;
    note: string | null;
  } | null;
  user: { __typename: "User"; id: number; photoURL: string | null } | null;
  activeEmployment: {
    __typename: "Employment";
    id: number;
    jobTitle: string | null;
    positionId: number | null;
  } | null;
  activeCashCompensation: Array<{
    __typename: "CashCompensation";
    employeeId: number;
    type: CashCompType;
    activeAt: GraphQL_Date;
    annualCashEquivalent: GraphQL_Money;
    adjustedCashBand: {
      __typename: "AdjustedCashBand";
      id: string;
      currencyCode: CurrencyCode;
      bandPoints: Array<{
        __typename: "AdjustedCashBandPoint";
        id: string;
        annualCashEquivalent: GraphQL_Money;
      }>;
    } | null;
  }> | null;
  manager: {
    __typename: "MinimalEmployee";
    id: number;
    displayName: string;
  } | null;
  homeLocation: {
    __typename: "Location";
    id: number;
    city: string;
    state: string;
    country: string;
  } | null;
};

export type GetAllEmployeesVariables = Exact<{
  input?: InputMaybe<ExportAllEmployeesInput>;
}>;

export type GetAllEmployees = {
  exportAllEmployees: { __typename: "CsvExport"; data: string };
};

export type LevelingErrorsTable_employee = {
  __typename: "Employee2";
  id: number;
  displayName: string;
  activeAt: GraphQL_Date | null;
  user: { __typename: "User2"; id: number; photoURL: string | null } | null;
  employments: Array<{
    __typename: "Employment2";
    id: number;
    jobTitle: string | null;
    activeAt: GraphQL_Date;
    levelingCode: string | null;
    levelingMethod: LevelingMethod | null;
    position: {
      __typename: "Position";
      id: number;
      name: string;
      level: number;
    } | null;
  }>;
  activeEmployment: {
    __typename: "Employment2";
    id: number;
    jobTitle: string | null;
    activeAt: GraphQL_Date;
    levelingCode: string | null;
    levelingMethod: LevelingMethod | null;
    payPeriod: PayPeriodType;
    position: {
      __typename: "Position";
      id: number;
      name: string;
      level: number;
    } | null;
  } | null;
  location: { __typename: "LocationGroup2"; id: number; name: string } | null;
  manager: {
    __typename: "MinimalEmployee2";
    id: number;
    displayName: string | null;
    user: { __typename: "User2"; id: number; photoURL: string | null } | null;
  } | null;
  activeCashCompensation: Array<{
    __typename: "CashCompensation2";
    activeAt: GraphQL_Date;
    employeeId: number;
    type: CashCompType;
    annualCashEquivalent: GraphQL_Money;
    hourlyCashEquivalent: GraphQL_Money;
    unit: CompUnit;
    percentOfSalary: number;
  }> | null;
};

export type PeopleQueryVariables = Exact<{
  includeInactive?: InputMaybe<Scalars["Boolean"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Employee2sSort>;
  filter?: InputMaybe<Employee2sFilter>;
}>;

export type PeopleQuery = {
  employee2s: {
    __typename: "Employee2s";
    meta: {
      __typename: "Employee2sMeta";
      levels: Array<number>;
      counts: {
        __typename: "Employee2sCounts";
        ALL: number;
        ACTIVE: number;
        INACTIVE: number;
        LEVELING: number;
        LEVELING_ISSUES: number;
        filteredTotal: number;
      };
      managers: Array<{
        __typename: "MinimalEmployee2";
        id: number;
        displayName: string | null;
      }>;
      departments: Array<{
        __typename: "Department";
        id: number;
        name: string;
        organizationId: number;
      }>;
      ladders: Array<{ __typename: "Ladder"; id: number; name: string }>;
      locations: Array<{
        __typename: "LocationGroup2";
        id: number;
        name: string;
      }>;
    };
    employees: Array<{
      __typename: "Employee2";
      id: number;
      displayName: string;
      activeAt: GraphQL_Date | null;
      employmentStatus: EmploymentStatus;
      activeCashCompensation: Array<{
        __typename: "CashCompensation2";
        activeAt: GraphQL_Date;
        employeeId: number;
        type: CashCompType;
        annualCashEquivalent: GraphQL_Money;
        hourlyCashEquivalent: GraphQL_Money;
        unit: CompUnit;
        percentOfSalary: number;
      }> | null;
      adjustedCashBands: Array<{
        __typename: "AdjustedCashBand";
        id: string;
        name: string;
        bandPoints: Array<{
          __typename: "AdjustedCashBandPoint";
          name: string;
          annualCashEquivalent: GraphQL_Money;
          value: {
            __typename: "CashValue";
            annualRate: GraphQL_Money | null;
            hourlyRate: GraphQL_Money | null;
            currencyCode: CurrencyCode;
          };
        }>;
      }> | null;
      equityHoldings: {
        __typename: "EquityHoldings2";
        id: number;
        totalUnitCount: number;
        totalGrossHoldingValue: GraphQL_Money | null;
        grants: Array<{
          __typename: "EquityGrant2";
          id: number;
          name: string;
          issueDate: GraphQL_Date;
          vestingStartDate: GraphQL_Date;
          vestingScheduleDescription: string;
          unitCount: number;
          price: GraphQL_Money;
          equityType: EquityGrantTypes;
          optionType: OptionTypes | null;
          awardType: string | null;
        }> | null;
      };
      activeEmployment: {
        __typename: "Employment2";
        id: number;
        positionId: number | null;
        salary: GraphQL_Money | null;
        jobTitle: string | null;
        levelingCode: string | null;
        levelingMethod: LevelingMethod | null;
        payPeriod: PayPeriodType;
        position: {
          __typename: "Position";
          id: number;
          name: string;
          level: number;
          ladder: {
            __typename: "Ladder";
            id: number;
            name: string;
            department: { __typename: "Department"; id: number; name: string };
          };
        } | null;
      } | null;
      employments: Array<{
        __typename: "Employment2";
        id: number;
        positionId: number | null;
      }>;
      user: { __typename: "User2"; id: number; photoURL: string | null } | null;
      manager: {
        __typename: "MinimalEmployee2";
        id: number;
        displayName: string | null;
        user: {
          __typename: "User2";
          id: number;
          photoURL: string | null;
        } | null;
      } | null;
      location: {
        __typename: "LocationGroup2";
        id: number;
        name: string;
      } | null;
    }>;
  };
  positions: Array<{
    __typename: "Position";
    id: number;
    name: string;
    level: number;
    type: PositionType;
    jobCodes: Array<string>;
    ladder: {
      __typename: "Ladder";
      id: number;
      name: string;
      department: { __typename: "Department"; id: number; name: string };
    };
  }>;
};

export type PeopleTabSwitch_employee = {
  __typename: "Employee2";
  id: number;
  employmentStatus: EmploymentStatus;
  displayName: string;
  activeAt: GraphQL_Date | null;
  activeEmployment: {
    __typename: "Employment2";
    id: number;
    positionId: number | null;
    jobTitle: string | null;
    activeAt: GraphQL_Date;
    levelingCode: string | null;
    levelingMethod: LevelingMethod | null;
    payPeriod: PayPeriodType;
    salary: GraphQL_Money | null;
    payRate: GraphQL_Money | null;
    position: {
      __typename: "Position";
      id: number;
      name: string;
      level: number;
      ladder: {
        __typename: "Ladder";
        id: number;
        name: string;
        department: { __typename: "Department"; id: number; name: string };
      };
    } | null;
  } | null;
  employments: Array<{
    __typename: "Employment2";
    positionId: number | null;
    id: number;
    jobTitle: string | null;
    activeAt: GraphQL_Date;
    levelingCode: string | null;
    levelingMethod: LevelingMethod | null;
    inactiveAt: GraphQL_Date | null;
    position: {
      __typename: "Position";
      id: number;
      name: string;
      level: number;
    } | null;
  }>;
  user: { __typename: "User2"; id: number; photoURL: string | null } | null;
  location: { __typename: "LocationGroup2"; id: number; name: string } | null;
  manager: {
    __typename: "MinimalEmployee2";
    id: number;
    displayName: string | null;
    user: { __typename: "User2"; id: number; photoURL: string | null } | null;
  } | null;
  activeCashCompensation: Array<{
    __typename: "CashCompensation2";
    activeAt: GraphQL_Date;
    employeeId: number;
    type: CashCompType;
    annualCashEquivalent: GraphQL_Money;
    hourlyCashEquivalent: GraphQL_Money;
    unit: CompUnit;
    percentOfSalary: number;
  }> | null;
  adjustedCashBands: Array<{
    __typename: "AdjustedCashBand";
    id: string;
    name: string;
    bandPoints: Array<{
      __typename: "AdjustedCashBandPoint";
      name: string;
      annualCashEquivalent: GraphQL_Money;
      value: {
        __typename: "CashValue";
        annualRate: GraphQL_Money | null;
        hourlyRate: GraphQL_Money | null;
        currencyCode: CurrencyCode;
      };
    }>;
  }> | null;
  equityHoldings: {
    __typename: "EquityHoldings2";
    id: number;
    totalUnitCount: number;
    totalGrossHoldingValue: GraphQL_Money | null;
    grants: Array<{
      __typename: "EquityGrant2";
      id: number;
      name: string;
      issueDate: GraphQL_Date;
      vestingStartDate: GraphQL_Date;
      vestingScheduleDescription: string;
      unitCount: number;
      price: GraphQL_Money;
      equityType: EquityGrantTypes;
      awardType: string | null;
    }> | null;
  };
};

export type PeopleTable_employee = {
  __typename: "Employee2";
  id: number;
  displayName: string;
  activeAt: GraphQL_Date | null;
  employmentStatus: EmploymentStatus;
  activeEmployment: {
    __typename: "Employment2";
    id: number;
    salary: GraphQL_Money | null;
    jobTitle: string | null;
    positionId: number | null;
    levelingCode: string | null;
    levelingMethod: LevelingMethod | null;
    payPeriod: PayPeriodType;
    payRate: GraphQL_Money | null;
    position: {
      __typename: "Position";
      id: number;
      name: string;
      level: number;
      ladder: {
        __typename: "Ladder";
        id: number;
        name: string;
        department: { __typename: "Department"; id: number; name: string };
      };
    } | null;
  } | null;
  activeCashCompensation: Array<{
    __typename: "CashCompensation2";
    activeAt: GraphQL_Date;
    employeeId: number;
    type: CashCompType;
    annualCashEquivalent: GraphQL_Money;
    hourlyCashEquivalent: GraphQL_Money;
    unit: CompUnit;
    percentOfSalary: number;
  }> | null;
  location: { __typename: "LocationGroup2"; id: number; name: string } | null;
  adjustedCashBands: Array<{
    __typename: "AdjustedCashBand";
    id: string;
    name: string;
    bandPoints: Array<{
      __typename: "AdjustedCashBandPoint";
      name: string;
      annualCashEquivalent: GraphQL_Money;
      value: {
        __typename: "CashValue";
        annualRate: GraphQL_Money | null;
        hourlyRate: GraphQL_Money | null;
        currencyCode: CurrencyCode;
      };
    }>;
  }> | null;
  user: { __typename: "User2"; id: number; photoURL: string | null } | null;
  manager: {
    __typename: "MinimalEmployee2";
    id: number;
    displayName: string | null;
    user: { __typename: "User2"; id: number; photoURL: string | null } | null;
  } | null;
  employments: Array<{
    __typename: "Employment2";
    id: number;
    activeAt: GraphQL_Date;
    inactiveAt: GraphQL_Date | null;
    positionId: number | null;
  }>;
  equityHoldings: {
    __typename: "EquityHoldings2";
    id: number;
    totalUnitCount: number;
    totalGrossHoldingValue: GraphQL_Money | null;
    grants: Array<{
      __typename: "EquityGrant2";
      id: number;
      name: string;
      issueDate: GraphQL_Date;
      vestingStartDate: GraphQL_Date;
      vestingScheduleDescription: string;
      unitCount: number;
      price: GraphQL_Money;
      equityType: EquityGrantTypes;
      awardType: string | null;
    }> | null;
  };
};

export type ReportsLoadingBoundaryQueryVariables = Exact<{
  managerId: Scalars["Int"]["input"];
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Employee2sSort>;
  filter?: InputMaybe<Employee2sFilter>;
}>;

export type ReportsLoadingBoundaryQuery = {
  employee2s: {
    __typename: "Employee2s";
    meta: {
      __typename: "Employee2sMeta";
      levels: Array<number>;
      counts: { __typename: "Employee2sCounts"; filteredTotal: number };
      managers: Array<{
        __typename: "MinimalEmployee2";
        id: number;
        displayName: string | null;
      }>;
      departments: Array<{
        __typename: "Department";
        organizationId: number;
        id: number;
        name: string;
      }>;
      ladders: Array<{ __typename: "Ladder"; id: number; name: string }>;
      locations: Array<{
        __typename: "LocationGroup2";
        id: number;
        name: string;
      }>;
    };
    employees: Array<{
      __typename: "Employee2";
      id: number;
      displayName: string;
      activeAt: GraphQL_Date | null;
      employmentStatus: EmploymentStatus;
      activeCashCompensation: Array<{
        __typename: "CashCompensation2";
        activeAt: GraphQL_Date;
        employeeId: number;
        type: CashCompType;
        annualCashEquivalent: GraphQL_Money;
        hourlyCashEquivalent: GraphQL_Money;
        unit: CompUnit;
        percentOfSalary: number;
      }> | null;
      adjustedCashBands: Array<{
        __typename: "AdjustedCashBand";
        id: string;
        name: string;
        bandPoints: Array<{
          __typename: "AdjustedCashBandPoint";
          name: string;
          annualCashEquivalent: GraphQL_Money;
          value: {
            __typename: "CashValue";
            annualRate: GraphQL_Money | null;
            hourlyRate: GraphQL_Money | null;
            currencyCode: CurrencyCode;
          };
        }>;
      }> | null;
      equityHoldings: {
        __typename: "EquityHoldings2";
        id: number;
        totalUnitCount: number;
        totalGrossHoldingValue: GraphQL_Money | null;
        grants: Array<{
          __typename: "EquityGrant2";
          id: number;
          name: string;
          issueDate: GraphQL_Date;
          vestingStartDate: GraphQL_Date;
          vestingScheduleDescription: string;
          unitCount: number;
          price: GraphQL_Money;
          equityType: EquityGrantTypes;
          optionType: OptionTypes | null;
          awardType: string | null;
        }> | null;
      };
      activeEmployment: {
        __typename: "Employment2";
        id: number;
        positionId: number | null;
        salary: GraphQL_Money | null;
        jobTitle: string | null;
        levelingCode: string | null;
        levelingMethod: LevelingMethod | null;
        payPeriod: PayPeriodType;
        activeAt: GraphQL_Date;
        payRate: GraphQL_Money | null;
        position: {
          __typename: "Position";
          id: number;
          name: string;
          level: number;
          ladder: {
            __typename: "Ladder";
            id: number;
            name: string;
            department: { __typename: "Department"; id: number; name: string };
          };
        } | null;
      } | null;
      employments: Array<{
        __typename: "Employment2";
        id: number;
        positionId: number | null;
        jobTitle: string | null;
        activeAt: GraphQL_Date;
        levelingCode: string | null;
        levelingMethod: LevelingMethod | null;
        inactiveAt: GraphQL_Date | null;
        position: {
          __typename: "Position";
          id: number;
          name: string;
          level: number;
        } | null;
      }>;
      user: { __typename: "User2"; id: number; photoURL: string | null } | null;
      manager: {
        __typename: "MinimalEmployee2";
        id: number;
        displayName: string | null;
        user: {
          __typename: "User2";
          id: number;
          photoURL: string | null;
        } | null;
      } | null;
      location: {
        __typename: "LocationGroup2";
        id: number;
        name: string;
      } | null;
    }>;
  };
  positions: Array<{
    __typename: "Position";
    id: number;
    name: string;
    level: number;
    jobCodes: Array<string>;
    ladder: {
      __typename: "Ladder";
      id: number;
      name: string;
      department: { __typename: "Department"; id: number; name: string };
    };
  }>;
};

export type ReportsTable_employee = {
  __typename: "Employee2";
  id: number;
  displayName: string;
  activeAt: GraphQL_Date | null;
  employmentStatus: EmploymentStatus;
  activeEmployment: {
    __typename: "Employment2";
    id: number;
    salary: GraphQL_Money | null;
    jobTitle: string | null;
    positionId: number | null;
    levelingCode: string | null;
    levelingMethod: LevelingMethod | null;
    payPeriod: PayPeriodType;
    payRate: GraphQL_Money | null;
    position: {
      __typename: "Position";
      id: number;
      name: string;
      level: number;
      ladder: {
        __typename: "Ladder";
        id: number;
        name: string;
        department: { __typename: "Department"; id: number; name: string };
      };
    } | null;
  } | null;
  activeCashCompensation: Array<{
    __typename: "CashCompensation2";
    activeAt: GraphQL_Date;
    employeeId: number;
    type: CashCompType;
    annualCashEquivalent: GraphQL_Money;
    hourlyCashEquivalent: GraphQL_Money;
    unit: CompUnit;
    percentOfSalary: number;
  }> | null;
  location: { __typename: "LocationGroup2"; id: number; name: string } | null;
  adjustedCashBands: Array<{
    __typename: "AdjustedCashBand";
    id: string;
    name: string;
    bandPoints: Array<{
      __typename: "AdjustedCashBandPoint";
      name: string;
      annualCashEquivalent: GraphQL_Money;
      value: {
        __typename: "CashValue";
        annualRate: GraphQL_Money | null;
        hourlyRate: GraphQL_Money | null;
        currencyCode: CurrencyCode;
      };
    }>;
  }> | null;
  user: { __typename: "User2"; id: number; photoURL: string | null } | null;
  manager: {
    __typename: "MinimalEmployee2";
    id: number;
    displayName: string | null;
    user: { __typename: "User2"; id: number; photoURL: string | null } | null;
  } | null;
  employments: Array<{
    __typename: "Employment2";
    id: number;
    activeAt: GraphQL_Date;
    inactiveAt: GraphQL_Date | null;
    positionId: number | null;
  }>;
  equityHoldings: {
    __typename: "EquityHoldings2";
    id: number;
    totalUnitCount: number;
    totalGrossHoldingValue: GraphQL_Money | null;
    grants: Array<{
      __typename: "EquityGrant2";
      id: number;
      name: string;
      issueDate: GraphQL_Date;
      vestingStartDate: GraphQL_Date;
      vestingScheduleDescription: string;
      unitCount: number;
      price: GraphQL_Money;
      equityType: EquityGrantTypes;
      awardType: string | null;
    }> | null;
  };
};

export type ReportsTableRow_employee = {
  __typename: "Employee2";
  id: number;
  displayName: string;
  activeAt: GraphQL_Date | null;
  employmentStatus: EmploymentStatus;
  user: { __typename: "User2"; id: number; photoURL: string | null } | null;
  manager: {
    __typename: "MinimalEmployee2";
    id: number;
    displayName: string | null;
    user: { __typename: "User2"; id: number; photoURL: string | null } | null;
  } | null;
  employments: Array<{
    __typename: "Employment2";
    id: number;
    activeAt: GraphQL_Date;
    inactiveAt: GraphQL_Date | null;
    positionId: number | null;
  }>;
  activeCashCompensation: Array<{
    __typename: "CashCompensation2";
    employeeId: number;
    type: CashCompType;
    activeAt: GraphQL_Date;
    annualCashEquivalent: GraphQL_Money;
    percentOfSalary: number;
  }> | null;
  activeEmployment: {
    __typename: "Employment2";
    id: number;
    payPeriod: PayPeriodType;
    jobTitle: string | null;
    positionId: number | null;
    levelingCode: string | null;
    levelingMethod: LevelingMethod | null;
    position: {
      __typename: "Position";
      id: number;
      name: string;
      level: number;
      ladder: {
        __typename: "Ladder";
        id: number;
        name: string;
        department: { __typename: "Department"; id: number; name: string };
      };
    } | null;
  } | null;
  equityHoldings: {
    __typename: "EquityHoldings2";
    id: number;
    totalUnitCount: number;
    totalGrossHoldingValue: GraphQL_Money | null;
    grants: Array<{
      __typename: "EquityGrant2";
      id: number;
      name: string;
      issueDate: GraphQL_Date;
      vestingStartDate: GraphQL_Date;
      vestingScheduleDescription: string;
      unitCount: number;
      price: GraphQL_Money;
      equityType: EquityGrantTypes;
      awardType: string | null;
    }> | null;
  };
  adjustedCashBands: Array<{
    __typename: "AdjustedCashBand";
    id: string;
    name: string;
    bandPoints: Array<{
      __typename: "AdjustedCashBandPoint";
      name: string;
      annualCashEquivalent: GraphQL_Money;
    }>;
  }> | null;
};

export type YourTeamView_employee = {
  __typename: "Employee2";
  id: number;
  displayName: string;
  activeAt: GraphQL_Date | null;
  employmentStatus: EmploymentStatus;
  activeEmployment: {
    __typename: "Employment2";
    id: number;
    salary: GraphQL_Money | null;
    jobTitle: string | null;
    positionId: number | null;
    levelingCode: string | null;
    levelingMethod: LevelingMethod | null;
    payPeriod: PayPeriodType;
    payRate: GraphQL_Money | null;
    position: {
      __typename: "Position";
      id: number;
      name: string;
      level: number;
      ladder: {
        __typename: "Ladder";
        id: number;
        name: string;
        department: { __typename: "Department"; id: number; name: string };
      };
    } | null;
  } | null;
  activeCashCompensation: Array<{
    __typename: "CashCompensation2";
    activeAt: GraphQL_Date;
    employeeId: number;
    type: CashCompType;
    annualCashEquivalent: GraphQL_Money;
    hourlyCashEquivalent: GraphQL_Money;
    unit: CompUnit;
    percentOfSalary: number;
  }> | null;
  location: { __typename: "LocationGroup2"; id: number; name: string } | null;
  adjustedCashBands: Array<{
    __typename: "AdjustedCashBand";
    id: string;
    name: string;
    bandPoints: Array<{
      __typename: "AdjustedCashBandPoint";
      name: string;
      annualCashEquivalent: GraphQL_Money;
      value: {
        __typename: "CashValue";
        annualRate: GraphQL_Money | null;
        hourlyRate: GraphQL_Money | null;
        currencyCode: CurrencyCode;
      };
    }>;
  }> | null;
  user: { __typename: "User2"; id: number; photoURL: string | null } | null;
  manager: {
    __typename: "MinimalEmployee2";
    id: number;
    displayName: string | null;
    user: { __typename: "User2"; id: number; photoURL: string | null } | null;
  } | null;
  employments: Array<{
    __typename: "Employment2";
    id: number;
    activeAt: GraphQL_Date;
    inactiveAt: GraphQL_Date | null;
    positionId: number | null;
  }>;
  equityHoldings: {
    __typename: "EquityHoldings2";
    id: number;
    totalUnitCount: number;
    totalGrossHoldingValue: GraphQL_Money | null;
    grants: Array<{
      __typename: "EquityGrant2";
      id: number;
      name: string;
      issueDate: GraphQL_Date;
      vestingStartDate: GraphQL_Date;
      vestingScheduleDescription: string;
      unitCount: number;
      price: GraphQL_Money;
      equityType: EquityGrantTypes;
      awardType: string | null;
    }> | null;
  };
};

export type Portal_employee = {
  __typename: "Employee";
  displayName: string;
  email: string;
  id: number;
  activeAt: GraphQL_DateTime | null;
  totalRewardsStatementUrl: string | null;
  totalBenefitsValue: GraphQL_Money | null;
  compaRatio: number | null;
  activeEmployment: {
    __typename: "Employment";
    id: number;
    salary: GraphQL_Money | null;
    jobTitle: string | null;
    position: {
      __typename: "Position";
      id: number;
      name: string;
      level: number;
    } | null;
  } | null;
  equityHoldings: {
    __typename: "EquityHoldings";
    id: number;
    totalUnitCount: number | null;
    grants: Array<{
      __typename: "EquityGrant";
      id: number;
      sharePrice: GraphQL_Money | null;
      fromCarta: boolean;
      unitCount: number;
      name: string;
      issueDate: GraphQL_DateTime;
      vestingStartDate: GraphQL_DateTime;
      vestingScheduleDescription: string;
      price: GraphQL_Money;
      awardType: string | null;
      grossValue: GraphQL_Money | null;
      vestingInformation: {
        __typename: "VestingInformation";
        vestedUnits: number | null;
        unvestedUnits: number | null;
        percentVested: number | null;
        vestEvents: Array<{
          __typename: "EquityGrantVestEventSource";
          id: bigint;
          grantName: string;
          unitCount: number;
          vestingDate: GraphQL_DateTime;
          grant: {
            __typename: "EquityGrant";
            id: number;
            vestingStartDate: GraphQL_DateTime;
          };
        }>;
      } | null;
    }> | null;
    valuationCurrency: {
      __typename: "Currency";
      id: number;
      code: CurrencyCode;
      exchangeRate: number;
    };
    vestingInformation: {
      __typename: "VestingInformation";
      id: number;
      vestedUnits: number | null;
      unvestedUnits: number | null;
      vestEvents: Array<{
        __typename: "EquityGrantVestEventSource";
        grantName: string;
        vestingDate: GraphQL_DateTime;
        unitCount: number;
        id: bigint;
        grant: {
          __typename: "EquityGrant";
          sharePrice: GraphQL_Money | null;
          id: number;
          vestingStartDate: GraphQL_DateTime;
          unitCount: number;
        };
      }>;
    } | null;
  };
  cashCompensation: Array<{
    __typename: "CashCompensation";
    employeeId: number;
    type: CashCompType;
    activeAt: GraphQL_Date;
    annualCashEquivalent: GraphQL_Money;
    hourlyCashEquivalent: GraphQL_Money;
    percentOfSalary: number;
    unit: CompUnit;
    jobTitle: string | null;
  }> | null;
  benefitsPackage: {
    __typename: "BenefitsPackage";
    id: number;
    name: string;
    totalValue: GraphQL_Money;
    benefits: Array<{
      __typename: "Benefit";
      id: number;
      name: string;
      description: string;
      value: GraphQL_Money | null;
    }>;
  } | null;
  user: { __typename: "User"; id: number; photoURL: string | null } | null;
  manager: {
    __typename: "MinimalEmployee";
    id: number;
    displayName: string;
  } | null;
  homeLocation: {
    __typename: "Location";
    id: number;
    city: string;
    state: string;
    country: string;
  } | null;
  activeCashCompensation: Array<{
    __typename: "CashCompensation";
    employeeId: number;
    type: CashCompType;
    activeAt: GraphQL_Date;
    annualCashEquivalent: GraphQL_Money;
    percentOfSalary: number;
    unit: CompUnit;
    hourlyCashEquivalent: GraphQL_Money;
  }> | null;
  adjustedCashBands: Array<{
    __typename: "AdjustedCashBand";
    id: string;
    name: string;
    bandPoints: Array<{
      __typename: "AdjustedCashBandPoint";
      id: string;
      name: string;
      bandName: string;
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      value: {
        __typename: "CashValue";
        annualRate: GraphQL_Money | null;
        hourlyRate: GraphQL_Money | null;
        currencyCode: CurrencyCode;
      };
    }>;
  }> | null;
  nextCashBands: Array<{
    __typename: "EmployeeLevelingInfo";
    id: string;
    position: {
      __typename: "Position";
      id: number;
      name: string;
      level: number;
    } | null;
    cashBands: Array<{
      __typename: "AdjustedCashBand";
      id: string;
      name: string;
      bandPoints: Array<{
        __typename: "AdjustedCashBandPoint";
        id: string;
        name: string;
        bandName: string;
        annualCashEquivalent: GraphQL_Money;
        hourlyCashEquivalent: GraphQL_Money;
        value: {
          __typename: "CashValue";
          annualRate: GraphQL_Money | null;
          hourlyRate: GraphQL_Money | null;
          currencyCode: CurrencyCode;
        };
      }>;
    }>;
  }> | null;
};

export type Portal_organization = {
  __typename: "Organization";
  id: number;
  name: string;
  logoUrlPath: string | null;
  permissionSettings: {
    __typename: "PermissionSettings";
    id: number;
    employeeBandAccess: EmployeeBandAccessType;
  };
};

export type PortalBenefitsGrid_benefitsPackage = {
  __typename: "BenefitsPackage";
  totalValue: GraphQL_Money;
  benefits: Array<{
    __typename: "Benefit";
    id: number;
    name: string;
    description: string;
    value: GraphQL_Money | null;
  }>;
};

export type PortalQueryVariables = Exact<{
  employeeId: Scalars["Int"]["input"];
}>;

export type PortalQuery = {
  employee: {
    __typename: "Employee";
    id: number;
    displayName: string;
    email: string;
    activeAt: GraphQL_DateTime | null;
    totalRewardsStatementUrl: string | null;
    totalBenefitsValue: GraphQL_Money | null;
    compaRatio: number | null;
    activeEmployment: {
      __typename: "Employment";
      id: number;
      salary: GraphQL_Money | null;
      jobTitle: string | null;
      position: {
        __typename: "Position";
        id: number;
        name: string;
        level: number;
      } | null;
    } | null;
    equityHoldings: {
      __typename: "EquityHoldings";
      id: number;
      totalUnitCount: number | null;
      grants: Array<{
        __typename: "EquityGrant";
        id: number;
        sharePrice: GraphQL_Money | null;
        fromCarta: boolean;
        unitCount: number;
        name: string;
        issueDate: GraphQL_DateTime;
        vestingStartDate: GraphQL_DateTime;
        vestingScheduleDescription: string;
        price: GraphQL_Money;
        awardType: string | null;
        grossValue: GraphQL_Money | null;
        vestingInformation: {
          __typename: "VestingInformation";
          vestedUnits: number | null;
          unvestedUnits: number | null;
          percentVested: number | null;
          vestEvents: Array<{
            __typename: "EquityGrantVestEventSource";
            id: bigint;
            grantName: string;
            unitCount: number;
            vestingDate: GraphQL_DateTime;
            grant: {
              __typename: "EquityGrant";
              id: number;
              vestingStartDate: GraphQL_DateTime;
            };
          }>;
        } | null;
      }> | null;
      valuationCurrency: {
        __typename: "Currency";
        id: number;
        code: CurrencyCode;
        exchangeRate: number;
      };
      vestingInformation: {
        __typename: "VestingInformation";
        id: number;
        vestedUnits: number | null;
        unvestedUnits: number | null;
        vestEvents: Array<{
          __typename: "EquityGrantVestEventSource";
          grantName: string;
          vestingDate: GraphQL_DateTime;
          unitCount: number;
          id: bigint;
          grant: {
            __typename: "EquityGrant";
            sharePrice: GraphQL_Money | null;
            id: number;
            vestingStartDate: GraphQL_DateTime;
            unitCount: number;
          };
        }>;
      } | null;
    };
    cashCompensation: Array<{
      __typename: "CashCompensation";
      employeeId: number;
      type: CashCompType;
      activeAt: GraphQL_Date;
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      percentOfSalary: number;
      unit: CompUnit;
      jobTitle: string | null;
    }> | null;
    benefitsPackage: {
      __typename: "BenefitsPackage";
      id: number;
      name: string;
      totalValue: GraphQL_Money;
      benefits: Array<{
        __typename: "Benefit";
        id: number;
        name: string;
        description: string;
        value: GraphQL_Money | null;
      }>;
    } | null;
    user: { __typename: "User"; id: number; photoURL: string | null } | null;
    manager: {
      __typename: "MinimalEmployee";
      id: number;
      displayName: string;
    } | null;
    homeLocation: {
      __typename: "Location";
      id: number;
      city: string;
      state: string;
      country: string;
    } | null;
    activeCashCompensation: Array<{
      __typename: "CashCompensation";
      employeeId: number;
      type: CashCompType;
      activeAt: GraphQL_Date;
      annualCashEquivalent: GraphQL_Money;
      percentOfSalary: number;
      unit: CompUnit;
      hourlyCashEquivalent: GraphQL_Money;
    }> | null;
    adjustedCashBands: Array<{
      __typename: "AdjustedCashBand";
      id: string;
      name: string;
      bandPoints: Array<{
        __typename: "AdjustedCashBandPoint";
        id: string;
        name: string;
        bandName: string;
        annualCashEquivalent: GraphQL_Money;
        hourlyCashEquivalent: GraphQL_Money;
        value: {
          __typename: "CashValue";
          annualRate: GraphQL_Money | null;
          hourlyRate: GraphQL_Money | null;
          currencyCode: CurrencyCode;
        };
      }>;
    }> | null;
    nextCashBands: Array<{
      __typename: "EmployeeLevelingInfo";
      id: string;
      position: {
        __typename: "Position";
        id: number;
        name: string;
        level: number;
      } | null;
      cashBands: Array<{
        __typename: "AdjustedCashBand";
        id: string;
        name: string;
        bandPoints: Array<{
          __typename: "AdjustedCashBandPoint";
          id: string;
          name: string;
          bandName: string;
          annualCashEquivalent: GraphQL_Money;
          hourlyCashEquivalent: GraphQL_Money;
          value: {
            __typename: "CashValue";
            annualRate: GraphQL_Money | null;
            hourlyRate: GraphQL_Money | null;
            currencyCode: CurrencyCode;
          };
        }>;
      }>;
    }> | null;
  };
  organization: {
    __typename: "Organization";
    id: number;
    name: string;
    logoUrlPath: string | null;
    permissionSettings: {
      __typename: "PermissionSettings";
      id: number;
      employeeBandAccess: EmployeeBandAccessType;
    };
  };
};

export type PortalTabs_employee = {
  __typename: "Employee";
  totalRewardsStatementUrl: string | null;
};

export type TotalCompGrid_employee = {
  __typename: "Employee";
  totalBenefitsValue: GraphQL_Money | null;
  activeCashCompensation: Array<{
    __typename: "CashCompensation";
    employeeId: number;
    type: CashCompType;
    activeAt: GraphQL_Date;
    annualCashEquivalent: GraphQL_Money;
    percentOfSalary: number;
    unit: CompUnit;
  }> | null;
  equityHoldings: {
    __typename: "EquityHoldings";
    id: number;
    valuationCurrency: {
      __typename: "Currency";
      id: number;
      code: CurrencyCode;
      exchangeRate: number;
    };
    grants: Array<{
      __typename: "EquityGrant";
      id: number;
      sharePrice: GraphQL_Money | null;
    }> | null;
    vestingInformation: {
      __typename: "VestingInformation";
      id: number;
      vestEvents: Array<{
        __typename: "EquityGrantVestEventSource";
        id: bigint;
        vestingDate: GraphQL_DateTime;
        unitCount: number;
        grant: {
          __typename: "EquityGrant";
          id: number;
          sharePrice: GraphQL_Money | null;
        };
      }>;
    } | null;
  };
  activeEmployment: {
    __typename: "Employment";
    id: number;
    salary: GraphQL_Money | null;
  } | null;
  benefitsPackage: {
    __typename: "BenefitsPackage";
    id: number;
    name: string;
    totalValue: GraphQL_Money;
  } | null;
};

export type AlternateForms_position = {
  __typename: "Position";
  id: number;
  type: PositionType;
};

export type AlternateForms_cashBandPoint = {
  __typename: "AdjustedCashBandPoint";
  id: string;
  name: string;
  bandName: string;
  value: {
    __typename: "CashValue";
    currencyCode: CurrencyCode;
    annualRate: GraphQL_Money | null;
  };
};

export type AlternateForms_equityBandPoint = {
  __typename: "AdjustedEquityBandPoint";
  id: string;
  name: string;
  bandName: string;
  annualCashEquivalent: GraphQL_Money;
  totalGrossValue: GraphQL_Money;
  totalUnits: number | null;
  vestingMonths: number;
  value:
    | {
        __typename: "CashValue";
        currencyCode: CurrencyCode;
        annualRate: GraphQL_Money | null;
      }
    | { __typename: "PercentValue"; decimalValue: number | null }
    | { __typename: "UnitValue"; unitValue: number | null };
  totalPercentOwnership: {
    __typename: "PercentValue";
    decimalValue: number | null;
  } | null;
};

export type CustomizeCurrencyModal_cashBand = {
  __typename: "AdjustedCashBand";
  name: string;
  currencyCode: CurrencyCode;
};

export type CustomizeCurrencyModal_equityBand = {
  __typename: "AdjustedEquityBand";
  name: string;
  currencyCode: CurrencyCode;
};

export type EditableBandPointCell_position = {
  __typename: "Position";
  id: number;
  type: PositionType;
};

export type EditableBandPointCell_cashBandPoint = {
  __typename: "AdjustedCashBandPoint";
  name: string;
  bandName: string;
  id: string;
  value: {
    __typename: "CashValue";
    rate: GraphQL_Money | null;
    currencyCode: CurrencyCode;
    annualRate: GraphQL_Money | null;
  };
};

export type EditableBandPointCell_equityBandPoint = {
  __typename: "AdjustedEquityBandPoint";
  name: string;
  bandName: string;
  vestingMonths: number;
  id: string;
  annualCashEquivalent: GraphQL_Money;
  totalGrossValue: GraphQL_Money;
  totalUnits: number | null;
  value:
    | {
        __typename: "CashValue";
        rate: GraphQL_Money | null;
        currencyCode: CurrencyCode;
        annualRate: GraphQL_Money | null;
      }
    | {
        __typename: "PercentValue";
        percentValue: number | null;
        decimalValue: number | null;
      }
    | { __typename: "UnitValue"; unitValue: number | null };
  totalPercentOwnership: {
    __typename: "PercentValue";
    decimalValue: number | null;
  } | null;
};

export type PositionDescription_position = {
  __typename: "Position";
  level: number;
  description: string | null;
  jobCodes: Array<string>;
  type: PositionType;
};

export type PositionDetail_position = {
  __typename: "Position";
  id: number;
  name: string;
  description: string | null;
  level: number;
  jobCodes: Array<string>;
  type: PositionType;
  ladder: {
    __typename: "Ladder";
    id: number;
    name: string;
    department: { __typename: "Department"; id: number; name: string };
    positions: Array<{
      __typename: "Position";
      id: number;
      name: string;
      level: number;
    }>;
  };
  organization: { __typename: "Organization"; id: number; name: string };
  adjustedCashBands: Array<{
    __typename: "AdjustedCashBand";
    id: string;
    name: string;
    bandPoints: Array<{
      __typename: "AdjustedCashBandPoint";
      id: string;
      name: string;
      bandName: string;
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      value: {
        __typename: "CashValue";
        annualRate: GraphQL_Money | null;
        hourlyRate: GraphQL_Money | null;
        currencyCode: CurrencyCode;
        rate: GraphQL_Money | null;
      };
    }>;
  }> | null;
  adjustedEquityBands: Array<{
    __typename: "AdjustedEquityBand";
    id: string;
    name: string;
    bandPoints: Array<{
      __typename: "AdjustedEquityBandPoint";
      id: string;
      name: string;
      bandName: string;
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      totalGrossValue: GraphQL_Money;
      totalUnits: number | null;
      vestingMonths: number;
      value:
        | {
            __typename: "CashValue";
            annualRate: GraphQL_Money | null;
            currencyCode: CurrencyCode;
            rate: GraphQL_Money | null;
          }
        | { __typename: "PercentValue"; decimalValue: number | null }
        | { __typename: "UnitValue"; unitValue: number | null };
      totalPercentOwnership: {
        __typename: "PercentValue";
        decimalValue: number | null;
      } | null;
    }>;
  }> | null;
};

export type PositionDetail_compStructure = {
  __typename: "CompStructure";
  id: number;
  showPercentOwnership: boolean;
  cashBandTypes: Array<string>;
  equityBandTypes: Array<string>;
};

export type PositionDetailBandPointCell_position = {
  __typename: "Position";
  id: number;
  type: PositionType;
};

export type PositionDetailBandPointCell_cashBandPoint = {
  __typename: "AdjustedCashBandPoint";
  id: string;
  name: string;
  bandName: string;
  value: {
    __typename: "CashValue";
    rate: GraphQL_Money | null;
    currencyCode: CurrencyCode;
    annualRate: GraphQL_Money | null;
  };
};

export type PositionDetailBandPointCell_equityBandPoint = {
  __typename: "AdjustedEquityBandPoint";
  id: string;
  name: string;
  bandName: string;
  annualCashEquivalent: GraphQL_Money;
  totalGrossValue: GraphQL_Money;
  totalUnits: number | null;
  vestingMonths: number;
  value:
    | {
        __typename: "CashValue";
        rate: GraphQL_Money | null;
        currencyCode: CurrencyCode;
        annualRate: GraphQL_Money | null;
      }
    | { __typename: "PercentValue"; decimalValue: number | null }
    | { __typename: "UnitValue"; unitValue: number | null };
  totalPercentOwnership: {
    __typename: "PercentValue";
    decimalValue: number | null;
  } | null;
};

export type PositionDetailEdit_position = {
  __typename: "Position";
  id: number;
  name: string;
  description: string | null;
  jobCodes: Array<string>;
  level: number;
  type: PositionType;
  ladder: {
    __typename: "Ladder";
    id: number;
    name: string;
    department: { __typename: "Department"; id: number; name: string };
    positions: Array<{
      __typename: "Position";
      id: number;
      name: string;
      level: number;
    }>;
  };
  organization: { __typename: "Organization"; id: number; name: string };
  adjustedCashBands: Array<{
    __typename: "AdjustedCashBand";
    id: string;
  }> | null;
  adjustedEquityBands: Array<{
    __typename: "AdjustedEquityBand";
    id: string;
  }> | null;
  unadjustedCashBands: Array<{
    __typename: "AdjustedCashBand";
    id: string;
    name: string;
    currencyCode: CurrencyCode;
    bandPoints: Array<{
      __typename: "AdjustedCashBandPoint";
      id: string;
      name: string;
      bandUnit: BandUnit;
      annualCashEquivalent: GraphQL_Money;
      bandName: string;
      value: {
        __typename: "CashValue";
        rate: GraphQL_Money | null;
        currencyCode: CurrencyCode;
        annualRate: GraphQL_Money | null;
      };
    }>;
  }> | null;
  unadjustedEquityBands: Array<{
    __typename: "AdjustedEquityBand";
    id: string;
    name: string;
    currencyCode: CurrencyCode;
    bandPoints: Array<{
      __typename: "AdjustedEquityBandPoint";
      id: string;
      name: string;
      annualCashEquivalent: GraphQL_Money;
      vestingMonths: number;
      bandName: string;
      totalGrossValue: GraphQL_Money;
      totalUnits: number | null;
      value:
        | {
            __typename: "CashValue";
            rate: GraphQL_Money | null;
            currencyCode: CurrencyCode;
            annualRate: GraphQL_Money | null;
          }
        | {
            __typename: "PercentValue";
            decimalValue: number | null;
            percentValue: number | null;
          }
        | { __typename: "UnitValue"; unitValue: number | null };
      totalPercentOwnership: {
        __typename: "PercentValue";
        decimalValue: number | null;
      } | null;
    }>;
  }> | null;
};

export type PositionDetailEdit_valuation = {
  __typename: "Valuation";
  fdso: number;
  valuationMoney: GraphQL_Money;
};

export type PositionDetailEditLoadingBoundaryQueryVariables = Exact<{
  positionId: Scalars["Int"]["input"];
  currencyCode: CurrencyCode;
  marketId: Scalars["Int"]["input"];
  locationGroupId?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type PositionDetailEditLoadingBoundaryQuery = {
  position: {
    __typename: "Position";
    id: number;
    name: string;
    description: string | null;
    jobCodes: Array<string>;
    level: number;
    type: PositionType;
    ladder: {
      __typename: "Ladder";
      id: number;
      name: string;
      department: { __typename: "Department"; id: number; name: string };
      positions: Array<{
        __typename: "Position";
        id: number;
        name: string;
        level: number;
      }>;
    };
    organization: { __typename: "Organization"; id: number; name: string };
    adjustedCashBands: Array<{
      __typename: "AdjustedCashBand";
      id: string;
    }> | null;
    adjustedEquityBands: Array<{
      __typename: "AdjustedEquityBand";
      id: string;
    }> | null;
    unadjustedCashBands: Array<{
      __typename: "AdjustedCashBand";
      id: string;
      name: string;
      currencyCode: CurrencyCode;
      bandPoints: Array<{
        __typename: "AdjustedCashBandPoint";
        id: string;
        name: string;
        bandUnit: BandUnit;
        annualCashEquivalent: GraphQL_Money;
        bandName: string;
        value: {
          __typename: "CashValue";
          rate: GraphQL_Money | null;
          currencyCode: CurrencyCode;
          annualRate: GraphQL_Money | null;
        };
      }>;
    }> | null;
    unadjustedEquityBands: Array<{
      __typename: "AdjustedEquityBand";
      id: string;
      name: string;
      currencyCode: CurrencyCode;
      bandPoints: Array<{
        __typename: "AdjustedEquityBandPoint";
        id: string;
        name: string;
        annualCashEquivalent: GraphQL_Money;
        vestingMonths: number;
        bandName: string;
        totalGrossValue: GraphQL_Money;
        totalUnits: number | null;
        value:
          | {
              __typename: "CashValue";
              rate: GraphQL_Money | null;
              currencyCode: CurrencyCode;
              annualRate: GraphQL_Money | null;
            }
          | {
              __typename: "PercentValue";
              decimalValue: number | null;
              percentValue: number | null;
            }
          | { __typename: "UnitValue"; unitValue: number | null };
        totalPercentOwnership: {
          __typename: "PercentValue";
          decimalValue: number | null;
        } | null;
      }>;
    }> | null;
  } | null;
  valuation: {
    __typename: "Valuation";
    id: number;
    fdso: number;
    valuationMoney: GraphQL_Money;
  } | null;
};

export type PositionDetailEditTable_position = {
  __typename: "Position";
  id: number;
  type: PositionType;
  unadjustedCashBands: Array<{
    __typename: "AdjustedCashBand";
    id: string;
    name: string;
    currencyCode: CurrencyCode;
    bandPoints: Array<{
      __typename: "AdjustedCashBandPoint";
      id: string;
      name: string;
      bandUnit: BandUnit;
      annualCashEquivalent: GraphQL_Money;
      bandName: string;
      value: {
        __typename: "CashValue";
        rate: GraphQL_Money | null;
        currencyCode: CurrencyCode;
        annualRate: GraphQL_Money | null;
      };
    }>;
  }> | null;
  unadjustedEquityBands: Array<{
    __typename: "AdjustedEquityBand";
    id: string;
    name: string;
    currencyCode: CurrencyCode;
    bandPoints: Array<{
      __typename: "AdjustedEquityBandPoint";
      id: string;
      name: string;
      annualCashEquivalent: GraphQL_Money;
      vestingMonths: number;
      bandName: string;
      totalGrossValue: GraphQL_Money;
      totalUnits: number | null;
      value:
        | {
            __typename: "CashValue";
            rate: GraphQL_Money | null;
            currencyCode: CurrencyCode;
            annualRate: GraphQL_Money | null;
          }
        | {
            __typename: "PercentValue";
            decimalValue: number | null;
            percentValue: number | null;
          }
        | { __typename: "UnitValue"; unitValue: number | null };
      totalPercentOwnership: {
        __typename: "PercentValue";
        decimalValue: number | null;
      } | null;
    }>;
  }> | null;
};

export type PositionDetailEditTable_valuation = {
  __typename: "Valuation";
  fdso: number;
  valuationMoney: GraphQL_Money;
};

export type PositionDetailLoadingBoundaryQueryVariables = Exact<{
  positionId: Scalars["Int"]["input"];
  currencyCode: CurrencyCode;
  marketId: Scalars["Int"]["input"];
  locationGroupId?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type PositionDetailLoadingBoundaryQuery = {
  position: {
    __typename: "Position";
    id: number;
    name: string;
    description: string | null;
    level: number;
    jobCodes: Array<string>;
    type: PositionType;
    ladder: {
      __typename: "Ladder";
      id: number;
      name: string;
      department: { __typename: "Department"; id: number; name: string };
      positions: Array<{
        __typename: "Position";
        id: number;
        name: string;
        level: number;
      }>;
    };
    organization: { __typename: "Organization"; id: number; name: string };
    adjustedCashBands: Array<{
      __typename: "AdjustedCashBand";
      id: string;
      name: string;
      bandPoints: Array<{
        __typename: "AdjustedCashBandPoint";
        id: string;
        name: string;
        bandName: string;
        annualCashEquivalent: GraphQL_Money;
        hourlyCashEquivalent: GraphQL_Money;
        value: {
          __typename: "CashValue";
          annualRate: GraphQL_Money | null;
          hourlyRate: GraphQL_Money | null;
          currencyCode: CurrencyCode;
          rate: GraphQL_Money | null;
        };
      }>;
    }> | null;
    adjustedEquityBands: Array<{
      __typename: "AdjustedEquityBand";
      id: string;
      name: string;
      bandPoints: Array<{
        __typename: "AdjustedEquityBandPoint";
        id: string;
        name: string;
        bandName: string;
        annualCashEquivalent: GraphQL_Money;
        hourlyCashEquivalent: GraphQL_Money;
        totalGrossValue: GraphQL_Money;
        totalUnits: number | null;
        vestingMonths: number;
        value:
          | {
              __typename: "CashValue";
              annualRate: GraphQL_Money | null;
              currencyCode: CurrencyCode;
              rate: GraphQL_Money | null;
            }
          | { __typename: "PercentValue"; decimalValue: number | null }
          | { __typename: "UnitValue"; unitValue: number | null };
        totalPercentOwnership: {
          __typename: "PercentValue";
          decimalValue: number | null;
        } | null;
      }>;
    }> | null;
  } | null;
  compStructure: {
    __typename: "CompStructure";
    id: number;
    showPercentOwnership: boolean;
    cashBandTypes: Array<string>;
    equityBandTypes: Array<string>;
  } | null;
};

export type PositionDetailReadTable_cashBandPoint = {
  __typename: "AdjustedCashBandPoint";
  name: string;
  bandName: string;
  annualCashEquivalent: GraphQL_Money;
  id: string;
  hourlyCashEquivalent: GraphQL_Money;
  value: {
    __typename: "CashValue";
    rate: GraphQL_Money | null;
    currencyCode: CurrencyCode;
    annualRate: GraphQL_Money | null;
    hourlyRate: GraphQL_Money | null;
  };
};

export type PositionDetailReadTable_equityBandPoint = {
  __typename: "AdjustedEquityBandPoint";
  name: string;
  bandName: string;
  annualCashEquivalent: GraphQL_Money;
  id: string;
  hourlyCashEquivalent: GraphQL_Money;
  totalGrossValue: GraphQL_Money;
  totalUnits: number | null;
  vestingMonths: number;
  value:
    | {
        __typename: "CashValue";
        rate: GraphQL_Money | null;
        currencyCode: CurrencyCode;
        annualRate: GraphQL_Money | null;
      }
    | { __typename: "PercentValue"; decimalValue: number | null }
    | { __typename: "UnitValue"; unitValue: number | null };
  totalPercentOwnership: {
    __typename: "PercentValue";
    decimalValue: number | null;
  } | null;
};

export type PositionDetailReadTable_position = {
  __typename: "Position";
  id: number;
  type: PositionType;
  adjustedCashBands: Array<{
    __typename: "AdjustedCashBand";
    id: string;
    name: string;
    bandPoints: Array<{
      __typename: "AdjustedCashBandPoint";
      id: string;
      name: string;
      bandName: string;
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      value: {
        __typename: "CashValue";
        rate: GraphQL_Money | null;
        currencyCode: CurrencyCode;
        annualRate: GraphQL_Money | null;
        hourlyRate: GraphQL_Money | null;
      };
    }>;
  }> | null;
  adjustedEquityBands: Array<{
    __typename: "AdjustedEquityBand";
    id: string;
    name: string;
    bandPoints: Array<{
      __typename: "AdjustedEquityBandPoint";
      id: string;
      name: string;
      bandName: string;
      annualCashEquivalent: GraphQL_Money;
      hourlyCashEquivalent: GraphQL_Money;
      totalGrossValue: GraphQL_Money;
      totalUnits: number | null;
      vestingMonths: number;
      value:
        | {
            __typename: "CashValue";
            rate: GraphQL_Money | null;
            currencyCode: CurrencyCode;
            annualRate: GraphQL_Money | null;
          }
        | { __typename: "PercentValue"; decimalValue: number | null }
        | { __typename: "UnitValue"; unitValue: number | null };
      totalPercentOwnership: {
        __typename: "PercentValue";
        decimalValue: number | null;
      } | null;
    }>;
  }> | null;
};

export type PositionPeerNavigation_position = {
  __typename: "Position";
  id: number;
  ladder: {
    __typename: "Ladder";
    id: number;
    positions: Array<{
      __typename: "Position";
      id: number;
      name: string;
      level: number;
    }>;
  };
};

export type GetOrgJobStructureCsvVariables = Exact<{ [key: string]: never }>;

export type GetOrgJobStructureCsv = {
  exportOrgJobStructureToCsv: { __typename: "CsvExport"; data: string };
};

export type GetOrgJobStructureWithBandsCsvVariables = Exact<{
  [key: string]: never;
}>;

export type GetOrgJobStructureWithBandsCsv = {
  exportOrgJobStructureWithBandsToCsv: {
    __typename: "CsvExport";
    data: string;
  };
};

export type GetOrgLevelingCodeMappingCsvVariables = Exact<{
  [key: string]: never;
}>;

export type GetOrgLevelingCodeMappingCsv = {
  exportOrgLevelingCodeMappingsToCsv: { __typename: "CsvExport"; data: string };
};

export type GetDataManagementInfoVariables = Exact<{ [key: string]: never }>;

export type GetDataManagementInfo = {
  organization: {
    __typename: "Organization";
    id: number;
    lastLevelingCodeUploadDate: GraphQL_DateTime | null;
    lastVariableUpload: GraphQL_DateTime | null;
    lastActualVariableCompUploadDate: GraphQL_DateTime | null;
    lastEquityGrantsUploadDate: GraphQL_DateTime | null;
    lastEquityVestingEventsUploadDate: GraphQL_DateTime | null;
    levelingCodeMappings: Array<{
      __typename: "LevelingCodeMapping";
      positionId: number;
      levelingCode: string;
    }>;
  };
  employees: Array<{
    __typename: "Employee";
    id: number;
    employeeNumber: string | null;
    email: string;
    personalEmail: string | null;
    cashCompensation: Array<{
      __typename: "CashCompensation";
      employeeId: number;
      type: CashCompType;
      activeAt: GraphQL_Date;
      annualCashEquivalent: GraphQL_Money;
      createdAt: GraphQL_DateTime;
    }> | null;
    equityHoldings: {
      __typename: "EquityHoldings";
      id: number;
      grants: Array<{
        __typename: "EquityGrant";
        id: number;
        name: string;
        unitCount: number;
      }> | null;
    };
  }>;
  mergeConnectionConfigs: Array<{
    __typename: "MergeConnectionConfig";
    id: number;
    integrationName: string;
    integrationType: string;
    imageUrl: string | null;
    lastSyncAt: GraphQL_DateTime | null;
    originId: string | null;
    isManualUpload: boolean;
    author: {
      __typename: "User";
      id: number;
      name: string | null;
      photoURL: string | null;
    };
  }>;
  cartaConnectionConfig: {
    __typename: "CartaConnectionConfig";
    id: number;
    isActive: boolean;
    lastSyncAt: GraphQL_DateTime | null;
    author: {
      __typename: "User";
      id: number;
      name: string | null;
      photoURL: string | null;
    };
  } | null;
  positions: Array<{
    __typename: "Position";
    id: number;
    name: string;
    level: number;
    ladder: {
      __typename: "Ladder";
      id: number;
      name: string;
      department: { __typename: "Department"; id: number; name: string };
    };
  }>;
  marketDataSets: Array<{
    __typename: "MarketDataSet";
    id: number;
    label: string;
    provider: string;
    createdAt: GraphQL_DateTime;
    market: { __typename: "Market"; id: number; name: string } | null;
    author: {
      __typename: "User";
      photoURL: string | null;
      name: string | null;
    };
  }>;
};

export type AssignBenefitsButton_employee = {
  __typename: "Employee";
  id: number;
  displayName: string;
  benefitsPackage: {
    __typename: "BenefitsPackage";
    id: number;
    name: string;
  } | null;
};

export type BenefitsDialog_benefitsPackage = {
  __typename: "BenefitsPackage";
  id: number;
  name: string;
};

export type BenefitsDialog_employee = {
  __typename: "Employee";
  id: number;
  displayName: string;
  benefitsPackage: {
    __typename: "BenefitsPackage";
    id: number;
    name: string;
  } | null;
};

export type BenefitsDialogQueryVariables = Exact<{ [key: string]: never }>;

export type BenefitsDialogQuery = {
  benefitsPackages: Array<{
    __typename: "BenefitsPackage";
    id: number;
    name: string;
  }>;
};

export type LittleGiftButton_employee = {
  __typename: "Employee";
  id: number;
  displayName: string;
  benefitsPackage: {
    __typename: "BenefitsPackage";
    id: number;
    name: string;
  } | null;
};

export type EmployeePortal_employee = {
  __typename: "Employee";
  employmentStatus: EmploymentStatus;
  id: number;
  displayName: string;
  email: string;
  employeeNumber: string | null;
  personalEmail: string | null;
  activeEmployment: {
    __typename: "Employment";
    id: number;
    jobTitle: string | null;
  } | null;
  manager: {
    __typename: "MinimalEmployee";
    id: number;
    displayName: string;
    user: { __typename: "User"; id: number; photoURL: string | null } | null;
  } | null;
  homeLocation: {
    __typename: "Location";
    id: number;
    city: string;
    state: string;
    country: string;
  } | null;
  user: {
    __typename: "User";
    id: number;
    photoURL: string | null;
    isDisabled: boolean;
    userAccessGrant: {
      __typename: "UserAccessGrant";
      id: number;
      roleName: PrimaryRoleName;
    } | null;
  } | null;
  latestUserInvite: {
    __typename: "UserInvitation";
    id: number;
    createdAt: GraphQL_DateTime;
    deletedAt: GraphQL_DateTime | null;
    expiredAt: GraphQL_DateTime;
    tokenUsedAt: GraphQL_DateTime | null;
    name: string;
    email: string;
    employee: {
      __typename: "Employee";
      id: number;
      latestUserInvite: { __typename: "UserInvitation"; id: number } | null;
    } | null;
  } | null;
  benefitsPackage: {
    __typename: "BenefitsPackage";
    id: number;
    name: string;
  } | null;
  equityHoldings: {
    __typename: "EquityHoldings";
    id: number;
    grants: Array<{
      __typename: "EquityGrant";
      id: number;
      name: string;
      unitCount: number;
    }> | null;
  };
};

export type EmployeePortal_portalConfig = {
  __typename: "PortalConfig";
  id: number;
};

export type EmployeePortal_organization = {
  __typename: "Organization";
  id: number;
  hasAnyEquityVestEvents: boolean;
  lastEquityGrantsUploadDate: GraphQL_DateTime | null;
  lastEquityVestingEventsUploadDate: GraphQL_DateTime | null;
};

export type EmployeePortal_valuation = { __typename: "Valuation"; id: number };

export type EmployeePortalQueryVariables = Exact<{ [key: string]: never }>;

export type EmployeePortalQuery = {
  employees: Array<{
    __typename: "Employee";
    id: number;
    employmentStatus: EmploymentStatus;
    displayName: string;
    email: string;
    employeeNumber: string | null;
    personalEmail: string | null;
    activeEmployment: {
      __typename: "Employment";
      id: number;
      jobTitle: string | null;
    } | null;
    manager: {
      __typename: "MinimalEmployee";
      id: number;
      displayName: string;
      user: { __typename: "User"; id: number; photoURL: string | null } | null;
    } | null;
    homeLocation: {
      __typename: "Location";
      id: number;
      city: string;
      state: string;
      country: string;
    } | null;
    user: {
      __typename: "User";
      id: number;
      photoURL: string | null;
      isDisabled: boolean;
      userAccessGrant: {
        __typename: "UserAccessGrant";
        id: number;
        roleName: PrimaryRoleName;
      } | null;
    } | null;
    latestUserInvite: {
      __typename: "UserInvitation";
      id: number;
      createdAt: GraphQL_DateTime;
      deletedAt: GraphQL_DateTime | null;
      expiredAt: GraphQL_DateTime;
      tokenUsedAt: GraphQL_DateTime | null;
      name: string;
      email: string;
      employee: {
        __typename: "Employee";
        id: number;
        latestUserInvite: { __typename: "UserInvitation"; id: number } | null;
      } | null;
    } | null;
    benefitsPackage: {
      __typename: "BenefitsPackage";
      id: number;
      name: string;
    } | null;
    equityHoldings: {
      __typename: "EquityHoldings";
      id: number;
      grants: Array<{
        __typename: "EquityGrant";
        id: number;
        name: string;
        unitCount: number;
      }> | null;
    };
  }>;
  portalConfig: { __typename: "PortalConfig"; id: number } | null;
  organization: {
    __typename: "Organization";
    id: number;
    hasAnyEquityVestEvents: boolean;
    lastEquityGrantsUploadDate: GraphQL_DateTime | null;
    lastEquityVestingEventsUploadDate: GraphQL_DateTime | null;
  };
  valuation: { __typename: "Valuation"; id: number } | null;
};

export type PortalBulkInvite_employee = {
  __typename: "Employee";
  id: number;
  user: { __typename: "User"; id: number } | null;
  latestUserInvite: {
    __typename: "UserInvitation";
    id: number;
    name: string;
    email: string;
    createdAt: GraphQL_DateTime;
    expiredAt: GraphQL_DateTime;
    deletedAt: GraphQL_DateTime | null;
    tokenUsedAt: GraphQL_DateTime | null;
    employee: {
      __typename: "Employee";
      id: number;
      latestUserInvite: { __typename: "UserInvitation"; id: number } | null;
    } | null;
  } | null;
};

export type UploadEquityBanner_employee = {
  __typename: "Employee";
  id: number;
  employeeNumber: string | null;
  email: string;
  personalEmail: string | null;
  equityHoldings: {
    __typename: "EquityHoldings";
    id: number;
    grants: Array<{
      __typename: "EquityGrant";
      id: number;
      name: string;
      unitCount: number;
    }> | null;
  };
};

export type UploadEquityBanner_organization = {
  __typename: "Organization";
  id: number;
  hasAnyEquityVestEvents: boolean;
  lastEquityGrantsUploadDate: GraphQL_DateTime | null;
  lastEquityVestingEventsUploadDate: GraphQL_DateTime | null;
};

export type CompensationBandsForm_permissionSettings = {
  __typename: "Organization";
  id: number;
  permissionSettings: {
    __typename: "PermissionSettings";
    id: number;
    employeeBandAccess: EmployeeBandAccessType;
  };
};

export type EquityOutcomesForm_portalConfig = {
  __typename: "PortalConfig";
  id: number;
  exitOutcomes: Array<number>;
  defaultExitOutcome: number;
  valuationCurrencyCode: CurrencyCode;
};

export type EquityOutcomesFormPair_portalConfig = {
  __typename: "PortalConfig";
  id: number;
  defaultExitOutcome: number;
  valuationCurrencyCode: CurrencyCode;
};

export type PortalConfig_portalConfig = {
  __typename: "PortalConfig";
  id: number;
  exitOutcomes: Array<number>;
  defaultExitOutcome: number;
  xAxisAnnotations: Array<string>;
  valuationCurrencyCode: CurrencyCode;
};

export type PortalConfig_permissionSettings = {
  __typename: "Organization";
  id: number;
  name: string;
  permissionSettings: {
    __typename: "PermissionSettings";
    id: number;
    employeeBandAccess: EmployeeBandAccessType;
  };
};

export type PortalConfigQueryVariables = Exact<{ [key: string]: never }>;

export type PortalConfigQuery = {
  portalConfig: {
    __typename: "PortalConfig";
    id: number;
    exitOutcomes: Array<number>;
    defaultExitOutcome: number;
    xAxisAnnotations: Array<string>;
    valuationCurrencyCode: CurrencyCode;
  } | null;
  organization: {
    __typename: "Organization";
    id: number;
    name: string;
    permissionSettings: {
      __typename: "PermissionSettings";
      id: number;
      employeeBandAccess: EmployeeBandAccessType;
    };
  };
};

export type PortalTableButton_employee = {
  __typename: "Employee";
  id: number;
  displayName: string;
  email: string;
  user: {
    __typename: "User";
    id: number;
    isDisabled: boolean;
    userAccessGrant: {
      __typename: "UserAccessGrant";
      id: number;
      roleName: PrimaryRoleName;
    } | null;
  } | null;
  latestUserInvite: {
    __typename: "UserInvitation";
    id: number;
    createdAt: GraphQL_DateTime;
    deletedAt: GraphQL_DateTime | null;
    expiredAt: GraphQL_DateTime;
    tokenUsedAt: GraphQL_DateTime | null;
  } | null;
};

export type TableAccessButton_employee = {
  __typename: "Employee";
  id: number;
  displayName: string;
  email: string;
};

export type TableAccountButton_employee = {
  __typename: "Employee";
  id: number;
  displayName: string;
  user: { __typename: "User"; id: number; isDisabled: boolean } | null;
};

export type TableInviteButton_employee = {
  __typename: "Employee";
  id: number;
  displayName: string;
  email: string;
};

export type PortalTable_employee = {
  __typename: "Employee";
  id: number;
  displayName: string;
  email: string;
  manager: {
    __typename: "MinimalEmployee";
    id: number;
    displayName: string;
    user: { __typename: "User"; id: number; photoURL: string | null } | null;
  } | null;
  activeEmployment: {
    __typename: "Employment";
    id: number;
    jobTitle: string | null;
  } | null;
  homeLocation: {
    __typename: "Location";
    id: number;
    city: string;
    state: string;
    country: string;
  } | null;
  user: {
    __typename: "User";
    id: number;
    photoURL: string | null;
    isDisabled: boolean;
    userAccessGrant: {
      __typename: "UserAccessGrant";
      id: number;
      roleName: PrimaryRoleName;
    } | null;
  } | null;
  benefitsPackage: {
    __typename: "BenefitsPackage";
    id: number;
    name: string;
  } | null;
  latestUserInvite: {
    __typename: "UserInvitation";
    id: number;
    createdAt: GraphQL_DateTime;
    deletedAt: GraphQL_DateTime | null;
    expiredAt: GraphQL_DateTime;
    tokenUsedAt: GraphQL_DateTime | null;
  } | null;
};

export type Locations_compStructure = {
  __typename: "CompStructure";
  id: number;
  cashBandTypes: Array<string>;
  equityBandTypes: Array<string>;
  levels: Array<number>;
};

export type Locations_locations = {
  __typename: "Location";
  id: number;
  city: string;
  state: string;
  country: string;
  locationGroup: {
    __typename: "LocationGroup";
    id: number;
    name: string;
    market: { __typename: "Market"; id: number; name: string };
    author: {
      __typename: "User";
      id: number;
      name: string | null;
      photoURL: string | null;
    } | null;
  } | null;
};

export type Locations_locationGroups = {
  __typename: "LocationGroup";
  id: number;
  name: string;
  createdAt: GraphQL_DateTime;
  updatedAt: GraphQL_DateTime;
  rankedAdjustmentIds: Array<number>;
  description: string | null;
  isSourceComp: boolean;
  locationAdjustments: Array<{
    __typename: "LocationAdjustment";
    id: number;
    condition: GraphQL_Json;
    adjustment: GraphQL_Json;
    description: string | null;
  }>;
  locations: Array<{ __typename: "Location"; id: number; city: string }>;
  author: { __typename: "User"; id: number; name: string | null } | null;
};

export type Locations_departments = {
  __typename: "Department";
  id: number;
  name: string;
  ladders: Array<{ __typename: "Ladder"; id: number; name: string }>;
};

export type Locations_markets = {
  __typename: "Market";
  id: number;
  name: string;
};

export type LocationsActions_locations = {
  __typename: "Location";
  id: number;
  city: string;
  state: string;
  country: string;
  locationGroup: { __typename: "LocationGroup"; id: number } | null;
};

export type LocationsActions_markets = { __typename: "Market"; id: number };

export type LocationsQueryVariables = Exact<{ [key: string]: never }>;

export type LocationsQuery = {
  locations: Array<{
    __typename: "Location";
    id: number;
    city: string;
    state: string;
    country: string;
    locationGroup: {
      __typename: "LocationGroup";
      id: number;
      name: string;
      market: { __typename: "Market"; id: number; name: string };
      author: {
        __typename: "User";
        id: number;
        name: string | null;
        photoURL: string | null;
      } | null;
    } | null;
  }>;
  markets: Array<{
    __typename: "Market";
    id: number;
    name: string;
    locationGroups: Array<{
      __typename: "LocationGroup";
      id: number;
      name: string;
      createdAt: GraphQL_DateTime;
      updatedAt: GraphQL_DateTime;
      rankedAdjustmentIds: Array<number>;
      description: string | null;
      isSourceComp: boolean;
      locationAdjustments: Array<{
        __typename: "LocationAdjustment";
        id: number;
        condition: GraphQL_Json;
        adjustment: GraphQL_Json;
        description: string | null;
      }>;
      locations: Array<{ __typename: "Location"; id: number; city: string }>;
      author: { __typename: "User"; id: number; name: string | null } | null;
    }>;
  }>;
  compStructure: {
    __typename: "CompStructure";
    id: number;
    cashBandTypes: Array<string>;
    equityBandTypes: Array<string>;
    levels: Array<number>;
  } | null;
  departments: Array<{
    __typename: "Department";
    id: number;
    name: string;
    ladders: Array<{ __typename: "Ladder"; id: number; name: string }>;
  }>;
};

export type LocationsWithMarketIdQueryVariables = Exact<{
  marketId: Scalars["Int"]["input"];
}>;

export type LocationsWithMarketIdQuery = {
  locations: Array<{
    __typename: "Location";
    id: number;
    city: string;
    state: string;
    country: string;
    locationGroup: {
      __typename: "LocationGroup";
      id: number;
      name: string;
      market: { __typename: "Market"; id: number; name: string };
      author: {
        __typename: "User";
        id: number;
        name: string | null;
        photoURL: string | null;
      } | null;
    } | null;
  }>;
  market: {
    __typename: "Market";
    id: number;
    name: string;
    locationGroups: Array<{
      __typename: "LocationGroup";
      id: number;
      name: string;
      createdAt: GraphQL_DateTime;
      updatedAt: GraphQL_DateTime;
      rankedAdjustmentIds: Array<number>;
      description: string | null;
      isSourceComp: boolean;
      locationAdjustments: Array<{
        __typename: "LocationAdjustment";
        id: number;
        condition: GraphQL_Json;
        adjustment: GraphQL_Json;
        description: string | null;
      }>;
      locations: Array<{ __typename: "Location"; id: number; city: string }>;
      author: { __typename: "User"; id: number; name: string | null } | null;
    }>;
  };
  markets: Array<{
    __typename: "Market";
    id: number;
    name: string;
    locationGroups: Array<{
      __typename: "LocationGroup";
      id: number;
      name: string;
      createdAt: GraphQL_DateTime;
      updatedAt: GraphQL_DateTime;
      rankedAdjustmentIds: Array<number>;
      description: string | null;
      isSourceComp: boolean;
      locationAdjustments: Array<{
        __typename: "LocationAdjustment";
        id: number;
        condition: GraphQL_Json;
        adjustment: GraphQL_Json;
        description: string | null;
      }>;
      locations: Array<{ __typename: "Location"; id: number; city: string }>;
      author: { __typename: "User"; id: number; name: string | null } | null;
    }>;
  }>;
  compStructure: {
    __typename: "CompStructure";
    id: number;
    cashBandTypes: Array<string>;
    equityBandTypes: Array<string>;
    levels: Array<number>;
  } | null;
  departments: Array<{
    __typename: "Department";
    id: number;
    name: string;
    ladders: Array<{ __typename: "Ladder"; id: number; name: string }>;
  }>;
};

export type MarketDataSettings_marketDataSet = {
  __typename: "MarketDataSet";
  id: number;
  label: string;
  provider: string;
  createdAt: GraphQL_DateTime;
  market: { __typename: "Market"; id: number; name: string } | null;
  author: { __typename: "User"; photoURL: string | null; name: string | null };
};

export type MarketCard_market = {
  __typename: "Market";
  id: number;
  name: string;
  currencyCode: CurrencyCode;
  locationGroups: Array<{ __typename: "LocationGroup"; id: number }>;
};

export type MarketCardActions_market = {
  __typename: "Market";
  id: number;
  name: string;
  currencyCode: CurrencyCode;
  locationGroups: Array<{ __typename: "LocationGroup"; id: number }>;
};

export type MarketDeleter_market = {
  __typename: "Market";
  id: number;
  name: string;
  locationGroups: Array<{ __typename: "LocationGroup"; id: number }>;
};

export type MarketEditor_market = {
  __typename: "Market";
  id: number;
  name: string;
  currencyCode: CurrencyCode;
};

export type Markets_market = { __typename: "Market"; id: number; name: string };

export type MarketsList_market = {
  __typename: "Market";
  id: number;
  name: string;
  currencyCode: CurrencyCode;
  locationGroups: Array<{ __typename: "LocationGroup"; id: number }>;
};

export type MarketsQueryVariables = Exact<{ [key: string]: never }>;

export type MarketsQuery = {
  markets: Array<{
    __typename: "Market";
    id: number;
    name: string;
    currencyCode: CurrencyCode;
    locationGroups: Array<{ __typename: "LocationGroup"; id: number }>;
  }>;
};

export type GetDepartmentsVariables = Exact<{ [key: string]: never }>;

export type GetDepartments = {
  organization: { __typename: "Organization"; id: number; name: string };
  departments: Array<{
    __typename: "Department";
    id: number;
    name: string;
    ladders: Array<{
      __typename: "Ladder";
      id: number;
      name: string;
      positions: Array<{
        __typename: "Position";
        id: number;
        level: number;
        name: string;
      }>;
    }>;
  }>;
};

export type GetDepartmentVariables = Exact<{
  departmentId: Scalars["Int"]["input"];
}>;

export type GetDepartment = {
  department: {
    __typename: "Department";
    id: number;
    name: string;
    description: string | null;
    organization: {
      __typename: "Organization";
      id: number;
      name: string;
      departments: Array<{
        __typename: "Department";
        id: number;
        name: string;
      }>;
    };
    ladders: Array<{
      __typename: "Ladder";
      id: number;
      name: string;
      description: string | null;
      positions: Array<{ __typename: "Position"; id: number; level: number }>;
    }>;
  } | null;
};

export type GetOfferVariables = Exact<{
  offerId: Scalars["Int"]["input"];
}>;

export type GetOffer = {
  offer: {
    __typename: "Offer";
    id: number;
    message: string | null;
    closingMessage: string | null;
    outcomeDescription: string | null;
    offeredAt: GraphQL_DateTime;
    title: string | null;
    candidate: { __typename: "Candidate"; candidateName: string };
    cash: {
      __typename: "OfferCash";
      data: Array<{
        __typename: "OfferCashBand";
        type: string;
        money: GraphQL_Money;
      }>;
    };
    equity: {
      __typename: "OfferEquity";
      data: Array<{
        __typename: "OfferEquityBand";
        type: string;
        money: GraphQL_Money | null;
        units: number | null;
      }>;
    };
    position: {
      __typename: "Position";
      id: number;
      name: string;
      level: number;
      type: PositionType;
    } | null;
    organization: { __typename: "Organization"; id: number; name: string };
    benefitsPackage: {
      __typename: "BenefitsPackage";
      id: number;
      name: string;
      currencyCode: CurrencyCode;
      totalValue: GraphQL_Money;
      benefits: Array<{
        __typename: "Benefit";
        id: number;
        benefitsPackageId: number;
        name: string;
        value: GraphQL_Money | null;
        description: string;
        rank: string;
      }>;
    } | null;
  };
  compStructure: {
    __typename: "CompStructure";
    id: number;
    showPercentOwnership: boolean;
    showValuation: boolean;
    vestingCliff: number;
    vestingMonths: number;
    companyDescription: string | null;
    equityGrantTypes: Array<EquityGrantTypes>;
    equityGrantMethod: EquityGrantMethod;
    employmentHoursPerWeek: number;
    employmentWeeksPerYear: number;
  } | null;
  valuation: {
    __typename: "Valuation";
    id: number;
    activeAt: GraphQL_DateTime;
    estimatedDilution: number;
    financingStage: string;
    fdso: number;
    strikePrice: GraphQL_Money;
    valuation: GraphQL_Money;
  } | null;
  offerConfig: {
    __typename: "OfferConfig";
    id: number;
    exitOutcomes: Array<number>;
    xAxisAnnotations: Array<string>;
    defaultOutcomeDescription: string;
    equityFootnote: string | null;
    defaultWelcomeMessage: string | null;
    defaultClosingMessage: string | null;
    sharePriceLabel: string;
    showEquityInformation: boolean;
    showFdso: boolean;
    showStage: boolean;
    showCurrentEquityValue: boolean;
    showSharePriceFootnote: boolean;
    showAnnualizedEquity: boolean;
    requireApproval: boolean;
    brandColorPrimary: string | null;
    primaryLogoUrlPath: string | null;
    whiteLogoUrlPath: string | null;
    greyLogoUrlPath: string | null;
    backgroundUrlPath: string | null;
    equityCashInValuationCurrency: boolean;
    customFields: Array<{
      __typename: "CustomField";
      name: string;
      description: string;
      variant: CustomFieldVariant;
      options: Array<string>;
    }>;
  } | null;
};

export type GetOffersVariables = Exact<{ [key: string]: never }>;

export type GetOffers = {
  offers: Array<{
    __typename: "Offer";
    id: number;
    isLegacy: boolean;
    offeredAt: GraphQL_DateTime;
    updatedAt: GraphQL_DateTime | null;
    expiredAt: GraphQL_DateTime | null;
    title: string | null;
    candidate: { __typename: "Candidate"; candidateName: string };
    position: {
      __typename: "Position";
      id: number;
      name: string;
      level: number;
    } | null;
  }>;
};

export type GetOfferConfigVariables = Exact<{ [key: string]: never }>;

export type GetOfferConfig = {
  offerConfig: {
    __typename: "OfferConfig";
    id: number;
    exitOutcomes: Array<number>;
    xAxisAnnotations: Array<string>;
    defaultOutcomeDescription: string;
    equityFootnote: string | null;
    defaultWelcomeMessage: string | null;
    defaultClosingMessage: string | null;
    sharePriceLabel: string;
    showEquityInformation: boolean;
    showFdso: boolean;
    showStage: boolean;
    showCurrentEquityValue: boolean;
    showSharePriceFootnote: boolean;
    showAnnualizedEquity: boolean;
    requireApproval: boolean;
    brandColorPrimary: string | null;
    primaryLogoUrlPath: string | null;
    whiteLogoUrlPath: string | null;
    greyLogoUrlPath: string | null;
    backgroundUrlPath: string | null;
    equityCashInValuationCurrency: boolean;
    customFields: Array<{
      __typename: "CustomField";
      name: string;
      description: string;
      variant: CustomFieldVariant;
      options: Array<string>;
    }>;
  } | null;
};

export type GetOrganizationVariables = Exact<{ [key: string]: never }>;

export type GetOrganization = {
  organization: { __typename: "Organization"; id: number; name: string };
};

export type GetCompStructureVariables = Exact<{ [key: string]: never }>;

export type GetCompStructure = {
  compStructure: {
    __typename: "CompStructure";
    id: number;
    bandPointTypes: Array<string>;
    cashBandTypes: Array<string>;
    equityBandTypes: Array<string>;
    allBandTypes: Array<string>;
    availableCashBandTypes: Array<string>;
    availableEquityBandTypes: Array<string>;
    businessStructure: BusinessStructure | null;
    companyDescription: string | null;
    equityGrantTypes: Array<EquityGrantTypes>;
    showPercentOwnership: boolean;
    showValuation: boolean;
    vestingCliff: number;
    vestingMonths: number;
    levels: Array<number>;
    equityGrantMethod: EquityGrantMethod;
    allowHourlyEmployees: boolean;
    employmentHoursPerWeek: number;
    employmentWeeksPerYear: number;
  } | null;
};

export type GetLaddersAndPositionsVariables = Exact<{ [key: string]: never }>;

export type GetLaddersAndPositions = {
  compStructure: {
    __typename: "CompStructure";
    id: number;
    bandPointTypes: Array<string>;
    cashBandTypes: Array<string>;
    equityBandTypes: Array<string>;
    vestingMonths: number;
    allBandTypes: Array<string>;
  } | null;
  ladders: Array<{
    __typename: "Ladder";
    id: number;
    name: string;
    department: { __typename: "Department"; id: number; name: string };
    positions: Array<{
      __typename: "Position";
      id: number;
      name: string;
      description: string | null;
      level: number;
      areCashBandsAuthorized: boolean;
      areEquityBandsAuthorized: boolean;
    }>;
  }>;
};

export type GetValuationVariables = Exact<{ [key: string]: never }>;

export type GetValuation = {
  valuation: {
    __typename: "Valuation";
    id: number;
    activeAt: GraphQL_DateTime;
    estimatedDilution: number;
    financingStage: string;
    fdso: number;
    strikePrice: GraphQL_Money;
    valuation: GraphQL_Money;
  } | null;
};

export type GetUsersWithAccessControlsVariables = Exact<{
  [key: string]: never;
}>;

export type GetUsersWithAccessControls = {
  userRecords: Array<{
    __typename: "User";
    id: number;
    email: string;
    name: string | null;
    createdAt: GraphQL_DateTime;
    photoURL: string | null;
    isDisabled: boolean;
    userAccessGrant: {
      __typename: "UserAccessGrant";
      id: number;
      roleName: PrimaryRoleName;
    } | null;
    employee: {
      __typename: "Employee";
      id: number;
      isManager: boolean;
      reports: Array<{ __typename: "Employee"; id: number }> | null;
    } | null;
  }>;
  organization: {
    __typename: "Organization";
    id: number;
    name: string;
    permissionSettings: {
      __typename: "PermissionSettings";
      id: number;
      teamCompensationAccess: TeamCompensationAccessType;
    };
  };
};

export type GetUserWithAccessControlsVariables = Exact<{
  userId: Scalars["Int"]["input"];
}>;

export type GetUserWithAccessControls = {
  userRecord: {
    __typename: "User";
    id: number;
    name: string | null;
    email: string;
    photoURL: string | null;
    isDisabled: boolean;
    createdAt: GraphQL_DateTime;
    userAccessGrant: {
      __typename: "UserAccessGrant";
      id: number;
      roleName: PrimaryRoleName;
      nounScopes: {
        __typename: "NounScopes";
        AccessControl: {
          __typename: "BaseNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: { __typename: "BaseScope"; global: boolean } | null;
        };
        CashBand: {
          __typename: "MarketJobStructureNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: {
            __typename: "MarketJobStructureScope";
            allMarkets: {
              __typename: "JobStructureScope";
              global: boolean;
              departmentIDs: Array<number>;
              ladderIDs: Array<number>;
              positionIDs: Array<number>;
            } | null;
            markets: Array<{
              __typename: "IndividualMarketJobStructureScope";
              marketId: number;
              scope: {
                __typename: "JobStructureScope";
                global: boolean;
                departmentIDs: Array<number>;
                ladderIDs: Array<number>;
                positionIDs: Array<number>;
              } | null;
            }>;
          } | null;
        };
        CompCycle: {
          __typename: "BaseNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: { __typename: "BaseScope"; global: boolean } | null;
        };
        CompCycleBudget: {
          __typename: "BaseNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: { __typename: "BaseScope"; global: boolean } | null;
        };
        CompRecommendation: {
          __typename: "EmployeeNounScope";
          edit: {
            __typename: "EmployeeScope";
            global: boolean;
            directReportIDs: Array<number>;
            indirectReportIDs: Array<number>;
            supportingManagerEmployeeIDs: Array<number | null> | null;
          } | null;
          view: {
            __typename: "EmployeeScope";
            global: boolean;
            directReportIDs: Array<number>;
            indirectReportIDs: Array<number>;
            supportingManagerEmployeeIDs: Array<number | null> | null;
          } | null;
        };
        CompStructure: {
          __typename: "BaseNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: { __typename: "BaseScope"; global: boolean } | null;
        };
        Employee: {
          __typename: "EmployeeNounScope";
          edit: {
            __typename: "EmployeeScope";
            global: boolean;
            directReportIDs: Array<number>;
            indirectReportIDs: Array<number>;
            supportingManagerEmployeeIDs: Array<number | null> | null;
          } | null;
          view: {
            __typename: "EmployeeScope";
            global: boolean;
            directReportIDs: Array<number>;
            indirectReportIDs: Array<number>;
            supportingManagerEmployeeIDs: Array<number | null> | null;
          } | null;
        };
        EmployeeCashCompensation: {
          __typename: "EmployeeNounScope";
          edit: {
            __typename: "EmployeeScope";
            global: boolean;
            directReportIDs: Array<number>;
            indirectReportIDs: Array<number>;
            supportingManagerEmployeeIDs: Array<number | null> | null;
          } | null;
          view: {
            __typename: "EmployeeScope";
            global: boolean;
            directReportIDs: Array<number>;
            indirectReportIDs: Array<number>;
            supportingManagerEmployeeIDs: Array<number | null> | null;
          } | null;
        };
        EmployeeEquityCompensation: {
          __typename: "EmployeeNounScope";
          edit: {
            __typename: "EmployeeScope";
            global: boolean;
            directReportIDs: Array<number>;
            indirectReportIDs: Array<number>;
            supportingManagerEmployeeIDs: Array<number | null> | null;
          } | null;
          view: {
            __typename: "EmployeeScope";
            global: boolean;
            directReportIDs: Array<number>;
            indirectReportIDs: Array<number>;
            supportingManagerEmployeeIDs: Array<number | null> | null;
          } | null;
        };
        EquityBand: {
          __typename: "MarketJobStructureNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: {
            __typename: "MarketJobStructureScope";
            allMarkets: {
              __typename: "JobStructureScope";
              global: boolean;
              departmentIDs: Array<number>;
              ladderIDs: Array<number>;
              positionIDs: Array<number>;
            } | null;
            markets: Array<{
              __typename: "IndividualMarketJobStructureScope";
              marketId: number;
              scope: {
                __typename: "JobStructureScope";
                global: boolean;
                departmentIDs: Array<number>;
                ladderIDs: Array<number>;
                positionIDs: Array<number>;
              } | null;
            }>;
          } | null;
        };
        Integration: {
          __typename: "BaseNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: { __typename: "BaseScope"; global: boolean } | null;
        };
        JobStructure: {
          __typename: "JobStructureNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: {
            __typename: "JobStructureScope";
            global: boolean;
            departmentIDs: Array<number>;
            ladderIDs: Array<number>;
            positionIDs: Array<number>;
          } | null;
        };
        Market: {
          __typename: "BaseNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: { __typename: "BaseScope"; global: boolean } | null;
        };
        MarketData: {
          __typename: "BaseNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: { __typename: "BaseScope"; global: boolean } | null;
        };
        Offer: {
          __typename: "OfferNounScope";
          edit: {
            __typename: "OfferScope";
            global: boolean;
            authored: boolean;
          } | null;
          view: {
            __typename: "OfferScope";
            global: boolean;
            authored: boolean;
          } | null;
        };
        Philosophy: {
          __typename: "BaseNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: { __typename: "BaseScope"; global: boolean } | null;
        };
        SensitiveData: {
          __typename: "BaseNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: { __typename: "BaseScope"; global: boolean } | null;
        };
      };
    } | null;
    employee: {
      __typename: "Employee";
      id: number;
      isManager: boolean;
      reports: Array<{
        __typename: "Employee";
        id: number;
        displayName: string;
        activeEmployment: {
          __typename: "Employment";
          id: number;
          position: {
            __typename: "Position";
            id: number;
            name: string;
            level: number;
            type: PositionType;
            ladder: {
              __typename: "Ladder";
              id: number;
              name: string;
              department: {
                __typename: "Department";
                id: number;
                name: string;
              };
            };
          } | null;
        } | null;
      }> | null;
    } | null;
  } | null;
  organization: {
    __typename: "Organization";
    id: number;
    permissionSettings: {
      __typename: "PermissionSettings";
      id: number;
      teamCompensationAccess: TeamCompensationAccessType;
    };
  };
};

export type GetCurrentUserPermissionsVariables = Exact<{
  [key: string]: never;
}>;

export type GetCurrentUserPermissions = {
  whoami: {
    __typename: "AuthSubject";
    id: number;
    uid: string;
    email: string;
    employeeId: number | null;
    displayName: string | null;
    createdAt: GraphQL_DateTime;
    photoURL: string | null;
    emailVerified: boolean;
    organization: {
      __typename: "Organization";
      id: number;
      name: string;
      hasActiveCompCycle: boolean;
      permissionSettings: {
        __typename: "PermissionSettings";
        id: number;
        teamCompensationAccess: TeamCompensationAccessType;
      };
    };
    userAccessGrant: {
      __typename: "UserAccessGrant";
      roleName: PrimaryRoleName;
      nounScopes: {
        __typename: "NounScopes";
        AccessControl: {
          __typename: "BaseNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: { __typename: "BaseScope"; global: boolean } | null;
        };
        CashBand: {
          __typename: "MarketJobStructureNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: {
            __typename: "MarketJobStructureScope";
            allMarkets: {
              __typename: "JobStructureScope";
              global: boolean;
              departmentIDs: Array<number>;
              ladderIDs: Array<number>;
              positionIDs: Array<number>;
            } | null;
            markets: Array<{
              __typename: "IndividualMarketJobStructureScope";
              marketId: number;
              scope: {
                __typename: "JobStructureScope";
                global: boolean;
                departmentIDs: Array<number>;
                ladderIDs: Array<number>;
                positionIDs: Array<number>;
              } | null;
            }>;
          } | null;
        };
        CompCycle: {
          __typename: "BaseNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: { __typename: "BaseScope"; global: boolean } | null;
        };
        CompCycleBudget: {
          __typename: "BaseNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: { __typename: "BaseScope"; global: boolean } | null;
        };
        CompRecommendation: {
          __typename: "EmployeeNounScope";
          edit: {
            __typename: "EmployeeScope";
            global: boolean;
            directReportIDs: Array<number>;
            indirectReportIDs: Array<number>;
            supportingManagerEmployeeIDs: Array<number | null> | null;
          } | null;
          view: {
            __typename: "EmployeeScope";
            global: boolean;
            directReportIDs: Array<number>;
            indirectReportIDs: Array<number>;
            supportingManagerEmployeeIDs: Array<number | null> | null;
          } | null;
        };
        CompStructure: {
          __typename: "BaseNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: { __typename: "BaseScope"; global: boolean } | null;
        };
        Employee: {
          __typename: "EmployeeNounScope";
          edit: {
            __typename: "EmployeeScope";
            global: boolean;
            directReportIDs: Array<number>;
            indirectReportIDs: Array<number>;
            supportingManagerEmployeeIDs: Array<number | null> | null;
          } | null;
          view: {
            __typename: "EmployeeScope";
            global: boolean;
            directReportIDs: Array<number>;
            indirectReportIDs: Array<number>;
            supportingManagerEmployeeIDs: Array<number | null> | null;
          } | null;
        };
        EmployeeCashCompensation: {
          __typename: "EmployeeNounScope";
          edit: {
            __typename: "EmployeeScope";
            global: boolean;
            directReportIDs: Array<number>;
            indirectReportIDs: Array<number>;
            supportingManagerEmployeeIDs: Array<number | null> | null;
          } | null;
          view: {
            __typename: "EmployeeScope";
            global: boolean;
            directReportIDs: Array<number>;
            indirectReportIDs: Array<number>;
            supportingManagerEmployeeIDs: Array<number | null> | null;
          } | null;
        };
        EmployeeEquityCompensation: {
          __typename: "EmployeeNounScope";
          edit: {
            __typename: "EmployeeScope";
            global: boolean;
            directReportIDs: Array<number>;
            indirectReportIDs: Array<number>;
            supportingManagerEmployeeIDs: Array<number | null> | null;
          } | null;
          view: {
            __typename: "EmployeeScope";
            global: boolean;
            directReportIDs: Array<number>;
            indirectReportIDs: Array<number>;
            supportingManagerEmployeeIDs: Array<number | null> | null;
          } | null;
        };
        EquityBand: {
          __typename: "MarketJobStructureNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: {
            __typename: "MarketJobStructureScope";
            allMarkets: {
              __typename: "JobStructureScope";
              global: boolean;
              departmentIDs: Array<number>;
              ladderIDs: Array<number>;
              positionIDs: Array<number>;
            } | null;
            markets: Array<{
              __typename: "IndividualMarketJobStructureScope";
              marketId: number;
              scope: {
                __typename: "JobStructureScope";
                global: boolean;
                departmentIDs: Array<number>;
                ladderIDs: Array<number>;
                positionIDs: Array<number>;
              } | null;
            }>;
          } | null;
        };
        Integration: {
          __typename: "BaseNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: { __typename: "BaseScope"; global: boolean } | null;
        };
        JobStructure: {
          __typename: "JobStructureNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: {
            __typename: "JobStructureScope";
            global: boolean;
            departmentIDs: Array<number>;
            ladderIDs: Array<number>;
            positionIDs: Array<number>;
          } | null;
        };
        Market: {
          __typename: "BaseNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: { __typename: "BaseScope"; global: boolean } | null;
        };
        MarketData: {
          __typename: "BaseNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: { __typename: "BaseScope"; global: boolean } | null;
        };
        Offer: {
          __typename: "OfferNounScope";
          edit: {
            __typename: "OfferScope";
            global: boolean;
            authored: boolean;
          } | null;
          view: {
            __typename: "OfferScope";
            global: boolean;
            authored: boolean;
          } | null;
        };
        Philosophy: {
          __typename: "BaseNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: { __typename: "BaseScope"; global: boolean } | null;
        };
        SensitiveData: {
          __typename: "BaseNounScope";
          edit: { __typename: "BaseScope"; global: boolean } | null;
          view: { __typename: "BaseScope"; global: boolean } | null;
        };
      };
    } | null;
  } | null;
};

export type GetBusinessStructureSettingsVariables = Exact<{
  [key: string]: never;
}>;

export type GetBusinessStructureSettings = {
  organization: { __typename: "Organization"; id: number; name: string };
  compStructure: {
    __typename: "CompStructure";
    id: number;
    businessStructure: BusinessStructure | null;
  } | null;
};

export type GetEquitySettingsVariables = Exact<{ [key: string]: never }>;

export type GetEquitySettings = {
  valuation: {
    __typename: "Valuation";
    id: number;
    activeAt: GraphQL_DateTime;
    estimatedDilution: number;
    financingStage: string;
    fdso: number;
    strikePrice: GraphQL_Money;
    valuation: GraphQL_Money;
  } | null;
  organization: {
    __typename: "Organization";
    id: number;
    name: string;
    defaultCurrency: CurrencyCode;
  };
  compStructure: {
    __typename: "CompStructure";
    id: number;
    bandPointTypes: Array<string>;
    cashBandTypes: Array<string>;
    equityBandTypes: Array<string>;
    availableCashBandTypes: Array<string>;
    availableEquityBandTypes: Array<string>;
    businessStructure: BusinessStructure | null;
    companyDescription: string | null;
    equityGrantTypes: Array<EquityGrantTypes>;
    showPercentOwnership: boolean;
    showValuation: boolean;
    vestingCliff: number;
    vestingMonths: number;
    equityGrantMethod: EquityGrantMethod;
    refreshGrantValue: number | null;
  } | null;
  offerConfig: {
    __typename: "OfferConfig";
    id: number;
    exitOutcomes: Array<number>;
    xAxisAnnotations: Array<string>;
    defaultOutcomeDescription: string;
    equityFootnote: string | null;
    defaultWelcomeMessage: string | null;
    defaultClosingMessage: string | null;
    sharePriceLabel: string;
    showEquityInformation: boolean;
    showFdso: boolean;
    showStage: boolean;
    showCurrentEquityValue: boolean;
    showSharePriceFootnote: boolean;
    showAnnualizedEquity: boolean;
    requireApproval: boolean;
    brandColorPrimary: string | null;
    primaryLogoUrlPath: string | null;
    whiteLogoUrlPath: string | null;
    greyLogoUrlPath: string | null;
    backgroundUrlPath: string | null;
    equityCashInValuationCurrency: boolean;
    customFields: Array<{
      __typename: "CustomField";
      name: string;
      description: string;
      variant: CustomFieldVariant;
      options: Array<string>;
    }>;
  } | null;
};

export type GetPhilosophyVariables = Exact<{ [key: string]: never }>;

export type GetPhilosophy = {
  philosophy: {
    __typename: "Philosophy";
    id: number;
    json: GraphQL_Json;
    isActive: boolean;
    createdAt: GraphQL_DateTime;
    author: {
      __typename: "User";
      id: number;
      name: string | null;
      email: string;
    } | null;
  } | null;
};

export type GetCurrenciesVariables = Exact<{ [key: string]: never }>;

export type GetCurrencies = {
  currencies: Array<{
    __typename: "Currency";
    id: number;
    code: CurrencyCode;
    exchangeRate: number;
    isDefault: boolean;
  }>;
};

export type GetCurrenciesSettingsVariables = Exact<{ [key: string]: never }>;

export type GetCurrenciesSettings = {
  currencies: Array<{
    __typename: "Currency";
    id: number;
    code: CurrencyCode;
    exchangeRate: number;
    isDefault: boolean;
  }>;
  organization: { __typename: "Organization"; id: number; name: string };
};

export type GetMergeConfigsVariables = Exact<{ [key: string]: never }>;

export type GetMergeConfigs = {
  mergeConnectionConfigs: Array<{
    __typename: "MergeConnectionConfig";
    id: number;
    integrationName: string;
    integrationType: string;
    imageUrl: string | null;
    lastSyncAt: GraphQL_DateTime | null;
    originId: string | null;
    isManualUpload: boolean;
    author: {
      __typename: "User";
      id: number;
      name: string | null;
      photoURL: string | null;
    };
  }>;
};

export type GetNeedsMappingCountsVariables = Exact<{ [key: string]: never }>;

export type GetNeedsMappingCounts = {
  countEmployeesNeedingLeveling: number;
  countLocationsNeedingMapping: number;
};

export type GetEmployeeAnalyticsVariables = Exact<{
  groupByFilterOpts: GroupByFilterOptions;
  allTimeSeriesOpts: TimeSeriesOptions;
  lastYearTimeSeriesOpts: TimeSeriesOptions;
}>;

export type GetEmployeeAnalytics = {
  employeeAnalytics: {
    __typename: "EmployeeAnalytics";
    count: Array<{
      __typename: "LabelValueTuple";
      label: string;
      value: number;
    }>;
    allTimeTimeSeries: Array<{
      __typename: "TimeSeriesResponse";
      label: string;
      values: Array<{
        __typename: "DateValueTuple";
        label: GraphQL_Date;
        value: number;
      }>;
    }>;
    lastYearTimeSeries: Array<{
      __typename: "TimeSeriesResponse";
      label: string;
      values: Array<{
        __typename: "DateValueTuple";
        label: GraphQL_Date;
        value: number;
      }>;
    }>;
  };
};

export type GetEmployeeAnalyticsQuartilesVariables = Exact<{
  groupByFilterOpts: GroupByFilterOptions;
}>;

export type GetEmployeeAnalyticsQuartiles = {
  employeeAnalytics: {
    __typename: "EmployeeAnalytics";
    quartiles: Array<{
      __typename: "QuartileResponse";
      label: string;
      values: Array<number>;
      sampleSize: number;
    }>;
  };
};

export type GetBenefitsPackagesVariables = Exact<{ [key: string]: never }>;

export type GetBenefitsPackages = {
  benefitsPackages: Array<{
    __typename: "BenefitsPackage";
    id: number;
    name: string;
    currencyCode: CurrencyCode;
    totalValue: GraphQL_Money;
    benefits: Array<{
      __typename: "Benefit";
      id: number;
      benefitsPackageId: number;
      name: string;
      value: GraphQL_Money | null;
      description: string;
      rank: string;
    }>;
  }>;
};

export type GetPermissionSettingsVariables = Exact<{ [key: string]: never }>;

export type GetPermissionSettings = {
  organization: {
    __typename: "Organization";
    id: number;
    permissionSettings: {
      __typename: "PermissionSettings";
      id: number;
      canRecruitersViewAnonymizedStatistics: boolean;
      teamCompensationAccess: TeamCompensationAccessType;
      anonymizedCompensationStatisticsAccess: AnonymizedCompensationStatisticsAccessType;
      anonymizedCompensationEmployeeThreshold: number;
      automatedManagerAccess: AutomatedManagerAccessType;
      employeeBandAccess: EmployeeBandAccessType;
    };
  };
};

export type GetCompRecommendationsCsvExportVariables = Exact<{
  compCycleId: Scalars["Int"]["input"];
}>;

export type GetCompRecommendationsCsvExport = {
  exportCompRecommendationsToCsv: {
    __typename: "CompRecommendationCsvExport";
    data: string | null;
  };
};

export type GetEmployeeRecommendationsCsvExportVariables = Exact<{
  compCycleId: Scalars["Int"]["input"];
  employeeIds: Array<Scalars["Int"]["input"]> | Scalars["Int"]["input"];
}>;

export type GetEmployeeRecommendationsCsvExport = {
  exportEmployeeRecommendationsToCsv: {
    __typename: "CompRecommendationCsvExport";
    data: string | null;
  };
};

export type GetActiveCompCyclesVariables = Exact<{ [key: string]: never }>;

export type GetActiveCompCycles = {
  activeCompCycles: Array<{
    __typename: "CompCycle";
    id: number;
    endedAt: GraphQL_DateTime | null;
  }>;
};

export type GetCompCyclesVariables = Exact<{ [key: string]: never }>;

export type GetCompCycles = {
  compCycles: Array<{
    __typename: "CompCycle";
    id: number;
    name: string;
    endedAt: GraphQL_DateTime | null;
    deletedAt: GraphQL_DateTime | null;
    createdAt: GraphQL_DateTime;
  }>;
};

export type GetCompCycleVariables = Exact<{
  id: Scalars["Int"]["input"];
}>;

export type GetCompCycle = {
  compCycle: {
    __typename: "CompCycle";
    id: number;
    name: string;
    endedAt: GraphQL_DateTime | null;
  } | null;
};
