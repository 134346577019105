import { gql } from "@apollo/client";
import {
  CashCompType,
  CompComponent,
  RecItemInput,
  RecItemType,
  TargetVariablePayRows_compCycle,
  TargetVariablePayRows_employee,
  TargetVariablePayRows_position,
} from "../../../__generated__/graphql";
import { BudgetType } from "../../../models/Budget";
import { CompRecommendation } from "../../../models/CompRecommendation";
import { CompCycleLineItemRowButton } from "./CompCycleLineItemRowButton";
import { HeaderSeparatorRow } from "./LineItemRows/HeaderSeparator";
import { TargetIncreaseRow } from "./LineItemRows/TargetIncrease";

type Props = {
  show: boolean;
  employee: TargetVariablePayRows_employee;
  promotedPosition: TargetVariablePayRows_position | null;
  compCycle: TargetVariablePayRows_compCycle;
  submittedRecommendation: CompRecommendation | null;
  revisedRecommendation: CompRecommendation | null;
  onChangeRecommendationItem: (
    newRecommendationItem: RecItemInput | null
  ) => unknown;
};

export function TargetVariablePayRows({
  show,
  employee,
  promotedPosition,
  compCycle,
  submittedRecommendation,
  revisedRecommendation,
  onChangeRecommendationItem,
}: Props): JSX.Element {
  return (
    <>
      <HeaderSeparatorRow
        text={`New ${BudgetType.TARGET_VAR_PAY}`}
        filtered={!show}
        first
      />
      {show && compCycle.allowTargetCommission && (
        <TargetIncreaseRow
          employee={employee}
          promotedPosition={promotedPosition}
          component={CompComponent.TARGET_COMMISSION}
          label={BudgetType.TARGET_COMMISSION}
          revisedRecommendation={revisedRecommendation}
          submittedRecommendation={submittedRecommendation}
          recommendationType={RecItemType.TARGET_COMMISSION}
          cashCompType={CashCompType.COMMISSION}
          onChangeRecommendationItem={onChangeRecommendationItem}
        />
      )}
      {show && compCycle.allowTargetRecurringBonus && (
        <TargetIncreaseRow
          employee={employee}
          promotedPosition={promotedPosition}
          component={CompComponent.TARGET_RECURRING_BONUS}
          label={BudgetType.TARGET_RECURRING_BONUS}
          revisedRecommendation={revisedRecommendation}
          submittedRecommendation={submittedRecommendation}
          recommendationType={RecItemType.TARGET_RECURRING_BONUS}
          cashCompType={CashCompType.RECURRING_BONUS}
          onChangeRecommendationItem={onChangeRecommendationItem}
        />
      )}
    </>
  );
}

TargetVariablePayRows.fragments = {
  employee: gql`
    ${CompCycleLineItemRowButton.fragments.employee}
    ${TargetIncreaseRow.fragments.employee}
    fragment TargetVariablePayRows_employee on Employee2 {
      ...CompCycleLineItemRowButton_employee
      ...TargetIncreaseRow_employee
      id
      activeEmployment {
        id
        salary
      }
    }
  `,
  position: gql`
    ${TargetIncreaseRow.fragments.position}
    fragment TargetVariablePayRows_position on Position {
      ...TargetIncreaseRow_position
    }
  `,
  compCycle: gql`
    fragment TargetVariablePayRows_compCycle on CompCycle2 {
      id
      allowTargetCommission
      allowTargetRecurringBonus
    }
  `,
};
