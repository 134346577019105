import { gql } from "@apollo/client";
import { CurrencyCode } from "@asmbl/shared/constants";
import { Currency, exchangeFromTo } from "@asmbl/shared/currency";
import { FeatureFlag } from "@asmbl/shared/feature-flags";
import { contramap } from "@asmbl/shared/sort";
import { useCompStructure } from "src/components/CompStructureContext";
import { useFeatureFlags } from "src/components/FeatureContext";
import { getSalaryCashComp } from "src/models/CashCompensation";
import {
  CompUnit,
  CondensedTableSalaryMeritCell2_participant as Participant,
  RecItemType,
} from "../../../../__generated__/graphql";
import { AssembleTruncatedTypography } from "../../../../components/AssembleTruncatedTypography";
import { useCurrencies } from "../../../../components/CurrenciesContext";
import { getMeritSalaryIncrease } from "../../../../models/CompRecommendation";
import { getSimpleCashLabel } from "../../../../models/Currency";
import { ColumnComponent2 } from "../CondensedTableInner2";
import { ColumnIds, ColumnIdsToHeaders } from "../Contexts/ColumnOrderContext";
import { LARGE_COL_WIDTH } from "./dimensions";

type Props = {
  row: { original: Participant };
};

export function CondensedTableSalaryMeritCell2({
  row: { original: employee },
}: Props): JSX.Element {
  const { compStructure } = useCompStructure();
  const { isEnabled } = useFeatureFlags();
  const showHourly =
    (isEnabled(FeatureFlag.HourlyEmployeesInCompCycles) &&
      compStructure?.allowHourlyEmployees) === true;
  const { defaultCurrencyCode } = useCurrencies();
  const recItems = employee.compRecommendation?.latestSubmittedItems ?? [];

  const meritItem = recItems.find(
    (item) => item.recommendationType === RecItemType.MERIT_INCREASE
  );

  const currPay = getSalaryCashComp(employee.subject.activeCashCompensation);
  const currency = currPay
    ? currPay.annualCashEquivalent.currency
    : defaultCurrencyCode;
  const isHourly = showHourly && meritItem?.unitType === CompUnit.HOURLY_CASH;
  return (
    <AssembleTruncatedTypography align="right">
      {getSimpleCashLabel(
        getMeritSalaryIncrease(recItems, currency),
        false,
        isHourly
      )}
    </AssembleTruncatedTypography>
  );
}

CondensedTableSalaryMeritCell2.fragments = {
  participant: gql`
    fragment CondensedTableSalaryMeritCell2_participant on CompCycleParticipant {
      subject {
        id
        activeCashCompensation(currencyCode: $currencyCode) {
          type
          employeeId
          unit
          annualCashEquivalent
          hourlyCashEquivalent
        }
      }
      compRecommendation(skipEligibility: $skipEligibility) {
        subjectId
        compCycleId
        latestSubmittedItems {
          id
          recommendationType
          recommendedCashValue(currencyCode: $currencyCode)
          unitType
        }
      }
    }
  `,
};

CondensedTableSalaryMeritCell2.Header = ColumnIdsToHeaders.get(
  ColumnIds.SALARY_MERIT
);
CondensedTableSalaryMeritCell2.id = ColumnIds.SALARY_MERIT;

const column: ColumnComponent2["column"] = {
  Cell: CondensedTableSalaryMeritCell2,
  Header: CondensedTableSalaryMeritCell2.Header,
  id: CondensedTableSalaryMeritCell2.id,
  width: LARGE_COL_WIDTH,
};

CondensedTableSalaryMeritCell2.column = column;
CondensedTableSalaryMeritCell2.ordering = ({
  defaultCurrencyCode,
  currencies,
}: {
  defaultCurrencyCode: CurrencyCode;
  currencies: Map<CurrencyCode, Currency>;
}) =>
  contramap((e: Participant) => {
    const meritSalaryIncrease = getMeritSalaryIncrease(
      e.compRecommendation?.latestSubmittedItems ?? [],
      defaultCurrencyCode
    );

    const currentCurrency = currencies.get(meritSalaryIncrease.currency);
    const defaultCurrency = currencies.get(defaultCurrencyCode);

    if (currentCurrency == null || defaultCurrency == null) {
      return 0;
    }

    return exchangeFromTo(meritSalaryIncrease, currentCurrency, defaultCurrency)
      .value;
  });
