import { gql } from "@apollo/client";
import { EquityBandName } from "@asmbl/shared/constants";
import { FeatureFlag } from "@asmbl/shared/feature-flags";
import { useFeatureFlags } from "src/components/FeatureContext";
import {
  CondensedTable2_compCycleBudget as Budget,
  CondensedTable2_compCycle as CompCycle,
  EquityGrantMethod,
  CondensedTable_meritAdjustmentGuide as MeritAdjustmentGuide,
  CondensedTable_meritAdjustmentRange as MeritAdjustmentRange,
  CondensedTable2_participant as Participant,
  CondensedTable_perfRatingOption as PerfRatingOption,
  CondensedTable2_position as Position,
  CondensedTable2_user as User,
  CondensedTable2_valuation as Valuation,
} from "../../../__generated__/graphql";
import { CycleBudgetAppBar2 } from "../../../components/CompCycle/CycleBudgetAppBar2";
import { useCompStructure } from "../../../components/CompStructureContext";
import { NoTableData } from "../../../components/EmptyState/EmptyState";
import { CondensedTableActionsCell2 as ActionsCell } from "./Cells/CondensedTableActionsCell2";
import { CondensedTableActivityCell2 as ActivityCell } from "./Cells/CondensedTableActivityCell2";
import { CondensedTableActualRecurringBonusCell2 as ActualRecurringBonusCell } from "./Cells/CondensedTableActualRecurringBonusCell2";
import { CondensedTableActualRecurringBonusPercentCell2 as ActualRecurringBonusPercentCell } from "./Cells/CondensedTableActualRecurringBonusPercentCell2";
import { CondensedTableBonusCell2 as BonusCell } from "./Cells/CondensedTableBonusCell2";
import { CondensedTableBonusPercentCell2 as BonusPercentCell } from "./Cells/CondensedTableBonusPercentCell2";
import { CondensedTableCurrentDepartmentCell2 as CurrentDepartmentCell } from "./Cells/CondensedTableCurrentDepartmentCell2";
import { CondensedTableCurrentEquityUnitsCell2 as CurrentEquityUnitsCell } from "./Cells/CondensedTableCurrentEquityUnitsCell2";
import { CondensedTableCurrentEquityValueCell2 as CurrentEquityValueCell } from "./Cells/CondensedTableCurrentEquityValueCell2";
import { CondensedTableCurrentLadderCell2 as CurrentLadderCell } from "./Cells/CondensedTableCurrentLadderCell2";
import { CondensedTableCurrentLevelCell2 as CurrentLevelCell } from "./Cells/CondensedTableCurrentLevelCell2";
import { CondensedTableCurrentPositionCell2 as CurrentPositionCell } from "./Cells/CondensedTableCurrentPositionCell2";
import { CondensedTableCurrentSalaryCompaRatioCell2 as CurrentSalaryCompaRatioCell } from "./Cells/CondensedTableCurrentSalaryCompaRatioCell2";
import { CondensedTableCurrentTCCCompaRatioCell as CurrentTCCCompaRatioCell } from "./Cells/CondensedTableCurrentTCCCompaRatioCell";
import { CondensedTableCurrentTargetCashCell2 as CurrentTargetCashCell } from "./Cells/CondensedTableCurrentTargetCashCell2";
import { CondensedTableCurrentTargetCommissionCell2 as CurrentTargetCommissionCell } from "./Cells/CondensedTableCurrentTargetCommissionCell2";
import { CondensedTableCurrentTargetCommissionPercentCell2 as CurrentTargetCommissionPercentCell } from "./Cells/CondensedTableCurrentTargetCommissionPercentCell2";
import { CondensedTableCurrentTargetRecurringBonusCell2 as CurrentTargetRecurringBonusCell } from "./Cells/CondensedTableCurrentTargetRecurringBonusCell2";
import { CondensedTableCurrentTargetRecurringBonusPercentCell2 as CurrentTargetRecurringBonusPercentCell } from "./Cells/CondensedTableCurrentTargetRecurringBonusPercentCell2";
import { CondensedTableEquityBandCell2 as EquityBandCell } from "./Cells/CondensedTableEquityBandCell2";
import { CondensedTableEquityUnitsCell2 as EquityUnitsCell } from "./Cells/CondensedTableEquityUnitsCell2";
import { CondensedTableEquityValueCell2 as EquityValueCell } from "./Cells/CondensedTableEquityValueCell2";
import { CondensedTableLastSalaryChangeAmountCell2 as LastSalaryChangeAmountCell } from "./Cells/CondensedTableLastSalaryChangeAmountCell2";
import { CondensedTableLastSalaryChangeDateCell2 as LastSalaryChangeDateCell } from "./Cells/CondensedTableLastSalaryChangeDateCell2";
import { CondensedTableLocationGroupCell2 as LocationGroupCell } from "./Cells/CondensedTableLocationGroupCell2";
import { CondensedTableManagerCell2 as ManagerCell } from "./Cells/CondensedTableManagerCell2";
import { CondensedTableNameCell2 as NameCell } from "./Cells/CondensedTableNameCell2";
import { CondensedTableNewDepartmentCell2 as NewDepartmentCell } from "./Cells/CondensedTableNewDepartmentCell2";
import { CondensedTableNewLadderCell2 as NewLadderCell } from "./Cells/CondensedTableNewLadderCell2";
import { CondensedTableNewLevelCell2 as NewLevelCell } from "./Cells/CondensedTableNewLevelCell2";
import { CondensedTableNewPayCell as NewPayCell } from "./Cells/CondensedTableNewPayCell";
import { CondensedTableNewPositionCell2 as NewPositionCell } from "./Cells/CondensedTableNewPositionCell2";
import { CondensedTableNewPositionIfChangedCell2 as NewPositionIfChangedCell } from "./Cells/CondensedTableNewPositionIfChangedCell2";
import { CondensedTableNewSalaryBandCell2 as NewSalaryBandCell } from "./Cells/CondensedTableNewSalaryBandCell2";
import { CondensedTableNewSalaryCompaRatioCell2 as NewSalaryCompaRatioCell } from "./Cells/CondensedTableNewSalaryCompaRatioCell2";
import { CondensedTableNewTCCCompaRatioCell as NewTCCCompaRatioCell } from "./Cells/CondensedTableNewTCCCompaRatioCell";
import { CondensedTableNewTargetCashCell2 as NewTargetCashCell } from "./Cells/CondensedTableNewTargetCashCell2";
import { CondensedTableNewTargetCommissionCell2 as NewTargetCommissionCell } from "./Cells/CondensedTableNewTargetCommissionCell2";
import { CondensedTableNewTargetCommissionPercentCell2 as NewTargetCommissionPercentCell } from "./Cells/CondensedTableNewTargetCommissionPercentCell2";
import { CondensedTableNewTargetRecurringBonusCell2 as NewTargetRecurringBonusCell } from "./Cells/CondensedTableNewTargetRecurringBonusCell2";
import { CondensedTableNewTargetRecurringBonusPercentCell2 as NewTargetRecurringBonusPercentCell } from "./Cells/CondensedTableNewTargetRecurringBonusPercentCell2";
import { CondensedTablePerfRatingCell2 as PerfRatingCell } from "./Cells/CondensedTablePerfRatingCell2";
import { CondensedTablePrevPayCell as PrevPayCell } from "./Cells/CondensedTablePrevSalaryCell2";
import { CondensedTablePromotionCell2 as PromotionCell } from "./Cells/CondensedTablePromotionCell2";
import { CondensedTableSalaryBandCell2 as SalaryBandCell } from "./Cells/CondensedTableSalaryBandCell2";
import { CondensedTablePayIncreaseCell as PayIncreaseCell } from "./Cells/CondensedTableSalaryIncreaseCell2";
import { CondensedTableSalaryMarketCell2 as SalaryMarketCell } from "./Cells/CondensedTableSalaryMarketCell2";
import { CondensedTableSalaryMeritCell2 as SalaryMeritCell } from "./Cells/CondensedTableSalaryMeritCell2";
import { CondensedTableSalaryMeritDifferenceCell2 as SalaryMeritDiffCell } from "./Cells/CondensedTableSalaryMeritDifferenceCell2";
import { CondensedTableSalaryMeritGuidanceCell2 as SalaryMeritGuidanceCell } from "./Cells/CondensedTableSalaryMeritGuidanceCell2";
import { CondensedTableSalaryMeritPercentCell2 as SalaryMeritPercentCell } from "./Cells/CondensedTableSalaryMeritPercentCell2";
import { CondensedTableSalaryPercentChangeCell2 as SalaryPercentChangeCell } from "./Cells/CondensedTableSalaryPercentChangeCell2";
import { CondensedTableSalaryPromoCell2 as SalaryPromoCell } from "./Cells/CondensedTableSalaryPromoCell2";
import { CondensedTableStatusCell2 as StatusCell } from "./Cells/CondensedTableStatusCell2";
import { CondensedTableStatusCellTooltip2 as StatusCellTooltip } from "./Cells/CondensedTableStatusCellTooltip2";
import { CondensedTableTenureCell2 as TenureCell } from "./Cells/CondensedTableTenureCell2";
import { CurrencyCodeWithAll } from "./CondensedTableCurrencyPicker";
import { CondensedTableInner2 } from "./CondensedTableInner2";
import { TableDataContextProvider } from "./Contexts/TableDataContext2";

type Props = {
  selectedCurrency: CurrencyCodeWithAll;
  employees: Participant[];
  availablePositions: Position[];
  isActiveCycle: boolean;
  budget: Budget | null;
  valuation: Valuation;
  user: User;
  compCycle: CompCycle;
  isAdmin: boolean;
  meritGuidance: {
    meritAdjustmentGuides: MeritAdjustmentGuide[];
    meritAdjustmentRanges: MeritAdjustmentRange[];
    perfRatingOptions: PerfRatingOption[];
  };
};

export function CondensedTable2({
  selectedCurrency,
  employees,
  availablePositions,
  isActiveCycle,
  budget,
  valuation,
  user,
  compCycle,
  isAdmin,
  meritGuidance,
}: Props): JSX.Element {
  const { compStructure } = useCompStructure();
  const { isEnabled } = useFeatureFlags();

  const hideStatus = !isAdmin && compCycle.phases.length > 0;

  const hideLastCompChange = isEnabled(FeatureFlag.HideLastCompChange);
  if (employees.length === 0) return <NoTableData />;
  const {
    allowSalary: salary,
    allowSalaryPromotion: promotion,
    allowSalaryMarket: market,
    allowSalaryMerit: merit,
    allowBonus: bonus,
    allowEquity: equity,
    allowTargetCommission: targetCommission,
    allowTargetRecurringBonus: targetRecurringBonus,
    allowActualRecurringBonus: actualRecurringBonus,
  } = compCycle;

  return (
    <CondensedTableInner2
      selectedCurrency={selectedCurrency}
      columns={[
        NameCell,
        ActionsCell, // needs fields
        ...(hideStatus ? [ActivityCell] : [StatusCell]), // needs fields
        NewPositionCell,
        ...(promotion ? [PromotionCell] : []),
        NewPositionIfChangedCell,
        SalaryBandCell,
        NewSalaryBandCell,
        CurrentSalaryCompaRatioCell,
        CurrentTCCCompaRatioCell,
        NewSalaryCompaRatioCell,
        NewTCCCompaRatioCell,
        ...(salary
          ? [PrevPayCell, PayIncreaseCell, SalaryPercentChangeCell, NewPayCell]
          : []),
        ...(hideLastCompChange
          ? []
          : [LastSalaryChangeDateCell, LastSalaryChangeAmountCell]),
        CurrentDepartmentCell,
        NewDepartmentCell,
        CurrentLadderCell,
        NewLadderCell,
        CurrentPositionCell,
        CurrentLevelCell,
        NewLevelCell,
        ManagerCell,
        PerfRatingCell,
        TenureCell,
        LocationGroupCell,
        ...(salary
          ? [
              ...(market ? [SalaryMarketCell] : []),
              ...(merit
                ? [
                    SalaryMeritCell,
                    SalaryMeritPercentCell,
                    SalaryMeritGuidanceCell,
                    SalaryMeritDiffCell,
                  ]
                : []),
              ...(promotion ? [SalaryPromoCell] : []),
            ]
          : []),
        ...(salary || targetCommission || targetRecurringBonus
          ? [CurrentTargetCashCell, NewTargetCashCell]
          : []),
        ...(targetCommission
          ? [
              CurrentTargetCommissionCell,
              CurrentTargetCommissionPercentCell,
              NewTargetCommissionCell,
              NewTargetCommissionPercentCell,
            ]
          : []),
        ...(targetRecurringBonus
          ? [
              CurrentTargetRecurringBonusCell,
              CurrentTargetRecurringBonusPercentCell,
              NewTargetRecurringBonusCell,
              NewTargetRecurringBonusPercentCell,
            ]
          : []),
        ...(actualRecurringBonus ? [ActualRecurringBonusCell] : []),
        ...(bonus ? [BonusCell, BonusPercentCell] : []),
        ...(equity
          ? [
              CurrentEquityValueCell,
              EquityValueCell,
              ...(compStructure?.equityBandTypes.includes(
                EquityBandName.EQUITY_REFRESH_GRANT
              )
                ? [EquityBandCell]
                : []),
            ]
          : []),
        ...(equity &&
        compStructure?.equityGrantMethod !== EquityGrantMethod.CASH
          ? [CurrentEquityUnitsCell, EquityUnitsCell]
          : []),
      ]}
      employees={employees}
      availablePositions={availablePositions}
      isActiveCycle={isActiveCycle}
      compCycle={compCycle}
      budget={budget}
      valuation={valuation}
      user={user}
      meritGuidance={meritGuidance}
    />
  );
}

CondensedTable2.fragments = {
  participant: gql`
    ${NameCell.fragments.participant}
    ${CurrentDepartmentCell.fragments.participant}
    ${NewDepartmentCell.fragments.participant}
    ${CurrentLadderCell.fragments.participant}
    ${NewLadderCell.fragments.participant}
    ${CurrentPositionCell.fragments.participant}
    ${NewPositionCell.fragments.participant}
    ${NewPositionIfChangedCell.fragments.participant}
    ${CurrentLevelCell.fragments.participant}
    ${NewLevelCell.fragments.participant}
    ${ManagerCell.fragments.participant}
    ${PerfRatingCell.fragments.participant}
    ${TenureCell.fragments.participant}
    ${LocationGroupCell.fragments.participant}
    ${PromotionCell.fragments.participant}
    ${SalaryBandCell.fragments.participant}
    ${NewSalaryBandCell.fragments.participant}
    ${CurrentSalaryCompaRatioCell.fragments.participant}
    ${CurrentTCCCompaRatioCell.fragments.participant}
    ${NewSalaryCompaRatioCell.fragments.participant}
    ${NewTCCCompaRatioCell.fragments.participant}
    ${PrevPayCell.fragments.participant}
    ${SalaryPercentChangeCell.fragments.participant}
    ${PayIncreaseCell.fragments.participant}
    ${SalaryPromoCell.fragments.participant}
    ${SalaryMarketCell.fragments.participant}
    ${SalaryMeritCell.fragments.participant}
    ${NewPayCell.fragments.participant}
    ${SalaryMeritPercentCell.fragments.participant}
    ${SalaryMeritGuidanceCell.fragments.participant}
    ${ActionsCell.fragments.participant}
    # ^ needs fields
    ${ActivityCell.fragments.participant}
    # ^ needs fields
    ${ActualRecurringBonusCell.fragments.participant}
    ${BonusCell.fragments.participant}
    ${BonusPercentCell.fragments.participant}
    ${LastSalaryChangeDateCell.fragments.participant}
    ${LastSalaryChangeAmountCell.fragments.participant}
    ${CurrentEquityValueCell.fragments.participant}
    ${CurrentEquityUnitsCell.fragments.participant}
    ${EquityValueCell.fragments.participant}
    ${EquityUnitsCell.fragments.participant}
    ${EquityBandCell.fragments.participant}
    ${CurrentTargetCashCell.fragments.participant}
    ${NewTargetCashCell.fragments.participant}
    ${ActualRecurringBonusPercentCell.fragments.participant}
    ${CurrentTargetCommissionCell.fragments.participant}
    ${NewTargetCommissionCell.fragments.participant}
    ${CurrentTargetRecurringBonusCell.fragments.participant}
    ${NewTargetRecurringBonusCell.fragments.participant}
    ${CurrentTargetCommissionPercentCell.fragments.participant}
    ${NewTargetCommissionPercentCell.fragments.participant}
    ${CurrentTargetRecurringBonusPercentCell.fragments.participant}
    ${NewTargetRecurringBonusPercentCell.fragments.participant}
    ${StatusCell.fragments.participant}
    # ^ needs fields

    fragment CondensedTable2_participant on CompCycleParticipant {
      subjectId
      ...CondensedTableNameCell2_participant
      ...CondensedTableCurrentDepartmentCell2_participant
      ...CondensedTableNewDepartmentCell2_participant
      ...CondensedTableCurrentLadderCell2_participant
      ...CondensedTableNewLadderCell2_participant
      ...CondensedTableCurrentPositionCell2_participant
      ...CondensedTableNewPositionCell2_participant
      ...CondensedTableNewPositionIfChangedCell2_participant
      ...CondensedTableCurrentLevelCell2_participant
      ...CondensedTableNewLevelCell2_participant
      ...CondensedTableManagerCell2_participant
      ...CondensedTablePerfRatingCell2_participant
      ...CondensedTableTenureCell2_participant
      ...CondensedTableLocationGroupCell2_participant
      ...CondensedTablePromotionCell2_participant
      ...CondensedTableSalaryBandCell2_participant
      ...CondensedTableEquityBandCell2_compCycleParticipant
      ...CondensedTableNewSalaryBandCell2_participant
      ...CondensedTableCurrentSalaryCompaRatioCell2_participant
      ...CondensedTableCurrentTCCCompaRatioCell_participant
      ...CondensedTableNewSalaryCompaRatioCell2_participant
      ...CondensedTableNewTCCCompaRatioCell_participant
      ...CondensedTablePrevSalaryCell2_participant
      ...CondensedTableSalaryPercentChangeCell2_participant
      ...CondensedTableSalaryIncreaseCell2_participant
      ...CondensedTableSalaryPromoCell2_participant
      ...CondensedTableSalaryMarketCell2_participant
      ...CondensedTableSalaryMeritCell2_participant
      ...CondensedTableSalaryMeritGuidanceCell2_participant
      ...CondensedTableSalaryMeritPercentCell2_participant
      ...CondensedTableNewSalaryCell2_participant
      ...CondensedTableCurrentTargetCashCell2_participant
      ...CondensedTableNewTargetCashCell2_participant
      ...CondensedTableActualRecurringBonusCell2_participant
      ...CondensedTableActualRecurringBonusPercentCell2_participant
      ...CondensedTableCurrentTargetCommissionCell2_participant
      ...CondensedTableCurrentTargetCommissionPercentCell2_participant
      ...CondensedTableNewTargetCommissionCell2_participant
      ...CondensedTableNewTargetCommissionPercentCell2_participant
      ...CondensedTableCurrentTargetRecurringBonusCell2_participant
      ...CondensedTableCurrentTargetRecurringBonusPercentCell2_participant
      ...CondensedTableNewTargetRecurringBonusCell2_participant
      ...CondensedTableNewTargetRecurringBonusPercentCell2_participant
      ...CondensedTableBonusCell2_participant
      ...CondensedTableBonusPercentCell2_participant
      ...CondensedTableLastSalaryChangeDateCell2_participant
      ...CondensedTableLastSalaryChangeAmountCell2_participant
      ...CondensedTableCurrentEquityValueCell2_participant
      ...CondensedTableCurrentEquityUnitsCell2_participant
      ...CondensedTableEquityValueCell2_participant
      ...CondensedTableEquityUnitsCell2_participant
      ...CondensedTableActionsCell2_participant
      ...CondensedTableActivityCell2_participant
      ...CondensedTableStatusCell2_participant @include(if: $isAdmin)
      compRecommendation(skipEligibility: $skipEligibility) {
        subjectId
        compCycleId
        reviewStatus
      }
    }
  `,

  position: gql`
    ${TableDataContextProvider.fragments().position}
    fragment CondensedTable2_position on Position {
      id
      ...TableDataContext2_position
    }
  `,
  valuation: gql`
    ${CycleBudgetAppBar2.fragments.valuation}
    fragment CondensedTable2_valuation on Valuation {
      id
      ...CycleBudgetAppBar2_valuation
    }
  `,
  compCycleBudget: gql`
    ${CycleBudgetAppBar2.fragments.compCycleBudget}
    fragment CondensedTable2_compCycleBudget on CompCycleBudget {
      ...CycleBudgetAppBar2_compCycleBudget
    }
  `,
  user: gql`
    ${TableDataContextProvider.fragments().user}
    fragment CondensedTable2_user on User {
      id
      ...TableDataContext_user
    }
  `,
  compCycle: gql`
    ${CycleBudgetAppBar2.fragments.compCycle}
    ${StatusCell.fragments.compCycle}
    ${StatusCellTooltip.fragments.compCycle2}
    ${ActivityCell.fragments.compCycle2}
    fragment CondensedTable2_compCycle on CompCycle2 {
      id
      finalApproversIds
      allowSalary
      allowSalaryMarket
      allowSalaryMerit
      allowSalaryPromotion
      allowBonus
      allowEquity
      allowActualRecurringBonus
      allowTargetCommission
      allowTargetRecurringBonus
      recommendationsPreFill
      phases {
        id
        phaseOrder
        startDate
      }
      ...CycleBudgetAppBar2_compCycle
      ...CondensedTableStatusCell2_compCycle @include(if: $isAdmin)
      ...CondensedTableStatusCellTooltip2_compCycle2
      ...CondensedTableActivityCell2_compCycle2
    }
  `,
};
