import { gql, useQuery } from "@apollo/client";
import { useAuth } from "src/components/Auth/AuthContext";
import {
  CondensedTableLoadingBoundary as CondensedTableLoadingBoundaryResponse,
  CondensedTableLoadingBoundaryVariables,
  Noun,
} from "../../../__generated__/graphql";
import { ErrorView } from "../../../components/ErrorView";
import { LoadingSpinner } from "../../../components/LoadingSpinner";
import { CondensedTableStatusCell2 as StatusCell } from "./Cells/CondensedTableStatusCell2";
import { CondensedTableView } from "./CondensedTableView";

type Props = {
  compCycleId: number;
};

export function CondensedTableLoadingBoundary({
  compCycleId,
}: Props): JSX.Element {
  const { permissions } = useAuth();
  const hasGlobalCycleAccess = permissions.canViewGlobal(Noun.CompCycle);

  const { data, loading } = useQuery<
    CondensedTableLoadingBoundaryResponse,
    CondensedTableLoadingBoundaryVariables
  >(CondensedTableLoadingBoundary.query, {
    variables: { compCycleId, isAdmin: hasGlobalCycleAccess },
  });

  if (!data || data.compCycle2 === null) {
    return loading ? (
      <LoadingSpinner />
    ) : (
      <ErrorView text="Unable to load comp cycle data." />
    );
  }

  return (
    <CondensedTableView
      compCycle={data.compCycle2}
      isAdmin={hasGlobalCycleAccess}
    />
  );
}

// the status cell fragment needs to be aliased here
// in order to pass down isAdmin to the fragment
CondensedTableLoadingBoundary.query = gql`
  ${CondensedTableView.fragments.compCycle}
  ${StatusCell.fragments.compCycle}
  query CondensedTableLoadingBoundary($compCycleId: Int!, $isAdmin: Boolean!) {
    compCycle2(id: $compCycleId) {
      id
      ...CondensedTableView_compCycle
      ...CondensedTableStatusCell2_compCycle @include(if: $isAdmin)
    }
  }
`;
