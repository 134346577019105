import { gql } from "@apollo/client";
import { Currency } from "@asmbl/shared/currency";
import { contramap } from "@asmbl/shared/sort";
import { getSalaryBand } from "src/models/Band";
import {
  CurrencyCode,
  CondensedTableNewSalaryBandCell2_participant as Participant,
  PositionType,
} from "../../../../__generated__/graphql";
import { CondensedBandVisualization } from "../../../../components/CompSlider/CondensedBandVisualization";
import { NullCompSlider } from "../../../../components/CompSlider/NullCompSlider";
import { useCurrencies } from "../../../../components/CurrenciesContext";
import { isBandPointDefined } from "../../../../models/BandPoint";
import { getNewPay } from "../../../../models/Employee";
import { getSalaryBandPlacement } from "../../../../models/Employment";
import { ColumnComponent2 } from "../CondensedTableInner2";
import { ColumnIds, ColumnIdsToHeaders } from "../Contexts/ColumnOrderContext";
import { BAND_COL_WIDTH } from "./dimensions";

type Props = {
  row: { original: Participant };
};
export function CondensedTableNewSalaryBandCell2({
  row: { original: employee },
}: Props): JSX.Element {
  const { currencies, defaultCurrency } = useCurrencies();
  const promoItemBand = employee.compRecommendation?.latestSubmittedItems.find(
    (item) => item.adjustedCashBands !== null
  );

  const adjustedSalaryBand =
    getSalaryBand(promoItemBand?.adjustedCashBands) ??
    getSalaryBand(employee.subject.adjustedCashBands);

  const newSalary = getNewPay(
    {
      activeCashCompensation: employee.subject.activeCashCompensation,
      compRecommendation: employee.compRecommendation,
    },
    currencies,
    defaultCurrency
  );
  const newPosition = getNewPosition(employee);

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      {newSalary &&
      adjustedSalaryBand &&
      adjustedSalaryBand.bandPoints.some(isBandPointDefined) ? (
        <CondensedBandVisualization
          bandPoints={adjustedSalaryBand.bandPoints}
          value={
            newPosition?.type === PositionType.HOURLY
              ? newSalary.hourlyCashEquivalent
              : newSalary.annualCashEquivalent
          }
          position={newPosition}
          outOfRangeStyle="circle"
          showHourly={newPosition?.type === PositionType.HOURLY}
        />
      ) : (
        <NullCompSlider variant="condensed" />
      )}
    </div>
  );
}

function getNewPosition(employee: Participant) {
  const recItems = employee.compRecommendation?.latestSubmittedItems ?? [];

  const newPosition = recItems.find(
    (item) => item.recommendedPosition != null
  )?.recommendedPosition;

  return newPosition ?? employee.subject.activeEmployment?.position ?? null;
}

CondensedTableNewSalaryBandCell2.ordering = ({
  currencies,
  defaultCurrency,
}: {
  currencies: Map<CurrencyCode, Currency>;
  defaultCurrency: Currency;
}) =>
  contramap((employee: Participant) => {
    const newSalary = getNewPay(
      {
        activeCashCompensation: employee.subject.activeCashCompensation,
        compRecommendation: employee.compRecommendation,
      },
      currencies,
      defaultCurrency
    );
    const promoItem = employee.compRecommendation?.latestSubmittedItems.find(
      (item) => item.adjustedCashBands !== null
    );
    const cashBands =
      promoItem?.adjustedCashBands != null
        ? promoItem.adjustedCashBands
        : employee.subject.adjustedCashBands;
    return (
      getSalaryBandPlacement(
        employee.subject.activeCashCompensation,
        cashBands,
        currencies,
        defaultCurrency,
        newSalary?.annualCashEquivalent
      ) ?? 0
    );
  });

const positionFragment = gql`
  fragment CondensedTableNewSalaryBandCell2_position on Position {
    id
    name
    type
    level
    ladder {
      id
      name
      department {
        id
        name
      }
    }
  }
`;

CondensedTableNewSalaryBandCell2.fragments = {
  participant: gql`
    ${positionFragment}
    fragment CondensedTableNewSalaryBandCell2_participant on CompCycleParticipant {
      subject {
        adjustedCashBands(currencyCode: $currencyCode) {
          id
          name
          bandPoints {
            name
            value {
              ... on CashValue {
                annualRate
                hourlyRate
                currencyCode
              }
            }
          }
        }
        activeCashCompensation(currencyCode: $currencyCode) {
          employeeId
          type
          activeAt
          annualCashEquivalent
          hourlyCashEquivalent
          unit
        }
        activeEmployment {
          id
          salary(currencyCode: $currencyCode)
          position {
            ...CondensedTableNewSalaryBandCell2_position
          }
        }
      }
      compRecommendation(skipEligibility: $skipEligibility) {
        subjectId
        compCycleId
        latestSubmittedPayIncrease(currencyCode: $currencyCode) {
          annualCashEquivalent
          hourlyCashEquivalent
          unitType
        }
        latestSubmittedItems {
          id
          recommendationType
          recommendedCashValue(currencyCode: $currencyCode)
          recommendedPosition {
            ...CondensedTableNewSalaryBandCell2_position
          }
          adjustedCashBands(currencyCode: $currencyCode) {
            id
            name
            bandPoints {
              name
              value {
                ... on CashValue {
                  annualRate
                  hourlyRate
                  currencyCode
                }
              }
            }
          }
        }
      }
    }
  `,
};

CondensedTableNewSalaryBandCell2.Header = ColumnIdsToHeaders.get(
  ColumnIds.NEW_BAND
);
CondensedTableNewSalaryBandCell2.id = ColumnIds.NEW_BAND;

const column: ColumnComponent2["column"] = {
  Cell: CondensedTableNewSalaryBandCell2,
  Header: CondensedTableNewSalaryBandCell2.Header,
  id: CondensedTableNewSalaryBandCell2.id,
  width: BAND_COL_WIDTH,
};
CondensedTableNewSalaryBandCell2.column = column;
