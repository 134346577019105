import { gql, useMutation } from "@apollo/client";
import { dollars } from "@asmbl/shared/money";
import { useCallback } from "react";
import {
  CompRecommendationInput,
  CompRecommendationStatus,
  CompUnit,
  EmplaceCompRecommendations,
  EmplaceCompRecommendationsVariables,
  RecReviewInput,
  SubmitRecReviews,
  SubmitRecReviewsVariables,
} from "../__generated__/graphql";

/*
  EmplaceCompRecommendations is a misnomer, as this mutation actually emplaces
  the current user's items on a CompRecommendation. If there is not yet
  a CompRecommendation for the subject Employee, one will be created.

  Items on a CompRecommendation are only accessible via the CompRecommendation,
  so resolving the relevant fields updates the Apollo cache properly. We also
  load the subject Employee in case this is a newly created CompRecommendation.
 */
const EMPLACE_COMP_RECOMMENDATIONS = gql`
  mutation EmplaceCompRecommendations(
    $compCycleId: Int!
    $data: [CompRecommendationInput!]!
    $actingManagerEmployeeId: Int
    $skipEligibility: Boolean
  ) {
    emplaceCompRecommendations(
      compCycleId: $compCycleId
      actingManagerEmployeeId: $actingManagerEmployeeId
      data: $data
    ) {
      participants {
        compCycleId
        subjectId
        compRecommendation(skipEligibility: $skipEligibility) {
          subjectId
          compCycleId
          canICurrentlyReview
          canICurrentlyEdit
          reviewStatus
          allSubmittedItems {
            id
            authorId
          }
          latestSubmittedItems {
            id
            recommendationType
            note
            submittedAt
            unitType
            recommendedCashValue
            recommendedPercentValue
            recommendedEquityUnitCount
            recommendedTitle
            recommendedPosition {
              id
              name
              level
              ladder {
                id
                name
                department {
                  id
                  name
                }
              }
            }
            adjustedCashBands {
              id
              name
              bandPoints {
                name
                value {
                  ... on CashValue {
                    annualRate
                    currencyCode
                  }
                }
              }
            }
            author {
              employee {
                id
              }
            }
          }
          latestSubmittedPayIncrease {
            annualCashEquivalent
            hourlyCashEquivalent
            unitType
          }
          latestSubmittedReviews {
            id
            status
            submittedAt

            author {
              id
            }
          }
          allSubmittedReviews {
            id
            authorId
          }
        }
      }
    }
  }
`;

export function useEmplaceCompRecommendations(
  compCycleId: number,
  actingManagerEmployeeId?: number | null
): (
  compRecommendations: CompRecommendationInput[]
) => Promise<EmplaceCompRecommendations | null> {
  const [emplaceCompRecommendations] = useMutation<
    EmplaceCompRecommendations,
    EmplaceCompRecommendationsVariables
  >(EMPLACE_COMP_RECOMMENDATIONS);

  return useCallback(
    async (recs: CompRecommendationInput[]) => {
      const { data } = await emplaceCompRecommendations({
        variables: {
          compCycleId,
          data: recs,
          actingManagerEmployeeId,
          skipEligibility: true,
        },
        optimisticResponse: {
          emplaceCompRecommendations: {
            __typename: "EmplaceCompRecommendationsResponse",
            participants: recs.map((r) => {
              return {
                __typename: "CompCycleParticipant",
                compCycleId,
                subjectId: r.subjectId,
                compRecommendation: {
                  __typename: "CompRecommendation2",
                  subjectId: r.subjectId,
                  compCycleId,
                  canICurrentlyReview: false,
                  canICurrentlyEdit: true,
                  reviewStatus: CompRecommendationStatus.REVIEWED,
                  latestSubmittedPayIncrease: {
                    __typename: "CompValue",
                    annualCashEquivalent: dollars(0),
                    hourlyCashEquivalent: dollars(0),
                    unitType: CompUnit.CASH,
                  },
                  latestSubmittedReviews: [],
                  allSubmittedReviews: [],
                  allSubmittedItems: [],
                  latestSubmittedItems: r.items.map((item) => ({
                    __typename: "RecItem2",
                    id: Math.random(),
                    recommendationType: item.recommendationType,
                    createdAt: new Date(),
                    note: item.note ?? null,
                    submittedAt: new Date(),
                    author: {
                      __typename: "User2",
                      employee: {
                        __typename: "Employee2",
                        id: Math.random(),
                      },
                    },
                    unitType: item.unitType ?? null,
                    latestSubmittedPayIncrease: {
                      annualCashEquivalent: dollars(0),
                      hourlyCashEquivalent: dollars(0),
                      unitType: CompUnit.CASH,
                    },
                    adjustedCashBands: null,
                    recommendedCashValue: item.recommendedCashValue ?? null,
                    recommendedPercentValue:
                      item.recommendedPercentValue ?? null,
                    recommendedTitle: item.recommendedTitle ?? null,
                    recommendedEquityUnitCount: null,
                    recommendedPosition:
                      item.recommendedPositionId == null
                        ? null
                        : {
                            __typename: "Position",
                            id: item.recommendedPositionId,
                            name: "",
                            level: 1,
                            ladder: {
                              __typename: "Ladder",
                              name: "",
                              id: Math.random(),
                              department: {
                                __typename: "Department",
                                id: Math.random(),
                                name: "",
                              },
                            },
                          },
                  })),
                },
              };
            }),
          },
        },
      });
      return data ?? null;
    },
    [actingManagerEmployeeId, compCycleId, emplaceCompRecommendations]
  );
}

// same as the `useEmplaceCompRecommendations` function above, but this
// function passes in the `compCycleId` as an argument instead of having it
// defined at hook definition time
export function useEmplaceCompRecommendationsWithCompCycleId(): (
  compCycleId: number,
  compRecommendations: CompRecommendationInput[]
) => Promise<EmplaceCompRecommendations | null> {
  const [emplaceCompRecommendations] = useMutation<
    EmplaceCompRecommendations,
    EmplaceCompRecommendationsVariables
  >(EMPLACE_COMP_RECOMMENDATIONS);

  return useCallback(
    async (
      compCycleId: number,
      compRecommendations: CompRecommendationInput[]
    ) => {
      const { data } = await emplaceCompRecommendations({
        variables: {
          compCycleId,
          data: compRecommendations,
        },
      });
      return data ?? null;
    },
    [emplaceCompRecommendations]
  );
}

export type RecReviewsUpsertInput = {
  data: RecReviewInput[];
  compCycleId: number;
  actingManagerEmployeeId?: number | null;
};

/*
  SubmitRecReviews can also affect several other fields. 
  When you submit a review, it is  added to the 'allSubmittedReviews' list.
  Also, the computed ReviewStatus may be updated.
  In order for the comp rec cache to update properly, we must pass the $skipEligibility param
 */
const SUBMIT_REC_REVIEWS = gql`
  mutation SubmitRecReviews(
    $reviews: [RecReviewInput!]!
    $actingManagerEmployeeId: Int
    $skipEligibility: Boolean = true
  ) {
    submitRecReviews(
      actingManagerEmployeeId: $actingManagerEmployeeId
      reviews: $reviews
    ) {
      participants {
        subjectId
        compCycleId
        compRecommendation(skipEligibility: $skipEligibility) {
          subjectId
          compCycleId
          allSubmittedReviews {
            id
            submittedAt
            status
            note
            author {
              id
            }
          }
          latestSubmittedReviews {
            id
            submittedAt
            status
            note
            author {
              id
            }
          }
          canICurrentlyReview
          canICurrentlyEdit
          reviewStatus
          latestSubmittedItems {
            id
            recommendationType
            # createdAt
            note
            submittedAt
            unitType
            recommendedCashValue
            recommendedPercentValue
            recommendedEquityUnitCount
            recommendedTitle
            recommendedPosition {
              id
            }
            adjustedCashBands {
              id
              name
              bandPoints {
                name
                value {
                  ... on CashValue {
                    annualRate
                    currencyCode
                  }
                }
              }
            }
            unitType
          }
          latestSubmittedPayIncrease {
            annualCashEquivalent
            hourlyCashEquivalent
            unitType
          }
        }
      }
    }
  }
`;

export function useSubmitRecReviews(): (
  data: RecReviewsUpsertInput
) => Promise<unknown> {
  const [submitRecReviews] = useMutation<
    SubmitRecReviews,
    SubmitRecReviewsVariables
  >(SUBMIT_REC_REVIEWS);

  return useCallback(
    (pendingReviews) =>
      submitRecReviews({
        variables: {
          reviews: pendingReviews.data,
          actingManagerEmployeeId: pendingReviews.actingManagerEmployeeId,
        },
        optimisticResponse({ reviews }) {
          const reviewsArray = Array.isArray(reviews) ? reviews : [reviews];

          return {
            submitRecReviews: {
              __typename: "SubmitRecReviewsResponse",
              participants: reviewsArray.map((r) => ({
                __typename: "CompCycleParticipant",
                compCycleId: r.compCycleId,
                subjectId: r.subjectId,
                compRecommendation: {
                  __typename: "CompRecommendation2",
                  compCycleId: r.compCycleId,
                  subjectId: r.subjectId,
                  canICurrentlyReview: false,
                  canICurrentlyEdit: true,
                  reviewStatus: CompRecommendationStatus.REVIEWED,
                  latestSubmittedPayIncrease: {
                    __typename: "CompValue",
                    annualCashEquivalent: dollars(0),
                    hourlyCashEquivalent: dollars(0),
                    unitType: CompUnit.CASH,
                  },
                  allSubmittedReviews: [],
                  latestSubmittedReviews: [],
                  latestSubmittedItems: [],
                },
              })),
            },
          };
        },
      }),
    [submitRecReviews]
  );
}
