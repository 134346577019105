import { gql } from "@apollo/client";
import { makeStyles } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import {
  CondensedTableView_compCycle as CompCycle,
  GetParticipantsInput,
} from "../../../__generated__/graphql";
import { ActionBar } from "../../../components/Layout/ActionBar";
import { FilterParam, getNumericListParam } from "../../../models/FilterParams";
import { useURLSearchParams } from "../../../models/URLSearchParams";
import CompCycleFilterBar from "./CompCycleFilterBar";
import { CompCycleSubmitChangesButtonLoadingBoundary } from "./CompCycleSubmitChangesButtonLoadingBoundary";
import { CondensedTablePeopleLoadingBoundary } from "./CondensedTablePeopleLoadingBoundary";
import { ColumnOrderProvider } from "./Contexts/ColumnOrderContext";
import { PaginationProvider } from "./Contexts/PaginationContext";
import { StatusSelectProvider } from "./Contexts/StatusSelectContext";
import { fieldToFilterParam, parseFilterFromUrl } from "./filterHelpers";
import { PhaseProgressBar } from "./PhaseProgressBar";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "calc(100vh - 5rem)", // Don't scroll, let the table do it
  },
}));

type Props = {
  compCycle: CompCycle;
  isAdmin: boolean;
};

export function CondensedTableView({ compCycle, isAdmin }: Props): JSX.Element {
  const classes = useStyles();
  const navigate = useNavigate();
  const urlSearchParams = useURLSearchParams();

  const handleFilterChange = (
    field: keyof Omit<GetParticipantsInput, "displayName" | "offset" | "limit">,
    values: string[] | number[]
  ) => {
    const filterParam = fieldToFilterParam(field);
    if (filterParam === null) return;

    if (values.length === 0) {
      navigate(
        {
          search: urlSearchParams.delete(filterParam).toString(),
        },
        { replace: true }
      );
    } else {
      navigate(
        {
          search: urlSearchParams
            .set(filterParam, values.toString())
            .toString(),
        },
        { replace: true }
      );
    }
  };

  const handleFilterReset = () => {
    // Only delete filter-specific params to keep unrelated URL params intact
    navigate(
      {
        search: urlSearchParams
          .deleteMany(Object.values(FilterParam))
          .toString(),
      },
      { replace: true }
    );
  };

  const filter = parseFilterFromUrl(urlSearchParams);
  const managersToEmpIds =
    getNumericListParam(urlSearchParams, FilterParam.MANAGER) ?? [];

  return (
    <PaginationProvider>
      <ColumnOrderProvider>
        <StatusSelectProvider>
          <div className={classes.root}>
            {compCycle.phases.length > 0 && (
              <PhaseProgressBar compCycle={compCycle} />
            )}
            <ActionBar
              slotLeft={
                <CompCycleFilterBar
                  compCycleId={compCycle.id}
                  filter={filter}
                  onChange={handleFilterChange}
                  onReset={handleFilterReset}
                />
              }
              slotRight={
                <CompCycleSubmitChangesButtonLoadingBoundary
                  compCycleId={compCycle.id}
                  filter={filter}
                />
              }
            />
            <CondensedTablePeopleLoadingBoundary
              compCycleId={compCycle.id}
              filter={filter}
              employeeIds={managersToEmpIds}
              isAdmin={isAdmin}
            />
          </div>
        </StatusSelectProvider>
      </ColumnOrderProvider>
    </PaginationProvider>
  );
}

CondensedTableView.fragments = {
  compCycle: gql`
    ${PhaseProgressBar.fragments.compCycle}
    fragment CondensedTableView_compCycle on CompCycle2 {
      id
      ...PhaseProgressBar_compCycle
    }
  `,
};
